/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Company } from './company';
import { UserRole } from './user-role';
import { UserKYBInfo } from './user-kyb-info';


export interface User { 
    id?: string;
    admin?: boolean;
    enabled?: boolean;
    email?: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
    title?: string;
    buyerCode?: string;
    company?: Company;
    bio?: string;
    createdAt?: string;
    subscriptionAgreement?: boolean;
    kybInfo?: UserKYBInfo;
    activeCampaignsCount?: number;
    activeOrdersCount?: number;
    activeProductsCount?: number;
    role?: UserRole;
}

