import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {CampaignCreateComponent} from "./components/campaign-create/campaign-create.component";
import {QuillModule} from "ngx-quill";
import {CampaignComponent} from "./components/campaign/campaign.component";
import {MatSelectModule} from "@angular/material/select";
import {MatTabsModule} from "@angular/material/tabs";
import {BreadcrumbModule} from "xng-breadcrumb";


@NgModule({
    declarations: [
        CampaignCreateComponent,
        CampaignComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        QuillModule,
        MatSelectModule,
        MatTabsModule,
        BreadcrumbModule
    ],
  providers: [DecimalPipe]
})
export class CampaignModule {
}
