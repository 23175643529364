import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    pure: true,
    name: 'toCamelCase'
})
export class ToCamelCasePipe implements PipeTransform {

    transform(element: string | undefined): string;
    transform(element: string[] | undefined): string[]
    transform(element: any): any {
        if (!element) {
            return undefined;
        } else if (element instanceof Array) {
            return element.map(this.toCamelCase).join(", ");
        } else {
            return this.toCamelCase(element);
        }
    }

    private toCamelCase(value: string): string {
        let separatedWords =  value.split(new RegExp("[ _]"));

        let capitalisedWords = separatedWords.map((part: string) =>
          part.substring(0, 1).toUpperCase().concat(part.substring(1).toLowerCase()));

        let semiwordHandledCapitalizedWords = capitalisedWords.map((part: string) => {
          if (part.includes("&")) {
            //transforms R&d to R&D
            return part.substring(0, part.indexOf("&") + 1)
                .concat(part.substring(part.indexOf("&") + 1, part.indexOf("&") + 2).toUpperCase())
                .concat(part.substring(part.indexOf("&") + 2));
          } else {
            return part;
          }
        });

        return semiwordHandledCapitalizedWords.join(' ');
    }
}
