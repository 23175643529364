import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AttributesSettingsComponent} from './components/attributes-settings/attributes-settings.component';
import {SharedModule} from '../shared/shared.module';
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
  declarations: [
    AttributesSettingsComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        MatTooltipModule
    ]
})
export class AttributesSettingsModule { }
