import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Certification, ProductResponse, ProductAddress, ProductsApiService, CertificationsApiService} from "../../../../api/cs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'cs-product-confirmation-form',
  templateUrl: './product-confirmation-form.component.html',
  styleUrls: ['./product-confirmation-form.component.scss']
})
export class ProductConfirmationFormComponent implements OnInit {


  form: FormGroup;

  private object: ProductAddress | Certification | ProductResponse | undefined;

  type: string;
  description: string;

  @Output() updated = new EventEmitter<boolean>();
  @Output() updatedProduct = new EventEmitter<ProductResponse>();

  constructor(private fb: FormBuilder,
              private dialogRef: MatDialogRef<ProductConfirmationFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                object: ProductAddress | Certification | ProductResponse | undefined,
                type: string},
              private productsApiService: ProductsApiService,
              private certificationsApiService: CertificationsApiService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({});
    this.type = this.data.type;
    this.object = this.data.object;
    if (this.type == 'Address') {
      this.description = 'address';
    } else if (this.type == 'Certificate') {
      this.description = 'certificate';
    } else {
      this.description = 'file';
    }
  }

  submit(): void {
    if (this.type == 'Address') {
      this.deleteAddress(<ProductAddress>this.object);
    } else if (this.type == 'Certificate') {
      this.deleteCertificate(<Certification>this.object);
    } else if (this.type == 'Product Spec Sheet'
        || this.type == 'Safety Data Sheet'
        || this.type == 'Lifecycle Analysis') {
      this.removeProductFile(this.type, <ProductResponse>this.object);
    } else {
      this.dialogRef.close();
    }
  }

  private deleteAddress({id}: ProductAddress) {
    if (id) {
      this.productsApiService.deleteProductAddress({
        id: id
      }).subscribe(() => {
        this.updated.emit(true);
        this.dialogRef.close();
      });
    }
  }

  private deleteCertificate({id}: Certification): void {
    if (id) {
      this.certificationsApiService.deleteCertification({
        id: id
      }).subscribe(() => {
        this.updated.emit(true);
        this.dialogRef.close();
      });
    }
  }

  removeProductFile(fileType: 'Product Spec Sheet' | 'Safety Data Sheet' | 'Lifecycle Analysis',
                    {id}: ProductResponse): void {
    let request;
    if (fileType === 'Product Spec Sheet') {
      request = this.productsApiService.updateProductPss({
        id: id || '',
        fileUpdateRequest: {
          reference: ''
        }
      });
    } else if (fileType === 'Safety Data Sheet') {
      request = this.productsApiService.updateProductSds({
        id: id || '',
        fileUpdateRequest: {
          reference: ''
        }
      });
    } else {
      request = this.productsApiService.updateProductLca({
        id: id || '',
        fileUpdateRequest: {
          reference: ''
        }
      });
    }

    request.subscribe((product: ProductResponse) => {
      this.updatedProduct.emit(product);
      this.dialogRef.close();
    });
  }
}
