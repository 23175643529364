import {Injectable} from '@angular/core';
import {CampaignsApiService, UnitScalingEntity} from "../../api/cs";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UnitsScalingService {

  public list$: Observable<UnitScalingEntity[]>
  private unitMeasureList: UnitScalingEntity[];
  private unitsPoliciesSet: Set<string> = new Set<string>();
  private measurePolicies$: BehaviorSubject<Set<string>> = new BehaviorSubject<Set<string>>(new Set<string>());
  private policyScaling$: BehaviorSubject<string[]>;
  private currentPolicy: string;

  constructor(private campaignsApiService: CampaignsApiService) {
   this.list$ = this.campaignsApiService.getListOfMeasureUnits();
     this.list$.subscribe(res => {
       this.unitMeasureList = res;
       for (let scaling of this.unitMeasureList) {
         if (!scaling.isRetired) {
           this.unitsPoliciesSet.add(scaling.measurePolicy || '');
         }
       }
       this.measurePolicies$.next(this.unitsPoliciesSet);
       if (!this.policyScaling$?.getValue()) {
         this.policyScaling$ = new BehaviorSubject<string[]>(['units']);
       } else {
         this.setPolicyScaling(this.currentPolicy);
       }
    });
  }

  getPolicies(): BehaviorSubject<Set<string>> {
    return this.measurePolicies$;
  }

  setPolicyScaling(policy: string): void  {
    this.currentPolicy = policy;
    let policyScalingValues: string[] = [];
    this.unitMeasureList?.forEach(entity => {
      if (entity.measurePolicy == policy && !entity.isRetired) {
        policyScalingValues.push(entity.measurePluralName || '');
      }
    })
    if (!!this.policyScaling$?.getValue()) {
      this.policyScaling$.next(policyScalingValues);
    } else {
      this.policyScaling$ = new BehaviorSubject<string[]>([]);
    }
  }


  getPolicyScaling(): BehaviorSubject<string[]> {
    return this.policyScaling$;
  }

  getScalingEntityById(id: number): UnitScalingEntity {
    if (!!this.unitMeasureList) {
      for (let scale of this.unitMeasureList) {
        if (scale.id == id) {
          return scale;
        }
      }
    }
    return {};
  }

  getScalingEntityByName(policy: string, name: string): UnitScalingEntity {
    if (!!this.unitMeasureList) {
      for (let scale of this.unitMeasureList) {
        if (scale.measurePolicy == policy &&
          (scale.measurePluralName == name || scale.measureSingularName == name)) {
          return scale || {};
        }
      }
    }
    return {};
  }

  getScalingEntityId(policy: string, name: string): number {
    return this.getScalingEntityByName(policy, name).id || 0;
  }

  isItBigger(dividend: number | undefined, dividendMeasure: UnitScalingEntity | undefined, divisor: number | undefined, divisorMeasure: UnitScalingEntity | undefined): boolean {
    return (dividend || 0) * (dividendMeasure?.measureFactor || 0) >= (divisor || 0) * (divisorMeasure?.measureFactor || 0);
  }

  isItLower(dividend: number | undefined, dividendMeasure: UnitScalingEntity | undefined, divisor: number | undefined, divisorMeasure: UnitScalingEntity | undefined): boolean {
    return (dividend || 0) * (dividendMeasure?.measureFactor || 0) <= (divisor || 0) * (divisorMeasure?.measureFactor || 0);
  }

  isItFullyDivisible(dividend: number | undefined, dividendMeasure: UnitScalingEntity | undefined, divisor: number | undefined, divisorMeasure: UnitScalingEntity | undefined): boolean {
    //This tricky conversion uses to fight with inaccuracy of floating point numbers.
    //TODO make conversion better
    return Number.parseInt(((dividend || 0) * (dividendMeasure?.measureFactor || 0) * 1000000).toString()) %
      Number.parseInt(((divisor || 0) * (divisorMeasure?.measureFactor || 0) * 1000000).toString()) == 0;
  }
}
