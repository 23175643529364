<div class="navigation-container">
  <div class="container">
    <nav class="navbar is-transparent"
        [class.white]="whiteBg"
        role="navigation"
        aria-label="footer navigation"
        *ngIf="!menuShown">
      <div class="navbar-brand">
        <a class="logo-wrapper is-flex py-0" routerLink="/">
          <img class="logo" src="../../../../assets/images/logo-real.svg" width="130" height="32">
        </a>


        <button type="button" class="navbar-burger" mat-button (click)="drawer.toggle();toggleMenu()">
          <mat-icon>menu</mat-icon>
<!--          <div class="badge">4</div>-->
        </button>

      </div>

      <div id="navbarBasicExample"
          class="navbar-menu"
          [class.is-active]="menuShown"
      >
        <div class="navbar-end">

          <a class="navbar-item has-text-weight-semibold" [matMenuTriggerFor]="adminMenu" aria-label="Dropdown menu" *ngIf="isAdmin">
            <span>
              Administration
            </span>
            <mat-icon>expand_more</mat-icon>
          </a>
          <mat-menu #adminMenu="matMenu" class="menu-dropdown-items-list">
            <a mat-menu-item routerLink="/administration/users" routerLinkActive="active" class="left-aligned-text">Users</a>
            <a mat-menu-item routerLink="/administration/sign-ups" routerLinkActive="active" class="left-aligned-text">Sign Ups</a>
            <a mat-menu-item routerLink="/companies" routerLinkActive="active" class="left-aligned-text">Companies</a>
            <a mat-menu-item routerLink="/products" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="left-aligned-text">Products</a>
            <a mat-menu-item routerLink="/products/featured/lists" routerLinkActive="active" class="left-aligned-text">Featured Products</a>
            <a mat-menu-item routerLink="/campaigns" routerLinkActive="active" class="left-aligned-text">Campaigns</a>
            <a mat-menu-item routerLink="/profile/orders" routerLinkActive="active" class="left-aligned-text">Orders</a>
            <hr class="my-1">
            <a mat-menu-item routerLink="/administration/dictionaries" routerLinkActive="active" class="left-aligned-text">Dictionaries</a>
            <a mat-menu-item routerLink="/administration/attributes" routerLinkActive="active" class="left-aligned-text">Attributes</a>
          </mat-menu>

          <a class="navbar-item has-text-weight-semibold" [href]="'/campaigns'" [class.active]="router.url == '/campaigns'" *ngIf="false && !isLoggedIn">
            Campaigns
          </a>

          <a class="navbar-item has-text-weight-semibold" [matMenuTriggerFor]="menuAboutUs" aria-label="Dropdown menu" *ngIf="!isLoggedIn">
            <span>
              About
            </span>
            <mat-icon>expand_more</mat-icon>
          </a>
          <mat-menu #menuAboutUs="matMenu" class="menu-dropdown-items-list medium-width">
            <a mat-menu-item routerLink="/about-us" routerLinkActive="active" class="left-aligned-text">About us</a>
            <a mat-menu-item routerLink="/manifesto" routerLinkActive="active" class="left-aligned-text">Manifesto</a>
          </mat-menu>

          <a class="navbar-item has-text-weight-semibold" [routerLink]="'sign-up-buyer'" [class.active]="router.url == '/sign-up-buyer'" *ngIf="!isLoggedIn">
            Buyers
          </a>
          <a class="navbar-item has-text-weight-semibold" [routerLink]="'sign-up-supplier'" [class.active]="router.url == '/sign-up-supplier'" *ngIf="!isLoggedIn">
            Suppliers
          </a>
          <a class="navbar-item has-text-weight-semibold" [routerLink]="'creators'" [class.active]="router.url == '/creators'">
            Creators
          </a>
          <a class="navbar-item has-text-weight-semibold" [routerLink]="'faq'" [class.active]="router.url == '/faq'" *ngIf="false && !isLoggedIn">
            FAQ
          </a>
          <a class="navbar-item has-text-weight-semibold" [routerLink]="'contact-us'" [class.active]="router.url == '/contact-us'" *ngIf="!isLoggedIn">
            Contact
          </a>
          <a class="navbar-item has-text-weight-semibold" routerLink="/campaigns" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="false && isLoggedIn && !isAdmin">
            Search campaigns
          </a>
          <a class="navbar-item has-text-weight-semibold" routerLink="/dashboard" routerLinkActive="active" *ngIf="isDashboardVisible()">
            Dashboard
          </a>

          <a class="navbar-item btn-active with-white-bg has-text-weight-semibold js-modal-trigger"
            *ngIf="!isLoggedIn && !isAlgae && false"
            (click)="showLogInWindow()"
          >
            Log In
          </a>
          <a class="navbar-item btn-active has-text-weight-semibold js-modal-trigger"
             *ngIf="!isLoggedIn && !isAlgae && false"
             (click)="showSignUpWindow()"
          >
            Sign Up
          </a>

          <button mat-icon-button class="user-menu-dropdown" [matMenuTriggerFor]="menu" aria-label="Dropdown menu" *ngIf="isLoggedIn">
            <span class="circle">
              <img src="/assets/images/new-home/user-icon.png">
            </span>
            <mat-icon>expand_more</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="menu-dropdown-items-list">
            <p *ngIf="!isSupplyChainUser" class="px-4">{{username}}</p>
            <p class="active px-4" [class.active]="!isSupplyChainUser">{{email}}</p>
            <a mat-menu-item *ngIf="!isAdmin && !isSupplyChainUser" routerLink="/companies/{{userCompanyAlias}}" routerLinkActive="active">My Company</a>
            <a mat-menu-item *ngIf="!isAdmin && !isSupplyChainUser" routerLink="/profile/orders" routerLinkActive="active">My Orders</a>
            <a mat-menu-item *ngIf="!isSupplyChainUser" routerLink="/profile" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">My Profile</a>
            <a mat-menu-item class="text-danger" (click)="logout()">Sign out</a>
          </mat-menu>

        </div>
      </div>
    </nav>
  </div>
  <mat-drawer #drawer class="mobile-sidenav" style="display: none" mode="over">
    <div class="mobile-menu-header">
      <h3>Menu</h3>
      <button type="button" class="close" mat-button (click)="drawer.toggle();toggleMenu()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="container">
      <a class="navbar-item list-header" (click)="adminListShow()" *ngIf="isAdmin">Administration
        <mat-icon class="mat-icon-rtl-mirror">
          {{showAdminList ? 'expand_more' : 'chevron_right'}}
        </mat-icon></a>
      <ul class="account-menu" *ngIf="showAdminList && isAdmin">
        <li><a routerLink="/administration/users" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()">Users</a></li>
        <li><a routerLink="/administration/sign-ups" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()">Sign Ups</a></li>
        <li><a routerLink="/companies" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()">Companies</a></li>
        <li><a routerLink="/products" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="drawer.toggle();toggleMenu()">Products</a></li>
        <li><a routerLink="/products/featured/lists" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()">Featured Products</a></li>
        <li><a routerLink="/campaigns" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()">Campaigns</a></li>
        <li><a routerLink="/profile/orders" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()">Orders</a></li>
        <li><a routerLink="/administration/dictionaries" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()">Dictionaries</a></li>
        <li><a routerLink="/administration/attributes" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()">Attributes</a></li>
      </ul>

      <a class="navbar-item " [routerLink]="'/campaigns'" [class.active]="router.url == '/campaigns'" (click)="drawer.toggle();toggleMenu()" *ngIf="false && !isLoggedIn">
        Campaigns
      </a>
      <a class="navbar-item list-header" (click)="accountListShow()" *ngIf="!isLoggedIn">About
        <mat-icon class="mat-icon-rtl-mirror">
          {{showAccountList ? 'expand_more' : 'chevron_right'}}
        </mat-icon></a>
      <ul class="account-menu" *ngIf="showAccountList && !isLoggedIn">
        <li><a [routerLink]="'about-us'" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()">About Us</a></li>
        <li><a [routerLink]="'manifesto'" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()">Manifesto</a></li>
      </ul>
      <a class="navbar-item " [routerLink]="'sign-up-buyer'" [class.active]="router.url == '/sign-up-buyer'" (click)="drawer.toggle();toggleMenu()" *ngIf="!isLoggedIn">
        Buyers
      </a>
      <a class="navbar-item " [routerLink]="'sign-up-supplier'" [class.active]="router.url == '/sign-up-supplier'" (click)="drawer.toggle();toggleMenu()" *ngIf="!isLoggedIn">
        Suppliers
      </a>
      <a class="navbar-item " [routerLink]="'creators'" [class.active]="router.url == '/creators'" (click)="drawer.toggle();toggleMenu()">
        Creators
      </a>
      <a class="navbar-item " [routerLink]="'contact-us'" [class.active]="router.url == '/contact-us'" (click)="drawer.toggle();toggleMenu()" *ngIf="!isLoggedIn">
        Contact
      </a>
      <a class="navbar-item " routerLink="/campaigns" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="drawer.toggle();toggleMenu()" *ngIf="false && isLoggedIn && !isAdmin">
        Search campaigns
      </a>
      <a class="navbar-item " routerLink="/dashboard" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()" *ngIf="isDashboardVisible()">
        Dashboard
      </a>

      <a class="navbar-item btn-active with-white-bg js-modal-trigger"
         *ngIf="!isLoggedIn && !isAlgae && false"
         (click)="showLogInWindow();drawer.toggle();toggleMenu()"
      >
        Log In
      </a>
      <a class="navbar-item btn-active js-modal-trigger"
         *ngIf="!isLoggedIn && !isAlgae && false"
         (click)="showSignUpWindow();drawer.toggle();toggleMenu()"
      >
        Sign Up
      </a>
      <a class="navbar-item list-header" (click)="accountListShow()" *ngIf="isLoggedIn">My account
        <mat-icon class="mat-icon-rtl-mirror">
          {{showAccountList ? 'expand_more' : 'chevron_right'}}
        </mat-icon></a>
      <ul class="account-menu" *ngIf="showAccountList && isLoggedIn">
        <li *ngIf="!isSupplyChainUser"><a (click)="drawer.toggle();toggleMenu()">{{username}}</a></li>
        <li><a (click)="drawer.toggle();toggleMenu()" class="mail">{{email}}</a></li>
        <li *ngIf="!isAdmin && !isSupplyChainUser"><a routerLink="/companies/{{userCompanyAlias}}" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()">My Company</a></li>
        <li *ngIf="!isAdmin && !isSupplyChainUser"><a routerLink="/profile/orders" routerLinkActive="active" (click)="drawer.toggle();toggleMenu()">My Orders</a></li>
        <li *ngIf="!isSupplyChainUser"><a routerLink="/profile" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="drawer.toggle();toggleMenu()">User Profile</a></li>
        <li><a class="text-danger" (click)="logout();drawer.toggle();toggleMenu()">Sign out</a></li>
      </ul>
    </div>
  </mat-drawer>
</div>

