import {Component, EventEmitter, Inject, LOCALE_ID, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, Observable} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {
  Campaign,
  CampaignsApiService,
  CampaignState,
  CompaniesApiService, CompanyProduct,
  CompanyProfile, DiscountCode,
  FilesApiService,
  ProductResponse,
  ShippingCostInterval,
  UsersApiService
} from "../../../api/cs";
import {DictionariesService} from "../../../shared/services/dictionaries.service";
import {DecimalPipe, formatDate} from '@angular/common';
import {ToastService} from "../../../shared/services/toast.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";
import {LoaderService} from "../../../shared/services/loader.service";
import {UnitsScalingService} from "../../../shared/services/units-scaling.service";
import {ValidatorsService} from "../../../core/util/validators.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {AuthService} from "../../../shared/services/auth.service";
import {ConfirmationWindowComponent} from "../../../core/components/confirmation-window/confirmation-window.component";

@Component({
  selector: 'cs-campaign-create',
  templateUrl: './campaign-create.component.html',
  styleUrls: ['./campaign-create.component.scss']
})
export class CampaignCreateComponent implements OnInit {

  form: FormGroup;

  @Output() campaignResponse = new EventEmitter<Campaign>();

  requiredFields = ['title', 'quantity', 'price', 'product', 'deliveryTime', 'duration'];

  campaign: Campaign = {};
  campaign$: Observable<Campaign>;
  companyProducts: CompanyProduct[] = [];
  shippingCosts$: BehaviorSubject<ShippingCostInterval[]>;
  discountCodes: DiscountCode[] = [];
  headerText: string = "Create New Campaign";

  images: File[] = [];
  private specification: File | undefined;

  products: CompanyProduct[] | undefined = [];
  private contract: File;
  duration: number;
  measurePolicies$: BehaviorSubject<Set<string>>;
  measurePolicies: Set<string> = new Set<string>();
  measurePolicy: string = 'Count';
  measureScaling: string[] = ['units'];
  unitMeasure: string = 'units';
  minimumOrderMeasure: string = 'units';
  orderStepMeasure: string = 'units';
  maximumOrderMeasure: string = 'units';
  states = Object.values(CampaignState);
  isAdmin: boolean;
  showSubmitForApprovalButton: boolean;
  selectedState: CampaignState | undefined;
  isCampaignFeatured: boolean = false;
  isChosenStateIsActive: boolean;

  constructor(private fb: FormBuilder,
              private loader: LoaderService,
              private toastService: ToastService,
              private activatedRoute: ActivatedRoute,
              private companiesApiService: CompaniesApiService,
              private dictionariesService: DictionariesService,
              private campaignsApiService: CampaignsApiService,
              private usersApiService: UsersApiService,
              private unitsScalingService: UnitsScalingService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<CampaignCreateComponent>,
              private decimalPipe: DecimalPipe,
              @Inject(LOCALE_ID) private locale: string,
              @Inject(MAT_DIALOG_DATA) private data: {
                company: CompanyProfile;
                campaign?: Campaign | null;
              },
              private fileService: FilesApiService,
              private sanitizer: DomSanitizer,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
    this.isAdmin = this.authService.isAdmin();
    if (!!this.data.campaign) {
      this.campaign$ = this.campaignsApiService.getCampaign({id: this.data.campaign?.alias || ''});
      this.campaign$.subscribe(campaign => {
        this.campaign = campaign;
        this.initForm();
        this.campaignsApiService.getCampaignDiscounts({
          alias: this.campaign.alias || ''
        }).subscribe((discounts: DiscountCode[]) => {
          this.discountCodes = discounts;
          this.setDiscountCodes()
        });
        this.selectedState = this.campaign.state;
        this.isChosenStateIsActive = this.selectedState == CampaignState.ACTIVE;
        if (!this.measurePolicies.has(this.measurePolicy)) {
          this.measurePolicies.add(this.measurePolicy);
        }
        this.setPolicy(this.campaign?.measurePolicy || '');
        this.showSubmitForApprovalButton = campaign.state === CampaignState.DRAFT;
      });
    } else {
      this.initForm();
      this.setPolicy('Count');
    }
  }

  initForm() {
    this.companyProducts = this.data.company.products || [];
    this.shippingCosts$ = new BehaviorSubject<ShippingCostInterval[]>(this.campaign?.shippingCostIntervals || [{}]);
    this.measurePolicies$ = this.unitsScalingService?.getPolicies();
    this.measurePolicies$.subscribe(policies => this.measurePolicies = policies);
    this.isCampaignFeatured = this.data.campaign?.isFeatured || false;
    this.form = this.fb.group({
      title: new FormControl(this.campaign?.title || '', Validators.required),
      subtitle: new FormControl(this.campaign?.subtitle || ''),
      shortDescription: new FormControl(this.campaign?.shortDescription || ''),
      country: new FormControl(this.campaign?.originCountry || ''),
      location: new FormControl(this.campaign?.location || ''),
      images: new FormControl([]),
      imageRef: new FormControl(''),
      video: new FormControl(this.campaign?.video || '', Validators.pattern('^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube(-nocookie)?\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$')),
      measurePolicy: new FormControl(this.campaign?.measurePolicy?.valueOf() || this.measurePolicy, Validators.required),
      quantity: new FormControl(this.campaign?.unitCount || '', [Validators.pattern('^[0-9]*[.,]?[0-9]+$'), Validators.required, Validators.min(0.001), Validators.max(9999999.99)]),
      unitMeasure: new FormControl(this.campaign?.unitMeasure?.measurePluralName || this.measureScaling[0]),
      minQuantity: new FormControl(this.campaign?.minimumOrder || '', [Validators.pattern('^[0-9]*[.,]?[0-9]+$'), Validators.required, Validators.min(0.001), Validators.max(9999999.99)]),
      minUnitMeasure: new FormControl(this.campaign?.minimumOrderMeasure?.measurePluralName || this.measureScaling[0], Validators.required),
      maxQuantity: new FormControl(this.campaign.maximumOrder || ''),
      maxUnitMeasure: new FormControl(this.campaign?.maximumOrderMeasure?.measurePluralName || this.measureScaling[0], Validators.required),
      stepQuantity: new FormControl(this.campaign?.orderStep || '', [Validators.pattern('^[0-9]*[.,]?[0-9]+$'), Validators.required, Validators.min(0.001), Validators.max(9999999.99)]),
      stepMeasure: new FormControl(this.campaign?.orderStepMeasure?.measurePluralName || this.measureScaling[0], Validators.required),
      price: new FormControl(this.campaign?.unitPrice?.toFixed(2) || '', [Validators.required, Validators.pattern('^[0-9]{1,7}(?:\\.[0-9]{0,2})?$'), Validators.min(0.01), Validators.max(9999999.99)]),
      unitCurrency: new FormControl(this.campaign?.unitCurrency || '$'),
      goal: new FormControl((this.campaign?.goalAmount) || ''),
      sampleCost: new FormControl(this.campaign?.sampleCost || ''),
      sampleDescription: new FormControl(this.campaign?.sampleDescription || ''),
      description: new FormControl(this.campaign?.description || ''),
      specification: new FormControl(this.campaign?.specification || ''),
      specSheetFile: new FormControl(this.campaign?.specSheetFileRef || ''),
      specSheetFileRef: new FormControl(''),
      faq: new FormControl(this.campaign?.faq || ''),
      howItsMade: new FormControl(this.campaign?.howItsMade || ''),
      meetTheTeam: new FormControl(this.campaign?.meetTheTeam || ''),
      contract: new FormControl(''),
      contractRef: new FormControl(''),
      paymentTerms: new FormControl(this.campaign?.paymentTerms || ''),
      name: new FormControl((this.data.company?.contact?.firstName || '') + " " + (this.data.company?.contact?.lastName || '')),
      email: new FormControl(this.data.company?.contact?.email),
      bio: new FormControl(this.data.company?.contact?.title),
      product: new FormControl(this.data.campaign?.productId || (this.companyProducts[0]?.id), Validators.required),
      companyProducts: Array.of(this.companyProducts),
      sequenceNumber: new FormControl(this.campaign.sequenceNumber || '')
    })
    if (!!this.data.campaign) {
      this.addExistingImageToEditing();
      this.addExistingSpecSheetToEditing();
      this.addExistingContractTemplateToEditing();
      this.headerText = this.data.campaign.title + " Editing";
      this.setPolicy(this.campaign.measurePolicy || '');
      this.form.addControl('date', new FormControl(this.campaign.deliveryDate));
      this.form.addControl('duration', new FormControl(this.calcDeliveryDuration(), [Validators.pattern('^[0-9]*'), Validators.required]));
      this.form.addControl('deliveryTime', new FormControl(this.campaign?.deliveryTime || '', [Validators.pattern('^[0-9]*'), Validators.required]));
    } else {
      this.form.addControl('date', new FormControl(this.getCurrentDate()));
      this.form.addControl('duration', new FormControl('', [Validators.pattern('^[0-9]*'), Validators.required]));
      this.form.addControl('deliveryTime', new FormControl('', [Validators.pattern('^[0-9]*'), Validators.required]));
    }
    this.form.addControl('state', new FormControl(this.campaign.state || CampaignState.DRAFT));
    if (!!this.campaign.shippingCostIntervals && this.campaign.shippingCostIntervals.length > 0) {
      for (let i = 0; i < this.campaign.shippingCostIntervals?.length; i++) {
        this.form.addControl('lowShippingLimit_' + i, new FormControl(this.campaign.shippingCostIntervals[i].lowLimit, Validators.required));
        this.form.addControl('lowShippingLimitMeasure_' + i, new FormControl(this.unitsScalingService.getScalingEntityById(this.campaign.shippingCostIntervals[i].lowLimitMeasureId || 0)?.measurePluralName, Validators.required));
        this.form.addControl('highShippingLimit_' + i, new FormControl(this.campaign.shippingCostIntervals[i].highLimit, Validators.required));
        this.form.addControl('highShippingLimitMeasure_' + i, new FormControl(this.unitsScalingService.getScalingEntityById(this.campaign.shippingCostIntervals[i].highLimitMeasureId || 0)?.measurePluralName, Validators.required));
        this.form.addControl('shippingCost_' + i, new FormControl(this.campaign.shippingCostIntervals[i].price, Validators.required));
      }
    } else {
      this.form.addControl('lowShippingLimit_0', new FormControl(this.campaign.maximumOrder, Validators.required));
      this.form.addControl('lowShippingLimitMeasure_0', new FormControl(this.campaign.minimumOrderMeasure?.measurePluralName || 'units', Validators.required));
      this.form.addControl('highShippingLimit_0', new FormControl(this.campaign.maximumOrder, Validators.required));
      this.form.addControl('highShippingLimitMeasure_0', new FormControl(this.campaign.maximumOrderMeasure?.measurePluralName || 'units', Validators.required));
      this.form.addControl('shippingCost_0', new FormControl(0, Validators.required));
    }
  }

  private addExistingImageToEditing() {
    if (this.campaign.images) {
      for (let i = 0; i < this.campaign.images.length; i++) {
        let image = this.campaign.images[i];
        this.fileService.getFileContent({reference: image.reference || '', isImage: true}).subscribe(
          data => {
            let file = new File([data], `image-${image.id}`);
            this.images[i] = file;
            this.form.get('images')?.setValue(this.images);
        });
      }
    }
  }

  private addExistingSpecSheetToEditing() {
    if (this.campaign.specSheetFileRef) {
      this.fileService.getFileContent({reference: this.campaign.specSheetFileRef || '', isImage: false}).subscribe(
        data => {
          this.specification = new File([data], `${this.campaign.alias}-spec-sheet`);
          this.form.get('specSheetFile')?.setValue(this.specification);
        }
      )
    }
  }

  public dropSpecification() {
    this.specification = undefined;
  }

  private addExistingContractTemplateToEditing(): void {
    if (this.campaign.template_key) {
      this.campaignsApiService.getTemplateContract({alias: this.campaign.alias || ''}).subscribe(
        data => {
          this.contract = new File([data], `${this.campaign.alias}-contract-template`);
          this.form.get('contract')?.setValue(this.contract);
        });
    }
  }

  submit() {
    for (let field of this.requiredFields) {
      this.form.get(field)!.markAsTouched();
    }
    let invalidFields = Array.of(document
      .getElementById("campaign-form")!
      .getElementsByClassName('ng-invalid'));
    if (invalidFields[0].length > 0) {
      invalidFields[0][invalidFields.length - 1].scrollIntoView({block: "center"});
      return;
    }
    if (!this.data.campaign) {
      this.campaignsApiService.createCampaign({
        images: this.form.value.images,
        contractTemplate: this.form.value.contract,
        specSheet: this.form.value.specSheetFile,
        campaignCreateRequest: JSON.stringify({
          companyId: this.data.company.id,
          title: this.form.value.title,
          subtitle: this.form.value.subtitle,
          shortDescription: this.form.value.shortDescription,
          originCountry: this.form.value.country,
          location: this.form.value.location,
          video: this.getVideoLink(),
          measurePolicy: this.measurePolicy,
          unitCount: this.form.value.quantity,
          unitMeasure: this.unitsScalingService.getScalingEntityId(this.measurePolicy, this.form.value.unitMeasure),
          minimumOrder: this.form.value.minQuantity,
          minimumOrderMeasure: this.unitsScalingService.getScalingEntityId(this.measurePolicy, this.form.value.minUnitMeasure),
          maximumOrder: this.form.value.maxQuantity,
          maximumOrderMeasure: this.unitsScalingService.getScalingEntityId(this.measurePolicy, this.form.value.maxUnitMeasure),
          orderStep: this.form.value.stepQuantity,
          orderStepMeasure: this.unitsScalingService.getScalingEntityId(this.measurePolicy, this.form.value.stepMeasure),
          unitPrice: this.form.value.price,
          unitCurrency: this.form.value.unitCurrency,
          goalAmount: this.form.value.goal,
          shippingCostIntervals: this.getShippingCostsValues(),
          discountCodes: this.discountCodes,
          sampleCost: this.form.value.sampleCost,
          sampleDescription: this.form.value.sampleDescription,
          deliveryDate: this.form.value.date,
          deliveryTime: this.form.value.deliveryTime,
          description: this.form.value.description,
          specification: this.form.value.specification,
          faq: this.form.value.faq,
          howItsMade: this.form.value.howItsMade,
          meetTheTeam: this.form.value.meetTheTeam,
          paymentTerms: this.form.value.paymentTerms,
          name: this.form.value.name,
          email: this.form.value.email,
          bio: this.form.value.bio,
          productId: this.form.value.product,
          sequenceNumber: this.form.value.sequenceNumber,
          isFeatured: this.isCampaignFeatured
        })
      }).subscribe({
        next: (res) => {
          this.dialogRef.close();
          this.toastService.success("Campaign has been created");
          this.campaignResponse.emit(res);
          this.data.company.campaigns?.push(res);
        },
        error: (err) => {
          console.log(err);
          if (+err.status == 417) {
            this.toastService.error("Sorry, selected product should be active");
          }
        }
      })
    } else {
      this.dropCampaignFromList(this.campaign.productId);
      this.campaignsApiService.updateCampaign({
        id: this.campaign.alias || '',
        images: this.form.value.images,
        contractTemplate: this.form.value.contract,
        specSheet: this.form.value.specSheetFile,
        campaignUpdateRequest: JSON.stringify({
          companyId: this.data.company.id,
          title: this.form.value.title,
          subtitle: this.form.value.subtitle,
          shortDescription: this.form.value.shortDescription,
          originCountry: this.form.value.country,
          location: this.form.value.location,
          video: this.getVideoLink(),
          measurePolicy: this.measurePolicy,
          unitCount: this.form.value.quantity,
          unitMeasure: this.unitsScalingService.getScalingEntityId(this.measurePolicy, this.form.value.unitMeasure),
          minimumOrder: this.form.value.minQuantity,
          minimumOrderMeasure: this.unitsScalingService.getScalingEntityId(this.measurePolicy, this.form.value.minUnitMeasure),
          maximumOrder: this.form.value.maxQuantity,
          maximumOrderMeasure: this.unitsScalingService.getScalingEntityId(this.measurePolicy, this.form.value.maxUnitMeasure),
          orderStep: this.form.value.stepQuantity,
          orderStepMeasure: this.unitsScalingService.getScalingEntityId(this.measurePolicy, this.form.value.stepMeasure),
          unitPrice: this.form.value.price,
          unitCurrency: this.form.value.unitCurrency,
          goalAmount: this.form.value.goal,
          shippingCostIntervals: this.getShippingCostsValues(),
          discountCodes: this.discountCodes,
          sampleCost: this.form.value.sampleCost,
          sampleDescription: this.form.value.sampleDescription,
          deliveryDate: this.form.value.date,
          deliveryTime: this.form.value.deliveryTime,
          description: this.form.value.description,
          specification: this.form.value.specification,
          faq: this.form.value.faq,
          howItsMade: this.form.value.howItsMade,
          meetTheTeam: this.form.value.meetTheTeam,
          paymentTerms: this.form.value.paymentTerms,
          name: this.form.value.name,
          email: this.form.value.email,
          bio: this.form.value.bio,
          productId: this.form.value.product,
          state: this.form.value.state,
          sequenceNumber: this.form.value.sequenceNumber,
          isFeatured: this.isCampaignFeatured
        })
      }).subscribe({
        next: (res) => {
          this.dialogRef.close();
          this.toastService.success("Campaign has been updated");
          this.campaignResponse.emit(res);
          this.data.company.campaigns?.push(res);
        },
        error: (err) => {
          console.log(err);
        }
      })
    }
  }

  confirmClose(): void {
    let confirmation = this.dialog.open(ConfirmationWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        header: "Window Close Confirmation",
        description: "discard changes and close the window"}
    });
    confirmation.beforeClosed()?.subscribe(() => {
      if (confirmation.componentInstance.confirmed.getValue()) {
        this.dialogRef.close();
      }
    })
  }

  /**
   * Adds prefix to link.
   * Without prefix link is always linked to Cultured Supply Home Page
   * @param link external link
   */
  getValidExternalLink(link: string): string {
    let validLink: string = '';
    if (!link) {
      return validLink;
    }
    if (link.startsWith("https://")) {
      validLink = link;
    } else if (link.startsWith("http://")) {
      validLink = link.replace("http://", "https://")
    } else {
      validLink = "https://" + link;
    }
    return validLink;
  }

  /**
   * Transforms link to the youtube page or link to the youtube video into embedded video link
   */
  getVideoLink(): string {
    let value: string = this.getValidExternalLink(this.form.value.video);
    if (value.includes("&")) {
      value = value.substring(0, value.indexOf("&")); //cuts off query params
    }
    if (value.includes("youtu.be")) {
      value = value.replace("youtu.be", "youtube.com/embed");
    }
    if (value.includes("youtube.com/watch?v=")) {
      value = value.replace("watch?v=", "embed/")
    }
    return value;
  }

  /**
   * Removes campaign from list of existing campaigns.
   * Prevent campaign duplicate after campaign updating
   * @param id - product id
   */
  dropCampaignFromList(id: string | undefined): void {
    let newCampaignList: Campaign[] = [];
    this.data.company.campaigns?.forEach(campaign => {
      if (campaign.productId != id) {
        newCampaignList.push(campaign);
      }
    })
    this.data.company.campaigns = newCampaignList;
  }

  getCurrentDate() {
    return formatDate(Date.now(), 'yyyy-MM-dd', this.locale);
  }

  get baseUrl(): string {
    return environment.baseUrl;
  }

  getImagePreview(index: number): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.images[index])) ?? '';
  }

  get termsOfUseFilename(): string {
    return this.contract?.name ?? '';
  }

  get specificationFilename(): string {
    return this.specification?.name ?? '';
  }

  onFileChoose({target}: Event, source: string): void {
    const fileInput = target as HTMLInputElement;
    const files = fileInput.files;
    if ((files?.length || 0) > 0) {
      if ((files?.item(0)?.size || 0) > 20_000_000) {
        this.form.controls[source + "Ref"].setErrors({"too-big": true});
        return;
      } else if (files && files?.length > 6 || this.images.length >= 6) {
        this.form.controls[source + "Ref"].setErrors({"big-amount-files": true});
        return;
      } else {
        this.form.controls[source + "Ref"].setErrors(null);
      }
    }
    if (files instanceof FileList && files.length) {
      this.handleFiles(files, source);
      fileInput.value = '';
    }
  }

  handleFiles(files: FileList, source: string): void {
    this.loader.show();
    let file = files[0];
    switch (source) {
      case "image": {
        let filesArray = Array.from(files);
        filesArray.forEach(file => this.images.push(file));
        this.form.get('images')?.setValue(this.images);
        break;
      }
      case "contract": {
        this.contract = file;
        this.form.get('contract')?.setValue(file);
        break;
      }
      case "specSheetFile": {
        this.specification = file;
        this.form.get('specSheetFile')?.setValue(file);
        break;
      }
    }
    this.loader.hide();

  }

  /**
   * Calculates goal amount of money based on number of products and its price.
   */
  calcGoal(): void {
    let price = parseFloat(this.form.get('price')?.value);
    let quantity = parseFloat(this.form.get('quantity')?.value);

    let goal = +price * +quantity;
    this.form.get('goal')?.setValue(Number.isNaN(goal) ? 0 : goal);
  }

  checkLimits(): void {
    let minOrderAmount = parseFloat(this.form.value.minQuantity);
    let minOrderMeasure = this.unitsScalingService.getScalingEntityById(this.unitsScalingService.getScalingEntityId(this.measurePolicy, this.form.value.minUnitMeasure));
    let maxOrderAmount = parseFloat(this.form.value.maxQuantity);
    let maxOrderMeasure = this.unitsScalingService.getScalingEntityById(this.unitsScalingService.getScalingEntityId(this.measurePolicy, this.form.value.maxUnitMeasure));
    let stepOrderAmount = parseFloat(this.form.value.stepQuantity);
    let stepOrderMeasure = this.unitsScalingService.getScalingEntityById(this.unitsScalingService.getScalingEntityId(this.measurePolicy, this.form.value.stepMeasure));

    let isMinimumLowerThanMaximum = this.unitsScalingService.isItLower(
      minOrderAmount,
      minOrderMeasure,
      maxOrderAmount,
      maxOrderMeasure
    );
    let isStepLowerThanMinimum = this.unitsScalingService.isItLower(
      stepOrderAmount,
      stepOrderMeasure,
      minOrderAmount,
      minOrderMeasure
    );
    let isMinimumOrderDivisible = this.unitsScalingService.isItFullyDivisible(
      minOrderAmount,
      minOrderMeasure,
      stepOrderAmount,
      stepOrderMeasure
    );
    let isMaximumOrderDivisible = this.unitsScalingService.isItFullyDivisible(
      maxOrderAmount,
      maxOrderMeasure,
      stepOrderAmount,
      stepOrderMeasure
    );

    if (!isMinimumLowerThanMaximum) {
      this.form.controls['minQuantity'].setErrors({'bigger-than-max': true});
      this.form.controls['maxQuantity'].setErrors({'lower-than-min': true});
      this.form.controls['stepQuantity'].setErrors(null);
    } else if (!isStepLowerThanMinimum) {
      this.form.controls['minQuantity'].setErrors({'lower-than-step': true});
      this.form.controls['maxQuantity'].setErrors(null);
      this.form.controls['stepQuantity'].setErrors({'bigger-than-min': true});
    } else if (!isMinimumOrderDivisible) {
      this.form.controls['minQuantity'].setErrors({'non-divided': true});
      this.form.controls['maxQuantity'].setErrors(null);
      this.form.controls['stepQuantity'].setErrors({'min-non-divided': true});
    } else if (!isMaximumOrderDivisible) {
      this.form.controls['minQuantity'].setErrors(null);
      this.form.controls['maxQuantity'].setErrors({'non-divided': true});
      this.form.controls['stepQuantity'].setErrors({'max-non-divided': true});
    } else {
      this.form.controls['minQuantity'].setErrors(null);
      this.form.controls['maxQuantity'].setErrors(null);
      this.form.controls['stepQuantity'].setErrors(null);
    }
  }

  /**
   * Transforms number of days to a specified date that will be after this count of days will be expired.
   */
  calcDeliveryDate(): void {
    let duration = parseInt(this.form.get('duration')?.value);
    this.form.get('date')?.setValue(this.convertDurationToDate(duration));
  }

  private convertDurationToDate(duration: number) {
    if (Number.isNaN(duration)) {
      return formatDate(Date.now(), 'yyyy-MM-dd', this.locale);
    } else {
      return formatDate(Date.now() + duration * 24 * 60 * 60 * 1000, 'yyyy-MM-dd', this.locale);
    }
  }

  calcDeliveryDuration(): number {
    return this.convertFinishDateToDuration(this.campaign.deliveryDate || '');
  }

  convertFinishDateToDuration(finishDate: string) {
    return Math.round((Date.parse(finishDate) - Date.now()) / (1000 * 60 * 60 * 24));
  }

  setPolicy(policy: string): void {
    this.measurePolicy = policy;
    this.unitsScalingService.setPolicyScaling(policy);
    this.unitsScalingService.getPolicyScaling().subscribe(scaling => this.measureScaling = scaling);
    if (!!this.data?.campaign && !this.measureScaling.includes(this.data?.campaign?.unitMeasure?.measurePluralName || '')) {
      this.measureScaling.push(this.data?.campaign?.unitMeasure?.measurePluralName || '');
    }
    if (!!this.campaign && this.campaign.measurePolicy != this.measurePolicy) {
      let measureUnitValue = this.measureScaling[0] || 'units';
      this.form.setControl('minUnitMeasure', new FormControl(measureUnitValue, Validators.required));
      this.form.setControl('maxUnitMeasure', new FormControl(measureUnitValue, Validators.required));
      this.form.setControl('stepMeasure', new FormControl(measureUnitValue, Validators.required));
      this.form.setControl('unitMeasure', new FormControl(measureUnitValue, Validators.required));
      for (let i = 0; i < this.shippingCosts$.getValue().length; i++) {
        if (i == 0) {
          this.form.get("lowShippingLimitMeasure_" + i)?.setValue(measureUnitValue);
        }
        this.form.get("highShippingLimitMeasure_" + i)?.setValue(measureUnitValue);
      }
    } else {
      this.form.setControl('minUnitMeasure', new FormControl(this.campaign?.minimumOrderMeasure?.measurePluralName || this.measureScaling[0], Validators.required));
      this.form.setControl('maxUnitMeasure', new FormControl(this.campaign?.maximumOrderMeasure?.measurePluralName || this.measureScaling[0], Validators.required));
      this.form.setControl('stepMeasure', new FormControl(this.campaign?.orderStepMeasure?.measurePluralName || this.measureScaling[0], Validators.required));
      this.form.setControl('unitMeasure', new FormControl(this.campaign?.unitMeasure?.measurePluralName || this.measureScaling[0], Validators.required));
    }
  }

  setUnitMeasure(scaling: string): void {
    this.unitMeasure = scaling;
  }

  setMinimumOrderMeasure(scaling: string): void {
    this.minimumOrderMeasure = scaling;
    if (this.form.value.minUnitMeasure == scaling && this.form.get("lowShippingLimitMeasure_" + (0))?.value != scaling) {
      this.form.get("lowShippingLimitMeasure_" + (0))?.setValue(scaling);
    }
    this.checkLimits();
  }

  setMaximumOrderMeasure(scaling: string): void {
    this.maximumOrderMeasure = scaling;
    if (this.form.value.maxUnitMeasure == scaling && this.form.get("highShippingLimitMeasure_" + (this.shippingCosts$.getValue().length - 1))?.value != scaling) {
      this.form.get("highShippingLimitMeasure_" + (this.shippingCosts$.getValue().length - 1))?.setValue(scaling);
    }
    this.checkLimits();
  }

  setOrderStepMeasure(scaling: string): void {
    this.orderStepMeasure = scaling;
    this.checkLimits();
  }

  onlyEnglish($event: KeyboardEvent) {
    ValidatorsService.onlyEnglish($event);
  }

  onlyNumbers($event: KeyboardEvent) {
    ValidatorsService.onlyNumbers($event);
  }

  getCurrentStateDefinition(state: CampaignState) {
      return state?.substring(0, 1) + state?.replace(/_/g, ' ').substring(1, state?.length).toLowerCase();
  }

  submitForApproval() {
    this.form.get('state')?.setValue(CampaignState.SUBMITTED_FOR_APPROVAL);
    this.submit();
  }

  private getShippingCostsValues(): ShippingCostInterval[] {
    let shippingCosts: ShippingCostInterval[] = [];
    for (let i = 0; i < this.shippingCosts$.getValue().length; i++) {
      shippingCosts.push({
        campaign_id: Number.parseInt(this.data.campaign?.id || '') || 0,
        lowLimit: this.form.get("lowShippingLimit_" + i)?.value,
        lowLimitMeasureId: this.unitsScalingService.getScalingEntityByName(this.measurePolicy || '', this.form.get("lowShippingLimitMeasure_" + (i))?.value).id,
        highLimit: this.form.get("highShippingLimit_" + i)?.value,
        highLimitMeasureId: this.unitsScalingService.getScalingEntityByName(this.measurePolicy || '', this.form.get("highShippingLimitMeasure_" + (i))?.value).id,
        price: this.form.get("shippingCost_" + i)?.value
      });
    }
    return shippingCosts;
  }

  setLowShippingLimit(event: any): void {
    this.form.get("lowShippingLimit_" + (0))?.setValue(event);
  }

  setHighShippingLimit(event: any): void {
    this.form.get("highShippingLimit_" + (this.shippingCosts$.getValue().length - 1))?.setValue(event);
  }

  addShippingCost(after: number): void {
    let shippingCosts = this.shippingCosts$.getValue();
    shippingCosts.push(shippingCosts[after]);

    let length = shippingCosts.length;
    this.form.addControl('lowShippingLimit_' + (length - 1), new FormControl(this.form.value.maxQuantity, Validators.required));
    this.form.addControl('lowShippingLimitMeasure_' + (length - 1), new FormControl(this.form.value.maxUnitMeasure, Validators.required));
    this.form.addControl('highShippingLimit_' + (length - 1), new FormControl(this.form.value.maxQuantity, Validators.required));
    this.form.addControl('highShippingLimitMeasure_' + (length - 1), new FormControl(this.form.value.maxUnitMeasure, Validators.required));
    this.form.addControl('shippingCost_' + (length - 1), new FormControl(this.form.get("shippingCost_" + after)?.value, Validators.required));

    this.shippingCosts$.next(shippingCosts);
  }

  deleteShippingCost(number: number): void {
    let shippingCosts = this.shippingCosts$.getValue();
    shippingCosts.splice(number, 1);

    this.form.get("highShippingLimit_" + (shippingCosts.length - 1))?.setValue(this.campaign.maximumOrder);
    this.form.get("highShippingLimitMeasure_" + (shippingCosts.length - 1))?.setValue(this.campaign.maximumOrderMeasure?.measurePluralName);

    this.form.removeControl('lowShippingLimit_' + (shippingCosts.length));
    this.form.removeControl('lowShippingLimitMeasure_' + (shippingCosts.length));
    this.form.removeControl('highShippingLimit_' + (shippingCosts.length));
    this.form.removeControl('highShippingLimitMeasure_' + (shippingCosts.length));
    this.form.removeControl('shippingCost_' + (shippingCosts.length));
    this.shippingCosts$.next(shippingCosts);
  }

  lowLimitChanged(event: any, number: number): void {
    let isItLower = this.unitsScalingService.isItLower(
      event,
      this.unitsScalingService.getScalingEntityByName(this.campaign.measurePolicy || '', this.form.get("lowShippingLimitMeasure_" + (number))?.value),
      this.form.get("highShippingLimit_" + (number))?.value,
      this.unitsScalingService.getScalingEntityByName(this.campaign.measurePolicy || '', this.form.get("highShippingLimitMeasure_" + (number))?.value)
    )
    this.setLowLimitError(number, !isItLower);
    if (number != 0 && this.form.get("highShippingLimit_" + (number - 1))?.value != event) {
      this.form.get("highShippingLimit_" + (number - 1))?.setValue(event);
    }
  }

  lowLimitMeasureChanged(event: any, number: number): void {
    let isItLower = this.unitsScalingService.isItLower(
      this.form.get("lowShippingLimit_" + (number))?.value,
      this.unitsScalingService.getScalingEntityByName(this.campaign.measurePolicy || '', event.source.value),
      this.form.get("highShippingLimit_" + (number))?.value,
      this.unitsScalingService.getScalingEntityByName(this.campaign.measurePolicy || '', this.form.get("highShippingLimitMeasure_" + (number))?.value)
    )
    this.setLowLimitError(number, !isItLower);
    if (number != 0 && this.form.get("highShippingLimitMeasure_" + (number - 1))?.value != event.source.value) {
      this.form.get("highShippingLimitMeasure_" + (number - 1))?.setValue(event.source.value);
    }
  }

  highLimitChanged(event: any, number: number): void {
    let isItBigger = this.unitsScalingService.isItBigger(
      event,
      this.unitsScalingService.getScalingEntityByName(this.campaign.measurePolicy || '', this.form.get("highShippingLimitMeasure_" + (number))?.value),
      this.form.get("lowShippingLimit_" + (number))?.value,
      this.unitsScalingService.getScalingEntityByName(this.campaign.measurePolicy || '', this.form.get("lowShippingLimitMeasure_" + (number))?.value),
    )
    this.setLowLimitError(number, !isItBigger);
    if (number != (this.shippingCosts$.getValue().length - 1) && this.form.get("lowShippingLimit_" + (number + 1))?.value != event) {
      this.form.get("lowShippingLimit_" + (number + 1))?.setValue(event);
    }
  }

  highLimitMeasureChanged(event: any, number: number): void {
    let isItBigger = this.unitsScalingService.isItBigger(
      this.form.get("highShippingLimit_" + (number))?.value,
      this.unitsScalingService.getScalingEntityByName(this.campaign.measurePolicy || '', event.source.value),
      this.form.get("lowShippingLimit_" + (number))?.value,
      this.unitsScalingService.getScalingEntityByName(this.campaign.measurePolicy || '', this.form.get("lowShippingLimitMeasure_" + (number))?.value),
    )
    this.setLowLimitError(number, !isItBigger);
    if (number != (this.shippingCosts$.getValue().length - 1) && this.form.get("lowShippingLimitMeasure_" + (number + 1))?.value != event.source.value) {
      this.form.get("lowShippingLimitMeasure_" + (number + 1))?.setValue(event.source.value);
    }
  }

  setLowLimitError(number: number, isError: boolean) {
    if (isError) {
      this.form.controls['lowShippingLimit_' + number].setErrors({'invalid-limits': true});
      this.form.controls['highShippingLimit_' + number].setErrors({'invalid-limits': true});
    } else {
      this.form.controls['lowShippingLimit_' + number].setErrors(null);
      this.form.controls['highShippingLimit_' + number].setErrors(null);
    }
  }

  private setDiscountCodes(): void {
    this.discountCodes.forEach((discountCode, index) => {
      this.form.addControl("discountCode_" + index, new FormControl(discountCode.discountCode, Validators.required));
      this.form.addControl("discountValue_" + index, new FormControl(discountCode.amountDiscountValue, Validators.required));
      this.form.addControl("expirationTime_" + index, new FormControl(this.convertFinishDateToDuration(discountCode.expirationDate || ''), Validators.required));
    })
  }

  public discountCodeChanged(event: any, number: number): void {
    this.discountCodes[number].discountCode = event;
    this.checkDiscountCodeDuplicate(event, number);
  }

  private checkDiscountCodeDuplicate(event: any, number: number): void {
    for (let i = 0; i < this.discountCodes.length; i++) {
      if (i == number) {
        continue;
      }
      if (this.form.get("discountCode_" + i)?.value != event) {
        this.form.controls['discountCode_' + number].setErrors(null);
      } else {
        this.form.controls["discountCode_" + number].setErrors({'duplicate-code': true});
      }
    }
  }

  public discountValueChanged(event: any, number: number): void {
    this.discountCodes[number].amountDiscountValue = event;
  }

  public expirationTimeChanged(event: any, number: number): void {
    this.discountCodes[number].expirationDate = this.convertDurationToDate(event);
  }

  public deleteDiscountCode(number: number): void {
    this.discountCodes.splice(number, 1);
  }

  public addDiscountCode(): void {
    this.discountCodes.push({
      campaignId: Number.parseInt(this.campaign.id || ''),
      discountCode: undefined,
      amountDiscountValue: undefined,
      expirationDate: undefined
    });
    this.form.addControl("discountCode_" + (this.discountCodes.length - 1), new FormControl('', Validators.required));
    this.form.addControl("discountValue_" + (this.discountCodes.length - 1), new FormControl('', [Validators.required, Validators.max(100)]));
    this.form.addControl("expirationTime_" + (this.discountCodes.length - 1), new FormControl('', Validators.required));
  }

  setSelectedState(event: any): void {
    if (!this.selectedState || event.isUserInput) {
      this.selectedState = <CampaignState>event.source.value;
      this.isChosenStateIsActive = this.selectedState == CampaignState.ACTIVE;
    }
  }
}
