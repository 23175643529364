import {Component, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PaymentApiService, User, UsersApiService} from "../../api/cs";
import {StripeElements, StripeElementsOptions} from "@stripe/stripe-js";
import {StripePaymentElementComponent, StripeService} from "ngx-stripe";
import {PaymentResultStatus, PaymentService} from "../payment.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {AuthService} from "../../shared/services/auth.service";

@Component({
  selector: 'cs-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Input() paymentAmount: number;
  @Input() receiptEmail: string;
  @Output() paymentMethod = new BehaviorSubject<string | null>(null);
  @Output() paymentIntentId = new BehaviorSubject<string | null>(null);

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  elements: StripeElements;
  payment$: Subscription;
  isUserSubscribed: boolean;
  intentToSubscribe: boolean = false;

  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent;

  constructor(private paymentService: PaymentApiService,
              private paymentProcessService: PaymentService,
              private stripeService: StripeService,
              private authService: AuthService,
              private userService: UsersApiService) {
  }

  ngOnInit(): void {
    this.authService.userInfo.subscribe(user => {
      this.isUserSubscribed = user?.subscriptionAgreement || false;
    });
    if (this.paymentAmount !== undefined) {
      this.paymentService.createPaymentIntent({
        paymentIntentRequest: {
          amount: this.paymentAmount * 100,
          currency: 'usd',
          receipt_email: this.receiptEmail
        }
      }).subscribe((pi: any) => {
          if (pi?.client_secret !== null && pi?.client_secret !== undefined) {
            this.elementsOptions.clientSecret = pi?.client_secret;
            this.paymentIntentId.next(pi?.intentId);
          } else {
            this.paymentProcessService.showDialog(false, 'Something went wrong. Try again', true);
          }
        });
    }
    this.paymentProcessService.paymentResult.subscribe(
      result => {
        if (result === PaymentResultStatus.START) {
          this.makePayment();
        }
      }
    )
  }

  makePayment(): void {
    this.payment$ = this.stripeService.confirmPayment({
      elements: this.paymentElement.elements,
      confirmParams: {
        receipt_email: this.receiptEmail
      },
      redirect: 'if_required'
    }).subscribe(({paymentIntent, error}) => {
      if (error) {
        let message: string = error.message || 'Something went wrong. Try again';
        this.paymentProcessService.showDialog(false, message);
        this.paymentProcessService.paymentResult.next(PaymentResultStatus.FAIL);
        this.payment$.unsubscribe();
      } else if (paymentIntent && (paymentIntent.status === 'succeeded' || paymentIntent.status === 'processing')) {
        this.paymentMethod.next(paymentIntent.payment_method?.toString() || null);
        this.paymentProcessService.paymentResult.next(PaymentResultStatus.SUCCESS);
      } else if (paymentIntent && paymentIntent.status === 'requires_action') {
        this.paymentMethod.next(paymentIntent.payment_method?.toString() || null);
        if (paymentIntent.next_action && paymentIntent.next_action.type === 'verify_with_microdeposits') {
          let message = "In order to verify your banking details and complete ACH payment, Stripe will send a micro-deposit to your " +
            "bank account in 1-2 days. You will receive an email from Stripe to complete verification and finalize payment";
          this.paymentProcessService.showDialog(true, message, true);
          this.paymentProcessService.paymentResult.next(PaymentResultStatus.SUCCESS);
        }
      } else if (paymentIntent && paymentIntent.status === 'requires_payment_method') {
        this.paymentProcessService.paymentResult.next(PaymentResultStatus.FAIL);
      } else if (paymentIntent && paymentIntent.status === 'requires_confirmation') {
        this.paymentProcessService.paymentResult.next(PaymentResultStatus.FAIL);
      }
    });
    if (!this.isUserSubscribed && this.intentToSubscribe) {
      this.userService.updateUserSubscriptionAgreement({
        id: this.authService.userInfo.getValue()?.id || '',
        value: true
      }).subscribe((user: User) => {
        this.authService.userInfo.next(user);
      });
    }
  }

  public showSubscriptionCheckBox(): boolean {
    return this.authService.userInfo.getValue() != null && !this.isUserSubscribed;
  }

}
