import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {LoaderService} from '../../../../shared/services/loader.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  ConfirmationWindowComponent
} from "../../../../core/components/confirmation-window/confirmation-window.component";
import {FeaturedProductsList} from "../../../../api/cs/model/featured-products-list";
import {ProductAddressSearchResult, ProductSearchResult} from "../../../../api/cs";

@Component({
  selector: 'cs-featured-product-list-edit',
  templateUrl: './list-edit-modal.component.html',
  styleUrls: ['./list-edit-modal.component.scss']
})
export class ListEditModalComponent implements OnInit {

  form: FormGroup;
  private portalId: string | undefined;
  private formId: string | undefined;
  hubspotPlaceholder: string = '{\n\ttitle: "Sample Request",\n\tform: {\n\t\tportalId: "25261979",\n\t\tformId: "019ac5e5-0d20-4a86-88e3-c94d3c7e3bd4",\n\t\ttarget: "#hubspotForm"\n\t}\n}';

  @Output() list = new EventEmitter<FeaturedProductsList>();

  constructor(@Inject(MAT_DIALOG_DATA) public data:
                  {
                      modalMode: "Create" | "Update",
                      list: FeaturedProductsList | undefined
                  },
              private fb: FormBuilder,
              private loader: LoaderService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ListEditModalComponent>)
  {}

  ngOnInit(): void {
    this.extractIds(this.data.list?.hubspotFormEmbedCode);
    this.initForm();
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: new FormControl(this.data.list?.name || ''),
      hubspotPortalId: new FormControl(this.portalId || ''),
      hubspotFormId: new FormControl(this.formId || '')
    });
  }

  confirmClose(): void {
    let confirmation = this.dialog.open(ConfirmationWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        header: "Window Close Confirmation",
        description: "discard changes and close the window"}
    });
    confirmation.beforeClosed().subscribe(() => {
      if (confirmation.componentInstance.confirmed.getValue()) {
        this.dialogRef.close();
      }
    })
  }

  submit(): void {
    this.loader.show();
    const { name, hubspotFormEmbedCode } = this.form.value;
    let newList: FeaturedProductsList = this.data.list || new class implements FeaturedProductsList {
        addresses: ProductAddressSearchResult;
        hubspotFormEmbedCode: string;
        id: string;
        name: string;
        products: ProductSearchResult;
    }
    newList.name = name;
    newList.hubspotFormEmbedCode = this.concatIds(this.form.value.hubspotPortalId, this.form.value.hubspotFormId);
    this.list.emit(newList);
    this.dialogRef.close();
  }

  private extractIds(hubspotFormEmberCode: string | undefined): void {
    if (!!hubspotFormEmberCode) {
      this.portalId = hubspotFormEmberCode.substring(hubspotFormEmberCode.indexOf('"portalId": "') + 13);
      this.portalId = this.portalId.substring(0, this.portalId.indexOf('",'));
      this.formId = hubspotFormEmberCode.substring(hubspotFormEmberCode.indexOf('"formId": "') + 11);
      this.formId = this.formId.substring(0, this.formId.indexOf('"'));
    }
  }

  private concatIds(portalId: string | undefined, formId: string | undefined): string | undefined {
    if (!!portalId && !!formId) {
      return '{"title": "Sample Request","form": {"portalId": "'
        + portalId
        + '","formId": "'
        + formId
        + '","target": "#hubspotForm"}}';
    } else {
      return undefined;
    }
  }
}
