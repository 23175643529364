<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">
      Frequently Asked Questions
    </p>
    <span class="icon cs-icon-button" aria-label="close" mat-dialog-close>
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <section class="modal-card-body">
    <div class="mt-4 pt-4">
      <article class="panel is-success" [class.mb-5]="i == QUESTIONS.length - 1"
               *ngFor="let question of QUESTIONS; let i = index"
      >
        <div class="panel-heading is-clickable" (click)="expandedIndex = i == expandedIndex ? -1 : i">
          {{question.question}}
        </div>
        <div class="panel-block py-5" [id]=i [class.expanded]="i == expandedIndex">
          <div class="markdown is-inline" [id]="'markdown_' + i">
            {{this.parse(i)}}
          </div>
        </div>
      </article>
    </div>
  </section>
  <footer class="modal-card-foot is-flex is-justify-content-center pt-4">
  </footer>
</div>
