/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductHeadingInfo } from './product-heading-info';
import { ProductGeneralInfo } from './product-general-info';
import { ProductPricingInfo } from './product-pricing-info';


export interface ProductRequest { 
    companyId: string;
    headingInfo: ProductHeadingInfo;
    generalInfo: ProductGeneralInfo;
    pricingInfo: ProductPricingInfo;
}

