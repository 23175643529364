import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  AuthenticationApiService,
  Campaign, ProductResponse,
  RequestSampleApiService,
  User
} from "../../api/cs";
import {AuthService} from "../../shared/services/auth.service";
import {GoogleMapsService} from "../../shared/services/google-maps.service";
import {PaymentResultStatus, PaymentService} from "../payment.service";
import {ValidatorsService} from "../../core/util/validators.service";
import {BehaviorSubject, Subscription} from "rxjs";

@Component({
  selector: 'cs-sample-request',
  templateUrl: './sample-request-modal.component.html',
  styleUrls: ['./sample-request-modal.component.scss']
})
export class SampleRequestModal implements OnInit {

  sampleEntityForm: FormGroup;

  user: User | null;
  intentToRegister: boolean = false;
  intentToSubscribe: boolean = false;
  paymentAmount: number;
  predictions: string[] = [];
  private UUID: string;
  receiptEmail: string;
  isPaymentSuccessful = false;
  paymentMethod = new BehaviorSubject<string | null>(null);
  paymentResult$: Subscription;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { campaign?: Campaign, product?: ProductResponse },
    private dialog: MatDialog,
    private sampleRequestService: RequestSampleApiService,
    public dialogRef: MatDialogRef<SampleRequestModal>,
    private authService: AuthService,
    private googleMapsService: GoogleMapsService,
    private authenticationApiService: AuthenticationApiService,
    private processPaymentService: PaymentService
  ) {
  }

  ngOnInit(): void {
    this.user = this.authService.userInfo.getValue();
    this.receiptEmail = this.user?.email || '';
    this.sampleEntityForm = this.fb.group({
      address: ['', Validators.required]
    });
    if (!this.user) {
      this.sampleEntityForm.addControl('firstName',
        new FormControl('', Validators.required));
      this.sampleEntityForm.addControl('lastName',
        new FormControl('', Validators.required));
      this.sampleEntityForm.addControl('email',
        new FormControl('', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,63}$')]));
      this.sampleEntityForm.addControl("companyName",
        new FormControl('', Validators.required));
      this.sampleEntityForm.addControl("phone",
        new FormControl('', Validators.required));
    }
    this.paymentAmount = (!!this.data.campaign
      ? this.data.campaign?.sampleCost
      : this.data.product?.pricingInfo.sampleCost) || 0;
    this.getUUID();
    this.googleMapsService.predicted.subscribe((predictions) => this.predictions = predictions);
  }

  pay() {
    this.receiptEmail = this.user ? (this.user?.email || '') : `${this.sampleEntityForm.get('email')?.value}`;
    let address = this.sampleEntityForm.get('address')?.value;
    if (address.toLowerCase().includes("united states") || address.toLowerCase().includes("usa")) {
      this.sampleEntityForm.get('address')?.setErrors(null);
      this.sampleEntityForm.value.address = address;
      if (!this.isPaymentSuccessful) {
        this.makePayment();
      } else {
        this.processSuccessfullyPayment();
      }
    } else {
      this.sampleEntityForm.get('address')?.setErrors({'illegal-area': true});
    }
  }

  private sendSampleRequest() {
    this.sampleRequestService.createSampleRequest({
      sampleRequest: {
        orderedEntity: !!this.data.campaign ? this.data.campaign : this.data.product,
        user: this.user || {},
        paymentAmount: this.paymentAmount,
        deliveryAddress: this.sampleEntityForm.value.address,
        paymentInfo: {
          payment_method_id: this.paymentMethod.getValue() || ''
        }
      }
    }).subscribe(
      () => {
        this.processPaymentService.paymentResult.next(PaymentResultStatus.NOT_PROCESS);
        let message = this.user ? 'Your payment has been received. You can view the status of your order on the <a href="./profile/orders">My Orders</a> tab.'
          : 'Your sample request has been received and will be shipped shortly. An email has been sent to your inbox with a temporary password to access your account. To view the status of your order - log-in and go to <a href="./#/orders">My Orders</a>.';
        this.showDialog(true, message);
        this.processPaymentService.endPaymentProcess();
      },
      error => {
        this.showDialog(false, error.message);
        this.processPaymentService.endPaymentProcess();
      }
    );
  }

  predict(address: string): void {
    if (this.sampleEntityForm.get('address')?.dirty) {
      if (address) {
        this.googleMapsService.predict(this.UUID, address);
      }
    }
  }

  showDialog(success: boolean, message: string, close?: boolean) {
    const dialogRef = this.processPaymentService.showDialog(success, message, close);
    dialogRef.afterClosed().subscribe(() => {
        if (success || close) {
          this.dialogRef.close();
          //When user finish working with Google Maps API it's better for close session for avoid additional costs
          this.googleMapsService.assume(this.UUID, this.sampleEntityForm.value.address, false);
        }
      }
    );
  }

  insert(prediction: string): void {
    if (this.sampleEntityForm.get('address')?.value != prediction) {
      this.sampleEntityForm.get('address')?.setValue(prediction);
    } else {
      this.predictions = [];
    }
  }

  isPaymentDisabled(): boolean {
    return this.sampleEntityForm.invalid ||
      (!this.user && !this.intentToRegister);
  }

  /**
   * For make working with Google Maps API cheaper all request from one user need to have same id.
   */
  getUUID(): void {
    this.authService.userInfo?.subscribe(user => this.UUID = user?.id || '');
  }

  private makePayment() {
    if (this.sampleEntityForm.valid) {
      this.processPaymentService.paymentResult.next(PaymentResultStatus.START);
      this.paymentResult$ = this.processPaymentService.paymentResult.subscribe(
        result => {
          if (result === PaymentResultStatus.SUCCESS) {
            this.isPaymentSuccessful = true;
            this.processSuccessfullyPayment();
          } else {
            this.paymentResult$?.unsubscribe();
          }
        }
      )
    } else {
      this.sampleEntityForm.markAllAsTouched();
      console.log(this.sampleEntityForm);
    }
  }

  private processSuccessfullyPayment() {
    if (this.user) {
      this.sendSampleRequest();
    } else {
      this.signUpUser();
    }
  }

  onlyEnglish($event: KeyboardEvent) {
    ValidatorsService.onlyEnglish($event);
  }

  onlyNumbers($event: KeyboardEvent) {
    ValidatorsService.onlyNumbers($event);
  }

  private signUpUser() {
    this.authenticationApiService.signUp({
      signUpRequest: {
        company: {
          name: this.sampleEntityForm.value.companyName
        },
        user: {
          firstName: this.sampleEntityForm.value.firstName,
          lastName: this.sampleEntityForm.value.lastName,
          email: this.sampleEntityForm.value.email,
          phone: this.sampleEntityForm.value.phone,
          sendEmail: true,
          subscriptionAgreement: this.intentToSubscribe
        }
      }
    }).subscribe((res) => {
      this.authService.updateTokens(res);
      this.authService.updateUserInfo();
      this.authService.userInfo.subscribe(user => {
        this.user = user;
        if (user) {
          this.sendSampleRequest();
        }
      });
    });
  }
}
