<div class="under-top-toolbar container " *ngIf="company$ | async">
  <div id="top-bar">
    <div id="bread-crumbs">
      <a routerLink="/algae" *ngIf="isSupplyChainBuilderUser">Supply Chain Builder</a>
      <span *ngIf="isSupplyChainBuilderUser">&nbsp;&nbsp;>&nbsp;&nbsp;</span>
      <xng-breadcrumb [separator]="'>'"></xng-breadcrumb>
    </div>
    <div id="actions-line">
    </div>
  </div>
  <div class="is-flex title-container p-3">
    <div class="logo-container"
         [class.editable]="canEdit"
         (mouseenter)="logoHovered = true"
         (mouseleave)="logoHovered = false">
      <img *ngIf="company?.logoImage != 'no-company-image.jpg'"
           src="https://{{s3BucketName}}.{{s3DefaultApi}}/images/{{company?.logoImage}}"
      >
      <div *ngIf="company?.logoImage == 'no-company-image.jpg'"
           class="cs-card no-image">
      </div>
      <div *ngIf="logoHovered && canEdit"
           class="logo-edit-overlay"
           (click)="showLogoEditModal()">
        <i
          class="fa fa-pencil is-size-5"
        ></i>
      </div>
    </div>
    <div class="title-container__inner is-flex is-justify-content-space-between is-flex-grow-1 is-align-self-center">
      <div>
        <div class="is-flex is-align-items-center name-container">
          <h1 class="title is-size-3 has-text-weight-semibold mb-1">
            {{company?.name}}
          </h1>
          <span class="tag verified-label ml-3 mb-4" *ngIf="showVerified">
            Verified
          </span>
          <i
            class="fa fa-pencil pl-5 cs-icon-button is-size-5"
            *ngIf="canEdit"
            (click)="showMainInfoEditWindow()"
          ></i>
        </div>
        <p class="has-text-grey has-text-weight-semibold is-7" *ngIf="showTagline">
          {{company.brief}}
        </p>
        <div *ngIf="showPlatforms"
            class="is-flex is-align-items-center mt-2">
          <div *ngFor="let platform of company.platforms"  class="is-flex platform-line">
            <p class="has-text-grey is-size-6 mr-1">
              {{platform?.value}}
            </p>
            <img *ngIf="platformImageService.isPlatformImageExists(platform?.key)"
                 src="{{platformImageService.getPlatformImage(platform?.key)}}"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="is-flex is-flex-direction-column is-align-self-center">
      <a class="button wide is-medium is-success has-text-weight-bold is-fullwidth"
         *ngIf="showContactButton"
         (click)="showContactRequestModal()"
      >
        Contact
      </a>
      <a class="button is-medium is-success has-text-weight-bold is-fullwidth px-5"
         *ngIf="!showContactButton && !showPublishButton"
         routerLink="/dashboard"
      >
        Company Dashboard
      </a>
      <a class="button wide is-medium is-success has-text-weight-bold is-fullwidth"
         *ngIf="showPublishButton"
         (click)="publishForm.showWindow(companyNewVisibilityStatusDescription, companyVisibilityStatus)"
      >
        {{companyNewVisibilityStatusDescription}}
      </a>
      <a class="button wide is-medium is-success has-text-weight-bold is-fullwidth mt-2"
         *ngIf="showPublishButton"
         (click)="publishForm.showWindow(companyNewVerificationStatusDescription, companyVerificationStatus)"
      >
        {{companyNewVerificationStatusDescription}}
      </a>
      <div class="field is-align-self-flex-end mr-0 mt-2" *ngIf="showAdminViewSwitch">
        <div class="control">
          <input type="checkbox"
                 name="adminView"
                 id="adminView"
                 class="switch is-success is-rtl"
                 (change)="adminViewService.changeAdminView()"
                 [checked]="isAdminView"
          >
          <label class="label" for="adminView">
            Admin View
          </label>
        </div>
      </div>
      <cs-edit-company-status-form #publishForm
                                   (changeAccessStatus)="changeAccessStatus()"
                                   (changeVerificationStatus)="changeVerificationStatus()"
      ></cs-edit-company-status-form>
    </div>
  </div>
  <div class="is-flex info-container mt-5">
    <div class="info-container__inner-container is-flex-grow-1 mr-6">
      <div id="info-section-about" class="pb-3">
        <h2 class="is-size-5 has-text-weight-bold">
          About
        </h2>
        <div *ngIf="showSummary">
          <markdown
            [textContent]="company?.summary">
          </markdown>
        </div>
        <div class="is-flex is-align-items-center pb-3 mt-5 main-tags-block">
          <div class="left-column">
            <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showBusinessType">
              <span class="has-text-success is-size-6">Type of Company: </span>
              <span class="is-size-6">{{getDictionaryValuesText(company.businessTypes)}}</span>
            </div>
            <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showIndustryFocuses">
              <span class="has-text-success is-size-6">Industry: </span>
              <span class="is-size-6">{{getDictionaryValuesText(company.industryFocuses)}}</span>
            </div>
          </div>
          <div>
            <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showSupplyChains">
              <span class="has-text-success is-size-6">Part of Supply Chain: </span>
              <span class="is-size-6">{{getDictionaryValuesText(company.supplyChains)}}</span>
            </div>
            <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showCustomerFocus">
              <span class="has-text-success is-size-6">Customer Focus: </span>
              <span class="is-size-6">{{ company.customerFocus | toCamelCase }}</span>
            </div>
          </div>
        </div>
      </div>
      <div id="info-section-campaign" class="pb-6 mt-5">
        <div class="is-flex is-justify-content-space-between">
          <h2 class="is-size-5 has-text-weight-bold">
            Campaign
            <a class="has-text-grey-darker"
               *ngIf="canEdit || companyOwner"
               (click)="addNewCampaign()">
              <i
                class="fa fa-plus-square pl-5 cs-icon-button is-size-5"
              ></i>
            </a>
          </h2>
          <a class="has-text-weight-semibold" [href]="['/companies/' + company?.alias + '/campaigns']">See all ></a>
        </div>
        <div class="is-flex product-cards-line mt-4 campaign-container mb-4">
          <cs-campaigns-carousel [campaigns]="companyCampaigns$.getValue()"
                                 [editable]="canEdit"
                                 *ngIf="!!companyCampaigns$"
                                 (editCampaignEmitter)="editCampaign($event)"
                                 [slideCount]="calcNumberOfSlidesInCarousel()">
          </cs-campaigns-carousel>
        </div>
      </div>
      <div id="info-section-products" class="pb-6 mt-5">
        <div class="is-flex is-justify-content-space-between">
          <h2 class="is-size-5 has-text-weight-bold">
            Products
            <a class="has-text-grey-darker"
               *ngIf="canEdit || companyOwner"
               (click)="addNewProduct()">
              <i
                class="fa fa-plus-square pl-5 cs-icon-button is-size-5"
              ></i>
            </a>
          </h2>
          <a class="has-text-weight-semibold" [href]="'/companies/' + company?.alias + '/products'">See all ></a>
        </div>
        <div class="is-flex product-cards-line mt-4">
          <ng-container *ngFor="let product of companyProducts$ | async; let i = index">
            <div *ngIf="i < 4" (click)="goToProduct(product.alias)"
                 class="cs-card product-card mr-4 is-flex-direction-column is-align-items-start">
              <div class="product-verified-label">
                 <span class="tag verified-label px-3" *ngIf="product.verified">
                    Verified
                </span>
              </div>
              <div class="is-flex is-flex-direction-column is-justify-content-space-between main-content px-4 pb-4">
                <div>
                  <p class="has-text-weight-bold is-size-6">
                    {{ product.name }}
                  </p>
                  <p class="has-text-grey has-text-weight-semibold is-size-7">
                    {{ product.type | toCamelCase }}
                  </p>
                  <p class="has-text-weight-semibold is-flex-grow-1 is-size-7">
                    {{ product.category | toCamelCase }}
                  </p>
                  <p *ngIf="isAdminView" class="has-text-weight-semibold is-flex-grow-1 is-size-7"
                     [style.color]="product.state == 'ACTIVE' ? csGreen
                : product.state == 'ARCHIVED' ? darkGray
                : product.state == 'DRAFT' ? lightGray
                : lightRed">
                    {{toCamelCase(product.state)}}
                  </p>
                </div>
                <div class="is-flex is-justify-content-space-between" [style.width.%]="100">
                  <a class="button is-success has-text-weight-bold is-size-7"
                     routerLink="/companies/{{company.alias}}/products/{{product.alias}}" routerLinkActive="active">
                    Details
                  </a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div id="info-section-capacity" class="pb-5 mt-5 pt-1 markdown">
        <h2 class="is-size-5 has-text-weight-bold">
          Capacity
          <i
            class="fa fa-pencil pl-5 cs-icon-button"
            *ngIf="canEdit"
            (click)="capacityForm.showWindow(company)"
          ></i>
        </h2>
        <cs-edit-company-capacity-form #capacityForm
                                       (updated)="load()"
        ></cs-edit-company-capacity-form>
        <div class="mt-4" id="capacity">
          <markdown
            [textContent]="company?.capacity">
          </markdown>
        </div>
      </div>
      <div id="info-section-rnd" class="pb-5 mt-5 pt-1 markdown">
        <h2 class="is-size-5 has-text-weight-bold">
          R&D Capabilities
          <i
            class="fa fa-pencil pl-5 cs-icon-button"
            *ngIf="canEdit"
            (click)="rndForm.showWindow(company)"
          ></i>
        </h2>
        <cs-edit-company-rnd-form #rndForm
                                  (updated)="load()"
        ></cs-edit-company-rnd-form>
        <div class="mt-4" id="rnd">
          <markdown
            [textContent]="company?.rnd">
          </markdown>
        </div>
      </div>
      <div id="info-section-certification" class="pb-5 mt-5 pt-1 markdown">
        <h2 class="is-size-5 has-text-weight-bold">
          Certification
          <i
            class="fa fa-pencil pl-5 cs-icon-button"
            *ngIf="canEdit"
            (click)="certificationForm.showWindow(company)"
          ></i>
        </h2>
        <cs-edit-company-certification-form #certificationForm
                                            (updated)="load()"
        ></cs-edit-company-certification-form>
        <div class="mt-4" id="certification">
          <markdown
            [textContent]="company?.certification">
          </markdown>
        </div>
      </div>
    </div>
    <div class="cards-column">
      <div class="cs-card p-3 is-flex is-flex-direction-column is-justify-content-space-between">
        <p class="has-text-weight-bold is-size-5 pl-3 mb-2">
          General Info
          <i
            class="fa fa-pencil pl-5 cs-icon-button"
            *ngIf="canEdit"
            (click)="generalInfoForm.showWindow(company)"
          ></i>
        </p>
        <cs-edit-company-general-info-form #generalInfoForm
                                           (updated)="load()"
                                           (viewChanged)="adminViewService.changeAdminView()"
        ></cs-edit-company-general-info-form>
        <div class="field px-3" *ngIf="showPrivateInfo">
          <hr class="my-0 dashed">
          <span class="fields-access-tag pl-3">
            Private Info
          </span>
          <p class="has-text-weight-semibold is-size-6 py-1" *ngIf="showWebsite">
            <span class="icon is-small has-text-grey mr-1">
              <i
                class="fa fa-laptop" aria-hidden="true"
              ></i>
            </span>
            <a href="{{companySiteLink}}">
              {{company?.website}}
            </a>
          </p>
          <p class="has-text-weight-semibold is-size-6 py-1" *ngIf="showPhone">
            <span class="icon is-small has-text-grey mr-1">
              <i
                class="fa fa-phone" aria-hidden="true"
              ></i>
            </span>
            {{company?.phone}}
          </p>
          <p class="has-text-weight-semibold is-size-6 py-1" *ngIf="showAddress">
            <span class="icon is-small has-text-grey mr-1">
              <i
                class="fa fa-home" aria-hidden="true"
              ></i>
            </span>
            {{company?.address}}
          </p>
        </div>
        <div class="field px-3" *ngIf="showGeneralInfo">
          <hr class="my-0 dashed">
          <span class="fields-access-tag pl-3">Public Info</span>
          <p class="has-text-weight-semibold is-size-6 py-1" *ngIf="showLocation">
            <span class="icon is-small has-text-grey mr-1">
              <i class="fa fa-map-marker" aria-hidden="true">
              </i>
            </span>
            {{company?.location}}
          </p>
          <p class="has-text-weight-semibold is-size-6 py-1" *ngIf="showSize">
            <span class="icon is-small has-text-grey mr-1">
              <i class="fa fa-users" aria-hidden="true">
              </i>
            </span>
            {{company?.companySize?.value}} employees
          </p>
          <p class="has-text-weight-semibold is-size-6 py-1" *ngIf="showFoundationYear">
            <span class="icon is-small has-text-grey mr-1">
              <i
                class="fa fa-flag-o" aria-hidden="true">
              </i>
            </span>
            Founded in {{company?.foundationYear}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
