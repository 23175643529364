<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">{{formIdentifier}} Supply Location</p>
    <span class="icon cs-icon-button" aria-label="close" (click)="confirmClose()">
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <form class="modal-card-body pt-6"
        *ngIf="form"
        [formGroup]="form"
        (ngSubmit)="submit()">
    <div class="field pb-4 is-flex-grow-1">
      <label class="label">Address:</label>
      <div class="control">
        <input class="input is-medium"
               type="text"
               placeholder="address, city, country"
               aria-label="Delivery address"
               matInput
               formControlName="address"
               [matAutocomplete]="auto"
               (ngModelChange)="predict($event)">
        <p class="mt-2 field-access-hint">
          Visible only for company owners and admin
        </p>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="insert($event.option.value)">
          <mat-option *ngFor="let option of predictions" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>
    <div class="field pb-4">
      <label class="label">Location:</label>
      <div class="control">
        <input class="input is-medium"
               type="text"
               placeholder="Location"
               formControlName="location"
               (click)="assume()"
        />
      </div>
    </div>

    <div class="field is-grouped is-grouped-centered mt-6">
      <div class="control py-4">
        <button type="submit" class="button wide is-success has-text-weight-bold is-fullwidth">
          Save
        </button>
      </div>
    </div>
  </form>
</div>
