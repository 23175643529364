import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CutTextIfItGreaterThenLimitPipe,
  DeleteCategoryNumberIdentifierPipe,
  SupplyChainBuilderComponent, TransformArrayToStringPipe
} from './components/supply-chain-builder/supply-chain-builder.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatRippleModule} from "@angular/material/core";
import {MatTabsModule} from "@angular/material/tabs";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {MatTreeModule} from "@angular/material/tree";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule} from "@angular/router";
import {NgSelectModule} from "@ng-select/ng-select";
import {
  SupplyChainBuilderService,
  SupplyChainBuilderServiceImpl
} from "./services/supply-chain-builder.service";
import { SupplyChainLandingPageComponent } from './components/supply-chain-landing-page/supply-chain-landing-page.component';
import {
  AuthenticationModalComponent,
} from './components/authentication-modal/authentication-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import {HubspotFormModalComponent} from "../shared/components/hubspot-form-modal/hubspot-form-modal.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SharedModule} from "../shared/shared.module";
import {MatPaginatorModule} from "@angular/material/paginator";

@NgModule({
  declarations: [
    SupplyChainBuilderComponent,
    SupplyChainLandingPageComponent,
    DeleteCategoryNumberIdentifierPipe,
    CutTextIfItGreaterThenLimitPipe,
    TransformArrayToStringPipe,
    AuthenticationModalComponent,
    HubspotFormModalComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    MatTooltipModule,
    SharedModule,
    MatPaginatorModule,
  ],
  exports: [

  ],
  providers: [
    {
      provide: SupplyChainBuilderService,
      useClass: SupplyChainBuilderServiceImpl
    }
  ]
})
export class SupplyChainBuilderModule { }
