/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DictionaryValue } from './dictionary-value';
import { ProductState } from './product-state';
import {ProductPromotionStatus} from "./product-featuring-status";
import {ProductImage} from "./product-image";


export interface ProductHeadingInfo {
    name: string;
    verified?: boolean;
    description?: string;
    developmentStage: DictionaryValue;
    chassis: Array<string>;
    state?: ProductState;
    promotionStatus?: ProductPromotionStatus;
    images?: ProductImage[];
    videoLink?: string;
}

