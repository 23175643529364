import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() { }

  static onlyNumbers($event: KeyboardEvent): void {
    const pattern = /[0-9.+-/(/)]/;
    const inputChar = String.fromCharCode($event.charCode);
    if (!pattern.test(inputChar)) {
      $event.preventDefault();
    }
  }

  static onlyEnglish($event: KeyboardEvent): void {
    const pattern = /[0-9a-zA-Z .,!?@:/%\-#$^&*';()]/;
    const inputChar = String.fromCharCode($event.charCode);
    if (!pattern.test(inputChar)) {
      if ($event.code !== 'Enter') {
        $event.preventDefault();
      }
    }
  }
}
