<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">List {{data.modalMode}}</p>
    <span class="icon cs-icon-button" aria-label="close" (click)="confirmClose()">
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <form class="modal-card-body pt-6"
        *ngIf="form"
        [formGroup]="form"
        (ngSubmit)="submit()">

    <div class="field pb-4">
      <label class="label">Name:</label>
      <div class="control">
        <input class="input is-medium"
               type="text"
               placeholder="Hotels"
               formControlName="name"
        />
      </div>
    </div>

    <div class="field pb-4">
      <label class="label">Hubspot Portal ID:</label>
      <div class="control">
        <input class="input is-medium"
               type="text"
               placeholder="42342334"
               formControlName="hubspotPortalId">
      </div>
    </div>

    <div class="field pb-4">
      <label class="label">Hubspot Form ID:</label>
      <div class="control">
        <input class="input is-medium"
                  type="text"
                  placeholder="e343c2fc-9529-12b6-8deb-01437d312e7e"
                  formControlName="hubspotFormId">
      </div>
    </div>


    <button type="submit" class="button wide is-success has-text-weight-bold is-fullwidth">
      Save
    </button>
  </form>
</div>
