import {Injectable} from "@angular/core";
import {UnitsScalingService} from "./units-scaling.service";
import {ShippingCostInterval, UnitScalingEntity} from "../../api/cs";

/**
 * Service that calculates shipping cost
 */
@Injectable({
  providedIn: 'root'
})
export class ShippingCostService {

  constructor(private unitsScalingService: UnitsScalingService) {}

  getShippingCost(amount: number, amountMeasure: UnitScalingEntity, shippingCostsList: ShippingCostInterval[]): number {
    for (let i = 0; i < shippingCostsList.length; i++) {
      let isItBigger = this.unitsScalingService.isItBigger(
        amount,
        amountMeasure,
        shippingCostsList[i].lowLimit,
        this.unitsScalingService.getScalingEntityById(shippingCostsList[i].lowLimitMeasureId || 0)
      );
      let boundedAmount: number;
      if (i != (shippingCostsList.length - 1)) {
        boundedAmount = amount + 1;
      } else {
        boundedAmount = amount;
      }
      let isItLower = this.unitsScalingService.isItLower(
        boundedAmount,
        amountMeasure,
        shippingCostsList[i].highLimit,
        this.unitsScalingService.getScalingEntityById(shippingCostsList[i].highLimitMeasureId || 0)
      );
      if (isItLower && isItBigger) {
        return shippingCostsList[i].price || 0;
      }
    }
    return 0;
  }
}
