<section class="campaign-item is-flex is-justify-content-space-between is-align-items-center">
  <div class="campaign-item__info is-flex  is-align-items-center">
    <div class="campaign-item__info__logo is-flex is-justify-content-center is-align-items-center">
      <img *ngIf="campaign?.images" src="{{campaignPreviewImage}}" />
    </div>
    <div class="campaign-item__info__title">
      <div class="title-type has-text-weight-bold">CAMPAIGN STARTED: {{ campaign?.startCampaignDate | date: 'MM-dd-YYYY' | uppercase }}</div>
      <div class="title-text has-text-weight-bold">{{campaign?.title}}</div>
      <div class="actions mt-3 is-flex">
        <button class="button" type="button" (click)="editCampaign(campaign)" *ngIf="showActions && showEditButton">Edit</button>
        <button class="button" type="button" (click)="copyToDraft()" *ngIf="showActions && showCopyToDraftButton">Copy to draft</button>
        <button class="button" type="button" (click)="changeState(state.ARCHIVED)" *ngIf="showActions && showArchiveButton">Archive</button>
        <a class="button" type="button" target="_blank" [href]="['/companies/' + campaign?.companyAlias + '/campaigns/' + campaign?.alias]">View</a>
        <a class="button" type="button" routerLink="/companies/{{campaign?.companyAlias}}/campaigns/{{campaign?.alias}}/buyers-list" *ngIf="showActions && showBuyerList">Buyers list</a>
        <a class="button" type="button" (click)="changeState(state.ACTIVE)" *ngIf="showActions && showPublishButton">Publish</a>
        <div class="info-tag" *ngIf="!showActions && campaign?.productCategory?.value">
          <span class="has-text-success">Product Category: </span>{{campaign?.productCategory?.value}}
        </div>
      </div>
    </div>
  </div>
  <cs-campaign-stats class="mr-5" *ngIf="campaign" [campaign]="campaign" [showBuyers]="false" [showState]="showActions"></cs-campaign-stats>
</section>
