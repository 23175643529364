import {Component, OnInit} from '@angular/core';
import {
  ProductAddress,
  ProductResponse,
  ProductsApiService,
  ProductState,
  User
} from '../../../../api/cs';
import {BehaviorSubject, finalize} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {LoaderService} from '../../../../shared/services/loader.service';
import {AttributesService} from '../../../../shared/services/attributes.service';
import {PlatformImageService} from "../../../../shared/services/platform-image.service";
import {AuthService} from "../../../../shared/services/auth.service";
import {Title} from "@angular/platform-browser";
import {FeaturedProductsList} from "../../../../api/cs/model/featured-products-list";
import {FeaturedProductsApiService} from "../../../../api/cs/api/featured-products-api.service";
import {ActivatedRoute, Params} from "@angular/router";
import {RoutingHistoryService} from "../../../../shared/services/routing-history.service";

@Component({
  selector: 'cs-products-short-list',
  templateUrl: './featured-products-short-list.component.html',
  styleUrls: ['./featured-products-short-list.component.scss']
})
export class FeaturedProductsShortListComponent implements OnInit {

  listName: string;

  isAdmin: boolean;
  user: User | null;
  showTabs: boolean = false;
  tabsState: ("ACTIVE" | "ARCHIVED" | "DRAFT" | "HIDDEN")[] = ["ACTIVE", "ARCHIVED", "DRAFT", "HIDDEN"];
  selectedTabIndex: number = 0;

  hubspotForm: string;
  products$: BehaviorSubject<Array<ProductResponse> | undefined> = new BehaviorSubject<Array<ProductResponse> | undefined>(undefined);
  addresses$: BehaviorSubject<Array<ProductAddress> | undefined> = new BehaviorSubject<Array<ProductAddress> | undefined>(undefined);
  visibleProducts$: BehaviorSubject<Array<ProductResponse> | undefined>;
  isLongText: boolean = false;
  s3DefaultApi = environment.s3DefaultApi;
  s3BucketName = environment.s3BucketName;

  showDescription: boolean;
  showType: boolean;
  showCategory: boolean;
  showRecommendation: boolean;
  showDevelopmentStage: boolean;

  constructor(
    private productsApiService: ProductsApiService,
    private activatedRoute: ActivatedRoute,
    private featuredProductsApiService: FeaturedProductsApiService,
    private loader: LoaderService,
    private titleService: Title,
    private authService: AuthService,
    private attributesService: AttributesService,
    public platformImageService: PlatformImageService,
    private routingHistoryService: RoutingHistoryService) { }

  ngOnInit(): void {
    this.routingHistoryService.getCurrentUrl();
    this.titleService.setTitle("Products | Cultured Supply");
    this.isAdmin = this.authService.isAdmin();
    this.user = this.authService.userInfo.getValue();
    this.attributesService.productAttrsLoaded$.subscribe();
    this.resolveAttributesVisibility();
    this.setTabsVisibility();
    this.loader.setVisible(true);
    this.activatedRoute.params.subscribe((params: Params) => {
        this.listName = params['name'];
        this.load();
    });
  }

  private load(): void {
      this.featuredProductsApiService.getFeaturedProductsList({
          name: this.listName
      }).pipe(finalize(() => this.loader.setVisible(false)))
          .subscribe((result: FeaturedProductsList) => {
              this.products$.next(result.products?.items)
              this.addresses$.next(result.addresses?.items);
              this.hubspotForm = result.hubspotFormEmbedCode || '';
              this.visibleProducts$ = new BehaviorSubject<Array<ProductResponse> | undefined>(result.products?.items);
              this.setVisibleProducts(0);
          });
  }

  private setTabsVisibility(): void {
    this.showTabs = this.isAdmin;
  }

  setVisibleProducts(tabNumber: number): void {
    this.selectedTabIndex = tabNumber;
    let selector = this.tabsState[tabNumber];
    let visibleProducts: ProductResponse[] = [];
    this.products$.getValue()?.forEach(product => {
      if (product.headingInfo.state == ProductState[selector]) {
        visibleProducts.push(product);
      }
    });
    this.visibleProducts$.next(visibleProducts);
  }

  get baseUrl(): string {
    return environment.baseUrl;
  }

  setDescription(product: ProductResponse): string {
    let maxSymbolsCountInRow = window.innerWidth * 0.5 / 7;
    let maxSymbolsCount = maxSymbolsCountInRow * 6;

    return this.clipText(product.headingInfo?.description || '', maxSymbolsCountInRow, maxSymbolsCount);
  }

  public getProductAddresses(product: ProductResponse): Array<ProductAddress> {
    let addresses = this.addresses$.getValue();
    if (!!addresses) {
      return addresses.filter((address: ProductAddress) => address.product_id == product.id);
    }
    return [];
  }

  private clipText(fullText: string, maxSymbolsCountInRow: number, maxSymbolsCount: number): string {
    let clippedText = '';
    let currentSymbolsCount = 0;
    let strings = fullText.split('\n') || [];
    for (let string of strings) {
      clippedText = clippedText.concat(string);
      currentSymbolsCount += string.length;
      currentSymbolsCount += maxSymbolsCountInRow - string.length % maxSymbolsCountInRow;
      let words = string.split(' ');
      let row = '';
      for (let word of words) {
        if (row.concat(word).length < maxSymbolsCountInRow) {
          row = row.concat(word);
        } else {
          currentSymbolsCount += maxSymbolsCountInRow - row.length;
          row = '';
        }
      }

      if (currentSymbolsCount > maxSymbolsCount) {
        clippedText = clippedText.substring(0, maxSymbolsCount);
        let maxRegexIndex = Math.max(
          clippedText.lastIndexOf(' '),
          clippedText.lastIndexOf(','),
          clippedText.lastIndexOf('.')
        );
        clippedText = clippedText.substring(0, maxRegexIndex - 1).concat('...');
        this.isLongText = true;
        break;
      } else {
        this.isLongText = false;
      }
    }

    if (clippedText.charAt(clippedText.length - 1) == '\n') {
      clippedText = clippedText.substring(0, clippedText.lastIndexOf('\n'));
    }

    return clippedText;
  }

  updateProduct(updatedProduct: ProductResponse): void {
    this.products$.next(
      this.products$.getValue()?.map(existingProduct => {
        if (existingProduct.id == updatedProduct.id) {
          return updatedProduct;
        } else {
          return existingProduct;
        }
      })
    );
    this.setVisibleProducts(this.selectedTabIndex);
  }

  addProduct(newProduct: ProductResponse): void {
    let existingProducts = this.products$.getValue();
    existingProducts?.push(newProduct);
    this.products$.next(existingProducts);
    this.setVisibleProducts(this.selectedTabIndex);
  }

  private resolveAttributesVisibility(): void {
    this.setDescriptionVisibility();
    this.setTypeVisibility();
    this.setCategoryVisibility();
    this.setRecommendationVisibility();
    this.setDevelopmentStageVisibility();
  }

  private setDescriptionVisibility(): void {
    this.showDescription = this.attributesService.isShownProductAttribute('description');
  }

  private setTypeVisibility(): void {
    this.showType = this.attributesService.isShownProductAttribute('type');
  }

  private setCategoryVisibility(): void {
    this.showCategory = this.attributesService.isShownProductAttribute('category');
  }

  private setRecommendationVisibility(): void {
    this.showRecommendation = this.attributesService.isShownProductAttribute('recommendation');
  }

  private setDevelopmentStageVisibility(): void {
    this.showDevelopmentStage = this.attributesService.isShownProductAttribute('developmentStage');
  }
}
