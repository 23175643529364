import {NgModule} from '@angular/core';
import {LayoutComponent} from './components/layout/layout.component';
import {RouterModule} from '@angular/router';
import {NavigationComponent} from './components/navigation/navigation.component';
import {AuthComponent} from './components/auth-modal-window/auth.component';
import {SharedModule} from '../shared/shared.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {PlatformUserAuthGuard} from './guards/platform-user-auth.guard';
import {AdminGuard} from './guards/admin.guard';
import {ManifestoComponent} from './components/manifesto/manifesto.component';
import {CommonModule} from '@angular/common';
import {FaqComponent} from './components/faq/faq.component';
import {SubmitInterceptor} from "./interceptors/submit.interceptor";
import {ContactUsComponent} from './components/contact-us/contact-us.component';
import {SampleRequestModal} from "../payment/sample-request-modal/sample-request-modal.component";
import { InfoModalWindowComponent } from './components/info-modal-window/info-modal-window.component';
import { LandingInfoModalWindowComponent } from './components/landing-info-modal-window/landing-info-modal-window.component';
import {NotFoundPageComponent} from './components/not-found-page/not-found-page.component';
import { SignatureComponent } from './components/signature/signature.component';
import {SignaturePadModule} from "angular2-signaturepad";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import { SignUpBuyerComponent } from './components/sign-up-buyer/sign-up-buyer.component';
import { SignUpSupplierComponent } from './components/sign-up-supplier/sign-up-supplier.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatRadioModule} from "@angular/material/radio";
import { FooterComponent } from './components/footer/footer.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {QuillModule} from "ngx-quill";
import { TextModalComponent } from './components/text-modal/text-modal.component';
import { ConfirmationWindowComponent } from './components/confirmation-window/confirmation-window.component';
import {BreadcrumbModule} from "xng-breadcrumb";
import { AboutUsComponent } from './components/about-us/about-us.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatBadgeModule} from "@angular/material/badge";
import {MatSidenavModule} from "@angular/material/sidenav";
import {SupplyChainAuthGuard} from "./guards/supply-chain-auth.guard";
import {AuthGuard} from "./guards/auth.guard";
import { CreatorLedComponent } from './components/creator-led/creator-led.component';
import { FaqModalComponent } from './components/creator-led/faq-modal/faq-modal.component';
import { FaqPageComponent } from './components/creator-led/faq-page/faq-page.component';

@NgModule({
  declarations: [
    LayoutComponent,
    NavigationComponent,
    AuthComponent,
    ManifestoComponent,
    FaqComponent,
    ContactUsComponent,
    SampleRequestModal,
    InfoModalWindowComponent,
    LandingInfoModalWindowComponent,
    ContactUsComponent,
    NotFoundPageComponent,
    SignatureComponent,
    SignUpBuyerComponent,
    SignUpSupplierComponent,
    FooterComponent,
    TextModalComponent,
    ConfirmationWindowComponent,
    AboutUsComponent,
    CreatorLedComponent,
    FaqModalComponent,
    FaqPageComponent,
  ],
  imports: [
      RouterModule,
      SharedModule,
      CommonModule,
      SignaturePadModule,
      MatAutocompleteModule,
      MatTabsModule,
      MatRadioModule,
      MatCheckboxModule,
      QuillModule,
      BreadcrumbModule,
      MatMenuModule,
      MatIconModule,
      MatBadgeModule,
      MatSidenavModule,
  ],
  providers: [
    PlatformUserAuthGuard,
    AdminGuard,
    SupplyChainAuthGuard,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SubmitInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
}
