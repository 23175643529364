<div class="under-top-toolbar container ">
  <div class="products-header is-flex is-justify-content-space-between mb-2">
    <div class="page-title">Featured Products for {{listName}}</div>
  </div>
  <div class="b-table">
    <div class="table-wrapper has-mobile-cards">
      <table class="table">
        <tbody>
            <tr cs-featured-product-bar-item *ngFor="let product of visibleProducts$ | async"
                [product]="product"
                [addresses]="getProductAddresses(product)"
                [hubspotForm]="hubspotForm"
                (cratedProduct)="addProduct($event)"
                (updatedProduct)="updateProduct($event)"
            ></tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
