import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SignupsListComponent} from './components/signups-list/signups-list.component';
import {SharedModule} from '../shared/shared.module';
import {CoreModule} from "../core/core.module";
import {BreadcrumbModule} from "xng-breadcrumb";

@NgModule({
  declarations: [
    SignupsListComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        CoreModule,
        BreadcrumbModule
    ]
})
export class SignupsModule {
}
