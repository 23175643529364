<div class="under-top-toolbar container ">

  <div class="is-flex is-justify-content-space-between">
    <div class="page-title">Users</div>
    <div class="field">
      <div class="control">
        <a class="button is-size-6 is-success has-text-weight-bold is-fullwidth"
           (click)="createNewUser()"
        >New User</a>
      </div>
    </div>
  </div>

  <cs-mat-table *ngIf="tableData.length > 0"
                [inputList]="tableData">
  </cs-mat-table>

  <div *ngIf="users && !users.length" class="mt-5">
    There are no users
  </div>
</div>
