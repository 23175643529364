import {Injectable} from '@angular/core';
import {ToastService} from "./toast.service";
import {FilesApiService} from "../../api/cs";

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {

    private file?: File;

    constructor(private toastService: ToastService,
                private filesApiService: FilesApiService) {
    }

    processFileUpload(target: Event): File | undefined;
    processFileUpload(file: File): File | undefined;
    processFileUpload(input: any): File | undefined {
        if (input instanceof Event) {
            const fileInput = input.target as HTMLInputElement;
            const files = fileInput.files;
            if (files instanceof FileList && files.length) {
                this.file = this.setBufferFile(files[0]);
                fileInput.value = '';
            }
        } else if (input instanceof File) {
            this.file = this.setBufferFile(input);
        }
        return this.file;
    }

    private setBufferFile(file: File): File {
        this.file = file;
        if (this.file.size > 10485760) {
            delete this.file;
            this.toastService.error("File too large for uploading");
        }
        return file;
    }

    async loadFile(isImage: boolean): Promise<string> {
        return new Promise<any>(resolve => {
            this.filesApiService.uploadFile({
                file: this.file,
                isImage: isImage
            })
                .subscribe((res) => {
                    resolve(res.reference);
                });
        });
    }
}
