import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DictionariesService} from '../../../../shared/services/dictionaries.service';
import {DictionaryValue, FilesApiService, ProductResponse, ProductsApiService, ProductState} from '../../../../api/cs';
import {LoaderService} from '../../../../shared/services/loader.service';
import {finalize} from 'rxjs';
import {AttributesService} from '../../../../shared/services/attributes.service';
import {AdminViewService} from "../../../../shared/services/admin-view.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  ConfirmationWindowComponent
} from "../../../../core/components/confirmation-window/confirmation-window.component";
import {AuthService} from "../../../../shared/services/auth.service";
import {ToastService} from "../../../../shared/services/toast.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ProductImageService} from "../../../product-image.service";

@Component({
  selector: 'cs-product-heading-form',
  templateUrl: './product-heading-form.component.html',
  styleUrls: ['./product-heading-form.component.scss'],
})
export class ProductHeadingFormComponent implements OnInit {

  form: FormGroup;
  platformForm: FormGroup;

  isAdmin: boolean;
  private requiredFields: string[] = ['name'];
  isAdminView: boolean;

  @Output()
  updatedProduct = new EventEmitter<ProductResponse>();

  product: ProductResponse;

  chassis: string[];
  developmentStages: DictionaryValue[];
  states: string[];
  currentState: ProductState;
  isDescriptionVisible: boolean;
  isDevelopmentStageVisible: boolean;
  isVerifiedFlagVisible: boolean;
  images: File[] = [];

  constructor(private fb: FormBuilder,
              private loader: LoaderService,
              private sanitizer: DomSanitizer,
              private authService: AuthService,
              private toastService: ToastService,
              private fileService: FilesApiService,
              private productImageService: ProductImageService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ProductHeadingFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {product: ProductResponse},
              private attributesService: AttributesService,
              private productsApiService: ProductsApiService,
              public dictionariesService: DictionariesService,
              public adminViewService: AdminViewService) {
  }

  ngOnInit(): void {
    this.product = this.data.product;
    this.isAdmin = this.authService.isAdmin();
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
    this.isAdminView = this.adminViewService.isAdminView;
    this.adminViewService.adminView.subscribe(isAdminView => this.isAdminView = isAdminView);
    this.initDictionaries();
    this.setPlatforms();
    this.resolveVisibility();
    this.initForm();
    this.addExistingImageToEditing();
    this.currentState = this.product.headingInfo.state || ProductState.DRAFT;
  }

  private initForm(): void {
    this.platformForm = this.fb.group({});
    this.form = this.fb.group({
      name: new FormControl(this.product?.headingInfo?.name,
        [Validators.required, Validators.pattern('^[a-zA-Z0-9]+[a-zA-Z0-9\\s\\S]*')]),
      platform: this.platformForm,
      description: new FormControl(this.product?.headingInfo?.description),
      developmentStage: new FormControl(this.product.headingInfo?.developmentStage?.key),
      verified: new FormControl(this.product?.headingInfo?.verified),
      state: new FormControl(this.product.headingInfo.state),
      images: new FormControl([]),
      imageRef: new FormControl(''),
      video: new FormControl(this.product.headingInfo?.videoLink, Validators.pattern('^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube(-nocookie)?\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$'))
    });
  }



  confirmClose(): void {
    let confirmation = this.dialog.open(ConfirmationWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        header: "Window Close Confirmation",
        description: "discard changes and close the window"}
    });
    confirmation.beforeClosed().subscribe(() => {
      if (confirmation.componentInstance.confirmed.getValue()) {
        this.dialogRef.close();
      }
    })
  }

  setState(event: ProductState): void {
    this.currentState = ProductState[event];
  }

  toCamelCase(input: string): string {
    let firstSymbol = input.substring(0, 1);
    let rest = input.substring(1, input.length);
    return firstSymbol.concat(rest.toLowerCase());
  }

  submit(): void {
    for(let field of this.requiredFields) {
      this.form.get(field)!.markAsTouched();
    }
    let inputs = Array.from(document
      .getElementById("main-info-modal-card")!
      .querySelectorAll('input'));
    for (let input of inputs) {
      if (input.getAttribute("class")?.includes("invalid")) {
        input.scrollIntoView({block: 'center' });
        return;
      }
    }
    this.loader.show();
    const {name, description, platform, developmentStage, verified, images, video} = this.form.value;
    this.productImageService.processImages(images).then(references => {
      this.productsApiService.updateProductHeading({
        id: this.product?.id || '',
        productHeadingInfo: {
          name: name,
          verified: verified,
          description: description,
          chassis: Array.from(Object.keys(platform).filter((key) => platform[key]).map(key => key.toString())),
          developmentStage: this.getSelectedDictionaryValue("product-development-stage", developmentStage) || {},
          state: ProductState[this.currentState],
          images: references,
          videoLink: this.sanitizeVideoLink(video)
        }
      }).pipe(finalize(() => this.loader.setVisible(false)))
          .subscribe((product: ProductResponse) => {
            this.updatedProduct.emit(product);
            this.dialogRef.close();
          }, error => {
            if (+error.status == 417) {
              this.toastService.error("Sorry, this product state can't be applied");
            }
          });
    });
  }

  getSelectedDictionaryValue(dictionaryName: string, selectedValue: string): DictionaryValue | undefined {
    if (!dictionaryName || !selectedValue) {
      return undefined;
    }
    return this.dictionariesService.getDictionaryByName(dictionaryName).find(value => value.key == selectedValue);
  }

  getCheckedItemsKeys(controlName: string): string[] {
    const control = this.form.get(controlName) as FormGroup;
    if (!control) {
      return [];
    }

    return Object.keys(control.controls).filter((key) => control.value[key]);
  }

  isVerified(): boolean {
    return !!this.product?.headingInfo?.verified;
  }

  private initDictionaries(): void {
    this.dictionariesService.loaded$.subscribe(() => {
      this.setDevelopmentStages();
    });
    this.setStates();
  }

  private setPlatforms(): void {
    this.productsApiService.getProductChassisValues().subscribe(response => {
      this.chassis = response;
      this.chassis.forEach((platform) => {
        if (platform != null) {
          this.platformForm.addControl(platform,
              new FormControl(this.product.headingInfo.chassis.includes(platform)));
        }
      });
    })
  }

  private setDevelopmentStages(): void {
    this.developmentStages = this.dictionariesService.getDictionaryByName(
      'product-development-stage',
      true,
      [this.product?.headingInfo?.developmentStage || {}]);
  }

  private setStates(): void {
    this.states = Object.keys(ProductState);
  }

  private resolveVisibility(): void {
    this.setDescriptionVisibility();
    this.setDevelopmentStageVisibility();
    this.setVerifiedFlagVisibility();
  }

  private setDescriptionVisibility(): void {
    this.isDescriptionVisible = this.attributesService.isShownProductAttribute('description');
  }

  private setDevelopmentStageVisibility(): void {
    this.isDevelopmentStageVisible = this.attributesService.isShownProductAttribute('developmentStage');
  }

  private setVerifiedFlagVisibility(): void {
    this.isVerifiedFlagVisible = this.attributesService.isShownProductAttribute('verified');
  }

  getImagePreview(index: number): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.images[index])) ?? '';
  }

  onFileChoose({target}: Event): void {
    const fileInput = target as HTMLInputElement;
    const files = fileInput.files;
    if ((files?.length || 0) > 0) {
      if ((files?.item(0)?.size || 0) > 20_000_000) {
        this.form.controls["imageRef"].setErrors({"too-big": true});
        return;
      } else if (files && files?.length > 6 || this.images.length >= 6) {
        this.form.controls["imageRef"].setErrors({"big-amount-files": true});
        return;
      } else {
        this.form.controls["imageRef"].setErrors(null);
      }
    }
    if (files instanceof FileList && files.length) {
      let filesArray = Array.from(files);
      filesArray.forEach(file => this.images.push(file));
      this.form.get('images')?.setValue(this.images);
      fileInput.value = '';
    }
  }

  private addExistingImageToEditing() {
    if (this.product.headingInfo.images) {
      for (let i = 0; i < this.product.headingInfo.images.length; i++) {
        let image = this.product.headingInfo.images[i];
        this.fileService.getFileContent({reference: image.reference || '', isImage: true}).subscribe(
            data => {
              this.images[i] = new File([data], `image-${image.id}`, {type: data.type});
              this.form.get('images')?.setValue(this.images);
            });
      }
    }
  }

  /**
   * Transforms link to the youtube page or link to the youtube video into embedded video link
   */
  sanitizeVideoLink(videoLink: string): string {
    let value: string = this.getValidExternalLink(videoLink);
    if (value.includes("&")) {
      value = value.substring(0, value.indexOf("&")); //cuts off query params
    }
    if (value.includes("youtu.be")) {
      value = value.replace("youtu.be", "youtube.com/embed");
    }
    if (value.includes("youtube.com/watch?v=")) {
      value = value.replace("watch?v=", "embed/")
    }
    return value;
  }

  /**
   * Adds prefix to link.
   * Without prefix link is always linked to Cultured Supply Home Page
   * @param link external link
   */
  getValidExternalLink(link: string): string {
    let validLink: string = '';
    if (!link) {
      return validLink;
    }
    if (link.startsWith("https://")) {
      validLink = link;
    } else if (link.startsWith("http://")) {
      validLink = link.replace("http://", "https://")
    } else {
      validLink = "https://" + link;
    }
    return validLink;
  }
}
