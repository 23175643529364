import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LoaderService} from '../../../shared/services/loader.service';
import {ToastService} from "../../../shared/services/toast.service";
import {FormNames, HubSpotFormsService} from "../../../shared/services/hubspot-forms.service";
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'cs-sign-up-buyer',
    templateUrl: './sign-up-buyer.component.html',
    styleUrls: ['./sign-up-buyer.component.scss']
})
export class SignUpBuyerComponent implements OnInit {

  forBuyerForm: FormGroup;

  industries: string[] = [
    'Agriculture',
    'Food and Beverage',
    'Cosmetics',
    'Materials and Textiles',
    'Packaging',
    'Other'
  ]

  constructor(private fb: FormBuilder,
              private router: Router,
              private loader: LoaderService,
              private titleService: Title,
              private toast: ToastService,
              private hubSpotFormsService: HubSpotFormsService) {}

  ngOnInit(): void {
    this.titleService.setTitle("For Buyers | Cultured Supply");
  this.forBuyerForm = this.fb.group({
      companyName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      phone: new FormControl('', Validators.required),
      industry: new FormControl(this.industries[0], Validators.required),
      addInfo: new FormControl('')
    })
  }

  submit(form: FormGroup): void {
      this.loader.show();
      this.hubSpotFormsService.sendFormRequest(FormNames.FOR_BUYERS,
        'For Buyers',
        this.router.url,
        [
          form.value.companyName,
          form.value.firstName,
          form.value.lastName,
          form.value.email,
          form.value.phone,
          form.value.industry,
          form.value.addInfo
      ]).subscribe(() => {
        form.reset();
        this.loader.hide();
        this.router.navigate(['/']).then();
        this.toast.success("Your request sent");
      });
  }
}
