<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">
      {{title}}
    </p>
  </header>
  <section class="modal-card-body">
      <div class="is-flex is-justify-content-space-evenly is-align-items-center">
        <figure class="image info-icon">
          <img src="../../../../assets/images/checked.png" *ngIf="data.success">
          <img src="../../../../assets/images/close.png" *ngIf="!data.success">
        </figure>
        <quill-view content="<strong>{{data.message}}</strong>"></quill-view>
      </div>
      <div class="control py-4 is-flex is-justify-content-center is-align-items-center">
        <button class="button wide has-text-weight-bold" mat-dialog-close [ngClass]="data.success ? 'is-success' : 'is-danger'">OK</button>
      </div>
  </section>
  <footer class="modal-card-foot is-flex is-justify-content-center pt-4">
  </footer>
</div>
