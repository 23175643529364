<div class="under-top-toolbar container ">
  <div *ngIf="productConfigs?.length && productForm">
    <div class="page-title">Show/Hide Attributes</div>

    <div class="tabs">
      <ul>
        <li [class.is-active]="tab === 'PRODUCT'" (click)="tab = 'PRODUCT'">
          <a>Product</a>
        </li>
        <li [class.is-active]="tab === 'COMPANY'" (click)="tab = 'COMPANY'">
          <a>Company</a>
        </li>
      </ul>
    </div>

    <ng-container *ngIf="tab === 'PRODUCT'">
      <div [formGroup]="productForm">
        <h2 class="is-size-5 has-text-weight-bold my-3">
          Product Attributes
        </h2>

        <div class="attr-block" *ngFor="let at of productConfigs">
          <div class="attr-config mb-4">
            <div class="control">
              <input id="show-{{at.key}}"
                     type="checkbox"
                     name="{{at.key}}"
                     class="switch is-success"
                     formControlName="{{at.key}}"
              >
              <label class="label has-tooltip-top"
                     matTooltip="at.optional ? '' : 'Field is required'"
                     matTooltipPosition="right" matTooltipHideDelay=0 matTooltipShowDelay=10
                     for="show-{{at.key}}">{{at.value}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="field mt-6">
        <div class="control py-4">
          <a class="button wide is-success has-text-weight-bold"
             (click)="saveProducts()"
          >
            Save
          </a>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="tab === 'COMPANY'">
      <div [formGroup]="companyForm">
        <h2 class="is-size-5 has-text-weight-bold my-3">
          Company Attributes
        </h2>

        <div class="attr-block" *ngFor="let at of companyConfigs">
          <div class="attr-config mb-4">
            <div class="control">
              <input id="show-{{at.key}}"
                     type="checkbox"
                     name="{{at.key}}"
                     class="switch is-success"
                     formControlName="{{at.key}}"
              >
              <label class="label has-tooltip-top"
                     matTooltip="at.optional ? '' : 'Field is required'"
                     matTooltipPosition="right" matTooltipShowDelay="10" matTooltipHideDelay="0"
                     for="show-{{at.key}}">{{at.value}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="field mt-6">
        <div class="control py-4">
          <a class="button wide is-success has-text-weight-bold"
             (click)="saveCompany()"
          >
            Save
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</div>
