/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProductSearchResponse { 
    id?: string;
    productName?: string;
    productAlias?: string;
    companyName?: string;
    companyAlias?: string;
    productDescription?: string;
    developmentStage?: string;
    productRegion?: string;
    productCountryState?: string;
    productType?: string;
    productCategory?: string;
    productSupplyChainFlow?: string;
    productRecommendedUse?: string;
    productAlgaeType?: string;
    productFormat?: Array<string>;
    productIndustryFocus?: Array<string>;
    productCustomerFocus?: Array<string>;
    isProductVerified?: boolean;
    isProductVisible?: boolean;
}

