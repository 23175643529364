import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {finalize} from "rxjs";
import {Campaign, CampaignsApiService, CompaniesApiService, DictionaryValue, FilesApiService} from "../../../api/cs";
import {LoaderService} from "../../../shared/services/loader.service";
import {environment} from "../../../../environments/environment";
import {DictionariesService} from "../../../shared/services/dictionaries.service";
import {AttributesService} from "../../../shared/services/attributes.service";
import {DomSanitizer, Meta, SafeResourceUrl, Title} from "@angular/platform-browser";
import {MatDialog} from "@angular/material/dialog";
import {SampleRequestModal} from "../../../payment/sample-request-modal/sample-request-modal.component";
import {AuthService} from "../../../shared/services/auth.service";
import {AuthComponent} from "../../../core/components/auth-modal-window/auth.component";
import {ToastService} from "../../../shared/services/toast.service";
import fileUtils from "../../../shared/utils/file-utils";
import {CampaignCreateComponent} from "../campaign-create/campaign-create.component";
import {TextModalComponent} from "../../../core/components/text-modal/text-modal.component";
import {BreadcrumbService} from "xng-breadcrumb";

@Component({
  selector: 'cs-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {

  private readonly MAX_DESC_LENGTH = 450 * window.innerWidth / 1920;
  hasLongDescription: boolean = false;
  selectedTab: 'campaign' | 'spec' | 'faq' | 'howItsMade' | 'meetTheTeam' = 'campaign';
  campaignAlias: string;
  campaign: Campaign;

  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  currentUrl: string;
  isAdmin: boolean = false;

  slideConfig = {
    slidesToShow: 1,
    infinite: true,
    outerEdgeLimit: true,
    slidesToScroll: 1,
    dots: true,
    nextArrow: '<div style=\'position: absolute; top: 45%; right: 0; z-index: 100; cursor: pointer; width: 30px; height: 30px; background: none; transform: none\' class=\'next-slide is-flex is-align-items-center is-justify-content-center\'><i style="font-size: 100px;' +
      '  color: #22b07d; padding-right: 10px" class="fa fa-angle-right arrow"></i></div>',
    prevArrow: '<div style=\'position: absolute; top: 45%; left: 0; z-index: 100; cursor: pointer; width: 30px; height: 30px; background: none; transform: none\' class=\'next-slide is-flex is-align-items-center is-justify-content-center\'><i style="font-size: 100px;' +
      '  color: #22b07d; padding-left: 10px" class="fa fa-angle-left arrow"></i></div>',};

  campaignMedia: { isVideo: boolean; link: any; }[] = [];
  s3DefaultApi = environment.s3DefaultApi;
  s3BucketName = environment.s3BucketName;

  //FIXME a value introduced only for the demo conducted on 08.03.2023
  // For some weird reasons client 'dgpworks' didn't want samples for his campaigns
  showBuySample: boolean = true;

  constructor(private loader: LoaderService,
              private toastService: ToastService,
              private router: Router,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private campaignsApiService: CampaignsApiService,
              private dictionariesService: DictionariesService,
              private attributesService: AttributesService,
              private sanitizer: DomSanitizer,
              @Inject(LOCALE_ID) private locale: string,
              private filesApiService: FilesApiService,
              private dialog: MatDialog,
              private companyService: CompaniesApiService,
              private campaignService: CampaignsApiService,
              private metaService: Meta,
              private breadcrumbService: BreadcrumbService,
              private titleService: Title) {
  }

  ngOnInit(): void {
    this.breadcrumbService.set('/companies', {skip: false});
    this.breadcrumbService.set('/companies/:company-alias', {skip: false});
    this.activatedRoute.params.subscribe((params: Params) => {
      this.campaignAlias = params['campaign-alias'];
      this.load();
    });

    this.currentUrl = window.location.href;

    this.isAdmin = this.authService.isAdmin();

  }

  async load(): Promise<void> {
    if (this.campaignAlias) {
      this.loader.show();

      const campaign = await this.campaignsApiService.getCampaign({
        id: this.campaignAlias,
      }).pipe(finalize(() => this.loader.hide())).toPromise();

      if (campaign) {
        this.campaign = campaign;
        this.breadcrumbService.set('companies/:company-alias', this.campaign?.company_name || 'Company');
        this.breadcrumbService.set('companies/:company-alias/campaigns/:campaign-alias', this.campaign?.title || 'Campaign');
        this.transformText(this.campaign.shortDescription);
        this.addTags(campaign);
        this.getCampaignMedia(campaign);
        this.showBuySample = this.campaign.companyId !== 'dgpworks'; // FIXME a hardcode needed for the demo conducted on 08.03.2023
      }
    }
  }

  private getCampaignMedia(campaign: Campaign) {
    this.campaignMedia = [];
    const isValidVideoLink = this.urlRegex.test(this.campaign?.video || '');
    const safeVideoLink = isValidVideoLink ? this.sanitizer.bypassSecurityTrustResourceUrl((this.campaign?.video || '')) : null;
    this.createMediaList(campaign, safeVideoLink);
  }

  private createMediaList(res: Campaign, safeVideoLink: SafeResourceUrl | null) {
    if (safeVideoLink) {
      this.campaignMedia.push({isVideo: true, link: safeVideoLink});
    }
    if (res.images) {
      for (let image of res.images) {
        this.campaignMedia.push({isVideo: false, link: `https://${environment.s3BucketName}.${environment.s3DefaultApi}/images/${image.reference}`});
      }
    }
  }

  get baseUrl(): string {
    return environment.baseUrl;
  }

  getDictionaryValuesText(dictionaryValues?: DictionaryValue[]): string {
    return this.dictionariesService.getDictionaryValuesText(dictionaryValues);
  }

  get showIndustryFocuses(): boolean {
    return this.attributesService.isShownProductAttribute('industryFocuses');
  }

  get showType(): boolean {
    return this.attributesService.isShownProductAttribute('type');
  }

  get showCategory(): boolean {
    return this.attributesService.isShownProductAttribute('category');
  }

  copyEmail(): void {
    const cb = navigator.clipboard;
    cb.writeText(this.campaign?.email || '').then(() => this.toastService.success("Email has copied"));
  }

  copyLink(): void {
    const cb = navigator.clipboard;
    cb.writeText(window.location.href).then(() => this.toastService.success("Page link has copied"));
  }

  getLink(): string {
    return encodeURIComponent(window.location.href);
  }

  makePurchase(): void {
    this.router.navigate(['companies/' + this.campaign.companyAlias + '/campaigns/' + this.campaignAlias + '/purchase']);
  }

  buySample() {
    this.dialog.open(SampleRequestModal, {
      panelClass: 'custom-dialog-container',
      data: { campaign: this.campaign }
    });
  }

  private showAuthWindow(): void {
    this.dialog.open(AuthComponent, {panelClass: 'custom-dialog-container', data: "logIn"});
  }

  downloadFile(specSheetFileRef?: string, fileName?: string) {
    if (specSheetFileRef) {
      this.filesApiService.getFileContent({
        reference: specSheetFileRef, isImage: false
      }).subscribe((res) => {
        const name = fileName || `${this.campaign.title}_Specification`;
        fileUtils.downloadFile(res, name);
      });
    }
  }

  editCampaign(campaign: Campaign): void {
    if (this.campaign.companyId !== undefined) {
      this.companyService.getCompany({id: this.campaign.companyId}).subscribe(
        data => {
          this.dialog.open(CampaignCreateComponent, {
            panelClass: 'custom-dialog-container',
            data: {company: data, campaign: campaign}
          })
            .afterClosed()
            .subscribe(() => this.campaignService.getCampaign({id: this.campaign.alias || ''}).subscribe(
              data => {
                this.campaign = data;
                this.getCampaignMedia(data);
              }
            ));
        }
      );
    }
  }

  private addTags(campaign: Campaign) {
    this.metaService.updateTag({property: 'og:url', content: `${window.location.origin}`});
    this.metaService.updateTag({property: 'robots', content: 'index,follow'});
    this.metaService.updateTag({property: 'og:title', content: `${campaign.title}`});
    this.metaService.updateTag({property: 'og:description', content: `${campaign.shortDescription}`});
    this.metaService.updateTag({property: 'og:site_name', content: `Cultured Supply`});
    this.metaService.updateTag({property: 'og:image', content: this.addImageToMeta(campaign)})
    this.metaService.updateTag({property: 'og:image:alt', content: `${campaign.title} logo`});
    this.titleService.setTitle(`${campaign.title} | Cultured Supply` );
  }

  private addImageToMeta(campaign: Campaign) {
    if (campaign.images) {
      return `${this.baseUrl}/files/${campaign.images[0]?.reference}`
    }
    return `${window.location.origin}/assets/images/logo-real.svg`;
  }

  public transformText(text: string | undefined): string {
    let currentLength: number = 0;
    let result: string = '';
    let contents = text?.split(new RegExp("<[^>]*>")).filter(content => content != '') || [];
    for (let content of contents) {
      if (content.length + currentLength > this.MAX_DESC_LENGTH) {
        result = result.concat(text?.substring(0, this.MAX_DESC_LENGTH - currentLength) || '');
        result = result.concat(result.charAt(result.length - 1) == '.' ? '..</p>' : '...</p>');
        this.hasLongDescription = true;
        return result;
      }
      let newPart = text?.substring(text?.indexOf(result), text?.indexOf(content)).concat(content) || '';
      text = text?.replace(newPart, '');
      result = result.concat(newPart);
      currentLength += content.length;
    }
    this.hasLongDescription = false;
    return result;
  }

  public showFullDescription(): void {
    this.dialog.open(TextModalComponent, {
      panelClass: 'custom-dialog-container',
      data: {headerText: "Description", mainText: this.campaign.shortDescription}
    });
  }

  public showLiveChat(): void {
    window.HubSpotConversations.widget.open();
  }
}
