import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'cs-product-quick-status-change-modal',
  templateUrl: './product-quick-status-change-modal.component.html',
  styleUrls: ['./product-quick-status-change-modal.component.scss']
})
export class ProductQuickStatusChangeModalComponent implements OnInit {

  @Output()
  changePromotionStatus = new EventEmitter<boolean>();
  @Output()
  changeVerificationStatus = new EventEmitter<boolean>();

  constructor(private dialogRef: MatDialogRef<ProductQuickStatusChangeModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                mode: "verification" | "promotion"
                newStatusDescription: string
              },) { }

  ngOnInit(): void {
  }

  confirmClose(): void {
      this.dialogRef.close();
  }

  closeWindow(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.data.mode == "verification") {
      this.changeVerificationStatus.emit(true);
    } else {
      this.changePromotionStatus.emit(true);
    }
    this.dialogRef.close();
  }
}
