import {Injectable} from '@angular/core';
import {
  AuthenticationApiService,
  AuthenticationResponse,
  User,
  UserRole,
  UsersApiService
} from '../../api/cs';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {CookieService} from "ngx-cookie-service";

interface TokenParsed {
  exp?: number;
  iat?: number;
  sub?: number;
  principal: UserWithRoles
}

interface UserWithRoles extends User {
  roles: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _token?: string;
  private _refreshToken?: string;
  private _tokenExpireTime?: number;
  private _refreshTokenExpireTime?: number;
  private _tokenParsed?: TokenParsed;
  private _renewalTokenParsed?: TokenParsed;

  userInfo: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(private authenticationApiService: AuthenticationApiService,
              private router: Router,
              private usersApiService: UsersApiService,
              private cookieService: CookieService) {
    if (this.cookieService.get('refreshToken')) {
      this.refreshToken = this.cookieService.get('refreshToken');
    }
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token') || '';
    }
    if (!!localStorage.getItem("userInfo")) {
      if (this.token) {
        let user = JSON.parse(localStorage.getItem("userInfo")!);
        this.userInfo.next(user);
      } else {
        localStorage.clear();
      }
    }
    this.userInfo.subscribe(user => {
      this.updateUserInfoInLocalStorage(user);
    });
  }

  login(email: string, password: string): Observable<string | undefined> {
    return this.authenticationApiService.signIn({
      signInRequest: {
        email: email,
        password: password
      }
    }).pipe(map((response) => {
      this.token = response.accessToken;
      this.updateUserInfo();
      return this._token;
    }));
  }

  renew(): Observable<string | null> {
    return this.authenticationApiService.refreshTokens().pipe(map(response => {
      if (response.accessToken) {
        this.updateTokens(response);
        return response.accessToken;
      } else {
        this.logout();
        return null;
      }
    }));
  }

  updateTokens(response: AuthenticationResponse) {
    this.token = response.accessToken;
    this._refreshToken = this.cookieService.get("refreshToken");
  }

  get token(): string | undefined {
    return this._token;
  }

  set token(value: string | undefined) {
    this._token = value;
    if (value) {
      this._tokenParsed = AuthService.decodeToken(value);
      this.tokenExpireTime = (this._tokenParsed.exp || 0) * 1000;
      localStorage.setItem('token', value);
    }
  }

  get refreshToken(): string | undefined {
    return this._refreshToken;
  }

  set refreshToken(value: string | undefined) {
    this._refreshToken = value;
    if (value) {
      this._renewalTokenParsed = AuthService.decodeToken(value);
      this.refreshTokenExpireTime = (this._renewalTokenParsed.exp || 0) * 1000;
    }
  }

  get tokenExpireTime(): number | undefined {
    return this._tokenExpireTime;
  }

  set tokenExpireTime(value: number | undefined) {
    this._tokenExpireTime = value;
  }

  get refreshTokenExpireTime(): number | undefined {
    return this._refreshTokenExpireTime;
  }

  set refreshTokenExpireTime(value: number | undefined) {
    this._refreshTokenExpireTime = value;
  }

  get isLoggedIn(): boolean {
    return localStorage.getItem('token') !== null;
  }

  updateUserInfo(): void {
    if (this.getLoggedInUser()?.roles.includes("SUPPLY_CHAIN_USER")) {
      let parserUser = this.getLoggedInUser();
      let user: User = new class implements User {
        admin: boolean = false;
        email: string = parserUser?.email || '';
        enabled: boolean = true;
        role: UserRole = UserRole.SUPPLY_CHAIN_USER;
      }
      this.userInfo.next(user);
    } else {
      this.usersApiService.getUser({
          id: this.getLoggedInUser()?.id || ''
        }
      ).subscribe((user) => {
        this.userInfo.next(user);
      });
    }
  }

  public updateUserInfoInLocalStorage(user: User | null): void {
    localStorage.setItem("userInfo", JSON.stringify(user))
  }

  logout() {
    this.authenticationApiService.signOut().subscribe(
      () => {
        this.makingLogout();
      }
    )
  }

  makingLogout() {
    this._token = undefined;
    this._refreshToken = undefined;
    delete this._tokenParsed;
    delete this._renewalTokenParsed;
    localStorage.clear();
    this.router.navigate(["/"]).then(() => {
      window.location.reload();
    });
  }

  public getLoggedInUser(): UserWithRoles | null {
    return this._tokenParsed?.principal ?? null;
  }

  isAdmin(): boolean {
    return !!this.getLoggedInUser()?.admin;
  }

  isOwner(id: string): boolean {
    return id == this.getLoggedInUser()?.company?.id;
  }

  isSupplier(): boolean {
    return !!this.getLoggedInUser()?.roles.includes('SUPPLIER');
  }

  isBuyer(): boolean {
    return !!this.getLoggedInUser()?.roles.includes('BUYER');
  }

  isSupplyChainUser(): boolean {
    return !!this.getLoggedInUser()?.roles.includes("SUPPLY_CHAIN_USER");
  }

  private static decodeToken(str: string): TokenParsed {
    str = str.split('.')[1];

    str = str.replace(/-/g, '+');
    str = str.replace(/_/g, '/');
    switch (str.length % 4) {
      case 0:
        break;
      case 2:
        str += '==';
        break;
      case 3:
        str += '=';
        break;
      default:
        throw new Error('Invalid token');
    }

    str = decodeURIComponent(escape(atob(str)));
    str = JSON.parse(str);
    return str as any as TokenParsed;
  }
}
