import {EventEmitter, Injectable, Output} from "@angular/core";
import {LocationApiService} from "../../api/cs";
import {ToastService} from "./toast.service";

/**
 * Service that know how to send requests to backend for retrieving data about addresses
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {

  constructor(private locationApiService: LocationApiService,
              private toastService: ToastService) {}

  @Output()
  assumed = new EventEmitter<string>();

  @Output()
  predicted = new EventEmitter<string[]>();

  /**
   * When we have address we can send Place Details request to Google Maps API
   * and retrieve structured information about this place.
   * @param id - unique id of session that uses for grouping requests to Google Maps API
   * @param address - address of searched place
   * @param onlyLocation - true return only location, false - full address
   */
  assume(id: string, address: string, onlyLocation : boolean): void {
      this.locationApiService.normalise({
        locationRequest: {
          id: id,
          address: address
        }
      }).subscribe(
        location => {
          let address = ((location.street_number !== null && !onlyLocation) ? `${location.street_number}, ` : '') + (location.street_address !== null && !onlyLocation ? `${location.street_address}, ` : '')
            + (location.subpremise !== null && !onlyLocation ? `${location.subpremise}, ` : '')
            + (location.city !== null ? `${location.city}, ` : '')
            + ((location.region != null && location.region != location.city) ? location.region + ', ' : '')
            + location.country + (location.zipCode !== null ? ` ${location.zipCode}` : '');
          this.assumed.emit(address);
        },
        () => {
          this.toastService.error("Google wasn't able to recognise address");
          this.assumed.emit("");
      });
  }

  /**
   * When we send part of address to Google Maps API's Autocomplete Service
   * we retrieve a list of the most popular places that contains this part of address.
   * @param id - unique id of session that uses for grouping requests to Google Maps API
   * @param address - address of searched place
   */
  predict(id: string, address: string): void {
    this.locationApiService.predict({
      locationRequest: {
        id: id,
        address: address
      }
    }).subscribe(res => {
      this.predicted.emit(res.address);
    });
  }
}
