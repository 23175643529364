<div class="main-container">
  <div class="container is-flex">
    <div class="info-block is-flex is-flex-direction-column under-top-toolbar">
      <div class="info-block__top">
        <h1 class="title mb-2">
          For Suppliers
        </h1>
        <div class="content is-flex-grow-1">
          <div class="pb-5 pr-6" [style.font-size.rem]="1.9">
            Gain new customers without the hassle
          </div>
          <div class="cs-list">
            <p class="is-size-5">Cultured Supply provides suppliers with buying requests and introductions to potential customers looking for biobased products.</p>
            <div class="is-flex mb-2">
              <div class="cs-bullet mr-2">
              </div>
              <div>
                <strong>Step 1:</strong> Submit supplier information form.
              </div>
            </div>
            <div class="is-flex mb-2">
              <div class="cs-bullet mr-2">
              </div>
              <div>
                <strong>Step 2:</strong> Schedule an onboarding call with Cultured Supply to complete company and product listings.
              </div>
            </div>
            <div class="is-flex mb-2">
              <div class="cs-bullet mr-2">
              </div>
              <div>
                <strong>Step 3:</strong> Receive buying requests from potential customers.
              </div>
            </div>
            <p class="is-size-5">There is no upfront cost to suppliers for company and product listings. As part of the onboarding process, we agree on a fee for completed sales.</p>
          </div>
        </div>
      </div>
      <div class="info-block__bottom is-flex is-justify-content-center">
        <img src="/assets/images/new-home/bioreactor.png" />
      </div>
    </div>
    <div class="form-block is-flex is-flex-direction-column under-top-toolbar">
      <form [formGroup]="forSupplierForm" *ngIf="forSupplierForm" (ngSubmit)="onSubmit(forSupplierForm)">
        <h2 class="subtitle is-4 mb-1 has-text-weight-bold">
          Supplier Information Form
        </h2>

        <div class="field pt-5">
          <label class="label">Company name:*</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="Space X" formControlName="companyName">
          </div>
        </div>

        <div id="name-line" class="field is-flex pt-5">
          <div class="field mb-0 is-flex-grow-1">
            <label class="label">First name:*</label>
            <div class="control">
              <input class="input is-medium" type="text" placeholder="John" formControlName="firstName">
            </div>
          </div>
          <div class="field is-flex-grow-1">
            <label class="label">Last name:*</label>
            <div class="control">
              <input class="input is-medium" type="text" placeholder="Doe" formControlName="lastName">
            </div>
          </div>
        </div>

        <div id="contact-line" class="field is-flex pt-5">
          <div class="field mb-0 is-flex-grow-1">
            <label class="label">Email address:*</label>
            <div class="control">
              <input class="input is-medium" type="email" placeholder="example@email.com" formControlName="email">
            </div>
          </div>

          <div class="field is-flex-grow-1">
            <label class="label">Phone number:*</label>
            <div class="control">
              <input class="input is-medium" type="text" placeholder="+1 (XXX) XXX-XXXX" formControlName="phone">
            </div>
          </div>
        </div>

        <div class="field pt-5">
          <label class="label">I want to:*</label>
          <div class="control">
            <select class="input is-medium" formControlName="goal">
              <option *ngFor="let goal of goals" value="{{goal}}">{{goal}}</option>
            </select>
          </div>
        </div>

        <div class="field pt-5">
          <label class="label">Additional Info:</label>
          <div class="control">
            <textarea class="textarea is-medium" type="text" placeholder="Tell us more about what you're doing!" formControlName="addInfo"></textarea>
          </div>
        </div>

        <div class="field my-4">
          <div class="control pt-5">
            <button class="button wide is-medium is-success has-text-weight-bold is-fullwidth"
               type="submit" [disabled]="forSupplierForm.invalid">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
