<div class="under-top-toolbar container ">
  <section class="hero">
    <div class="hero-body has-text-centered">
      <div class="title has-text-weight-semibold">
        Contact us
      </div>
    </div>
  </section>

  <div class="is-flex contact-form">
    <div class="contact-form__info-container">
      <div class="info-subtitle">Get in touch</div>
      <div class="info">Looking for help or a new successful partnership? Feel free to drop us a message with some details of your question. We will be happy to help you. We answer within 1 business day.</div>
      <div class="mb-1"><a href="mailto:support@culturedsupply.com">support@culturedsupply.com</a></div>
      <div><a href="tel:(313) 241-7544">(313) 241-7544</a></div>
    </div>
    <form [formGroup]="contactUsForm" *ngIf="contactUsForm" (ngSubmit)="submit(contactUsForm)" class="contact-form__form-container is-flex">
      <div class="field is-flex is-flex-direction-column is-flex-grow-1">
        <div class="field is-flex-grow-1">
          <label class="label">Your name:*</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="John" formControlName="firstName">
          </div>
        </div>

        <div class="field is-flex-grow-1">
          <label class="label">Your contact email:*</label>
          <div class="control">
            <input class="input is-medium" type="email" placeholder="example@email.com" formControlName="email">
          </div>
        </div>

        <div class="field">
          <label class="label">Your message:*</label>
          <div class="control">
            <textarea class="textarea is-medium" type="text" placeholder="Describe your appeal in more details" formControlName="message"></textarea>
          </div>
        </div>

        <div class="field mt-4">
          <div class="control">
            <button type="submit" class="button widest is-medium is-success has-text-weight-bold"
                    [disabled]="contactUsForm.invalid">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

