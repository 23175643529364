import {Component, OnInit} from '@angular/core';
import {Campaign, CampaignsApiService, SupplyChainBuilderApiService, SupplyChainDataApiService} from "../../api/cs";
import {environment} from "../../../environments/environment";
import {AuthComponent} from "../../core/components/auth-modal-window/auth.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'cs-new-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.scss']
})
export class NewHomeComponent implements OnInit {
  baseUrl = '';
  campaigns: Campaign[] = [];

  companiesAmount: number;
  productsAmount: number;

  constructor(private router: Router,
              private titleService: Title,
              private dialog: MatDialog,
              private supplyChainDataApiService: SupplyChainDataApiService) {}

  ngOnInit() {
    this.titleService.setTitle("Cultured Supply");
    if (this.router.url === '/authentication/login') {
      this.dialog.open(AuthComponent, {panelClass: 'custom-dialog-container', data: {mode: 'logIn'}});
    }
    if (this.router.url === '/authentication/signup') {
      this.dialog.open(AuthComponent, {panelClass: 'custom-dialog-container', data: {mode: 'signUp'}});
    }
    if (this.router.url === '/password/reset') {
      this.dialog.open(AuthComponent, {panelClass: 'custom-dialog-container', data: {mode: 'resetPwd'}});
    }
    this.setBaseUrl();
    this.getSupplyChainItemsAmount();
  }

  private setBaseUrl(): void {
    this.baseUrl = environment.baseUrl;
  };

  private getSupplyChainItemsAmount(): void {
    this.supplyChainDataApiService.getAmount({})
      .subscribe(itemsAmount => {
        this.companiesAmount = itemsAmount.companiesAmount || 0;
        this.productsAmount = itemsAmount.productsAmount || 0;
      })
  }
}
