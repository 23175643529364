import {Injectable} from '@angular/core';
import {DictionariesApiService, DictionaryAggregation, DictionaryValue} from '../../api/cs';
import {mapTo, Observable, shareReplay, startWith, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DictionariesService {
  private dictionaries: Array<DictionaryAggregation> = [];

  public dictionaries$: Observable<DictionaryAggregation[]>;
  public loaded$: Observable<boolean>;

  constructor(private dictionariesApiService: DictionariesApiService) {
    this.dictionaries$ = this.dictionariesApiService.getDictionaries({withoutItems: false})
      .pipe(
        shareReplay(1),
        tap((res) => {
          DictionariesService.sortItems(res);
          this.dictionaries = res;
        })
      );

    this.loaded$ = this.dictionaries$.pipe(
      mapTo(true),
      startWith(false)
    );
  }

  getDictionaryByName(name: string, excludeRetired?: boolean, additionalValues?: DictionaryValue[]): DictionaryValue[] {
    let dictionaryValues = this.dictionaries.find((dict) => dict.name == name)
      ?.items?.filter((item) => !(excludeRetired && item.retired)) ?? [];
    if (!!additionalValues && !(additionalValues.length == 1 && additionalValues[0] == {})) {
      for (let additionalValue of additionalValues) {
        dictionaryValues.push(additionalValue);
      }
    }
    return dictionaryValues
      .sort((a, b) => {
        if (name != "product-development-stage") {
          if (a.value && b.value) {
            return a.value.localeCompare(b.value);
          } else {
            return 0
          }
        } else {
          return 1;
        }
      })
      .filter((value, i, arr) => {
        return arr.findIndex(t => t.key === value.key) === i;
      });
  }

  getAllDictionaries(): DictionaryAggregation[] {
    return this.dictionaries;
  }

  getDictionaryValuesText(dictionaryValues?: DictionaryValue[]): string {
    return dictionaryValues?.map(v => v.value).join(', ') ?? '';
  }

  isItemRetired(dictionaryName: string, dictionaryItemKey: string): boolean {
    return !!this.getDictionaryByName(dictionaryName)
      ?.find((item) => item.key === dictionaryItemKey)?.retired;
  }

  public static sortItems(res: Array<DictionaryAggregation>) {
    res.forEach((aggregation) => {
      if (aggregation.name !== "product-development-stage") {
        aggregation.items?.sort((a, b) => {
          if (a.value && b.value) {
            return a.value.localeCompare(b.value);
          } else {
            return 0
          }
        })
      }
    })
  }

  public static resolveSubTabs(groups: string[], res: Array<DictionaryAggregation>): Map<string, string[]> {
    let subTabsMap = new Map<string, string[]>();
    groups.forEach(group => {
      let subTabs: string[] = [];
      res.forEach(aggregation => {
        if (aggregation.title?.toLowerCase().startsWith(group.toLowerCase())) {
          subTabs.push(aggregation.title);
        }
      })
      subTabsMap.set(group, subTabs);
    })
    return subTabsMap;
  }

  public static orderDictionaries(groups: string[], res: Array<DictionaryAggregation>): Array<DictionaryAggregation> {
    let orderedArray: Array<DictionaryAggregation> = [];
    groups.forEach(group => {
      let orderedGroup: Array<DictionaryAggregation> = [];
      res.forEach(aggregation => {
        if (aggregation.title?.toLowerCase().startsWith(group.toLowerCase())) {
          orderedGroup[aggregation.sequenceNumber || 0] = aggregation;
        }
      });
      orderedGroup.forEach(dict => orderedArray.push(dict));
    });
    return orderedArray;
  }
}
