import { Component, OnInit } from '@angular/core';
import {
  CompaniesApiService,
  Company,
  UsersApiService,
  User,
  CompanyVisibilityStatusValue,
  CompanyVerificationStatusValue
} from '../../../api/cs';
import {LoaderService} from '../../../shared/services/loader.service';
import {finalize} from 'rxjs';
import {BreadcrumbService} from "xng-breadcrumb";
import {Title} from "@angular/platform-browser";
import {MatTableValue} from "../../../shared/components/mat-table/mat-table.component";

/**
 * Entity that will be sent to mat-table-component
 * Each record is a row
 * Each field is a name of table column
 * Each MatTableValue.value - is a cell value
 * Each MatTableValue.link - link to which user will redirect if he clicks on value
 */
class CompanyTableRecord {

  name: MatTableValue;
  description: MatTableValue;
  contact: MatTableValue;
  status: MatTableValue;
  visibility: MatTableValue;
  buyer: MatTableValue;
  supplier: MatTableValue;

  constructor(name: MatTableValue, description: MatTableValue, contact: MatTableValue, status: MatTableValue, visibility: MatTableValue, buyer: MatTableValue, supplier: MatTableValue) {
    this.name = name;
    this.description = description;
    this.contact = contact;
    this.status = status;
    this.visibility = visibility;
    this.buyer = buyer;
    this.supplier = supplier;
  }
}

@Component({
  selector: 'cs-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit {

  companies: Company[];
  users: User[];

  tableData: CompanyTableRecord[] = [];

  COMPANIES_URI_PREFIX = "/companies/";
  USERS_URI_PREFIX = "/administration/users/";


  constructor(private companiesApiService: CompaniesApiService,
              private userApiService: UsersApiService,
              private loader: LoaderService,
              private breadcrumbService: BreadcrumbService,
              private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Companies | Cultured Supply");
    this.breadcrumbService.set('companies', 'Companies');
    this.loader.setVisible(true);
    this.userApiService.getUserSearchResult()
      .subscribe(result => {
        this.users = result.items ?? [];
      });
    this.companiesApiService.getCompanySearchResult()
      .pipe(finalize(() => this.loader.hide()))
      .subscribe(result => {
        this.companies = result.items ?? [];
        let companiesRecords = this.companies.map(company => {
          let companyContact = this.getContact(company);
          return new CompanyTableRecord(
            new MatTableValue(company.name, this.COMPANIES_URI_PREFIX + company.alias),
            new MatTableValue(company.brief),
            new MatTableValue(companyContact.email, this.USERS_URI_PREFIX + companyContact.id),
            new MatTableValue(this.getCompanyStatus(company?.verificationStatus)),
            new MatTableValue(this.getCompanyStatus(company?.visibilityStatus)),
            new MatTableValue(company.buyer ? 'Yes' : 'No'),
            new MatTableValue(company.supplier ? 'Yes' : 'No')
          );
        });
        this.tableData.push(...companiesRecords);
      });
  }

  getContact(company: Company): User {
    return this.users?.filter(s => s.company?.id == company.id)[0] || {};
  }

  /**
   * Transforms Status Value to Camel Case
   * @param status - value of company status
   */
  getCompanyStatus(status: CompanyVisibilityStatusValue | CompanyVerificationStatusValue | undefined): string {
    if (status) {
      if (status?.length > 2) {
        return status.substring(0, 1).concat(status.substring(1, status.length).toLowerCase());
      } else {
        return status;
      }
    } else {
      return '';
    }
  }
}
