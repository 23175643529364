import {Component, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {LoaderService} from '../../../../shared/services/loader.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  ConfirmationWindowComponent
} from "../../../../core/components/confirmation-window/confirmation-window.component";
import {BehaviorSubject, map, Observable, of, startWith} from "rxjs";

@Component({
  selector: 'cs-list-products-action-modal',
  templateUrl: './list-products-action-modal.component.html',
  styleUrls: ['./list-products-action-modal.component.scss']
})
export class ListProductsActionModalComponent implements OnInit {

  form: FormGroup;
  productName: FormControl = new FormControl();
  productNameValue: string;
  productNames: string[] = [];
  productNames$: Observable<string[]>;

  @Output() productId = new BehaviorSubject<string | undefined>(undefined);

  constructor(@Inject(MAT_DIALOG_DATA) public data:
                  {
                    modalMode: "Add" | "Remove",
                    productMap: Map<string, string>
                  },
              private fb: FormBuilder,
              private loader: LoaderService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ListProductsActionModalComponent>)
  {}

  ngOnInit(): void {
    this.initForm();
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
    this.productNames = this.getValues(this.data.productMap);
    this.productNames$ = of(this.productNames);
    this.productNames$ = this.productName.valueChanges.pipe(
        startWith(''),
        map(value => {
          if (!value.hasOwnProperty("value")) {
            if (!!value) {
              return this.doFilter(value || '');
            } else {
              return this.productNames;
            }
          } else {
            return [];
          }
        }),
    );
  }

  private doFilter(value: string): string[] {
    if (!!value) {
      return Array.from(this.productNames)
          .filter(productName => productName.toLowerCase().includes(value?.toLowerCase()));
    } else {
      return [];
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      productName: this.productName
    });
  }

  confirmClose(): void {
    let confirmation = this.dialog.open(ConfirmationWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        header: "Window Close Confirmation",
        description: "discard changes and close the window"}
    });
    confirmation.beforeClosed().subscribe(() => {
      if (confirmation.componentInstance.confirmed.getValue()) {
        this.dialogRef.close();
      }
    })
  }

  public setProductName(event: any): void {
    if (!(event instanceof FormControl)) {
      this.productNameValue = event;
    }
  }

  submit(): void {
    this.loader.show();
    this.productId.next(this.getByValue(this.data.productMap, this.productNameValue));
    this.dialogRef.close();
  }

  private getValues(map: Map<string, string>): string[] {
    let values: string[] = [];
    for (let [key, value] of map.entries()) {
      values.push(value);
    }
    return values;
  }

  private getByValue(map: Map<string, string>, searchValue: string): string | undefined {
    for (const [key, value] of map.entries()) {
      if (value === searchValue) {
        return key;
      }
    }
    return undefined;
  }
}
