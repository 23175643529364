/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentMethodType } from './payment-method-type';
import { PaymentResultStatus } from './payment-result-status';


export interface PaymentInfo { 
    failure_message?: string;
    payment_method_id?: string;
    payment_type?: PaymentMethodType;
    receipt_url?: string;
    status?: PaymentResultStatus;
}

