import {CompanySearchResponse, ProductSearchResponse} from "../../api/cs";

/**
 * Defines data structure that provides to supply chain builder component to work with
 */
export class SupplyChainSearchResult {
  companies: CompanySearchResponse[];
  products: ProductSearchResponse[];
  filtersToSell: string[];
  filtersToUse: string[]

  constructor(companies: CompanySearchResponse[],
              products: ProductSearchResponse[],
              filtersToSell: string[],
              filtersToUse: string[]) {
    this.companies = companies;
    this.products = products;
    this.filtersToSell = filtersToSell;
    this.filtersToUse = filtersToUse;
  }
}
