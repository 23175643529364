<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">Add Product Certification</p>
    <span class="icon cs-icon-button" aria-label="close" (click)="confirmClose()">
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <form class="modal-card-body pt-6"
        *ngIf="form"
        [formGroup]="form"
        (ngSubmit)="submit()">
    <div class="field pb-4">
      <label class="label">Name:</label>
      <div class="control">
        <input class="input is-medium"
               type="text"
               placeholder="Biopreferred"
               formControlName="name"
        />
      </div>
    </div>

    <div class="field pb-4">
      <label class="label">File:</label>
      <div class="file has-name is-right is-fullwidth">
        <label class="file-label">
          <input class="file-input" type="file" (change)="onFileChoose($event)">
          <span class="file-cta">
            <span class="file-icon">
              <i class="fa fa-upload"></i>
            </span>
            <span class="file-label">
              Browse
            </span>
          </span>
          <span class="file-name">
            {{filename}}
          </span>
        </label>
      </div>
    </div>
    <div class="field is-grouped is-grouped-centered mt-6">
      <div class="control py-4">
        <button type="submit" class="button wide is-success has-text-weight-bold is-fullwidth">
          Save
        </button>
      </div>
    </div>
  </form>
</div>
