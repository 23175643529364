import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../../shared/services/auth.service';
import {environment} from "../../../environments/environment";

export const AUTH_HEADER = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private unsecuredEndpoints: string[] = [
    ".*/campaigns"
  ];

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.appendAuthHeader(request));
  }

  private appendAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    if (request.url.includes(environment.s3BucketName)) {
      return request;
    }
    if (request.url.includes("authentication") && !request.url.includes("admin/create")) {
      return request.clone({withCredentials: true});
    }
    if (this.authService.isLoggedIn) {
      return request.clone({
        headers: request.headers.set(AUTH_HEADER, `Bearer ${this.authService.token}`)
      });
    }
    for (let endpoint of this.unsecuredEndpoints) {
      if (request.url.match(endpoint)) {
        return request;
      }
    }

    return request;
  }
}
