/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';


export interface CreateSubmissionRequest { 
    id?: number;
    campaignAlias: string;
    quantity?: number;
    shippingCost?: number;
    orderMeasure?: number;
    deliveryAddress?: string;
    orderAmount?: number;
    fullUpfrontPayment?: boolean;
    paid?: boolean;
    creator?: User;
    buyer: User;
}

