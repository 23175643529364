import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';

/** A router wrapper, adding extra functions. */
@Injectable({
    providedIn: 'root'
})
export class RoutingHistoryService {

  private previousUrl: string | undefined = undefined;
  private currentUrl: string | undefined = undefined;

  constructor(private router : Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public getCurrentUrl(): string | undefined {
    return this.currentUrl;
  }

  public getPreviousUrl(): string | undefined {
    return this.previousUrl;
  }
}
