export const environment = {
  production: false,
  loopUrl: "https://dev.culturedsupply.com",
  baseUrl: "https://dev.culturedsupply.com",
  stripeApiPublishKey: 'pk_test_51LecBnDJIOnTz49H06l2mUITNKznSSpKNpuN1VYUv2JHIH4huDzHqDTI8Iy292F0V5pOisiApNsEry20DplByzYY00nUZLJiAw',
  HubSpotPortalId: '6232131',
  HubSpotSupplierFormId: '354556a6-c3ae-4def-b394-c3e655b0f221',
  HubSpotBuyerFormId: '725ad448-3eb5-48aa-87b0-d8c9ec301986',
  HubSpotFeedbackFormId: 'bd6a70ab-b66d-46cc-846d-4499dc700b97',
  HubSpotProductBuyingRequestFormId: 'accd7857-ed2e-4ab5-b679-fc96bfb4e706',
  HubSpotCompanyContactRequestFormId: 'e02d6e4b-ff0a-40f3-b266-44e35fd3d522',
  HubSpotSupplyChainSignUpRequestFormId: '39bf75c3-1c3d-4533-a774-e7663c339364',
  HubSpotSupplyChainSignInRequestFormId: 'b0fe29d1-d1a3-44fb-b61b-5c5356dafcb8',
  GA_TRACKING_ID: 'G-0QELLE4VE2',
  hideCampaigns: false,
  hotspotTrackingId: '6232131',
  s3DefaultApi: 's3.eu-west-3.amazonaws.com',
  s3BucketName: 'files-dev-culturedsupply',
  decryptPassword: 'w9z$C&F)J@NcRfUj',
};
