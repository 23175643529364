import {InjectionToken, Provider} from '@angular/core';
import {BASE_PATH} from './api/cs';
import {environment} from '../environments/environment';


const removeTrailingSlash = (value: string): string => value.replace(/\/$/, '');

const createProvider = (provide: InjectionToken<string>, path: string): Provider => ({
    provide,
    useFactory: (): string => {
        return removeTrailingSlash(String(path));
    }
});

export const API_PROVIDERS = [
    createProvider(BASE_PATH, environment.baseUrl),
];
