import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProductsListComponent} from "./components/products-list/products-list.component";
import {ProductComponent} from "./components/product/product.component";
import {AuthGuard} from "../core/guards/auth.guard";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ProductsListComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Products' }
      },
      {
        path: ':product-alias',
        component: ProductComponent,
        data: {breadcrumbs: {alias: 'Product'}}
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }

