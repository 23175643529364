/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentInfo } from './payment-info';
import { User } from './user';
import { SubmissionState } from './submission-state';
import { UnitScalingEntity } from './unit-scaling-entity';


export interface CampaignSubmission { 
    id?: number;
    campaignAlias?: string;
    quantity: number;
    orderMeasure?: UnitScalingEntity;
    shippingCost?: number;
    creator?: User;
    buyerInfo?: User;
    deliveryAddress: string;
    orderAmount: number;
    absoluteDiscountValue?: number;
    discountCode?: string;
    discountedOrderAmount?: number;
    fullUpfrontPayment: boolean;
    createSubmissionDate?: string;
    paid: boolean;
    submissionState: SubmissionState;
    paymentInfo?: PaymentInfo;
}

