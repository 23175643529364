import {Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationModalComponent } from '../authentication-modal/authentication-modal.component';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AuthService} from "../../../shared/services/auth.service";
import {SupplyChainAuthenticationApiService, SupplyChainDataApiService} from "../../../api/cs";
import {ToastService} from "../../../shared/services/toast.service";
import {Title} from "@angular/platform-browser";
import {HubspotFormModalComponent} from "../../../shared/components/hubspot-form-modal/hubspot-form-modal.component";

@Component({
  selector: 'cs-signup',
  templateUrl: './supply-chain-landing-page.component.html',
  styleUrls: ['./supply-chain-landing-page.component.scss']
})
export class SupplyChainLandingPageComponent implements OnInit {

  private static readonly LIST_YOUR_COMPANY_HUBSPOT_EMBED_CODE = {
    title: "List Your Company",
    form: {
      portalId: "25261979",
      formId: "018b8527-e7fc-4af6-b9ee-79ce69ab7595",
      target: "#hubspotForm"
    }
  };

  companiesAmount: number;
  productsAmount: number;

  constructor(private router: Router,
              private dialog: MatDialog,
              private titleService: Title,
              private authService: AuthService,
              private toastService: ToastService,
              private activatedRoute: ActivatedRoute,
              private supplyChainDataApiService: SupplyChainDataApiService,
              private authenticationApiService: SupplyChainAuthenticationApiService) { }

  ngOnInit() {
    this.titleService.setTitle("Supply Chain Builder | Cultured Supply");
    this.checkIfLoggedIn();
    this.checkIfIntendsToLogIn();
    this.getSupplyChainItemsAmount();
  }

  private checkIfLoggedIn(): void {
    if (this.authService.userInfo.getValue()?.role == "SUPPLY_CHAIN_USER" || this.authService.userInfo.getValue()?.role == "ADMIN") {
      this.router.navigate(["/algae"]).then();
    }
  }

  private checkIfIntendsToLogIn(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      let email = params['email'];
      let uuid = params['uuid'];
      if (!!email && !!uuid) {
        this.authenticationApiService.logInToSupplyChain({
          email: email,
          uuid: uuid
        }).subscribe(authResponse => {
          this.authService.updateTokens(authResponse);
          this.authService.updateUserInfo();
          this.router.navigate(["/algae"]).then();
        }, error => {
          if (+error.status == 419) {
            this.toastService.error("Sorry, your access link was expired. Please, request a new one");
            this.dialog.open(AuthenticationModalComponent, { data: { initMode: "Sign In" }});
          }
        })
      }
    });
  }

  private getSupplyChainItemsAmount(): void {
    this.supplyChainDataApiService.getAmount({})
      .subscribe(itemsAmount => {
        this.companiesAmount = itemsAmount.companiesAmount || 0;
        this.productsAmount = itemsAmount.productsAmount || 0;
      })
  }

  openAuthenticationModal() {
    this.dialog.open(AuthenticationModalComponent, { data: { initMode: "Sign Up" }});
  }

  openAddYourCompanyModal() {
    this.dialog.open(HubspotFormModalComponent, {
      panelClass: 'custom-dialog-container',
      data: SupplyChainLandingPageComponent.LIST_YOUR_COMPANY_HUBSPOT_EMBED_CODE
    });
  }

}
