import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from "rxjs";
import {environment} from "../environments/environment";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";

declare let gtag: Function;

@Component({
  selector: 'cs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cs';

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) {
    //Client side execution
    if (isPlatformBrowser(this.platformId)) {
      this.injectScript();
      this.grabTheTrackId();
      this.setUpAnalytics();
    }
  }

  /**
   * Every time Angular issues NavigationEnd event it catches actual url and send it to GA
   */
  setUpAnalytics() {
    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => {
        gtag('config', environment.GA_TRACKING_ID,
          {
            page_path: ev.urlAfterRedirects
          }
        );
      });
  }


  grabTheTrackId() {
    //Read the value of track id obtained in the global config variable.
    let gaTrackId = environment.GA_TRACKING_ID;

    //Add custom element of script with following attributes
    const node = this.document.createElement('script');
    node.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackId;
    node.type = 'text/javascript';
    node.async = true;
    node.defer = true;
    node.id = 'google-script-loader';
    this.document.getElementsByTagName('head')[0].appendChild(node);
  }

  injectScript() {
    const node = this.document.createElement('script');
    node.text = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());`;
    node.type = 'text/javascript';
    node.async = true;
    node.defer = true;
    node.id = 'google-gtag';
    this.document.getElementsByTagName('head')[0].appendChild(node);
  }
}
