import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/services/auth.service';
import {AuthComponent} from '../auth-modal-window/auth.component';
import {MatDialog} from "@angular/material/dialog";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";

@Component({
  selector: 'cs-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  menuShown = false;
  showAccountList: boolean = false;
  showAdminList: boolean = false;
  whiteBg = false;
  username: string;
  userCompanyAlias: string;
  email: string;
  isAlgae: boolean = false;

  constructor(private authService: AuthService,
              public router: Router,
              private dialog: MatDialog) {}

  ngOnInit(): void {
    this.authService.userInfo?.subscribe(user => {
      this.email = user?.email || '';
      this.username = `${user?.firstName} ${user?.lastName}`;
      this.userCompanyAlias = user?.company?.alias || '';
    });
    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => {
        if (ev.url.includes('/algae')) {
          this.isAlgae = true;
        } else {
          this.isAlgae = false;
        }
      });
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin();
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  get isSupplyChainUser(): boolean {
    return this.authService.isSupplyChainUser();
  }

  isDashboardVisible(): boolean {
    return this.isLoggedIn && !this.isAdmin && !this.isSupplyChainUser;
  }

  showLogInWindow() {
    this.dialog.open(AuthComponent, {panelClass: 'custom-dialog-container', data: {mode: "logIn"}});
  }

  showSignUpWindow() {
    this.dialog.open(AuthComponent, {panelClass: 'custom-dialog-container', data: {mode: "signUp"}});
  }

  toggleMenu(): void {
    this.menuShown = !this.menuShown;
  }

  adminListShow() {
    this.showAdminList = !this.showAdminList;
  }

  accountListShow() {
    this.showAccountList = !this.showAccountList;
  }

  logout() {
    this.authService.logout();
    this.menuShown = false;
  }
}
