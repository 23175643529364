<div class="modal" [class.is-active]="isModalActive">
  <div class="modal-background" (click)="closeWindow()"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered has-text-weight-bold">
        {{mode === 'EDIT' ? 'Edit Dictionary Item' : 'Add Dictionary Item'}}
      </p>
      <span class="icon cs-icon-button" aria-label="close" (click)="closeWindow()">
        <i class="fa fa-lg fa-times"></i>
      </span>
    </header>
    <section class="modal-card-body pt-6" [formGroup]="form" *ngIf="form" id="new-dictionary-item-modal-card">
      <div class="field pb-4">
        <label class="label">Dictionary:</label>
        <div class="control">
          <input class="input is-medium"
                 type="text"
                 formControlName="dictName"
          />
        </div>
      </div>

      <div class="field pb-4">
        <label class="label">Value</label>
        <div class="control">
          <input class="input is-medium"
                 type="text"
                 placeholder="Text value"
                 formControlName="value"
          />
        </div>
        <span *ngIf="isValueInvalid()"
          class="help-block">
          Enter valid dictionary value
        </span>
      </div>

      <div class="field pb-4">
        <label class="label">Description</label>
        <div class="control">
          <textarea class="textarea is-medium"
                    placeholder="Some info about the option"
                    formControlName="description"
          ></textarea>
        </div>
      </div>

      <div class="field" *ngIf="mode === 'EDIT'">
        <div class="control">
          <input id="switchExample"
                 type="checkbox"
                 name="switchExample"
                 class="switch is-danger is-rtl"
                 formControlName="retired"
          >
          <label class="label" for="switchExample">Retired</label>
        </div>
      </div>

      <div class="field is-grouped is-grouped-centered mt-6">
        <div class="control py-4">
          <a class="button wide is-success has-text-weight-bold is-fullwidth"
             (click)="submit()"
          >
            Save
          </a>
        </div>
      </div>
    </section>
  </div>
</div>
