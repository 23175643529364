/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum CampaignState {
    DRAFT = 'DRAFT',
    SUBMITTED_FOR_APPROVAL = 'SUBMITTED_FOR_APPROVAL',
    APPROVED = 'APPROVED',
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    HIDDEN = 'HIDDEN'
};

