<div class="under-top-toolbar">
  <section class="hero">
    <div class="hero-body has-text-centered container">
      <div class="title has-text-weight-semibold">
        Direct from the Factory FAQs
      </div>
    </div>
  </section>

  <section class="modal-card-body container">
    <div class="mt-4 pt-4">
      <article class="panel is-success" [class.mb-5]="i == QUESTIONS.length - 1"
               *ngFor="let question of QUESTIONS; let i = index"
      >
        <div class="question" (click)="expandedIndex = i == expandedIndex ? -1 : i">
          {{question.question}}
        </div>
        <div class="answer" [id]=i [class.expanded]="i == expandedIndex">
          <div class="markdown is-inline" [id]="'markdown_' + i">
            {{this.parse(i)}}
          </div>
        </div>
      </article>
    </div>
  </section>
</div>
