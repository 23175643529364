<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">Buy Sample</p>
    <span class="icon cs-icon-button" aria-label="close" mat-dialog-close>
        <i class="fa fa-lg fa-times"></i>
      </span>
  </header>
  <section class="modal-card-body" [formGroup]="sampleEntityForm">
    <div *ngIf="!!data.campaign" class="block-info__numbers is-flex is-justify-content-space-between is-align-items-center">
      <div>
        <div class="info-number-title">PRICE</div>
        <div class="info-number">{{data.campaign?.unitCurrency}}{{data.campaign?.sampleCost | number: '1.2'}}</div>
      </div>
      <div class="ml-6 has-text-justified">{{data.campaign?.sampleDescription}}</div>
    </div>
    <div *ngIf="!!data.product" class="block-info__numbers is-flex is-justify-content-space-between is-align-items-center">
      <div>
        <div class="info-number-title">PRICE</div>
        <div class="info-number">${{data.product?.pricingInfo?.sampleCost | number: '1.2'}}</div>
      </div>
      <div class="ml-6 has-text-justified">{{data.product.pricingInfo?.sampleDescription}}</div>
    </div>
    <form [formGroup]="sampleEntityForm" class="mt-2" (submit)="pay()">
      <div class="field" *ngIf="!user">
        <label class="label">Company:*</label>
        <div class="control">
          <input class="input is-medium" type="text" placeholder="Space X" formControlName="companyName" (keypress)="onlyEnglish($event)">
        </div>
      </div>
      <div class="field is-flex name-line" *ngIf="!user">
        <div class="field mb-0 is-flex-grow-1">
          <label class="label">First name:*</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="John" formControlName="firstName" (keypress)="onlyEnglish($event)">
          </div>
        </div>
        <div class="field is-flex-grow-1">
          <label class="label">Last name:*</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="Doe" formControlName="lastName" (keypress)="onlyEnglish($event)">
          </div>
        </div>
      </div>
      <div class="field" *ngIf="!user">
        <label class="label">Email address:*</label>
        <div class="control">
          <input class="input is-medium" type="email" placeholder="example@email.com" (keypress)="onlyEnglish($event)"
                 formControlName="email">
        </div>
      </div>
      <div class="field" *ngIf="!user">
        <label class="label">Phone:*</label>
        <div class="control">
          <input class="input is-medium" type="text" placeholder="+123456789090" formControlName="phone" (keypress)="onlyNumbers($event)">
        </div>
      </div>
      <div class="field pb-4 is-flex-grow-1">
        <label class="label">Delivery Address:*</label>
        <div class="control">
          <input class="input is-medium"
                 type="text"
                 placeholder="address, city, country"
                 aria-label="Delivery address"
                 matInput
                 formControlName="address"
                 [matAutocomplete]="auto"
                 (keypress)="onlyEnglish($event)"
                 (ngModelChange)="predict($event)">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="insert($event.option.value)">
            <mat-option *ngFor="let option of predictions" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          <label *ngIf="sampleEntityForm.controls['address']?.errors?.hasOwnProperty('illegal-area')" class="error"><i>This product can only be shipped in the United States.</i></label>
        </div>
      </div>
      <cs-payment [paymentAmount]="paymentAmount" [receiptEmail]="receiptEmail" (paymentMethod)="paymentMethod.next($event)" *ngIf="!isPaymentSuccessful"></cs-payment>
      <mat-checkbox class="mt-5 ml-4 mat-checkbox-2" (change)="intentToSubscribe = !intentToSubscribe" *ngIf="!user">
        <div class="intention ml-2">I agree to receive email notifications.
        </div>
      </mat-checkbox>
      <mat-checkbox class="mt-2 mb-2 ml-4 mat-checkbox-2" (change)="intentToRegister = !intentToRegister" *ngIf="!user">
        <div class="intention ml-2">I agree to the website’s
          <a href="https://s3.eu-west-3.amazonaws.com/shared.files.culturedsupply.com/TermsOfUse.pdf" target="_blank">Terms & Conditions</a>,
          <a href="https://s3.eu-west-3.amazonaws.com/shared.files.culturedsupply.com/PrivacyPolicy.pdf" target="_blank">Privacy Policy</a>
          and consent to creating<br> an account on culturedsupply.com.
        </div>
      </mat-checkbox>
      <div class="field is-grouped is-grouped-centered">
        <div class="control py-4">
          <button class="button wide is-success has-text-weight-bold is-fullwidth" [disabled]="isPaymentDisabled()" type="submit">{{isPaymentSuccessful ? 'Next' : 'Pay'}}</button>
        </div>
      </div>
    </form>
  </section>
  <footer class="modal-card-foot is-flex is-justify-content-center pt-4">
  </footer>
</div>
