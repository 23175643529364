import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersListComponent} from './components/users-list/users-list.component';
import {SharedModule} from '../shared/shared.module';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import {AdminCreateComponent} from "./components/admin-create/admin-create.component";
import {CompaniesModule} from "../companies/companies.module";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {BreadcrumbModule} from "xng-breadcrumb";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";


@NgModule({
  declarations: [
    UsersListComponent,
    UserProfileComponent,
    UserFormComponent,
    UserEditComponent,
    AdminCreateComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CompaniesModule,
    MatAutocompleteModule,
    BreadcrumbModule,
    MatTableModule,
    MatSortModule
  ]
})
export class UsersModule { }
