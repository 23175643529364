import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MatDialogModule} from "@angular/material/dialog";
import {CampaignStatsComponent} from './components/campaign-stats/campaign-stats.component';
import {PaymentComponent} from "../payment/payment/payment.component";
import {NgxStripeModule} from "ngx-stripe";
import {environment} from "../../environments/environment";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {NgxMaskModule} from "ngx-mask";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {MatCardModule} from "@angular/material/card";
import {SubmissionOrderModal} from "../payment/submission-order-modal/submission-order-modal.component";
import {OrderBySequenceNumberAscPipe} from "./pipes/sequence-number.pipe";
import {CampaignsCarouselComponent} from "./components/campaigns-carousel/campaigns-carousel.component";
import {MatTableComponent} from './components/mat-table/mat-table.component';
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {UserEmailDirective} from "./directives/trim.directive";
import {FileUploadModal} from "./components/file-upload-modal/file-upload-modal";
import {ToCamelCasePipe} from "./pipes/to-camel-case.pipe";


@NgModule({
  declarations: [
    CampaignStatsComponent,
    PaymentComponent,
    SubmissionOrderModal,
    OrderBySequenceNumberAscPipe,
    ToCamelCasePipe,
    CampaignsCarouselComponent,
    MatTableComponent,
    UserEmailDirective,
    FileUploadModal,
  ],
  imports: [
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    NgxStripeModule.forRoot(environment.stripeApiPublishKey),
    PdfViewerModule,
    NgxMaskModule,
    MatCheckboxModule,
    SlickCarouselModule,
    MatCardModule,
    MatSortModule,
    MatTableModule,
  ],
  exports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    CampaignStatsComponent,
    PaymentComponent,
    MatDialogModule,
    PdfViewerModule,
    SubmissionOrderModal,
    NgxMaskModule,
    MatCheckboxModule,
    SlickCarouselModule,
    MatCardModule,
    OrderBySequenceNumberAscPipe,
    ToCamelCasePipe,
    CampaignsCarouselComponent,
    MatTableComponent,
    UserEmailDirective
  ]
})
export class SharedModule {
}
