<ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">
  <ngx-stripe-payment [clientSecret]="clientSecret">
  </ngx-stripe-payment>
</ng-container>
<div class="loader-wrapper" [class.is-active]="!elementsOptions?.clientSecret"
     *ngIf="!elementsOptions?.clientSecret">
  <div class="loader is-success is-loading"></div>
</div>
<p class="mt-1">Credit card payments may be subject to additional charges from your card provider</p>
<mat-checkbox class="mt-3 mb-2 ml-4 mat-checkbox-2" *ngIf="showSubscriptionCheckBox()" (change)="intentToSubscribe = !intentToSubscribe">
  <div class="intention ml-2">I agree to receive email notifications.
  </div>
</mat-checkbox>
