<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">
      Cultured Supply has received your request!
    </p>
    <span class="icon cs-icon-button" aria-label="close" mat-dialog-close>
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <section class="modal-card-body">
      <div class="is-flex is-justify-content-space-evenly is-align-items-center">
        <figure class="image info-icon">
          <img src="../../../../assets/images/checked.png">
        </figure>
        <quill-view content="<strong>{{data.message}}</strong>"></quill-view>
      </div>
      <div class="is-flex is-grouped-centered is-justify-content-space-around">
        <div class="control py-4 is-flex is-justify-content-center is-align-items-center">
          <button class="button button-primary wide has-text-weight-bold" (click)="copyReferal()">Copy the Invitation</button>
        </div>
      </div>
  </section>
  <footer class="modal-card-foot is-flex is-justify-content-center pt-4">
  </footer>
</div>
