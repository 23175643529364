import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProfileComponent} from './components/profile/profile.component';
import {SharedModule} from '../shared/shared.module';
import {CompaniesListComponent} from './components/companies-list/companies-list.component';
import {EditCompanyMainInfoFormComponent} from './components/profile/edit-company-main-info-form/edit-company-main-info-form.component';
import {EditCompanyGeneralInfoFormComponent} from './components/profile/edit-company-general-info-form/edit-company-general-info-form.component';
import {EditCompanyCapacityFormComponent} from './components/profile/edit-company-capacity-form/edit-company-capacity-form.component';
import {EditCompanyRndFormComponent} from './components/profile/edit-company-rnd-form/edit-company-rnd-form.component';
import {EditCompanyCertificationFormComponent} from './components/profile/edit-company-certification-form/edit-company-certification-form.component';
import {QuillModule} from "ngx-quill";
import {EditCompanyStatusFormComponent} from './components/profile/edit-company-status-form/edit-company-status-form.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {BreadcrumbModule} from "xng-breadcrumb";
import {ContactFormComponent} from "./components/contact-form/contact-form.component";
import {MarkdownModule} from "ngx-markdown";

@NgModule({
  declarations: [
    ProfileComponent,
    CompaniesListComponent,
    ContactFormComponent,
    EditCompanyMainInfoFormComponent,
    EditCompanyGeneralInfoFormComponent,
    EditCompanyCapacityFormComponent,
    EditCompanyRndFormComponent,
    EditCompanyCertificationFormComponent,
    EditCompanyStatusFormComponent
  ],
  exports: [
    EditCompanyMainInfoFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    QuillModule,
    MatAutocompleteModule,
    BreadcrumbModule,
    MarkdownModule
  ]
})
export class CompaniesModule {
}
