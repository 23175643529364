/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CampaignState } from './campaign-state';
import { DictionaryValue } from './dictionary-value';
import { ImageFileReference } from './image-file-reference';
import { UnitScalingEntity } from './unit-scaling-entity';
import { ShippingCostInterval } from './shipping-cost-interval';


export interface Campaign { 
    id?: string;
    alias?: string;
    companyId?: string;
    companyAlias?: string;
    productId?: string;
    productImage?: string;
    productName?: string;
    productAlias?: string;
    title?: string;
    subtitle?: string;
    shortDescription?: string;
    productType?: DictionaryValue;
    industryFocuses?: Array<DictionaryValue>;
    productCategory?: DictionaryValue;
    originCountry?: string;
    location?: string;
    images?: Array<ImageFileReference>;
    video?: string;
    measurePolicy?: string;
    unitCount?: number;
    unitMeasure?: UnitScalingEntity;
    minimumOrder?: number;
    minimumOrderMeasure?: UnitScalingEntity;
    maximumOrder?: number;
    maximumOrderMeasure?: UnitScalingEntity;
    orderStep?: number;
    orderStepMeasure?: UnitScalingEntity;
    unitPrice?: number;
    unitCurrency?: string;
    goalAmount?: number;
    sampleCost?: number;
    shippingCostIntervals?: Array<ShippingCostInterval>;
    sampleDescription?: string;
    deliveryDate?: string;
    deliveryTime?: number;
    description?: string;
    specification?: string;
    specSheetFileRef?: string;
    faq?: string;
    howItsMade?: string;
    meetTheTeam?: string;
    name?: string;
    email?: string;
    bio?: string;
    company_logo?: string;
    company_name?: string;
    company_brief?: string;
    company_location?: string;
    template_key?: string;
    paymentTerms?: string;
    startCampaignDate?: string;
    state?: CampaignState;
    sequenceNumber?: number;
    isFeatured?: boolean;
}

