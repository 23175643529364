<div class="under-top-toolbar container ">
  <section class="hero">
    <div class="hero-body has-text-centered">
      <div class="title has-text-weight-semibold">
        Manifesto
      </div>
    </div>
  </section>
  <div class="is-flex is-flex-direction-column is-align-items-center pt-4">
    <p>
      Growing up in Detroit, Michigan, I was raised in a place where the livelihoods of our communities were heavily dependent on companies making and designing physical products related to the automobile. Detroit’s economy boomed throughout the first half of the 20th century as the big three US automakers: Ford, Chrysler, and General Motors, built large manufacturing plants to mass produce cars and developed complex supply chains throughout the region to support the industry.
    </p>
    <p>
      The city was a shining example of how the Industrial Revolution of the 20th century led to building centralized systems of production of immense scale and automation.   This enabled us to produce relatively affordable food, energy, medicine, and products to support a growing global population.
    </p>
    <p>
      And now, we live in a world of abundance.
    </p>
    <p>
      But, the problem is our current man-made way of production comes at a cost - it relies on large amounts of land to feed animals, the extraction of fossil fuels, and the transportation of goods across several continents before a finished product is delivered to its final destination. In fact, the way we make things account for 31% of total annual CO2 emissions. With the last several years being the hottest ever recorded on Earth, we have reached the limit.
    </p>
    <p class="has-text-weight-bold is-italic">
      We must fundamentally change our systems of production.
    </p>

    <p>
      The good news is mother nature has provided us with an endless amount of energy and molecules to explore. The even better news is that over the last 50 years there have been mind-bending discoveries and breakthroughs - ranging from physics to biology to chemistry - to help us better understand nature and leverage it to re-engineer molecules and how we make things. These scientific discoveries are now colliding with software, hardware, and electronics that can lead to a Cambrian explosion of bio-based ingredients, chemicals, materials, and products. But, we need to move beyond the idea of sustainability and the status quo. We must move towards regenerative solutions that can help restore nature, climate, and human health.
    </p>

    <p>
      Franklin D. Roosevelt and the United States government set up the <a href="https://en.wikipedia.org/wiki/War_Production_Board" target="_blank">War Production Board</a> in 1942 to manage production during WWII. It directed companies to convert their production lines from peacetime work to war needs, allocated scarce materials, established priorities in the distribution of materials and services, and prohibited nonessential production. It also rationed such commodities as gasoline, heating oil, metals, rubber, paper and plastics.
    </p>

    <p>
      From 1942–45, the War Production Board supervised the production of $183 billion worth of weapons and supplies, about 40% of the world output of munitions.
    </p>

    <p>
      The story of the War Production Board demonstrates the progress that can be made when humans rally around a unified mission, act with a sense of urgency, and the necessary resources are deployed to support such efforts.
    </p>

    <p>
      We are in a race against the clock to change our systems of production and improve planetary health. It is all hands on deck and we look forward to working with you to create the future.
    </p>

    <p>
      Be great,
    </p>

    <p>
      <img class="signature-image" src="assets/images/Jordan-Wolfe-digital-signature-blue_acs.png">
    </p>

    <p>
      Jordan Wolfe
      <br/>
      Founder, Cultured Supply
    </p>

  </div>
</div>
