<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">{{modalDescription}} Editing</p>
    <span class="icon cs-icon-button" aria-label="close" (click)="confirmClose()">
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <form class="modal-card-body pt-6"
        id="product-image-modal-card"
        (ngSubmit)="submit()">
    <div class="field pb-4">
      <label class="label">{{modalDescription}}:</label>
      <div class="file has-name is-right is-fullwidth">
        <label class="file-label">
          <input class="file-input" type="file" [accept]="acceptedFormats" (change)="onFileChoose($event)">
          <span class="file-cta">
            <span class="file-icon">
              <i class="fa fa-upload"></i>
            </span>
            <span class="file-label">
              Browse
            </span>
          </span>
          <span class="file-name">
            {{filename}}
          </span>
        </label>
      </div>
      <div *ngIf="data.isImage">
        <p class="help">
          The file should be a <b>PNG</b> or a <b>JPEG</b>.
        </p>
        <p class="help">
          We recommend it to be at least 1024px on the long side. 4:3 ratio fits best.
        </p>
      </div>
    </div>

    <div *ngIf="data.isImage"
         class="logo-preview is-flex is-justify-content-center">
      <img src="{{url}}">
    </div>

    <div class="field is-grouped is-grouped-centered mt-6">
      <div class="control py-4">
        <button type="submit"
                class="button wide is-success has-text-weight-bold is-fullwidth"
                [disabled]="!file">
          Save
        </button>
      </div>
    </div>
  </form>
</div>
