<td data-label="Name" class="name-col">
  <a routerLink="/companies/{{product.companyAlias}}/products/{{product.alias}}" class="has-text-weight-bold">{{product.headingInfo?.name}}</a>
  <div class="product-property">
    <div *ngFor="let platform of product.headingInfo.chassis" class="platform-line is-flex is-align-items-center">
      <p class="has-text-grey is-size-6 mr-1">
        {{platform}}
      </p>
      <img src="{{platformImageService.getPlatformImage(platform)}}"
           *ngIf="platformImageService.isPlatformImageExists(platform)">
    </div>
    <span class="tag verified-label " *ngIf="product.headingInfo?.verified">
      Verified
    </span>
  </div>
</td>

<td data-label="Company" class="cmp-col">
  <a [href]="['/companies/' + product.companyAlias]" class="has-text-weight-bold">{{product.companyName}}</a>
  <div class="product-property">
    <span class="tag verified-label " *ngIf="product.isCompanyVerified">
      Verified
    </span>
  </div>
</td>

<td data-label="Description" class="desc-col">
  <div class="description">
    <quill-view *ngIf="!isLongText"
                [content]="getDescription(product)">
    </quill-view>
    <quill-view *ngIf="isLongText"
                [content]="getDescription(product) + '<a href=\'/companies/' + product.companyAlias + '/products/' + product.alias + '\'> see more.</a>'">
    </quill-view>
  </div>
</td>

<td class="cell-contact add-info-col" data-label="Contact">
  <div class="product-property" *ngIf="showType">
    <span class="has-text-weight-bold has-text-grey-dark pr-2">
      Product Type:
    </span>
    <span class="has-text-grey-dark">
      {{product.generalInfo?.type}}
    </span>
  </div>

  <div class="product-property product-property__category" *ngIf="showCategory">
    <span class="has-text-weight-bold has-text-grey-dark pr-2">
      Product Category:
    </span>
    <span class="has-text-grey-dark">
      {{product.generalInfo?.category}}
    </span>
  </div>

  <div class="product-property product-property__recommendation" *ngIf="showRecommendation">
    <p class="recommendations">
      <span class="has-text-weight-bold has-text-grey-dark pr-2">
        Recommended Use:
      </span>
      <span class="has-text-grey-dark">
        {{setRecommendation(product)}}
      </span>
      <a *ngIf="isLongText"
         [href]="['/companies/' + product.companyAlias + '/products/' + product.id]"
         target="_self"> see more.</a>
    </p>
  </div>

  <div class="product-property product-property__stage" *ngIf="showDevelopmentStage">
    <span class="has-text-weight-bold has-text-grey-dark pr-2">
      Stage of development:
    </span>
    <span class="has-text-grey-dark">
      {{product.headingInfo?.developmentStage?.value}}
    </span>
  </div>
</td>

<td *ngIf="showActions">
  <div cs-product-actions
       *ngIf="showActions"
       [isAdmin]="isAdmin"
       [product]="product"
       [isVerticalAlignment]="true"
       (updatedProduct)="updateProduct($event)"
       (createdProduct)="addProduct($event)">
  </div>
</td>
