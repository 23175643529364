import {Component, OnInit} from '@angular/core';
import {
  ProductsApiService,
  User
} from '../../../api/cs';
import {BehaviorSubject, finalize} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {LoaderService} from '../../../shared/services/loader.service';
import {PlatformImageService} from "../../../shared/services/platform-image.service";
import {AuthService} from "../../../shared/services/auth.service";
import {Title} from "@angular/platform-browser";
import {FeaturedProductsListSearchResult} from "../../../api/cs/model/featured-products-list-search-result";
import {FeaturedProductsApiService} from "../../../api/cs/api/featured-products-api.service";
import {MatDialog} from "@angular/material/dialog";
import {ToastService} from "../../../shared/services/toast.service";
import {ListEditModalComponent} from "./list-edit-modal/list-edit-modal.component";
import {FeaturedProductsList} from "../../../api/cs/model/featured-products-list";
import {ConfirmationWindowComponent} from "../../../core/components/confirmation-window/confirmation-window.component";
import {ListProductsActionModalComponent} from "./list-products-action-modal/list-products-action-modal.component";
import {HubspotFormModalComponent} from "../../../shared/components/hubspot-form-modal/hubspot-form-modal.component";
import {RoutingHistoryService} from "../../../shared/services/routing-history.service";

@Component({
  selector: 'cs-featured-products',
  templateUrl: './featured-products.component.html',
  styleUrls: ['./featured-products.component.scss']
})
export class FeaturedProductsComponent implements OnInit {

  isAdmin: boolean;
  user: User | null;
  productIdentifiersMap: Map<string, string> = new Map<string, string>();

  list$: BehaviorSubject<FeaturedProductsListSearchResult | undefined> =
      new BehaviorSubject<FeaturedProductsListSearchResult | undefined>(undefined);

  constructor(
    private loader: LoaderService,
    private titleService: Title,
    private authService: AuthService,
    public platformImageService: PlatformImageService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private featuredProductsApiService: FeaturedProductsApiService,
    private productsApiService: ProductsApiService) { }

  ngOnInit(): void {
    this.titleService.setTitle("Products | Cultured Supply");
    this.isAdmin = this.authService.isAdmin();
    this.user = this.authService.userInfo.getValue();
    this.loader.setVisible(true);
    this.load();
  }

  private load(): void {
      this.featuredProductsApiService.getFeaturedProductSearchResult({})
          .pipe(finalize(() => this.loader.setVisible(false)))
          .subscribe((list: FeaturedProductsListSearchResult) => {
              this.list$.next(list);
          });
      this.productsApiService.getProductIdentifiersSearchResult({
      }).subscribe(productsIdentifiers => {
          this.productIdentifiersMap = this.convertMap(productsIdentifiers.map);
      });
  }

    private convertMap(map: Map<string, string>): Map<string, string> {
        let convertedMap: Map<string, string> = new Map<string, string>();
        for (const [key, value] of Object.entries(map)) {
            convertedMap.set(key, value);
        }
        return convertedMap;
    }

  get baseUrl(): string {
    return environment.baseUrl;
  }

  public showListCreateModal(): void {
    let matDialogRef = this.dialog.open(ListEditModalComponent, {panelClass: 'custom-dialog-container',
      data: { list: undefined, modalMode: "Create" }});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.list?.subscribe((list: FeaturedProductsList) => {
          this.featuredProductsApiService.createFeaturedProductsList({
              list: list
          }).pipe(finalize(() => this.loader.hide()))
              .subscribe((list => {
                this.load();
                this.toastService.success("New List with name: " + list.name + " was successfully created");
              }));

      }));
  }

  public showListUpdateModal(list: FeaturedProductsList): void {
      let matDialogRef = this.dialog.open(ListEditModalComponent, {panelClass: 'custom-dialog-container',
          data: { list: list, modalMode: "Edit" }});
      matDialogRef
          .afterOpened()
          .subscribe(() => matDialogRef.componentInstance.list?.subscribe((list: FeaturedProductsList) => {
              this.featuredProductsApiService.updateFeaturedProductsList({
                  id: list.id,
                  list: list
              }).pipe(finalize(() => this.loader.hide()))
                  .subscribe((list => {
                      this.load();
                      this.toastService.success("List with name: " + list.name + " was successfully updated");
                  }));

          }));
  }

    public showLostDeleteModal(list: FeaturedProductsList): void {
        let confirmation = this.dialog.open(ConfirmationWindowComponent, {
            panelClass: 'custom-dialog-container',
            data: {
                header: "List Delete Confirmation",
                description: "delete this list" }
        });
        confirmation.beforeClosed().subscribe(() => {
            if (confirmation.componentInstance.confirmed.getValue()) {
              this.featuredProductsApiService.deleteFeaturedProductsList({
                  id: list.id!
              }).subscribe(() => {
                this.load();
                  this.toastService.success("List with name: " + list.name + " was successfully deleted");
              })
            }
        })
    }

    public showProductAddModal(featuredProductList: FeaturedProductsList): void {
      let unavailableForAddingProducts = featuredProductList.products?.items?.map(product => product.id);
      let availableForAddingProducts: Map<string, string> = new Map<string, string>();
      for (const [key, value] of this.productIdentifiersMap) {
          if (!unavailableForAddingProducts?.includes(key)) {
              availableForAddingProducts.set(key, value);
          }
      }
        let matDialogRef = this.dialog.open(ListProductsActionModalComponent, {
            panelClass: 'custom-dialog-container',
            data: {
                modalMode: "Add",
                productMap: availableForAddingProducts }
        });
        matDialogRef.beforeClosed().subscribe(() => {
            let productId = matDialogRef.componentInstance.productId.getValue();
            if (!!productId) {
                this.featuredProductsApiService.addProductToFeaturedList({
                    productId: productId,
                    listId: featuredProductList.id || ''
                }).pipe(finalize(() => this.loader.hide()))
                    .subscribe(() => {
                        this.load();
                        this.toastService.success("Product was added to: " + featuredProductList.name);
                })
            }
        })
    }

    public showProductDeleteModal(featuredProductList: FeaturedProductsList): void {
      let existingProductsMap: Map<string, string> = new Map<string, string>();
      featuredProductList.products?.items?.forEach(product => existingProductsMap.set(product.id, product.headingInfo.name));
        let matDialogRef = this.dialog.open(ListProductsActionModalComponent, {
            panelClass: 'custom-dialog-container',
            data: {
                modalMode: "Remove",
                productMap: existingProductsMap }
        });
        matDialogRef.beforeClosed().subscribe(() => {
            let productId = matDialogRef.componentInstance.productId.getValue();
            if (!!productId) {
                this.featuredProductsApiService.removeProductFromFeaturedList({
                    productId: productId,
                    listId: featuredProductList.id || ''
                }).pipe(finalize(() => this.loader.hide()))
                    .subscribe(() => {
                        this.load();
                        this.toastService.success("Product was removed from: " + featuredProductList.name);
                })
            }
        })
    }

    showSampleModal(featuredProductsList: FeaturedProductsList): void {
      this.dialog.open(HubspotFormModalComponent, {
            panelClass: 'custom-dialog-container',
            data: JSON.parse(featuredProductsList.hubspotFormEmbedCode || '')
        });
    }
}
