<div class="under-top-toolbar container">
  <div class="page-title">Dictionaries Management</div>

  <div class="tabs">
    <ul>
      <li [ngClass]="{'is-active': selectedTab === 'Common'}" (click)="selectedTab = 'Common'; selectedSubTabIndex = 0"><a>Common</a></li>
      <li [ngClass]="{'is-active': selectedTab === 'Company'}" (click)="selectedTab = 'Company'; selectedSubTabIndex = 0"><a>Company</a></li>
      <li [ngClass]="{'is-active': selectedTab === 'Product'}" (click)="selectedTab = 'Product'; selectedSubTabIndex = 0"><a>Product</a></li>
    </ul>
  </div>

  <div class="tabs sub-tabs">
    <ul>
      <li *ngFor="let subTab of subTabMap.get(selectedTab); let i = index"
          [ngClass]="{'is-active': selectedSubTabIndex === i}"
          (click)="selectedSubTabIndex = i">
        <a>
          {{getTabName(subTab)}}
        </a>
      </li>
    </ul>
  </div>

  <div class="mt-5 mb-5">
    <div class="dictionary-block" *ngIf="getFilteredData() as dict">
      <h2 class="is-size-5 has-text-weight-bold pt-5">
        {{selectedTab}} - {{getTabName(dict.title || '')}}
      </h2>
      <div class="b-table">
        <div class="table-wrapper has-mobile-cards">
          <table class="table my-5">
            <thead>
              <tr>
                <th [style.width.%]="30">Value</th>
                <th [style.width.%]="50">Description</th>
                <th [style.width.%]="20">Retired</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of dict.items">
                <td data-label="Value">
                  {{item.value}}
                </td>
                <td data-label="Description">
                  {{item.description}}
                </td>
                <td data-label="Retired">
                  {{item.retired ? 'Yes' : 'No'}}
                </td>
                <td class="is-actions-cell">
                  <div class="buttons is-right">
                    <button class="button is-small is-info jb-modal"
                            type="button"
                            (click)="itemForm.showWindow(dict.name || '', 'EDIT', item)"
                    >
                      <span class="icon">
                        <i class="fa fa-lg fa-pencil"></i>
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
            <tr>
              <td class="is-actions-cell">
                <div class="buttons is-left">
                  <button class="button is-small is-success jb-modal"
                          type="button"
                          (click)="itemForm.showWindow(dict.name || '', 'CREATE')"
                  >
                      <span class="icon">
                        <i class="fa fa-lg fa-plus"></i>
                      </span>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>


  <div class="mt-5 mb-5" *ngIf="selectedTab == 'Common' && selectedSubTabIndex == ((subTabMap?.get('Common')?.length || 0) - 1)">
    <div class="dictionary-block">
      <h2 class="is-size-5 has-text-weight-bold pt-5">
        Common - Measure Units
      </h2>
      <div class="b-table">
        <div class="table-wrapper has-mobile-cards">
          <table class="table my-5">
            <thead>
              <tr>
                <th [style.width.%]="13">Measure Policy</th>
                <th [style.width.%]="19">Plural Name</th>
                <th [style.width.%]="19">Singular Name</th>
                <th [style.width.%]="13">Short Plural Name</th>
                <th [style.width.%]="13">Short Singular Name</th>
                <th [style.width.%]="7">Scaling Factor</th>
                <th [style.width.%]="6">Retired</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let measure of measures">
                <td data-label="Policy">
                  {{measure.measurePolicy}}
                </td>
                <td data-label="PluralName">
                  {{measure.measurePluralName}}
                </td>
                <td data-label="SingularName">
                  {{measure.measureSingularName}}
                </td>
                <td data-label="ShortPluralName">
                  {{measure.measureShortPluralName}}
                </td>
                <td data-label="ShortSingularName">
                  {{measure.measureShortSingularName}}
                </td>
                <td data-label="Factor">
                  {{measure.measureFactor}}
                </td>
                <td data-label="Retired">
                  {{measure.isRetired ? 'Yes' : 'No'}}
                </td>
                <td class="is-actions-cell">
                  <div class="buttons is-right">
                    <button class="button is-small is-info jb-modal"
                            type="button"
                            (click)="showMeasureForm('EDIT', measure)"
                    >
                          <span class="icon">
                            <i class="fa fa-lg fa-pencil"></i>
                          </span>
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="is-actions-cell">
                  <div class="buttons is-left">
                    <button class="button is-small is-success jb-modal"
                            type="button"
                            (click)="showMeasureForm('CREATE')"
                    >
                          <span class="icon">
                            <i class="fa fa-lg fa-plus"></i>
                          </span>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<cs-dictionary-item-form #itemForm (updated)="load()"></cs-dictionary-item-form>
