<div class="step-main is-flex">
  <div class="step-fields">
    <p class="is-size-5 has-text-weight-bold">Order confirmation</p>
    <div class="confirmation-info is-flex is-flex-direction-column">
      <div class="info-item">
        <p class="info-item__value"><span class="info-item__title">Company Name:</span> {{submissionRequest.buyer?.company?.name}}</p>
      </div>
      <div class="info-item">
        <p class="info-item__value"><span class="info-item__title">Buyer’s name:</span> {{submissionRequest.buyer?.firstName}} {{submissionRequest.buyer?.lastName}}</p>
      </div>
      <div class="info-item">
        <p class="info-item__value"><span class="info-item__title">Buyer's email:</span> {{submissionRequest.buyer?.email}}</p>
      </div>
      <div class="info-item">
        <p class="info-item__value"><span class="info-item__title">Delivery address:</span> {{submissionRequest.deliveryAddress}}</p>
      </div>
      <div class="info-item">
        <p class="info-item__value"><span class="info-item__title">Order quantity:</span> {{submissionRequest.quantity | number: '1.0'}} {{orderMeasure?.measureShortPluralName}}</p>
      </div>
      <div class="info-item">
        <p class="info-item__value"><span class="info-item__title">Estimated order cost:</span> {{((submissionRequest.orderAmount || 0) + (submissionRequest?.shippingCost || 0)) | currency}}</p>
      </div>
      <div class="info-item">
        <p class="info-item__value"><span class="info-item__title">Payment plan:</span> {{getPaymentPlanDescription()}}</p>
      </div>
    </div>
    <div *ngIf="!submissionRequest.fullUpfrontPayment">
      <hr/>
      <p class="is-size-5 has-text-weight-bold">Additional business information</p>
      <div class="confirmation-info is-flex is-flex-direction-column">
        <div class="info-item">
          <p class="info-item__value"><span class="info-item__title">Company Type:</span> {{submissionRequest.buyer.kybInfo?.businessType}}</p>
        </div>
        <div class="info-item">
          <p class="info-item__value"><span class="info-item__title">Company Registration Address:</span> {{submissionRequest.buyer?.kybInfo?.registeredBusinessAddress}}</p>
        </div>
        <div class="info-item">
          <p class="info-item__value"><span class="info-item__title">Employer Identification Number:</span> {{submissionRequest.buyer?.kybInfo?.employerIdentificationNumber}}</p>
        </div>
        <div class="info-item">
          <p class="info-item__value"><span class="info-item__title">Company Website:</span> {{submissionRequest.buyer?.kybInfo?.website}}</p>
        </div>
        <div class="info-item">
          <p class="info-item__value"><span class="info-item__title">Company Contact Name:</span> {{submissionRequest.buyer?.kybInfo?.contactName}}</p>
        </div>
        <div class="info-item">
          <p class="info-item__value"><span class="info-item__title">Company Contact Title:</span> {{submissionRequest.buyer?.kybInfo?.contactTitle}}</p>
        </div>
        <div class="info-item">
          <p class="info-item__value"><span class="info-item__title">Company Contact Email:</span> {{submissionRequest.buyer?.kybInfo?.contactEmail}}</p>
        </div>
        <div class="info-item">
          <p class="info-item__value"><span class="info-item__title">Company Contact Phone:</span> {{submissionRequest.buyer?.kybInfo?.businessPhone}}</p>
        </div>
      </div>
    </div>
    <div class="step-buttons" [ngClass]="isPreviousStepAvailable ? 'is-flex-direction-row' : 'is-flex-direction-row-reverse'">
      <a class="button-previous is-flex" (click)="previousStep()" *ngIf="isPreviousStepAvailable">
        <span class="icon">
          <i class="fa fa-angle-left"></i>
        </span>
        <span>Back</span>
      </a>
      <button class="button button-next wide is-medium is-success has-text-weight-bold" (click)="confirmationComponent.openWidow()">Next: {{nextStep}}</button>
    </div>
    <cs-step-registration-confirmation #confirmationComponent (submitted)="submit()"></cs-step-registration-confirmation>
  </div>
  <div class="side-hint-container">

  </div>
</div>
