import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {User} from "../../../api/cs";
import {Router} from "@angular/router";
import {ToastService} from "../../../shared/services/toast.service";
import {AuthService} from "../../../shared/services/auth.service";
import {LoaderService} from "../../../shared/services/loader.service";
import {FormNames, HubSpotFormsService} from "../../../shared/services/hubspot-forms.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'cs-contacts',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  user: User | null = null;
  contactUsForm: FormGroup;
  hideImage: boolean;

  constructor(private fb: FormBuilder,
              private router: Router,
              private loader: LoaderService,
              private toast: ToastService,
              private toastService: ToastService,
              private authService: AuthService,
              private hubSpotFormsService: HubSpotFormsService,
              private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("ContactUs | Cultured Supply");
    window.screen.width < 768 ? this.hideImage = true : this.hideImage = false;
    if (!!this.authService.userInfo) {
      this.user = this.authService.userInfo.getValue();
    }
    this.contactUsForm = this.fb.group({
      firstName: new FormControl(this.user?.firstName || '', Validators.required),
      email: new FormControl(this.user?.email || '', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      message: new FormControl('', Validators.required)
    });
  }

  submit(form: FormGroup): void {
      this.loader.show();
      this.hubSpotFormsService.sendFormRequest(FormNames.CONTACT_US,
        'Contact Us',
        this.router.url,
        [
          form.value.firstName,
          form.value.email,
          form.value.message
      ]).subscribe(() => {
        form.reset();
        this.loader.hide();
        this.toast.success("Your request has been sent");
      });
  }
}
