<div class="under-top-toolbar container">
  <section class="hero">
    <div class="hero-body has-text-centered">
      <div class="title has-text-weight-semibold">
        Frequently Asked Questions
      </div>
    </div>
  </section>

  <div class="mt-4 pt-4">
    <article class="panel is-success" [class.mb-5]="i == QUESTIONS.length - 1"
             *ngFor="let question of QUESTIONS; let i = index"
    >
      <div class="panel-heading is-clickable" (click)="expandedIndex = i == expandedIndex ? -1 : i">
        {{question.question}}
      </div>
      <div class="panel-block py-5" [id]=i [class.expanded]="i == expandedIndex">
        <div class="markdown is-inline" [id]="'markdown_' + i">
          {{this.parse(i)}}
        </div>
      </div>
    </article>
  </div>
</div>
