import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  Campaign,
  CampaignsApiService,
  CreateSubmissionRequest, UnitScalingEntity,
} from "../../../api/cs";
import {LoaderService} from "../../../shared/services/loader.service";
import {AuthService} from "../../../shared/services/auth.service";
import {BehaviorSubject} from "rxjs";
import {UnitsScalingService} from "../../../shared/services/units-scaling.service";

@Component({
  selector: 'cs-step-confirmation',
  templateUrl: './step-confirmation.component.html',
  styleUrls: ['./step-confirmation.component.scss', './../purchase.common.scss']
})
export class StepConfirmationComponent implements OnInit {

  @Input() nextStep: string;

  @Output() prev = new EventEmitter<boolean>();
  @Output() confirmation = new EventEmitter<boolean>();

  @Input() campaign: Campaign;
  @Input() submissionRequest$: BehaviorSubject<CreateSubmissionRequest>;
  @Input() isPreviousStepAvailable: boolean;
  submissionRequest: CreateSubmissionRequest;
  orderMeasure: UnitScalingEntity;

  constructor(private loader: LoaderService,
              private authService: AuthService,
              private unitsScalingService: UnitsScalingService,
              private campaignsApiService: CampaignsApiService) { }

  ngOnInit(): void {
    this.submissionRequest$.subscribe(request => {
      this.submissionRequest = request;
      this.orderMeasure = this.unitsScalingService.getScalingEntityById(this.submissionRequest.orderMeasure || 0);
    });
  }

  previousStep(): void {
    this.prev.emit(true);
  }

  next(): void {
    this.confirmation.emit(true);
  }

  getPaymentPlanDescription(): string {
    return this.submissionRequest.fullUpfrontPayment ? 'Pay 100% upfront' : 'Pay 50% now & 50% later'
  }

  submit(): void {
    this.loader.show();
    if (this.submissionRequest.fullUpfrontPayment) {
      this.submissionRequest.buyer.kybInfo = undefined;
    }
    if (!!this.submissionRequest.id) {
      if (this.isPreviousStepAvailable) {
        this.campaignsApiService.updateSubmission({
          id: this.submissionRequest.id || 0,
          createSubmissionRequest: this.submissionRequest
        }).subscribe(() => {
          this.loader.hide();
          this.submissionRequest$.next(this.submissionRequest);
          this.next();
        });
      } else {
        this.loader.hide();
        this.next();
      }
    } else {
      this.campaignsApiService.createNewSubmission({
        alias: this.campaign.alias || '',
        createSubmissionRequest: this.submissionRequest
      }).subscribe((response) => {
        this.loader.hide();
        this.submissionRequest.id = response?.id;
        this.submissionRequest$.next(this.submissionRequest);
        this.next();
      });
    }
  }
}
