import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'orderBySequenceNumberAsc'
})
export class OrderBySequenceNumberAscPipe implements PipeTransform {
  transform(value: any[]): any[] {
    return value?.sort((n1,n2) =>
    {
      if (n1.sequenceNumber == null && n2.sequenceNumber == null) {
        return 0;
      }
      if (n1.sequenceNumber == null) {
        return 1;
      }
      if (n2.sequenceNumber == null) {
        return -1;
      }
      return n1.sequenceNumber - n2.sequenceNumber;
    });
  }
}
