import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductResponse, ProductsApiService, ProductState} from '../../../../api/cs';
import {PlatformImageService} from "../../../../shared/services/platform-image.service";
import {ToastService} from "../../../../shared/services/toast.service";
import {ProductPromotionStatus} from "../../../../api/cs/model/product-featuring-status";

@Component({
  selector: '[cs-product-actions]',
  templateUrl: './product-actions.component.html',
  styleUrls: ['./product-actions.component.scss']
})
export class ProductActionsComponent implements OnInit {
  @Input() product: ProductResponse;
  @Input() isAdmin: boolean;
  @Input() isVerticalAlignment: boolean;

  isProductDrafted: boolean = false;
  isProductArchived: boolean = false;
  isProductActive: boolean = false;
  isProductHidden: boolean = false;

  isProductPromoted: boolean = false;
  isProductDemoted: boolean = false;

  @Output() createdProduct = new EventEmitter<ProductResponse>();
  @Output() updatedProduct = new EventEmitter<ProductResponse>();

  constructor(
    private toastService: ToastService,
    private productsApiService: ProductsApiService,
    public platformImageService: PlatformImageService) { }

  ngOnInit(): void {
    this.setProductState();
  }

  private setProductState(): void {
    switch (this.product.headingInfo.state) {
      case ProductState.DRAFT: this.isProductDrafted = true; break;
      case ProductState.ARCHIVED: this.isProductArchived = true; break;
      case ProductState.ACTIVE: this.isProductActive = true; break;
      case ProductState.HIDDEN: this.isProductHidden = true; break;
    }
    switch (this.product.headingInfo.promotionStatus) {
      case ProductPromotionStatus.FEATURED: this.isProductPromoted = true; break;
      case ProductPromotionStatus.BASIC: this.isProductDemoted = true; break;
    }
  }

  copyProduct(): void {
    this.productsApiService.createCopyOfProduct({
      id: this.product.id
    }).subscribe((product: ProductResponse) => {
      this.createdProduct.emit(product);
      this.toastService.success("Product copied to draft");
    });
  }

  archiveProduct(): void {
    this.product.headingInfo.state = ProductState.ARCHIVED;
    this.updateProductVisibilityStatus();
  }

  publishProduct(): void {
    this.product.headingInfo.state = ProductState.ACTIVE;
    this.updateProductVisibilityStatus();
  }

  deleteProduct(): void {
    this.product.headingInfo.state = ProductState.HIDDEN;
    this.updateProductVisibilityStatus();
  }

  restoreProduct(): void {
    this.product.headingInfo.state = ProductState.DRAFT;
    this.updateProductVisibilityStatus();
  }

  promoteProduct(): void {
    this.product.headingInfo.promotionStatus = ProductPromotionStatus.FEATURED;
    this.updateProductPromotionStatus();
  }

  demoteProduct(): void {
    this.product.headingInfo.promotionStatus = ProductPromotionStatus.BASIC;
    this.updateProductPromotionStatus();
  }

  private updateProductVisibilityStatus(): void {
    this.productsApiService.updateProductHeading({
      id: this.product.id,
      productHeadingInfo: {
        name: this.product.headingInfo.name,
        description: this.product.headingInfo.description,
        chassis: this.product.headingInfo.chassis,
        developmentStage: this.product.headingInfo.developmentStage,
        verified: this.product.isCompanyVerified,
        state: this.product.headingInfo.state,
        promotionStatus: this.product.headingInfo.promotionStatus
      }
    }).subscribe((product: ProductResponse) => {
      this.updatedProduct.emit(product);
      this.toastService.success("Product state changed to " + product.headingInfo.state?.toLowerCase());
    });
  }

  private updateProductPromotionStatus(): void {
    this.productsApiService.updateProductHeading({
      id: this.product.id,
      productHeadingInfo: {
        name: this.product.headingInfo.name,
        description: this.product.headingInfo.description,
        chassis: this.product.headingInfo.chassis,
        developmentStage: this.product.headingInfo.developmentStage,
        verified: this.product.isCompanyVerified,
        state: this.product.headingInfo.state,
        promotionStatus: this.product.headingInfo.promotionStatus
      }
    }).subscribe((product: ProductResponse) => {
      this.updatedProduct.emit(product);
      this.toastService.success("Product promotion status changed to " + product.headingInfo.promotionStatus?.toLowerCase());
    });
  }
}
