<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">Product Availability and Pricing Info Editing</p>
    <span class="icon cs-icon-button" aria-label="close" (click)="confirmClose()">
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <form class="modal-card-body pt-6"
        id="pricing-modal-card"
        *ngIf="form"
        [formGroup]="form"
        (ngSubmit)="submit()">
    <div class="field mb-0" *ngIf="adminViewService.isAdmin">
      <div class="control view-switch is-flex is-flex-direction-row-reverse">
        <input type="checkbox"
               name="adminViewAvailabilityAndPricingInfo"
               id="adminViewAvailabilityAndPricingInfo"
               class="switch is-success is-rtl"
               (change)="adminViewService.changeAdminView()"
               [checked]="isAdminView"
        >
        <label class="label" for="adminViewAvailabilityAndPricingInfo">Admin View</label>
      </div>
    </div>
    <div class="field pb-4 pt-4" *ngIf="showPricing || isAdminView">
      <label class="label">Pricing</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="$2.00 per gallon" formControlName="pricing">
      </div>
    </div>
    <div id="shipping-line" class="field is-flex">
      <div class="field mb-0 is-flex-grow-1">
        <label class="label">Sample Cost:</label>
        <div class="control">
          <input class="input is-medium" type="text" (keypress)="onlyNumbers($event)" placeholder="$30"
                 mask="separator.2" prefix="$"
                 thousandSeparator="," formControlName="sampleCost">
        </div>
      </div>
      <div class="field is-flex-grow-1">
        <label class="label">Shipping Cost: (Not Active)</label>
        <div class="control">
          <input class="input is-medium" type="text" (keypress)="onlyNumbers($event)" placeholder="$15"
                 mask="separator.2" prefix="$"
                 thousandSeparator="," formControlName="shippingCost">
        </div>
      </div>
    </div>

    <div class="field pb-4 pt-4" *ngIf="showCapacity || isAdminView">
      <label class="label">Capacity</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="50 gallons per week" formControlName="capacity">
      </div>
    </div>

    <div class="field pb-4" *ngIf="showPackaging || isAdminView">
      <label class="label">Packaging</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="12 x 1.5L bottles" formControlName="packaging">
      </div>
    </div>

    <div class="field pb-4" *ngIf="showLeadTime || isAdminView">
      <label class="label">Lead time</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="1 week" formControlName="leadTime">
      </div>
    </div>

    <div class="field pb-4" *ngIf="showShippingDestination || isAdminView">
      <label class="label">Shipping Destination</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="North America" formControlName="shippingDestination">
      </div>
    </div>

    <div class="field pb-4">
      <label class="label">Sample Description:</label>
      <div class="control">
      <textarea class="textarea is-medium"
              placeholder="Sample Description"
              maxlength="200"
              formControlName="sampleDescription">
      </textarea>
      </div>
    </div>

    <div class="field is-grouped is-grouped-centered mt-6">
      <div class="control py-4">
        <button type="submit" class="button wide is-success has-text-weight-bold is-fullwidth">
          Save
        </button>
      </div>
    </div>
  </form>
</div>
