<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">Contact Supplier</p>
    <span class="icon cs-icon-button" aria-label="close" (click)="confirmClose()">
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <form class="modal-card-body pt-6"
        *ngIf="form"
        [formGroup]="form"
        (ngSubmit)="submit()">
    <div class="field pt-4 pb-4">
      <label class="label">Name:*</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="John" formControlName="name">
      </div>
    </div>
    <div class="field pb-4">
      <label class="label">Email:*</label>
      <div class="control">
        <input class="input is-medium" type="email" placeholder="example@email.com" formControlName="email">
      </div>
    </div>
    <div class="field pb-4">
      <label class="label">Subject</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="Subject" formControlName="subject">
      </div>
    </div>
    <div class="field pb-4">
      <label class="label">Message:*</label>
      <div class="control">
        <textarea class="textarea is-medium" placeholder="What are you interested in?" formControlName="message"></textarea>
      </div>
    </div>
    <div class="field is-grouped is-grouped-centered mt-6">
      <div class="control py-4">
        <button type="submit" class="button wide is-success has-text-weight-bold is-fullwidth">
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
