import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ValidationService} from "../../../shared/services/validation.service";
import {GoogleMapsService} from "../../../shared/services/google-maps.service";
import {AuthService} from "../../../shared/services/auth.service";
import {Campaign, CreateSubmissionRequest, UnitScalingEntity} from "../../../api/cs";
import {BehaviorSubject, Observable} from "rxjs";
import {ToastService} from "../../../shared/services/toast.service";
import {ValidatorsService} from "../../../core/util/validators.service";
import {UnitsScalingService} from "../../../shared/services/units-scaling.service";
import {MatDialog} from "@angular/material/dialog";
import {InfoModalWindowComponent} from "../../../core/components/info-modal-window/info-modal-window.component";
import {ShippingCostService} from "../../../shared/services/shipping-cost.service";

@Component({
  selector: 'cs-step-order-info',
  templateUrl: './step-order-info.component.html',
  styleUrls: ['./step-order-info.component.scss', './../purchase.common.scss']
})
export class StepOrderInfoComponent implements OnInit {

  @Input() campaign$: Observable<Campaign>;
  campaign: Campaign;
  @Input() submissionRequest$: BehaviorSubject<CreateSubmissionRequest>;
  submissionRequest: CreateSubmissionRequest;

  @Input() nextStep: string;
  @Output() order = new EventEmitter<boolean>();

  orderMeasure: UnitScalingEntity;
  MAX_PAYMENT_AMOUNT = 999999.99;
  maxPaymentError: boolean = false;

  showPaymentPlan:boolean = true;

  constructor(private fb: FormBuilder,
              private dialog: MatDialog,
              private authService: AuthService,
              private unitsScalingService: UnitsScalingService,
              private googleMapsService: GoogleMapsService,
              private validationService: ValidationService,
              private toastService: ToastService,
              private matDialog: MatDialog,
              private shippingCostService: ShippingCostService) { }

  form: FormGroup;

  requiredFields = ['quantity', 'address'];
  predictions: string[] = [];
  shippingCost: number = 0;
  private UUID: string;

  ngOnInit(): void {
    this.campaign$.subscribe(campaign => {
      this.campaign = campaign;
      this.submissionRequest = this.submissionRequest$.getValue();
      this.setOrderMeasure(this.campaign?.minimumOrderMeasure?.measurePluralName || '');
      this.form = this.fb.group({
        quantity: new FormControl(this.submissionRequest?.quantity || this.campaign?.minimumOrder, [Validators.required, Validators.pattern('^[0-9]*[.,]?[0-9]+$')]),
        address: new FormControl(this.submissionRequest?.deliveryAddress, Validators.required),
        cost: new FormControl('')
      });
      this.calcCost();
      this.showPaymentPlan = this.campaign.companyId !== 'dgpworks'; // FIXME a hardcode needed for the demo conducted on 08.03.2023
    });
    this.getUUID();
    this.googleMapsService.assumed.subscribe(assumption => this.form.get('address')?.setValue(assumption));
    this.googleMapsService.predicted.subscribe((predictions) => this.predictions = predictions);

  }

  next(form: FormGroup): void {
    if (form.valid) {
      this.submitStep(form);
    } else {
      for (let control in this.form.controls) {
        this.form.controls[control].markAsTouched();
      }
    }
  }

  private submitStep(form: FormGroup): void {
    let predictAddress = form.value.address;
    this.submissionRequest.shippingCost = this.shippingCost;
    //When user finish working with Google Maps API it's better for close session for avoid additional costs
    this.googleMapsService.assume(this.UUID, form.value.address, false);
    let addressSubscription = this.form.get('address')?.valueChanges.subscribe(address => {
      if (address !== predictAddress || this.submissionRequest.deliveryAddress) {
        if (address.toLowerCase().includes("united states") || address.toLowerCase().includes("usa")) {
          this.submissionRequest.deliveryAddress = address;
          this.form.get('address')?.setErrors(null);
          this.submissionRequest$.next(this.submissionRequest);
          this.order.emit(true);
        } else {
          this.form.get('address')?.setErrors({'illegal-area': true});
          addressSubscription?.unsubscribe();
        }
      }
    })
  }

  calcCost(): void {
    this.submissionRequest.quantity = this.form?.value?.quantity;
    if (!Number.isNaN(+(this.submissionRequest.quantity || 0))) {
      let price = Number.parseFloat((((this.submissionRequest.quantity || 0) * (this.orderMeasure?.measureFactor || 0)) *
        ((this.campaign?.unitPrice || 0) / (this.campaign?.unitMeasure?.measureFactor || 0))).toFixed(2));
      this.submissionRequest.orderAmount = price;
    } else {
      this.submissionRequest.orderAmount = 0;
    }
    if (this.submissionRequest.orderAmount < 2500) {
      this.submissionRequest.fullUpfrontPayment = true;
    }
    this.isOrderQuantityValid();
    this.calcShippingCost();
  }

  calcShippingCost(): void {
    this.shippingCost = this.shippingCostService.getShippingCost(
      this.submissionRequest.quantity || 0,
      this.unitsScalingService.getScalingEntityById(this.submissionRequest.orderMeasure || 0),
      this.campaign.shippingCostIntervals || [{}]
    );
  }

  changePaymentPlan() {
    if ((this.submissionRequest.orderAmount || 0) >= 2500) {
      this.submissionRequest.fullUpfrontPayment = !this.submissionRequest.fullUpfrontPayment;
      this.calcCost();
    } else {
      document.getElementById('upfront')?.click(); //It prevents situation when user can change radiobutton state without plan changing
      this.toastService.success('To receive payment terms, your order must be greater than $2,500');
    }
  }

  predict(address: string): void {
    if (this.form.get('address')?.dirty) {
      if (address) {
        this.googleMapsService.predict(this.UUID, address);
      }
    }
  }

  insert(prediction: string): void {
    if (this.form.get('address')?.value != prediction) {
      this.form.get('address')?.setValue(prediction);
    } else {
      this.predictions = [];
    }
  }

  /**
   * For make working with Google Maps API cheaper all request from one user need to have same id.
   */
  getUUID(): void {
    this.authService.userInfo?.subscribe(user => this.UUID = user?.id || '');
  }

  onlyEnglish($event: KeyboardEvent) {
    ValidatorsService.onlyEnglish($event);
  }

  isOrderQuantityValid() {
    let isItBigger = this.unitsScalingService.isItBigger(
      this.submissionRequest?.quantity,
      this.orderMeasure || this.campaign?.unitMeasure,
      this.campaign?.minimumOrder,
      this.campaign?.minimumOrderMeasure);
    let isItLower = this.unitsScalingService.isItLower(
      this.submissionRequest?.quantity,
      this.orderMeasure || this.campaign?.unitMeasure,
      this.campaign?.maximumOrder,
      this.campaign?.maximumOrderMeasure);
    let isItFullyDivisible = this.unitsScalingService.isItFullyDivisible(
      this.submissionRequest?.quantity,
      this.orderMeasure || this.campaign?.unitMeasure,
      this.campaign?.orderStep,
      this.campaign?.orderStepMeasure);
    let isBiggerThanStripePayment = (this.submissionRequest?.orderAmount || 0) >= this.MAX_PAYMENT_AMOUNT;


    const quantityControl = this.form?.controls['quantity'];

    if (!isItBigger) {
      quantityControl?.setErrors({'lower-than-min': true});
    } else if (!isItLower) {
      quantityControl?.setErrors({'bigger-than-max': true})
    } else if (!isItFullyDivisible) {
      quantityControl?.setErrors({'non-divisible': true});
    } else if (isBiggerThanStripePayment) {
      quantityControl?.setErrors({'max-payment': true});
    } else {
      quantityControl?.setErrors(null);
    }
  }

  setOrderMeasure(measure: string): void {
    this.submissionRequest.orderMeasure = this.unitsScalingService.getScalingEntityId(this.campaign?.measurePolicy || '', measure);
    this.orderMeasure = this.unitsScalingService.getScalingEntityById(this.submissionRequest.orderMeasure || 0);
    if (!!this.form) {
      this.calcCost();
    }
    this.isOrderQuantityValid();
  }

  openContactWindow() {
    this.matDialog.open(InfoModalWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {success: true, message: ' Contact us at <a href="mailto:info@culturedsupply.com?subject=Large Volume Order Request">info@culturedsupply.com</a> for large volume order requests.', subject: 'Contact us', hideStatus: true}
    })
  }

  getCurrentMeasureFactor(id: number): number {
    return this.unitsScalingService.getScalingEntityById(id).measureFactor || 0;
  }

  getMaxAmountOfDigits(): string {
    return ((this.campaign?.maximumOrder || 0) *
      (this.campaign?.maximumOrderMeasure?.measureFactor || 0) /
      this.getCurrentMeasureFactor(this.submissionRequest?.orderMeasure || 0))
      .toString();
  }
}

