import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class PlatformImageService {
  private readonly KNOWN_PLATFORM_IMAGES: any = {
    ALGAE: 'algae.png',
    BACTERIA: 'bacteria.png',
    FUNGI: 'fungi.png',
    PLANTS: 'plants.png',
    PLANT: 'plant.png',
    DNA: 'dna.png',
  };

  getPlatformImage(platform: string | undefined): string {
    return `../../../../assets/images/${this.KNOWN_PLATFORM_IMAGES[platform?.toUpperCase() || '']}`;
  }

  isPlatformImageExists(platform: string | undefined): boolean {
    return this.KNOWN_PLATFORM_IMAGES[platform?.toUpperCase() || ''] != undefined;
  }
}
