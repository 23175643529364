<div class="under-top-toolbar container ">
  <div id="bread-crumbs" class="mb-5" *ngIf="!!companyAlias">
    <a routerLink="/algae" *ngIf="isSupplyChainBuilderUser">Supply Chain Builder</a>
    <span *ngIf="isSupplyChainBuilderUser">&nbsp;&nbsp;>&nbsp;&nbsp;</span>
    <xng-breadcrumb [separator]="'>'"></xng-breadcrumb>
  </div>
  <div class="products-header is-flex is-justify-content-space-between mb-2">
    <div class="page-title">Products</div>
    <div class="is-flex">
      <div class="control text-control">
        <input class="input is-medium search-input-field"
               type="search"
               placeholder="I'm searching for..."
               [(ngModel)]="searchingText"
               (ngModelChange)="showSearchResult()"
        />
      </div>
      <div class="control">
        <a class="button is-success has-text-weight-bold search-input-button"
           (click)="searchProducts()"
        >
          <i class="fa fa-search"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="control control-line is-flex is-justify-content-space-between">
    <div class="tabs">
      <mat-tab-group animationDuration="0ms"
                     *ngIf="showTabs"
                     (selectedTabChange)="setVisibleProducts($event.index)">
        <mat-tab label="Active"></mat-tab>
        <mat-tab label="Archived"></mat-tab>
        <mat-tab label="Drafts"></mat-tab>
        <mat-tab label="Hidden" *ngIf="isAdmin"></mat-tab>
      </mat-tab-group>
    </div>
    <div>
      <a class="button button-bars" [class.is-selected] = "listType == 'bars'" (click)="setListTypeToBars()">
        <i class="fa fa-solid fa-bars"></i>
      </a>
      <a class="button button-grid" [class.is-selected] = "listType == 'grid'" (click)="setListTypeToGrid()">
        <i class="fa fa-th-large"></i>
      </a>
    </div>
  </div>
    <div class="b-table" *ngIf="listType == 'bars'">
      <div class="table-wrapper has-mobile-cards">
        <table class="table my-5">
          <thead>
            <tr>
              <th [style.width]="10">Name</th>
              <th [style.width]="10">Company</th>
              <th [style.width]="showTabs ? 45 : 55">Description</th>
              <th [style.width]="25">Additional Info</th>
              <th [style.width]="10" *ngIf="showTabs">Actions</th>
            </tr>
          </thead>
          <tbody>
              <tr cs-product-bar-item *ngFor="let product of visibleProducts$ | async"
                  [product]="product"
                  [showCategory]="showCategory"
                  [showDescription]="showDescription"
                  [showDevelopmentStage]="showDevelopmentStage"
                  [showRecommendation]="showRecommendation"
                  [showType]="showType"
                  [showActions]="showTabs"
                  (cratedProduct)="addProduct($event)"
                  (updatedProduct)="updateProduct($event)"
              ></tr>
          </tbody>
        </table>
      </div>
    </div>

    <div id="products-tiles" class="is-flex is-flex-wrap-wrap" *ngIf="listType == 'grid'">
      <cs-product-grid-item class="my-4 cs-card product-card"
                            [class.expanded]="expandedCards.get(product?.id || '')"
                            *ngFor="let product of visibleProducts$ | async"
                            [product]="product"
                            [showCategory]="showCategory"
                            [showDescription]="showDescription"
                            [showDevelopmentStage]="showDevelopmentStage"
                            [showRecommendation]="showRecommendation"
                            [showType]="showType"
                            [showActions]="showTabs"
                            (extend)="expandedCards.set(product.id || '', $event)"
                            (cratedProduct)="addProduct($event)"
                            (updatedProduct)="updateProduct($event)"
      ></cs-product-grid-item>
    </div>
  </div>
