<div class="under-top-toolbar container ">
  <section class="hero">
    <div class="hero-body has-text-centered">
      <p class="title has-text-weight-semibold">
        404: Page not Found
      </p>
      <p>
      Current Page is not available now
      </p>
    </div>
  </section>
</div>
