<header>
  <div class="header">
    <div class="header__inner-container">
      <div class="header__fake-background"></div>
      <div class="header__texts is-flex is-flex-direction-column is-justify-content-center">
        <h1>Procurement<br />for the <span class="green-text bioeconomy">Bioeconomy</span></h1>
        <div class="header__texts__subtexts is-flex is-flex-direction-column is-justify-content-center">
          <h2 class="hidden">Procurement for the bioeconomy. Cultured Supply has built a global network of suppliers transforming the way we make things.</h2>
          <p>Cultured Supply has built a global network of suppliers transforming the way we make things. They are building with nature, not oil, to create healthier, more planet-first products with superior performance.</p>
          <p>We help companies procure biobased chemicals, ingredients, materials, and finished products.</p>
          <a href="https://algae.culturedsupply.com" target="_blank" class="header__texts__subtexts__button button is-success is-medium has-text-weight-bold">Visit our Algae Site</a>
        </div>
        <a href="https://algae.culturedsupply.com" target="_blank" class="header__texts__button button is-success is-medium has-text-weight-bold">Visit our Algae Site</a>
      </div>
    </div>
  </div>
</header>
<main>
  <section class="creators">
    <div class="container">
      <div class="creators">
        <div class="creators__title">
          <div class="creators__title__text">Direct from the Factory</div>
        </div>
        <div class="creators__body">
          <div class="creators__body__text">
            Product design and manufacturing service
            built for creators.
          </div>
          <div class="creators__body__images">
            <img alt="" src="/assets/images/creators/photo_1.webp"/>
            <img alt="" src="/assets/images/creators/photo_2.webp"/>
            <img alt="" src="/assets/images/creators/photo_3.webp"/>
            <img alt="" src="/assets/images/creators/photo_4.webp"/>
            <img alt="" src="/assets/images/creators/photo_5.webp"/>
            <img alt="" src="/assets/images/creators/photo_6.webp"/>
            <img alt="" src="/assets/images/creators/photo_7.webp"/>
            <img alt="" src="/assets/images/creators/photo_8.webp"/>
            <img alt="" src="/assets/images/creators/photo_9.webp"/>
          </div>
          <button class="button is-success creators__body__button wide is-medium has-text-weight-bold is-halfwidth" routerLink="/creators">
            Learn more
          </button>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-image">
    <section class="container is-flex is-desktop icon-part">
      <div class="img-wrapper">
        <img src="/assets/images/new-home/bioreactor.png" alt="icon">
      </div>
      <div class="text-wrapper bioreactor-part">
        <h2 class="mb-4">Let’s build <span class="green-text">with nature</span>, not oil.</h2>
        <h4>Make nature part of the industrial ecosystem with biobased chemicals, ingredients, materials, and products.</h4>
      </div>
    </section>
  </section>
  <section class="container is-desktop phone-part">
    <div class="columns is-desktop">
      <div class="column text-wrapper">
        <h2>Looking for something specific?</h2>
        <h4>Hit us up on our procurement hotline.</h4>
        <h4>We call it the <span class="green-text">Drip Line</span>.</h4>
        <h4>Text or call <b>313-241-7544</b> with what you need; or if you are lonely. We respond to every message.</h4>
        <a class="button" href="https://wa.me/13132417544" target="_blank">
          <img src="/assets/images/new-home/whatsapp.png" alt="whatsapp">
          Text us now
        </a>
      </div>
      <div class="column img-wrapper">
        <img src="/assets/images/new-home/phone.png" alt="phone">
      </div>
    </div>
  </section>
  <section class="bg-image">
    <section class="container is-desktop drop-us">
      <h1>We are on a mission <br> to build <span class="green-text">regenerative</span> supply chains.</h1>
      <a href="contact-us">Drop us a line</a>
    </section>
  </section>
</main>
