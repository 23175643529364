/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DictionaryValue } from './dictionary-value';
import { ProductState } from './product-state';


export interface CompanyProduct {
    id?: string;
    verified?: boolean;
    name?: string;
    alias?: string;
    chassis?: Array<string>;
    type?: Array<string>;
    category?: Array<string>;
    industryFocuses?: Array<DictionaryValue>;
    productImage?: string;
    state?: ProductState;
}

