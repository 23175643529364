/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SignUpRequestCompany } from './sign-up-request-company';
import { SignUpRequestUser } from './sign-up-request-user';


export interface SignUpRequest { 
    user?: SignUpRequestUser;
    company?: SignUpRequestCompany;
}

