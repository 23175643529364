/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductHeadingInfo } from './product-heading-info';
import { ProductGeneralInfo } from './product-general-info';
import { ProductContactPerson } from './product-contact-person';
import { ProductPricingInfo } from './product-pricing-info';


export interface ProductResponse { 
    id: string;
    alias: string;
    profileImage?: string;
    companyId: string;
    companyName: string;
    companyAlias: string;
    isCompanyVerified: boolean;
    headingInfo: ProductHeadingInfo;
    generalInfo: ProductGeneralInfo;
    pricingInfo: ProductPricingInfo;
    contactPerson: ProductContactPerson;
    specSheet?: string;
    safetyDataSheet?: string;
    lcaSheet?: string;
}

