<div *ngIf="form && user">
  <div class="edit-user-info-block mt-6" [formGroup]="form">
    <h2 class="title is-size-4 has-text-weight-bold">User Info</h2>
    <div class="field pb-4">
      <label class="label">First Name:</label>
      <div class="control">
        <input class="input is-medium" type="text" (keypress)="onlyEnglish($event)" placeholder="John" formControlName="firstName">
      </div>
    </div>
    <div class="field pb-4">
      <label class="label">Last Name:</label>
      <div class="control">
        <input class="input is-medium" type="text" (keypress)="onlyEnglish($event)" placeholder="Doe" formControlName="lastName">
      </div>
    </div>
    <div class="field pb-4">
      <label class="label">Email:</label>
      <div class="control">
        <input class="input is-medium" type="email" (keypress)="onlyEnglish($event)" placeholder="@" formControlName="email">
      </div>
    </div>
    <div class="field pb-4">
      <label class="label">Phone:</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="" formControlName="phone">
      </div>
    </div>
    <div class="field pb-4">
      <label class="label">Title:</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="CEO" (keypress)="onlyEnglish($event)" formControlName="title">
      </div>
    </div>
    <div class="field pb-4">
      <label class="label">Bio:</label>
      <div class="control">
        <textarea class="textarea is-medium" type="text" placeholder="Write anything you want to tell about yourself" (keypress)="onlyEnglish($event)" formControlName="bio">
        </textarea>
      </div>
    </div>

    <div class="field">
      <button class="button is-success wide is-fullwidth is-medium" (click)="submit()">Save</button>
    </div>
  </div>

  <div class="edit-user-info-block mt-6" [formGroup]="passwordForm">
    <h2 class="title is-size-4 has-text-weight-bold">Change Password</h2>
<!--    <div class="field pb-4">-->
<!--      <label class="label">Current Password:</label>-->
<!--      <div class="control">-->
<!--        <input class="input is-medium" type="password" placeholder="Current password" formControlName="current">-->
<!--      </div>-->
<!--    </div>-->
    <div class="field pb-4">
      <label class="label">New Password:</label>
      <div class="control">
        <input class="input is-medium" type="password" placeholder="New password" formControlName="password">
      </div>
    </div>
    <div class="field pb-4">
      <label class="label">Confirm New Password:</label>
      <div class="control">
        <input class="input is-medium" type="password" placeholder="New password" formControlName="confirm_password">
      </div>
    </div>

    <div class="field">
      <button class="button is-success wide is-fullwidth is-medium"
              (click)="changePassword()"
              [disabled]="passwordForm.invalid"
      >Change</button>
    </div>
  </div>
  <div class="edit-user-info-block mt-6">
    <h2 class="title is-size-4 has-text-weight-bold">Newsletter Receiving</h2>
    <mat-checkbox class="mt-2 ml-4 mat-checkbox-2" (change)="intentToBeSubscribed = !intentToBeSubscribed" [checked]="intentToBeSubscribed">
      <div class="intention ml-2">I agree to receive email notifications.
      </div>
    </mat-checkbox>
    <div class="field mt-5">
      <button class="button is-success wide is-fullwidth is-medium"
              (click)="changeSubscriptionAgreement()"
      >Save</button>
    </div>
  </div>
</div>
