import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Campaign, CampaignsApiService, CampaignState, CompaniesApiService} from "../../../api/cs";
import {environment} from "../../../../environments/environment";
import {CampaignCreateComponent} from "../../../campaign/components/campaign-create/campaign-create.component";
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "../../../shared/services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'cs-campaign-list-item',
  templateUrl: './campaign-list-item.component.html',
  styleUrls: ['./campaign-list-item.component.scss']
})
export class CampaignListItemComponent implements OnInit {

  @Input() showActions: boolean = false;

  @Input() campaign: Campaign | undefined;
  date = new Date();
  baseUrl = '';
  campaignPreviewImage: string;
  state = CampaignState;
  @Output()
  campaignEventEmitter = new EventEmitter<Campaign>();

  constructor(private dialog: MatDialog,
              private companyService: CompaniesApiService,
              private campaignService: CampaignsApiService,
              private authService: AuthService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.setCampaignPreviewImage();
    this.setBaseUrl();
  }

  private setBaseUrl(): void {
    this.baseUrl = environment.baseUrl;
  };

  editCampaign(campaign: Campaign | undefined): void {
    if (this.campaign?.companyId !== undefined) {
      this.companyService.getCompany({id: this.campaign.companyId}).subscribe(
        data => {
          this.dialog.open(CampaignCreateComponent, {panelClass: 'custom-dialog-container',
            data: {company: data, campaign: campaign}})
            .afterClosed()
            .subscribe(() => this.campaignService.getCampaign({id: this.campaign?.alias || ''}).subscribe(
              data => this.campaign = data
            ));
        }
      );
    }
  }

  get showEditButton() {
    return (this.authService.isAdmin() && this.campaign?.state !== CampaignState.ARCHIVED)
      || (this.isCompanyOwner() && this.campaign?.state === CampaignState.DRAFT);
  }

  get showCopyToDraftButton() {
    return this.campaign?.state === CampaignState.ARCHIVED && (this.authService.isAdmin() || this.isCompanyOwner());
  }

  get showArchiveButton() {
    return this.campaign?.state === CampaignState.ACTIVE && this.isCompanyOwner();
  }

  get showBuyerList() {
    return (this.authService.isAdmin() || this.isCompanyOwner())
      && (this.campaign?.state === CampaignState.ACTIVE || this.campaign?.state === CampaignState.ARCHIVED ) &&
      !this.router.url.endsWith("/buyers-list");
  }

  get showPublishButton() {
    return this.campaign?.state === CampaignState.APPROVED && this.isCompanyOwner();
  }

  private isCompanyOwner(): boolean {
    return this.authService.userInfo.value?.company?.id === this.campaign?.companyId;
  }

  changeState(state: CampaignState) {
    this.campaignService.changeCampaignState({id: this.campaign?.alias || '', state: state}).subscribe(
      data => {
          this.campaign = data;
          this.campaignEventEmitter.emit(data);
      }
    )
  }

  copyToDraft() {
    this.campaignService.createCopyOfCampaign({id: this.campaign?.alias || ''}).subscribe(
      data => {
        this.campaignEventEmitter.emit(data);
      }
    )
  }

  private setCampaignPreviewImage() {
    const imageReference = (this.campaign?.images && this.campaign.images.length > 0) ? (this.campaign?.images[0].reference || '') : '';
    this.campaignPreviewImage = imageReference !== '' ? `https://${environment.s3BucketName}.${environment.s3DefaultApi}/images/${imageReference}` : '';
  }
}
