<div class="product-actions"
     [class.vertical-product-actions]="isVerticalAlignment"
     [class.horizontal-product-actions]="!isVerticalAlignment">
  <button type="button" class="button" (click)="archiveProduct()" *ngIf="isProductActive">Archive</button>
  <button type="button" class="button" (click)="copyProduct()" *ngIf="isProductArchived">Copy to Draft</button>
  <button type="button" class="button" (click)="publishProduct()" *ngIf="isAdmin && isProductDrafted">Publish</button>
  <button type="button" class="button" (click)="deleteProduct()" *ngIf="!isProductHidden">Delete</button>
  <button type="button" class="button" (click)="restoreProduct()" *ngIf="isAdmin && isProductHidden">Restore</button>
  <button type="button" class="button" (click)="promoteProduct()" *ngIf="isAdmin && isProductDemoted && isProductActive">Promote</button>
  <button type="button" class="button" (click)="demoteProduct()" *ngIf="isAdmin && isProductPromoted && isProductActive">Demote</button>
</div>
