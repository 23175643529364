<div class="main-container">
  <div class="container is-flex">
    <div class="info-block is-flex is-flex-direction-column under-top-toolbar">
      <div class="info-block__top">
        <h1 class="title mb-2">
          For Buyers
        </h1>
        <div class="content is-flex-grow-1">
          <div class="pr-6 mb-5 is-size-3">
            Take the pain out of procurement
          </div>
          <div class="cs-list">
            <p class="is-size-5">Cultured Supply is a one-stop shop for discovering and procuring biobased chemicals, ingredients, materials, and finished products.</p>
            <div class="is-flex mb-1">
              <div class="cs-bullet mr-2">
              </div>
              <div>
                <strong>Step 1:</strong> Submit a buying request
              </div>
            </div>
            <div class="is-flex mb-1">
              <div class="cs-bullet mr-2">
              </div>
              <div>
                <strong>Step 2:</strong> Cultured Supply shares the request with supplier network
              </div>
            </div>
            <div class="is-flex mb-1">
              <div class="cs-bullet mr-2">
              </div>
              <div>
                <strong>Step 3:</strong> Receive spec sheets, quotes, and direct introductions to relevant suppliers
              </div>
            </div>
            <p class="is-size-5">There is no cost to Buyers for Cultured Supply’s services. Suppliers pay us a fee on completed sales.</p>
          </div>
        </div>
      </div>
      <div class="info-block__bottom is-flex is-justify-content-center mt-6">
        <img src="/assets/images/bioreactor-no-text.png" />
      </div>
    </div>
    <div class="form-block is-flex is-flex-direction-column under-top-toolbar">
      <form [formGroup]="forBuyerForm" *ngIf="forBuyerForm" (ngSubmit)="submit(forBuyerForm)">
        <h2 class="subtitle is-4 mb-1 has-text-weight-bold">
          Submit a buying request
        </h2>

        <div class="field pt-4">
          <label class="label">Company Name:*</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="Space X" formControlName="companyName">
          </div>
        </div>

        <div id="name-line" class="field is-flex pt-5">
          <div class="field mb-0 is-flex-grow-1">
            <label class="label">First name:*</label>
            <div class="control">
              <input class="input is-medium" type="text" placeholder="John" formControlName="firstName">
            </div>
          </div>
          <div class="field is-flex-grow-1">
            <label class="label">Last name:*</label>
            <div class="control">
              <input class="input is-medium" type="text" placeholder="Doe" formControlName="lastName">
            </div>
          </div>
        </div>

        <div id="contact-line" class="field is-flex pt-5">
          <div class="field mb-0 is-flex-grow-1">
            <label class="label">Email:*</label>
            <div class="control">
              <input class="input is-medium" type="email" placeholder="example@email.com" formControlName="email">
            </div>
          </div>

          <div class="field is-flex-grow-1">
            <label class="label">Phone number:*</label>
            <div class="control">
              <input class="input is-medium" type="text" placeholder="+1 (XXX) XXX-XXXX" formControlName="phone">
            </div>
          </div>
        </div>

        <div class="field pt-5">
          <label class="label">Industry:*</label>
          <div class="control">
            <select class="input is-medium" formControlName="industry">
              <option *ngFor="let industry of industries" value="{{industry}}">{{industry}}</option>
            </select>
          </div>
        </div>

        <div class="field pt-5">
          <label class="label">Additional Info:</label>
          <div class="control">
            <textarea class="textarea is-medium" type="text" placeholder="Tell us more about what you're doing!"
                      formControlName="addInfo"></textarea>
          </div>
        </div>

        <div class="field my-4">
          <div class="control pt-5">
            <button class="button wide is-medium is-success has-text-weight-bold is-fullwidth" type="submit"
                    [disabled]="forBuyerForm.invalid">Send
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
