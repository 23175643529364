<div class="step-main is-flex is-flex-direction-column">
  <div class="is-flex">
    <form class="step-fields" [formGroup]="form" *ngIf="form" id="buyer-info" (ngSubmit)="next(form)">
      <div class="field is-flex-grow-1">
        <label class="label">Company Name:*</label>
        <div class="control">
          <input class="input is-medium" type="text" placeholder="Space X" formControlName="companyName"
                 (keypress)="onlyEnglish($event)"
                 (ngModelChange)="submissionRequest.buyer.company!.name = $event">
        </div>
      </div>
      <div class="field is-flex-grow-1">
        <label class="label">First Name:*</label>
        <div class="control">
          <input class="input is-medium" type="text" placeholder="John" formControlName="firstName"
                 (keypress)="onlyEnglish($event)"
                 (ngModelChange)="submissionRequest.buyer.firstName = $event">
        </div>
      </div>
      <div class="field is-flex-grow-1">
        <label class="label">Last Name:*</label>
        <div class="control">
          <input class="input is-medium" type="text" placeholder="Doe" formControlName="lastName"
                 (keypress)="onlyEnglish($event)"
                 (ngModelChange)="submissionRequest.buyer.lastName = $event">
        </div>
      </div>
      <div class="field is-flex-grow-1">
        <label class="label">Email:*</label>
        <div class="control">
          <input class="input is-medium" type="email" placeholder="john.doe@email.com" formControlName="email"
                 (keypress)="onlyEnglish($event)"
                 (ngModelChange)="submissionRequest.buyer.email = $event">
        </div>
      </div>
      <div class="field is-flex-grow-1">
        <label class="label">Phone:*</label>
        <div class="control">
          <input class="input is-medium" type="text" placeholder="+123456789009" formControlName="phone"
                 (keypress)="onlyNumbers($event)"
                 (ngModelChange)="submissionRequest.buyer.phone = $event">
        </div>
      </div>
      <div *ngIf="!submissionRequest.fullUpfrontPayment" class="mt-6">
        <div class="mb-4">
          <p class="is-size-4 has-text-weight-bold">Additional Business Information</p>
          <div class="purchase-subtitle">Required to receive payment terms</div>
        </div>
        <div class="field is-flex-grow-1">
          <label class="label">Company Type:*</label>
          <div class="control">
            <mat-select class="input is-medium" formControlName="businessType"
                    (ngModelChange)="submissionRequest.buyer.kybInfo!.businessType = $event">
              <mat-option  *ngFor="let type of businessTypes" value="{{type}}">{{type}}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="field is-flex-grow-1">
          <label class="label">Registered Company Address:*</label>
          <div class="control">
            <input class="input is-medium"
                   type="text"
                   placeholder="address, city, country"
                   aria-label="Delivery address"
                   matInput
                   formControlName="address"
                   [defaultValue]="submissionRequest.buyer.kybInfo?.registeredBusinessAddress"
                   [matAutocomplete]="auto"
                   (keypress)="onlyEnglish($event)"
                   (ngModelChange)="predict($event)">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="insert($event.option.value)">
              <mat-option *ngFor="let option of predictions" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <div class="field is-flex-grow-1">
          <label class="label">Employer Identification Number:*</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="XX-XXXXXXX" formControlName="employerIdentificationNumber"
                   (keypress)="onlyEnglish($event)"
                   (ngModelChange)="submissionRequest.buyer.kybInfo!.employerIdentificationNumber = $event">
          </div>
        </div>
        <div class="field is-flex-grow-1">
          <label class="label">Website:</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="website.com" formControlName="website"
                   (keypress)="onlyEnglish($event)"
                   (ngModelChange)="submissionRequest.buyer.kybInfo!.website = $event">
          </div>
        </div>
        <div class="field is-flex-grow-1">
          <label class="label">Name of Company Contact (if different than above):</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="Richard Roe" formControlName="contactName"
                   (keypress)="onlyEnglish($event)"
                   (ngModelChange)="submissionRequest.buyer.kybInfo!.contactName = $event">
          </div>
        </div>
        <div class="field is-flex-grow-1">
          <label class="label">Title of Company Contact (if different than above):</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="Lawyer" formControlName="contactTitle"
                   (keypress)="onlyEnglish($event)"
                   (ngModelChange)="submissionRequest.buyer.kybInfo!.contactTitle = $event">
          </div>
        </div>
        <div class="field is-flex-grow-1">
          <label class="label">Email of Company Contact (if different than above):</label>
          <div class="control">
            <input class="input is-medium" type="email" placeholder="richard.roe@email.com" formControlName="contactEmail"
                   (keypress)="onlyEnglish($event)"
                   (ngModelChange)="submissionRequest.buyer.kybInfo!.contactEmail = $event">
          </div>
        </div>
        <div class="field is-flex-grow-1">
          <label class="label">Phone of Company Contact (if different than above):</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="+123456789009" formControlName="businessPhone"
                   (keypress)="onlyNumbers($event)"
                   (ngModelChange)="submissionRequest.buyer.kybInfo!.businessPhone = $event">
          </div>
        </div>
      </div>
      <mat-checkbox class="mt-5 ml-4 mat-checkbox-2" (change)="intentToSubscribe = !intentToSubscribe" *ngIf="!isLoggedIn">
        <div class="intention ml-2">I agree to receive email notifications.
        </div>
      </mat-checkbox>
      <mat-checkbox class="mt-2 mb-2 ml-4 mat-checkbox-2" (change)="intentToRegister = !intentToRegister" *ngIf="!isLoggedIn">
        <div class="intention ml-2">I agree to the website’s
          <a href="https://s3.eu-west-3.amazonaws.com/shared.files.culturedsupply.com/TermsOfUse.pdf" target="_blank">Terms & Conditions</a>,
          <a href="https://s3.eu-west-3.amazonaws.com/shared.files.culturedsupply.com/PrivacyPolicy.pdf" target="_blank">Privacy Policy</a>
          and consent to creating<br> an account on culturedsupply.com.
        </div>
      </mat-checkbox>
      <div class="step-buttons">
        <a class="button-previous is-flex" (click)="previousStep()">
          <span class="icon">
            <i class="fa fa-angle-left"></i>
          </span>
          <span>Back</span>
        </a>
        <button type="submit" class="button button-next wide is-medium is-success has-text-weight-bold" [disabled]="!isLoggedIn && !intentToRegister">Next: {{nextStep}}</button>
      </div>
    </form>
    <div class="side-hint-container">
      <div class="side-hint">
        <div class="side-hint__icon">
          <img src="assets/images/order-box.png"/>
        </div>
        <p class="side-hint__title mb-3 ">Order summary</p>
        <div class="summary-item">
          <p class="summary-item__title">Order quantity:</p>
          <p class="summary-item__value">{{submissionRequest.quantity || 0 | number: '1.0'}} {{orderMeasure.measureShortPluralName}}</p>
        </div>
        <div class="summary-item">
          <p class="summary-item__title">Unit cost:</p>
          <p class="summary-item__value">{{campaign?.unitCurrency}}{{campaign?.unitPrice | number: '.2'}}
            /{{campaign?.unitMeasure?.measureShortSingularName}}</p>
        </div>
        <div class="summary-item">
          <p class="summary-item__title">Total product cost:</p>
          <p class="summary-item__value">{{ (submissionRequest?.orderAmount || 0) | currency }}</p>
        </div>
        <div class="summary-item">
          <p class="summary-item__title">Shipping and handling:</p>
          <p class="summary-item__value">{{ submissionRequest?.shippingCost | currency }}</p>
        </div>
        <div class="summary-item summary-item-total">
          <p class="summary-item__title">Total cost:</p>
          <p class="summary-item__value">{{ (submissionRequest?.orderAmount || 0) + (submissionRequest?.shippingCost || 0)| currency }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
