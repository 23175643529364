<div class="campaigns-wrapper">
  <ngx-slick-carousel
    #slickModal="slick-carousel"
    [config]="slideConfig" class="slickContainer">
    <div ngxSlickItem *ngFor="let campaign of campaigns | orderBySequenceNumberAsc">
      <mat-card>
        <mat-card-content>
          <div class="image-container" (click)="routeToCampaign(campaign)">
            <img *ngIf="campaign?.images" mat-card-image src="{{getImagePreview(campaign)}}">
          </div>
          <p class="has-text-success is-size-7 mt-2">
            {{getCampaignsDaysLeft(campaign)}}
          </p>
          <h6 class="has-text-weight-bold small-title campaign-title">
            {{campaign?.title}}
          </h6>
          <span class="product-category">
                  <span class="has-text-success">Product category:</span> {{campaign?.productCategory?.value}}
                </span>

          <div class="is-flex is-align-items-center justify-content-between mt-2 button-container">
            <a class="button is-success is-size-7 px-4" (click)="routeToCampaign(campaign)">
              Details >
            </a>
            <i *ngIf="editable"
               class="fa fa-pencil pl-6 cs-icon-button is-size-5"
               (click)="editCampaign(campaign)"></i>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ngx-slick-carousel>
</div>

