import {Component, Input, OnInit} from '@angular/core';
import {Sort} from "@angular/material/sort";

/**
 * Components that creates mat-table from input data.
 *
 * Receives array of objects that have structure like that:
 * object: {
 *   property_x: MatTableValue,
 *   property_y: MatTableValue
 * }
 *
 * After processing will construct table like:
 * Property X              | Property Y
 * <p>property_x.value</p> | <p>property_y.value</p>
 *
 * If, for example, property_x has link field that is not null,
 * then <p> property_x.value </p>
 * will be replaced by <a routerLink="property_x.link"> property_x.value </a>
 */

export class MatTableValue {

  value?: string;
  link?: string;

  constructor(value?: string, link?: string) {
    this.value = value;
    this.link = link;
  }
}

@Component({
  selector: 'cs-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss']
})
export class MatTableComponent implements OnInit {

  @Input() inputList: any[];

  public sortedData: any[];
  valueNames: string[] = [];

  dateRegex = /^\d{2}-\d{2}-\d{4}$/;

  constructor() { }

  ngOnInit(): void {
    this.sortedData = this.inputList;
    Object.keys(this.sortedData[0] || {})
      .forEach(key => this.valueNames.push(key));
  }

  toCamelCase(input: string): string {
    let firstSign = input.substring(0, 1).toUpperCase();
    let rest = input.substring(1).toLowerCase();
    return firstSign.concat(rest);
  }

  sortData(sort: Sort) {
    const data = this.inputList;
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active].value, b[sort.active].value, isAsc);
    });
  }

  public getColumnName(path: string): string {
    let columnName: string = '';
    let pathCrumbs: string[] = path.split(/(?=[A-Z])/g);
    for (let i = 0; i < pathCrumbs.length; i++) {
      columnName = columnName.concat(this.toCamelCase(pathCrumbs[i]));
      if (i != (pathCrumbs.length - 1)) {
        columnName = columnName.concat(' ');
      }
    }
    return columnName;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (a == null || a == '') return isAsc ? -1 : 1;
    if (b == null || b == '') return isAsc ? 1 : -1;
    if (this.dateRegex.test(a.toString()) && this.dateRegex.test(b.toString())) {
      return (new Date(a) < new Date(b) ? -1 : 1) * (isAsc ? 1 : -1);
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}

