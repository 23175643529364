import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {User, UsersApiService, UserSearchResult} from '../../../api/cs';
import {MatTableValue} from "../../../shared/components/mat-table/mat-table.component";
import {formatDate} from "@angular/common";
import {Title} from "@angular/platform-browser";

/**
 * Entity that will be sent to mat-table-component
 * Each record is a row
 * Each field is a name of table column
 * Each MatTableValue.value - is a cell value
 * Each MatTableValue.link - link to which user will redirect if he clicks on value
 */
class SignUpTableRecord {

  name: MatTableValue;
  email: MatTableValue;
  companyName: MatTableValue;
  createdAt: MatTableValue;

  constructor(name: MatTableValue, email: MatTableValue, company_name: MatTableValue, created_at: MatTableValue) {
    this.name = name;
    this.email = email;
    this.companyName = company_name;
    this.createdAt = created_at;
  }
}

@Component({
  selector: 'cs-signups-list',
  templateUrl: './signups-list.component.html',
  styleUrls: ['./signups-list.component.scss']
})
export class SignupsListComponent implements OnInit {

  signups: User[];

  tableData: SignUpTableRecord[] = [];

  COMPANIES_URI_PREFIX = "/companies/";
  USERS_URI_PREFIX = "/administration/users/";

  constructor(private usersApiService: UsersApiService,
              private titleService: Title,
              @Inject(LOCALE_ID) private locale: string) {
  }

  ngOnInit(): void {
    this.titleService.setTitle("Sign Up | Cultured Supply");
    this.load();
  }

  load(): void {
    this.usersApiService.getSignUpsSearchResult()
      .subscribe((result: UserSearchResult) => {
        this.signups = result.items || [];
        let signUpRecords = this.signups.map(user => new SignUpTableRecord(
          new MatTableValue(user.firstName + " " + user.lastName),
          new MatTableValue(user.email, this.USERS_URI_PREFIX + user.id),
          new MatTableValue(user.company?.name, this.COMPANIES_URI_PREFIX + user.company?.alias),
          new MatTableValue(formatDate(Number.parseFloat(user.createdAt || '') * 1000, 'MM-dd-YYYY', this.locale) || ''),
        ));
        this.tableData.push(...signUpRecords);
      });
  }
}
