<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">{{data.modalMode}} Product</p>
    <span class="icon cs-icon-button" aria-label="close" (click)="confirmClose()">
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <form class="modal-card-body"
        *ngIf="form"
        [formGroup]="form"
        (ngSubmit)="submit()">

    <div class="field pt-4">
      <div id="product-dropdown" class="field is-flex pb-4">
        <div class="field mb-0 is-flex-grow-1">
          <label class="label">Product:*</label>
          <input type="text"
                 class="input is-medium"
                 placeholder="Spirulina"
                 [formControl]="productName"
                 (ngModelChange)="setProductName($event)"
                 [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let product of productNames$ | async"
                        [value]="productName"
                        (onSelectionChange)="productName.setValue(product)">
              {{product}}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </div>


    <button type="submit" class="button wide is-success has-text-weight-bold is-fullwidth">
      {{data.modalMode}}
    </button>
  </form>
</div>
