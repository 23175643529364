<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">{{headerText}}</p>
    <span class="icon cs-icon-button" aria-label="close" mat-dialog-close>
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
<!--  Login Form-->
  <section class="modal-card-body pt-6"
           [formGroup]="form"
           *ngIf="form && data.mode == 'logIn'">
    <form [formGroup]="form" (submit)="login()">
      <div class="field pt-4">
        <label class="label">Login</label>
        <div class="control">
          <input class="input is-medium" type="email" (keypress)="onlyEnglish($event)" placeholder="example@email.com" formControlName="email">
        </div>
      </div>
      <div class="field pb-4">
        <label class="label">Password</label>
        <div class="control">
          <input class="input is-medium" type="password" placeholder="password" formControlName="password">
        </div>
      </div>
      <div class="field is-grouped is-grouped-centered mt-6">
        <div class="control py-4">
          <button class="button wide is-success has-text-weight-bold is-fullwidth" type="submit">Login</button>
        </div>
      </div>
    </form>
  </section>
<!--  Reset Password Form-->
  <section class="modal-card-body pt-6"
           [formGroup]="resetPasswordForm"
           *ngIf="resetPasswordForm && data.mode == 'resetPwd'">
  <!--    Step 1-->
    <form [formGroup]="resetPasswordForm" (submit)="resetPassword()">
      <ng-container *ngIf="resetPasswordStep === 1">
        <div class="field pt-4">
          <label class="label">Email</label>
          <div class="control">
            <input class="input is-medium" type="email" (keypress)="onlyEnglish($event)" placeholder="example@email.com" formControlName="email">
          </div>
        </div>
        <div class="field is-grouped is-grouped-centered mt-6">
          <div class="control py-4">
            <button type="submit"
                    class="button wide is-success has-text-weight-bold is-fullwidth"
                    [disabled]="resetPasswordForm.invalid"
            >
              Reset
            </button>
          </div>
        </div>
      </ng-container>
    </form>
  <!--    Step 2-->
    <form [formGroup]="resetPasswordFormConfirm" (submit)="confirmResetPassword()">
      <ng-container *ngIf="resetPasswordStep === 2">
        <article class="message is-dark">
          <div class="message-body">
            You should receive a security code on your email. Enter it here:
          </div>
        </article>

        <div class="field pt-4">
          <label class="label">Security Code</label>
          <div class="control">
            <input class="input is-medium" type="text" formControlName="code">
          </div>
        </div>

        <div class="field pt-4">
          <label class="label">New Password</label>
          <div class="control">
            <input class="input is-medium" type="password" formControlName="password">
          </div>
        </div>

        <div class="field pb-4">
          <label class="label">Confirm Password</label>
          <div class="control">
            <input class="input is-medium" type="password" placeholder="password" formControlName="confirm_password">
          </div>
        </div>

        <div class="field is-grouped is-grouped-centered mt-6">
          <div class="control py-4">
            <button class="button wide is-success has-text-weight-bold is-fullwidth" type="submit"
                    [disabled]="resetPasswordFormConfirm.invalid"
            >
              Confirm
            </button>
          </div>
        </div>
      </ng-container>
    </form>
  </section>
<!--  Sign Up-->
  <section class="modal-card-body pt-6"
           [formGroup]="form"
           *ngIf="form && data.mode == 'signUp'">
    <form [formGroup]="form" (submit)="signUp()">
      <div class="field">
        <label class="label">Company name:*</label>
        <div class="control">
          <input class="input is-medium" type="text" (keypress)="onlyEnglish($event)" placeholder="Space X" formControlName="companyName">
        </div>
      </div>

      <div class="field is-flex pt-4 name-line">
        <div class="field mb-0 is-flex-grow-1">
          <label class="label">First name:*</label>
          <div class="control">
            <input class="input is-medium" type="text" (keypress)="onlyEnglish($event)" placeholder="John" formControlName="firstName">
          </div>
        </div>
        <div class="field is-flex-grow-1">
          <label class="label">Last name:*</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="Doe" (keypress)="onlyEnglish($event)" formControlName="lastName">
          </div>
        </div>
      </div>

      <div class="field pt-4">
        <label class="label">Email address:*</label>
        <div class="control">
          <input class="input is-medium" type="email" placeholder="example@email.com" (keypress)="onlyEnglish($event)" formControlName="signUpEmail">
        </div>
      </div>

      <div class="field pt-4">
        <label class="label">Phone number:*</label>
        <div class="control">
          <input class="input is-medium" type="text" placeholder="+1 (XXX) XXX-XXXX" formControlName="phone">
        </div>
      </div>

      <mat-checkbox class="mt-2 ml-4 mat-checkbox-2" (change)="intentToSubscribe = !intentToSubscribe">
        <div class="intention ml-2">I agree to the receive email notifications.
        </div>
      </mat-checkbox>

      <mat-checkbox class="mt-2 ml-4 mat-checkbox-2" (change)="intentToRegister = !intentToRegister">
        <div class="intention ml-2">I agree to the website’s
          <a href="https://s3.eu-west-3.amazonaws.com/shared.files.culturedsupply.com/TermsOfUse.pdf" target="_blank">Terms & Conditions</a>,
          <a href="https://s3.eu-west-3.amazonaws.com/shared.files.culturedsupply.com/PrivacyPolicy.pdf" target="_blank">Privacy Policy</a>.
        </div>
      </mat-checkbox>

      <div class="field is-grouped is-grouped-centered mt-5">
        <div class="control py-4">
          <button class="button wide is-success has-text-weight-bold is-fullwidth" type="submit"
             [disabled]="form.invalid || !intentToRegister">
            Submit
          </button>
        </div>
      </div>
    </form>
  </section>
  <footer class="modal-card-foot is-flex is-justify-content-center pt-4" *ngIf="!adminMode">
    <div class="is-flex is-flex-direction-column is-align-items-end pr-3 footer-left" *ngIf="data.mode != 'resetPwd'">
      <p class="has-text-weight-semibold has-text-grey">Forgot your password?</p>
      <a class="has-text-weight-semibold" (click)="setUpResetPwdMode()">Reset password ></a>
    </div>
    <div class="is-flex is-flex-direction-column"
         [style.padding-left.em]="data.mode == 'resetPwd' ? 0 : 0.75"
         [style.padding-right.em]="data.mode == 'signUp' ? 0.75 : 0"
         [style.align-items]="data.mode == 'resetPwd' ? 'flex-end' : 'flex-start' "
         [style.footer-left]="data.mode == 'resetPwd'"
         *ngIf="data.mode != 'logIn'">
      <p class="has-text-weight-semibold has-text-grey">Already have an account?</p>
      <a class="has-text-weight-semibold" (click)="setUpLoginMode()">Login ></a>
    </div>
    <div class="is-flex is-flex-direction-column is-align-items-start pl-3" *ngIf="data.mode != 'signUp'">
      <p class="has-text-weight-semibold has-text-grey">Don't have an account?</p>
      <a class="has-text-weight-semibold" (click)="setUpSignUpMode()">Signup ></a>
    </div>
  </footer>
</div>
