<div class="form-container">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">Create New Account</p>
    <span class="icon cs-icon-button" aria-label="close" mat-dialog-close>
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>

  <form class="modal-card-body" [formGroup]="form" (ngSubmit)="submit()">
    <mat-checkbox class="mb-2 mat-checkbox-2" (change)="sendEmail = !sendEmail">
      <div class="intention ml-2">Send email about creating an account on culturedsupply.com.</div>
    </mat-checkbox>
    <div class="field pt-4" *ngIf="form.value.userType == 'customer'">
      <div id="company-dropdown" class="field is-flex pt-4">
        <div class="field mb-0 is-flex-grow-1">
          <label class="label">Company:*</label>
          <input type="text"
                 class="input is-medium"
                 placeholder="Space X"
                 [formControl]="companyName"
                 (ngModelChange)="setCompanyName($event)"
                 [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let company of filteredOptions | async"
                        [value]="companyName"
                        (onSelectionChange)="companyName.setValue(company)">
              {{company}}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </div>
    <div id="name-line" class="field is-flex pt-4">
      <div class="field mb-0 is-flex-grow-1">
        <label class="label">First name:*</label>
        <div class="control">
          <input class="input is-medium" type="text" (keypress)="onlyEnglish($event)" placeholder="John" formControlName="firstName">
        </div>
      </div>
      <div class="field is-flex-grow-1">
        <label class="label">Last name:*</label>
        <div class="control">
          <input class="input is-medium" type="text"  (keypress)="onlyEnglish($event)"  placeholder="Doe" formControlName="lastName">
        </div>
      </div>
    </div>
    <div class="field pt-4">
      <label class="label">Email address:*</label>
      <div class="control">
        <input class="input is-medium" type="email"  (keypress)="onlyEnglish($event)"  placeholder="example@email.com" formControlName="email">
      </div>
    </div>
    <div class="field pt-4" *ngIf="form.value.userType == 'customer'">
      <label class="label">Phone:*</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="+123456789090" formControlName="phone" (ngModelChange)="setPhone($event)">
      </div>
    </div>
    <div class="field pt-4">
      <label class="label">User Type:</label>
      <select class="input is-medium" formControlName="userType" (ngModelChange)="processUserTypeChange($event)">
        <option selected="selected" value="admin">Admin</option>
        <option value="customer">Customer</option>
      </select>

    </div>
    <div class="field mt-4">
      <div class="control pt-4">
        <button type="submit" class="button wide is-medium is-success has-text-weight-bold is-fullwidth" [disabled]="!form.valid">
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
