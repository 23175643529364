import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ValidatorsService } from 'src/app/core/util/validators.service';
import {SupplyChainAuthenticationApiService} from "../../../api/cs";
import {ToastService} from "../../../shared/services/toast.service";
import {FormNames, HubSpotFormsService} from "../../../shared/services/hubspot-forms.service";
import {Router} from "@angular/router";

@Component({
  selector: 'cs-signup-modal',
  templateUrl: './authentication-modal.component.html',
  styleUrls: ['./authentication-modal.component.scss']
})
export class AuthenticationModalComponent implements OnInit {

  signInForm: FormGroup;
  signUpForm: FormGroup;
  userEmail: string;

  mode: 'Sign Up' | 'Check Your Email' | 'Sign In' = 'Sign Up';

  constructor(@Inject(MAT_DIALOG_DATA) public data: { initMode: "Sign Up" | "Sign In" },
              private router: Router,
              private fb: FormBuilder,
              private toastService: ToastService,
              private hubSpotFormsService: HubSpotFormsService,
              public dialogRef: MatDialogRef<AuthenticationModalComponent>,
              private authenticationApiService: SupplyChainAuthenticationApiService) { }

  ngOnInit(): void {
    if (!!this.data.initMode) {
      this.mode = this.data.initMode;
    }
    this.initForms();
  }

  private initForms(): void {
    this.signInForm = this.fb.group({
      signInUserEmail: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")])
    })
    this.signUpForm = this.fb.group({
      companyName: new FormControl('', Validators.required),
      signUpUserEmail: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")])
    })
  }

  signUp(): void {
    if (this.signUpForm.valid) {
      this.mode = "Check Your Email";
      const {signUpUserEmail, companyName} = this.signUpForm.value;
      this.userEmail = signUpUserEmail;
      this.authenticationApiService.signUpToSupplyChain({
        supplyChainSignUpRequest: {
          userEmail: signUpUserEmail,
          companyName: companyName
        }
      }).subscribe(() => {
        this.hubSpotFormsService.sendFormRequest(FormNames.SUPPLY_CHAIN_SIGN_UP_REQUEST,
          "Supply Chain Builder",
          this.router.url,
          [
            companyName,
            signUpUserEmail
          ]).subscribe(() => {});
      });
    } else {
      this.signUpForm.get("companyName")?.markAsTouched();
      this.signUpForm.get("signUpUserEmail")?.markAsTouched();
    }
  }

  signIn(): void {
    if (this.signInForm.valid) {
      this.mode = "Check Your Email";
      const {signInUserEmail} = this.signInForm.value;
      this.userEmail = signInUserEmail;
      this.authenticationApiService.signInToSupplyChain({
        supplyChainEmailRequest: {
          userEmail: signInUserEmail
        }
      }).subscribe(
        () => {
          this.hubSpotFormsService.sendFormRequest(FormNames.SUPPLY_CHAIN_SIGN_IN_REQUEST,
            "Supply Chain Builder",
            this.router.url,
            [
              signInUserEmail
            ]).subscribe(() => {});
        },
        error => {
        if (+error.status == 419) {
          this.toastService.error("Sorry, you need to sign up at first");
          this.mode = "Sign Up";
        }
      })
    } else {
      this.signUpForm.get("signInUserEmail")?.markAsTouched();
    }
  }

  resendEmail(): void {
    this.authenticationApiService.resendEmail({
      supplyChainEmailRequest: {
        userEmail: this.userEmail
      }
    })
  }

  onlyEnglish($event: KeyboardEvent) {
    ValidatorsService.onlyEnglish($event);
  }

}
