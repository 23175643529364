<div class="banner is-flex is-justify-content-center" *ngIf="isBannerShowed()">
  <div class="banner-content">
    <quill-view class="banner-text is-align-self-center" [content]="getBannerContent()"></quill-view>
  </div>
</div>
<div id="main-wrapper">
  <cs-navigation *ngIf="isBrowser"></cs-navigation>
  <div id="main-content">
    <router-outlet></router-outlet>
  </div>
  <cs-footer></cs-footer>
</div>
<div class="loader-wrapper" [class.is-active]="showLoader">
  <div class="loader is-success is-loading"></div>
</div>
