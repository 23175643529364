import {Injectable} from "@angular/core";
import {map, Observable, take} from "rxjs";
import {
  SearchRequest,
  SupplyChainDataApiService
} from "../../api/cs";
import {SearchRequestEntity} from "../models/search-request-entity.model";
import {SearchResponse} from "../models/search-response.model";

/**
 * Service that knows how to send filters requests to elasticsearch
 */
@Injectable({
  providedIn: 'root'
})
export class ElasticSearchService {

  private supplyChainDataApiService: SupplyChainDataApiService;

  constructor(supplyChainDataApiService: SupplyChainDataApiService) {
    this.supplyChainDataApiService = supplyChainDataApiService;
  }

  /**
   * Processes searching among existing data
   * @param searchRequestEntity - filters values
   * @return Observable<json received from elastic>
   */
  searchByTextRequest(searchRequestEntity?: SearchRequestEntity): Observable<SearchResponse> {
    return this.supplyChainDataApiService.performSearch({
      searchRequest: new class implements SearchRequest {
        searchText = searchRequestEntity?.searchingText || "";
      }
    }).pipe(
      take(1),
      map(response => response || [])
    );
  }
}
