import {Component, Inject, OnInit} from '@angular/core';
import {UnitsScalingService} from "../../../shared/services/units-scaling.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, map, Observable, startWith} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CampaignsApiService, UnitScalingEntity} from "../../../api/cs";
import {ToastService} from "../../../shared/services/toast.service";

@Component({
  selector: 'cs-dictionary-measure-item',
  templateUrl: './dictionary-measure-item-form.component.html',
  styleUrls: ['./dictionary-measure-item-form.component.scss']
})
export class DictionaryMeasureItemFormComponent implements OnInit {

  headerText: string;

  form: FormGroup;

  measurePolicies: Set<string>;
  measurePolicies$: BehaviorSubject<Set<string>>;
  filteredOptions: Observable<string[]>;
  policy: FormControl;

  constructor(private fb: FormBuilder,
              private toast: ToastService,
              private unitsScalingService: UnitsScalingService,
              private campaignsApiService: CampaignsApiService,
              private dialogRef: MatDialogRef<DictionaryMeasureItemFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                mode: 'EDIT' | 'CREATE',
                item: UnitScalingEntity
              }) { }

  ngOnInit(): void {
    if (this.data.mode == 'EDIT') {
      this.headerText = this.data.item.measurePolicy + ": " + this.data.item.measurePluralName + ' Editing';
    } else {
      this.headerText = 'Measure Unit Creating';
    }
    this.measurePolicies$ = this.unitsScalingService.getPolicies();
    this.measurePolicies$.subscribe(policies => this.measurePolicies = policies);
    this.form = this.fb.group({
      pluralName: new FormControl(this.data.item?.measurePluralName || '', Validators.required),
      singularName: new FormControl(this.data.item?.measureSingularName || '', Validators.required),
      shortPluralName: new FormControl(this.data.item?.measureShortPluralName || '', Validators.required),
      shortSingularName: new FormControl(this.data.item?.measureShortSingularName || '', Validators.required),
      factor: new FormControl(this.data.item?.measureFactor || '', Validators.required),
      retired: new FormControl(this.data.item?.isRetired || false)
    })
    if (this.data.mode == 'EDIT') {
      this.policy = new FormControl({value: this.data.item?.measurePolicy, disabled: true}, Validators.required);
    } else {
      this.policy = new FormControl('', Validators.required);
    }
    this.form.addControl('policy', this.policy);
    this.filteredOptions = this.policy.valueChanges.pipe(
      startWith(''),
      map(value => this.doFilter(value || '')),
    );
  }

  private doFilter(value: string): string[] {
    return Array.from(this.measurePolicies).filter(measure => measure.toLowerCase().includes(value.toLowerCase()));
  }

  submit() {
    if (this.form.valid) {
      if (this.data.mode == 'EDIT') {
        this.campaignsApiService.updateMeasureUnit({
          unitScalingEntity: {
            id: this.data.item.id || 0,
            measurePolicy: this.data.item.measurePolicy || '',
            measurePluralName: this.form.value.pluralName || '',
            measureSingularName: this.form.value.singularName || '',
            measureShortPluralName: this.form.value.shortPluralName || '',
            measureShortSingularName: this.form.value.shortSingularName || '',
            measureFactor: this.form.value.factor || 0,
            isRetired: this.form.value.retired
          }
        }).subscribe(() => {
          this.toast.success("Measure Unit Successfully Updated");
          this.dialogRef.close();
        })
      } else {
        this.campaignsApiService.createMeasureUnit({
          unitScalingEntity: {
            measurePolicy: this.form.value.policy || '',
            measurePluralName: this.form.value.pluralName || '',
            measureSingularName: this.form.value.singularName || '',
            measureShortPluralName: this.form.value.shortPluralName || '',
            measureShortSingularName: this.form.value.shortSingularName || '',
            measureFactor: this.form.value.factor || 0,
            isRetired: false
          }
        }).subscribe(() => {
          this.toast.success("Measure Unit Successfully Created");
          this.dialogRef.close();
        })
      }
    } else {
      for (let control in this.form.controls) {
        this.form.controls[control].markAsTouched();
      }
    }
  }

}
