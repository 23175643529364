import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {AuthenticationApiService} from "../../../api/cs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ValidatorsService} from "../../../core/util/validators.service";
import {finalize, map, Observable, startWith} from "rxjs";
import {LoaderService} from "../../../shared/services/loader.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'cs-admin-create',
  templateUrl: './admin-create.component.html',
  styleUrls: ['./admin-create.component.scss']
})
export class AdminCreateComponent implements OnInit {

  isModalActive: boolean;

  form: FormGroup;

  @Output()
  created = new EventEmitter<boolean>();
  sendEmail: boolean;
  companyName: FormControl;
  companyNameValue: string;
  userPhone: string;
  companies: string[] = [];
  filteredOptions: Observable<string[]>;

  constructor(private fb: FormBuilder,
              private loader: LoaderService,
              private dialogRef: MatDialogRef<AdminCreateComponent>,
              private authenticationApiService: AuthenticationApiService,
              @Inject(MAT_DIALOG_DATA) public data: {companies: Map<string, string>}) { }

  ngOnInit(): void {
    for (let key of this.data.companies.keys()) {
      this.companies.push(key);
    }
    this.companyName = new FormControl(this.companyNameValue);
    this.form = this.fb.group({
      companyName: this.companyName,
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,63}$')]),
      userType: "admin"
    })
    this.filteredOptions = this.companyName.valueChanges.pipe(
      startWith(''),
      map(value => {
        if (!value.hasOwnProperty("value")) {
          return this.doFilter(value || '');
        } else {
          return [];
        }
      }),
    );
  }

  private doFilter(value: string): string[] {
    if (!!value) {
      return Array.from(this.companies).filter(company => company.toLowerCase().includes(value?.toLowerCase()));
    } else {
      return [];
    }
  }

  onlyEnglish($event: KeyboardEvent) {
    ValidatorsService.onlyEnglish($event);
  }

  processUserTypeChange(event: any): void {
    if (event == 'customer') {
      this.form.controls["companyName"].setValue(this.companyNameValue);
      this.form.controls["companyName"].addValidators(Validators.required);
      this.form.addControl("phone", new FormControl(this.userPhone, Validators.required));
    } else if (event == 'admin') {
      this.form.controls["companyName"].removeValidators(Validators.required);
      this.form.removeControl("phone");
    }
  }

  public setCompanyName(event: any): void {
    if (!(event instanceof FormControl)) {
      this.companyNameValue = event;
    }
  }

  public setPhone(event: any): void {
    this.userPhone = event;
  }

  submit() {
    this.loader.show();
    if (this.form.value.userType == 'admin') {
      this.authenticationApiService.createAdmin({
        signUpRequestUser: {
          firstName: this.form.value.firstName,
          lastName: this.form.value.lastName,
          email: this.form.value.email,
          sendEmail: this.sendEmail
        }
      }).pipe(finalize(() => this.loader.hide()))
        .subscribe(() => {
        this.dialogRef.close();
      })
    } else {
      let companyId = this.data.companies.get(this.companyNameValue);
      let companySignUpRequest;
      if (!!companyId) {
        companySignUpRequest = {
          id: companyId || '',
          name: this.companyNameValue
        }
      } else {
        companySignUpRequest = {
          name: this.form.value.companyName
        }
      }
      this.authenticationApiService.signUp({
        signUpRequest: {
          company: companySignUpRequest,
          user: {
            firstName: this.form.value.firstName,
            lastName: this.form.value.lastName,
            phone: this.form.value.phone,
            email: this.form.value.email,
            sendEmail: this.sendEmail
          }
        }
      }).pipe(finalize(() => this.loader.hide()))
        .subscribe(() => {
          this.dialogRef.close();
        })
    }
  }
}
