import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SignatureComponent} from "../../../core/components/signature/signature.component";
import {MatDialog} from "@angular/material/dialog";
import {
  Campaign,
  CampaignsApiService,
  CompaniesApiService,
  CompanyProfile,
  CreateSubmissionRequest,
  LocationApiService
} from "../../../api/cs";
import {AuthService} from "../../../shared/services/auth.service";
import {PDFDocument, PDFForm, PDFPage} from "pdf-lib";
import fontkit from '@pdf-lib/fontkit';
import {BehaviorSubject, map} from "rxjs";
import {InfoModalWindowComponent} from "../../../core/components/info-modal-window/info-modal-window.component";
import {formatNumber} from "@angular/common";
import {LoaderService} from "../../../shared/services/loader.service";


@Component({
  selector: 'cs-step-contract',
  templateUrl: './step-contract.component.html',
  styleUrls: ['./step-contract.component.scss', './../purchase.common.scss']
})
export class StepContractComponent implements OnInit {

  @Output() prev = new EventEmitter<boolean>();
  @Output() contract = new EventEmitter<boolean>();
  @Output() signatureEvent = new EventEmitter<any>();
  @Input() campaign: Campaign;
  @Input() submissionRequest$: BehaviorSubject<CreateSubmissionRequest>;
  @Input() isContractSigned: boolean;
  submissionRequest: CreateSubmissionRequest;
  company: CompanyProfile;

  pdfSource: any;
  pdfDoc: PDFDocument;

  signatureDate: Date;
  @Input() signature: any;
  intentToSign = false;

  @Input() nextStep: string;

  constructor(private dialog: MatDialog, private campaignService: CampaignsApiService, private authService: AuthService,
              private companiesApiService: CompaniesApiService, private campaignsService: CampaignsApiService,
              private locationService: LocationApiService, private loader: LoaderService) {
  }

  async ngOnInit(): Promise<void> {
    this.intentToSign = !!this.signature || this.isContractSigned;
    this.submissionRequest$.subscribe(request => this.submissionRequest = request);

    if (this.isContractSigned) {
      this.downloadSignedContract();
    } else {
      this.createNewContract();
    }
  }

  private createNewContract() {
    if (this.campaign?.companyId) {
      this.companiesApiService.getCompany({
        id: this.campaign.companyId
      }).subscribe(
        async data => {
          this.company = data;
          const contractBuffer = await this.modifyPdf();
          this.flatten(contractBuffer);
          if (this.signature) {
            await this.addSignatureToPdf(this.signature);
          }
        }
      );
    }
  }

  previousStep(): void {
    this.prev.emit(true);
  }

  next(): void {
    let dialogRef = this.dialog.open(InfoModalWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        success: true,
        message: "By clicking “OK” you will be submitting a signed purchase order to the supplier and be  bound to the terms of this legally binding contract. "
      }
    });
    dialogRef.afterClosed().subscribe(
      data => {
        this.loader.show();
        if (this.isContractSigned) {
          this.sendContractResult();
        } else {
          this.preparePdf(false)
        }
      }
    )
  }

  private sendContractResult() {
    this.contract.emit(true);
    this.loader.hide();
  }

  preparePdf(download: boolean) {
    if (download) {
      this.savePdf();
    } else {
      this.sendContract();
    }
  }

  sign() {
    const name = `${this.submissionRequest.buyer.firstName} ${this.submissionRequest.buyer.lastName}`
    let dialogRef = this.dialog.open(SignatureComponent, {data: {name: name}, panelClass: 'custom-dialog-container'});

    dialogRef.afterClosed().subscribe(res => {
      if (!!res.data) {
        this.signature = res.data;
        this.signatureDate = new Date();
        this.addSignatureToPdf(res.data);
        this.signatureEvent.emit(res.data);
        this.dialog.open(InfoModalWindowComponent, {
          panelClass: 'custom-dialog-container',
          data: {
            success: true,
            message: "You successfully signed the purchase order, which is a legally binding contract."
          }
        });
      }
    });
  }

  /**
   * Get order template from S3 bucket and if campaign have custom terms of use, add it to pdf,
   * else add template of terms of Use by Cultured Supply
   */
  public async modifyPdf() {
    const existingPdfBytes = await this.getPdfTemplateOrderSource();
    const termsOfUsePdfBytes = await this.getTermsAndConditionSource();
    if (existingPdfBytes && termsOfUsePdfBytes) {
      const termsOfUse: PDFDocument = await PDFDocument.load(termsOfUsePdfBytes)
      const pdfDoc: PDFDocument = await PDFDocument.load(existingPdfBytes);
      await this.fillForm(pdfDoc);

      if (termsOfUse !== undefined) {
        await this.copyPDF(pdfDoc, termsOfUse);
      }

      const pdfBytes = await pdfDoc.save();
      this.pdfDoc = pdfDoc;


      return pdfBytes.buffer;
    }
    return null;
  }

  private async getTermsAndConditionSource() {
    return await this.campaignsService.getTemplateContract({alias: this.campaign.alias || ''}).pipe(map(data => data.arrayBuffer())).toPromise()
  }

  private async getPdfTemplateOrderSource() {
    return await this.campaignsService.getPurchaseOrder().pipe(map(data => data.arrayBuffer())).toPromise()
  }

  /**
   * Copy all pages of one PDF and add them to the ending of destination PDF.
   * @param destinationPDF
   * @param sourcePDF
   * @private
   */
  private async copyPDF(destinationPDF: PDFDocument, sourcePDF: PDFDocument) {
    let pages = sourcePDF.getPages();
    let pagesIndexes = pages.map((page, index) => index);
    const copyPages = await destinationPDF.copyPages(sourcePDF, pagesIndexes);
    for (let page of copyPages) {
      destinationPDF.addPage(page);
    }
  }

  /**
   * Fill form with order details.
   * @param pdfDoc
   * @private
   */
  private async fillForm(pdfDoc: PDFDocument) {
    const form = pdfDoc.getForm();
    await this.addCustomFontToPdf(pdfDoc, form);

    form.getTextField("Campaign Name").setText(this.campaign?.title || '');
    form.getTextField("Order Number").setText(`${this.submissionRequest?.id}`);
    let date = new Date();
    form.getTextField("Current Date").setText(`${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`);

    // Was removed from template with CUL-572
    // form.getTextField("VendorContact").setText(`${this.company?.contact?.firstName} ${this.company?.contact?.lastName}`);
    // form.getTextField("BuyerContact").setText(`${this.submissionRequest?.buyer.firstName} ${this.submissionRequest.buyer.lastName}`);

    form.getTextField("VendorCompanyName").setText(this.company?.name || '');
    form.getTextField("BuyerCompanyName").setText(this.submissionRequest?.buyer?.company?.name || '');

    let vendorAddress = form.getTextField("VendorAddress");
    let vendorLocation = await this.locationService.normalise({
      locationRequest: {
        id: this.submissionRequest.buyer.id,
        address: this.company?.address
      }
    }).toPromise();
    vendorAddress.enableMultiline();
    vendorAddress.setText(`${vendorLocation?.street_number ? `${vendorLocation.street_number} ` : ''}${vendorLocation?.street_address ? `${vendorLocation.street_address}` : ''}` +
      `${vendorLocation?.subpremise !== null ? `\n${vendorLocation?.subpremise}` : '' }` +
      `\n${vendorLocation?.city !== null ? `${vendorLocation?.city}, ` : ''}${vendorLocation?.region ? `${vendorLocation.region} ` : ''}${vendorLocation?.zipCode ? `${vendorLocation.zipCode}` : ''}`);

    if (this.submissionRequest.deliveryAddress) {
      let buyerAddress = form.getTextField("BuyerAddress");
      buyerAddress.enableMultiline();
      let buyerLocation = await this.locationService.normalise({
        locationRequest: {
          id: this.submissionRequest.buyer.id,
          address: this.submissionRequest?.deliveryAddress
        }
      }).toPromise();
      buyerAddress.setText(`${buyerLocation?.street_number ? `${buyerLocation.street_number} ` : ''}${buyerLocation?.street_address ? `${buyerLocation.street_address}` : ''}` +
        `${buyerLocation?.subpremise !== null ? `\n${buyerLocation?.subpremise}` : '' }` +
        `\n${buyerLocation?.city !== null ?  `${buyerLocation?.city},` : ''}${buyerLocation?.region ? `${buyerLocation.region} ` : ''}${buyerLocation?.zipCode || ''}`);
    }

    form.getTextField("VendorPhone").setText(`${this.company?.contact?.phone || '-'}`);
    form.getTextField("BuyerPhone").setText(`${this.submissionRequest?.buyer?.phone || '-'}`);

    form.getTextField("VendorEmailAddress").setText(`${this.company?.contact?.email || '-'}`);
    form.getTextField("BuyerEmailAddress").setText(`${this.submissionRequest?.buyer.email}`);

    form.getTextField("Shipping Terms").setText('DDP - Delivered duty paid to shipping address');
    form.getTextField("Shipping Date").setText(`Via truck or air`);

    let deliveryDate = new Date();
    deliveryDate.setDate(deliveryDate.getDate() + (this.campaign?.deliveryTime || 0))
    form.getTextField("DeliveryDate").setText(`${deliveryDate.getMonth() + 1}-${deliveryDate.getDate()}-${deliveryDate.getFullYear()}`);

    form.getTextField("CodeRow1").setText("1");
    form.getTextField("DescriptionRow1").setText(`${this.campaign?.title}`);
    form.getTextField("QuantityRow1").setText(`${formatNumber(Number(this.submissionRequest?.quantity), 'en-US', '1.2-2')} ${this.campaign?.unitMeasure?.measureShortSingularName}`);
    form.getTextField("Unit PriceRow1").setText(`$${formatNumber(Number(this.campaign?.unitPrice), 'en-US', '1.2-2')}`)

    form.getTextField("AmountRow1").setText(`$${formatNumber(Number(this.submissionRequest?.orderAmount), 'en-US', '1.2-2')}`)

    form.getTextField("Subtotal").setText(`$${formatNumber(Number(this.submissionRequest?.orderAmount), 'en-US', '1.2-2')}`);
    form.getTextField("Shipping").setText(`$${formatNumber(Number(this.submissionRequest?.shippingCost), 'en-US', '1.2-2')}`);


    form.getTextField("TotalAmount").setText(`$${formatNumber(Number((this.submissionRequest?.orderAmount || 0) + (this.submissionRequest?.shippingCost || 0)), 'en-US', '1.2-2')}`);

    let paymentPeriod = form.getTextField("PaymentPeriod");
    paymentPeriod.enableMultiline();
    paymentPeriod.setFontSize(9);
    paymentPeriod.setText((this.campaign?.paymentTerms != null && this.campaign?.paymentTerms.length > 0 && !this.submissionRequest.fullUpfrontPayment) ? this.campaign.paymentTerms : this.getPaymentPeriod());
    form.getTextField("VendorName").setText(`${this.company?.name}`);
  }

  private async addCustomFontToPdf(pdfDoc: PDFDocument, form: PDFForm) {
    const url = 'https://s3.eu-west-3.amazonaws.com/shared.files.culturedsupply.com/fonts/Tinos-Regular.ttf';
    const fontBytes = await fetch(url).then(res => res.arrayBuffer())
    pdfDoc.registerFontkit(fontkit);
    const font = await pdfDoc.embedFont(fontBytes);
    const rawUpdateFieldAppearances = form.updateFieldAppearances.bind(form);
    form.updateFieldAppearances = function () {
      return rawUpdateFieldAppearances(font);
    };
  }

  /**
   * Copy PDF before flatten Forms, because if flatten in existing Pdf, we can`t add signature. After flatten forms, all
   * fields transform to text and user can`t change anything in purchase order. So required make flatten before downloading.
   */
  async savePdf() {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([this.pdfSource], {type: 'application/pdf'}));
    a.download = `${this.campaign.title}-${this.submissionRequest.id}-order.pdf`;
    a.click();
  }

  private async addSignatureToPdf(signature: any) {
    let signField = this.pdfDoc.getForm().getButton("SignHere");
    const signatureImage = await this.pdfDoc.embedPng(signature);

    signField.setImage(signatureImage);
    this.pdfDoc.getForm().getTextField("TimeStamp").setText(`${Date.now()}`);
    const signatureDms = signatureImage.scale(0.25);
    this.pdfDoc.getPages().forEach((page: PDFPage, i: number) => {
      if (i !== 0) {
        let  {width, height } = page.getSize();
       page.drawImage(signatureImage, {
         x: width - signatureDms.width,
         y: 0,
         width: signatureDms.width,
         height: signatureDms.height
       })
       page.drawText(Date.now().toString(), {
         x: width - signatureDms.width,
         y: 5,
         size: 10
       })
    }})

    // Delivery date should calculate after the document will be signed
    let deliveryDate = new Date();
    deliveryDate.setDate(deliveryDate.getDate() + (this.campaign?.deliveryTime || 0))
    this.pdfDoc.getForm().getTextField("DeliveryDate").setText(`${deliveryDate.getMonth() + 1}-${deliveryDate.getDate()}-${deliveryDate.getFullYear()}`);
    this.pdfDoc.getForm().flatten();
    const pdfBytes = await this.pdfDoc.save();
    this.pdfSource = await pdfBytes.buffer;
  }

  private async sendContract() {
    const pdfBytes = await this.pdfDoc.save();
    const blobObj = new Blob([pdfBytes], {type: "application/pdf"})
    if (this.authService.isLoggedIn && this.authService?.userInfo !== null && this.campaign?.alias && this.submissionRequest?.id !== undefined) {
      this.campaignService.uploadContract({
        id: this.submissionRequest?.id, file: blobObj
      }).subscribe(
        () => {
          this.sendContractResult();
        }
      )
    }
  }

  private getPaymentPeriod(): string {
    return this.submissionRequest.fullUpfrontPayment ? ' 100% upfront payment' : '50% upon order Placement \n' +
      '50% upon Delivery (net 10 days)';
  }

  private async flatten(param: ArrayBuffer | null) {
    if (param) {
      const copyPdfDoc = await PDFDocument.load(param);
      copyPdfDoc.getForm().flatten();
      let byteArray = await copyPdfDoc.save();
      this.pdfSource = byteArray.buffer;
    }
  }

  private downloadSignedContract() {
    this.campaignsService.getContract({id: this.submissionRequest.id || 0}).subscribe(
      async data => {
        this.pdfSource = await data.arrayBuffer();
      }
    )
  }
}
