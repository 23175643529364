<div class="under-top-toolbar container   is-flex is-flex-direction-column">
  <div class="page-title">{{ companyName ? companyName + ' Campaigns' : 'Campaigns' }}</div>
  <div class="tabs">
    <mat-tab-group animationDuration="0ms" *ngIf="showTabs">
      <mat-tab label="Active">
        <cs-campaign-list-item [campaign]="campaign" *ngFor="let campaign of activeCampaigns" [showActions]="showActions" class="mb-5" (campaignEventEmitter)="updateCampaignList($event)"></cs-campaign-list-item>
      </mat-tab>
      <mat-tab label="Completed">
        <cs-campaign-list-item [campaign]="campaign" *ngFor="let campaign of completedCampaigns" [showActions]="showActions" class="mb-5" (campaignEventEmitter)="updateCampaignList($event)"></cs-campaign-list-item>
      </mat-tab>
      <mat-tab label="Drafts">
        <cs-campaign-list-item [campaign]="campaign" *ngFor="let campaign of draftCampaigns" [showActions]="showActions" class="mb-5" (campaignEventEmitter)="updateCampaignList($event)"></cs-campaign-list-item>
      </mat-tab>
      <mat-tab label="Hidden" *ngIf="isAdmin">
        <cs-campaign-list-item [campaign]="campaign" *ngFor="let campaign of hiddenCampaigns" [showActions]="showActions" class="mb-5" (campaignEventEmitter)="updateCampaignList($event)"></cs-campaign-list-item>
      </mat-tab>
    </mat-tab-group>
    <div *ngIf="!showTabs" class="mt-5 p-0">
        <cs-campaign-list-item [campaign]="campaign" *ngFor="let campaign of activeCampaigns | orderBySequenceNumberAsc" [showActions]="showActions" class="mb-5" (campaignEventEmitter)="updateCampaignList($event)"></cs-campaign-list-item>
    </div>
  </div>
</div>
