import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'cs-info-modal-window',
  templateUrl: './info-modal-window.component.html',
  styleUrls: ['./info-modal-window.component.scss']
})
export class InfoModalWindowComponent implements OnInit {

  title: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    success: boolean,
    message: string,
    subject?: string,
    hideStatus?: boolean}) { }

  ngOnInit(): void {
    if (!this.data.hideStatus) {
      if (!!this.data.subject) {
        this.title = this.data.subject + ' ';
      }
      if (this.data.success) {
        this.title = this.title.concat('Successful!');
      } else {
        this.title = this.title.concat('Error!');
      }
    } else {
      this.title = this.data.subject || '';
    }
  }
}
