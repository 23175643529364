import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { StepBuyerInfoComponent } from './components/step-buyer-info/step-buyer-info.component';
import { StepOrderInfoComponent } from './components/step-order-info/step-order-info.component';
import { StepConfirmationComponent } from './components/step-confirmation/step-confirmation.component';
import { StepContractComponent } from './components/step-contract/step-contract.component';
import { StepPaymentComponent } from './components/step-payment/step-payment.component';
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { StepRegistrationConfirmationComponent } from './components/step-registration-confirmation/step-registration-confirmation.component';
import {NgxStripeModule} from "ngx-stripe";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {NgxMaskModule} from "ngx-mask";
import {MatSelectModule} from "@angular/material/select";
import {SharedModule} from "../shared/shared.module";


@NgModule({
  declarations: [
    PurchaseComponent,
    StepBuyerInfoComponent,
    StepOrderInfoComponent,
    StepConfirmationComponent,
    StepContractComponent,
    StepPaymentComponent,
    StepRegistrationConfirmationComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxStripeModule,
        MatAutocompleteModule,
        NgxMaskModule,
        MatSelectModule,
        SharedModule
    ]
})
export class PurchaseModule { }
