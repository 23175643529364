<div class="modal" [class.is-active]="isModalActive">
  <div class="modal-background" (click)="closeWindow()"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered has-text-weight-bold">
        Sign Up Confirmation
      </p>
      <span class="icon cs-icon-button" aria-label="close" (click)="closeWindow()">
        <i class="fa fa-lg fa-times"></i>
      </span>
    </header>
    <section class="modal-card-body pt-4" id="registration-confirmation-modal-card">

      <div class="field mb-4">
        <p class="motto">We care about the safety of our users</p>
        <p class="info">For next steps we need to register you as user in our site</p>
      </div>

      <div class="field mb-6">
        <p class="question">Please, confirm registration</p>
      </div>

      <div class="field buttons is-flex is-justify-content-space-between mt-4 py-2">
        <a class="button wide is-unchecked-success has-text-weight-bold"
           (click)="closeWindow()"
        >
          No
        </a>
        <a class="button wide is-success has-text-weight-bold"
           (click)="submit()"
        >
          Confirm
        </a>
      </div>
    </section>
  </div>
</div>

