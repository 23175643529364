import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  CampaignsApiService, PaymentApiService, PaymentIntentResponse,
  SubmissionState,
  User,
  UserCampaignSubmission
} from "../../api/cs";
import {PaymentResultStatus, PaymentService} from "../payment.service";
import {AuthService} from "../../shared/services/auth.service";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'cs-submission-order-modal',
  templateUrl: './submission-order-modal.component.html',
  styleUrls: ['./submission-order-modal.component.scss']
})
export class SubmissionOrderModal implements OnInit {
  paymentAmount: number;
  estimatedCost: number;
  receiptEmail: string;
  paying = false;
  isOrderPage: boolean = false;
  user: User;
  headerText: string = 'Order Payment';
  @Output() paid = new EventEmitter<boolean>();
  paymentMethod = new BehaviorSubject<string | null>(null);
  paymentIntentId = new BehaviorSubject<string | null>(null);
  discountCode: string;
  isDiscountCodeApplied: boolean;
  discountAmount: number;
  paymentWithDiscountAmount: number;

  constructor(@Inject(MAT_DIALOG_DATA) public submissionRequest: UserCampaignSubmission,
              private campaignsApiService: CampaignsApiService,
              private router: Router,
              private paymentApiService: PaymentApiService,
              private processPaymentService: PaymentService,
              public dialogRef: MatDialogRef<SubmissionOrderModal>,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.discountCode = this.submissionRequest.discountCode || '';
    this.discountAmount = this.submissionRequest.absoluteDiscountValue || 0;
    this.isDiscountCodeApplied = !!this.submissionRequest.discountCode;
    let submissionCost: number = this.submissionRequest.discountedOrderAmount || this.submissionRequest?.orderAmount;
    if (this.submissionRequest.fullUpfrontPayment) {
      this.paymentAmount = submissionCost;
    } else {
      this.paymentAmount = (submissionCost / 2);
    }
    if (this.submissionRequest.submissionState == SubmissionState.CONTRACT_SIGNED) {
      this.estimatedCost = this.paymentAmount + (this.submissionRequest?.shippingCost || 0);
    } else {
      this.estimatedCost = this.paymentAmount;
    }
    if (this.router.url == "/profile/orders") {
      this.isOrderPage = true;
      this.headerText = this.submissionRequest.campaignEntity?.title?.concat(" - ").concat(this.headerText) || this.headerText;
    }
  }


  private confirmSubmissionPayment(): void {
    if (this.submissionRequest?.id !== undefined) {
      this.campaignsApiService.confirmSubmissionPayment({
        id: this.submissionRequest?.id,
        confirmSubmissionPaymentRequest: {
          paymentMethod: this.paymentMethod.getValue() || undefined
        }
      }).subscribe(
        () => {
          this.processPaymentService.paymentResult.next(PaymentResultStatus.NOT_PROCESS);
          const matdialog = this.processPaymentService.showDialog(true, 'Your shipping request has been sent.');
          matdialog.afterClosed().subscribe(() => {
            this.dialogRef.close();
            this.processPaymentService.endPaymentProcess();
            this.paid.emit(true);
          });
        },
        (error: { message: string; }) => {
          this.processPaymentService.showDialog(false, error.message);
          this.processPaymentService.endPaymentProcess();
        }
      );
    }
  }

  pay() {
    if (this.authService.isLoggedIn && this.authService?.userInfo !== null) {
      this.authService.userInfo?.subscribe(user => {
        if (user !== null) {
          this.paying = true;
          this.receiptEmail = user.email || '';
          this.makePayment();
        }
      })
    } else {
      console.log("User needs to be logged in");
    }
  }

  isPaymentDisabled() {
    return !this.submissionRequest ||
      this.paying;
  }

  private makePayment() {
    this.processPaymentService.paymentResult.next(PaymentResultStatus.START);
    this.processPaymentService.paymentResult.subscribe(
      result => {
        if (result === PaymentResultStatus.SUCCESS) {
          this.confirmSubmissionPayment();
        }
      }
    )
  }

  checkDiscountCode(): void {
    this.paymentApiService.applyPaymentDiscount({
      discountRequest: {
        discountCode: this.discountCode,
        campaignId: Number.parseInt(this.submissionRequest?.campaignEntity?.id || '0'),
        submissionId: this.submissionRequest.id,
        intentId: this.paymentIntentId.getValue() || undefined,
      }
    }).subscribe(
      (response: PaymentIntentResponse) => {
        this.isDiscountCodeApplied = true;
        this.paymentWithDiscountAmount = response.paymentAmount;
        this.discountAmount = ((this.submissionRequest.orderAmount || 0) + (this.submissionRequest.shippingCost || 0))
          - response.paymentAmount;
        this.estimatedCost -= this.discountAmount;
        this.setTotalCost();
      });
  }

  private setTotalCost(): void {
    this.estimatedCost = (this.submissionRequest?.orderAmount || 0) + (this.submissionRequest.shippingCost || 0) - (this.discountAmount || 0);
  }

}
