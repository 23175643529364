/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentInfo } from './payment-info';
import { User } from './user';
import { SampleRequestOrderedEntity } from './sample-request-ordered-entity';


export interface SampleRequest { 
    id?: string;
    orderedEntity?: SampleRequestOrderedEntity;
    deliveryAddress?: string;
    user?: User;
    paymentDate?: string;
    paymentAmount?: number;
    paymentInfo?: PaymentInfo;
}

