import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.isAdmin()) {
      return true;
    }

    if (this.authService.isLoggedIn) {
      if (this.authService.isSupplier()) {
        this.router.navigate(['companies', 'own']);
      }
      if (this.authService.isBuyer()) {
        this.router.navigate(['products']);
      }
      return false;
    }

    this.router.navigate(['/']);
    return false;
  }

}
