import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CommonModule} from '@angular/common';
import {HomeModule} from './home/home.module';
import {CoreModule} from './core/core.module';
import {API_PROVIDERS} from './api-providers';
import {HttpClientModule} from '@angular/common/http';
import {ProductsModule} from './products/products.module';
import {CompaniesModule} from './companies/companies.module';
import {SignupsModule} from './signups/signups.module';
import {DictionariesModule} from './dictionaries/dictionaries.module';
import {UsersModule} from './users/users.module';
import {AttributesSettingsModule} from './attributes-settings/attributes-settings.module';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {CampaignModule} from "./campaign/campaign.module";
import {QuillModule} from 'ngx-quill';
import {PurchaseModule} from './purchase/purchase.module';
import {ReactiveFormsModule} from "@angular/forms";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {NgxMaskModule} from "ngx-mask";
import {BreadcrumbModule} from "xng-breadcrumb";
import {DashboardModule} from "./dashboard/dashboard.module";
import {SupplyChainBuilderModule} from "./supply-chain-builder/supply-chain-builder.module";
import {MarkdownModule} from "ngx-markdown";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HomeModule,
    CoreModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    ProductsModule,
    CompaniesModule,
    SignupsModule,
    DictionariesModule,
    AttributesSettingsModule,
    UsersModule,
    CampaignModule,
    PurchaseModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    DashboardModule,
    NgxMaskModule.forRoot(),
    QuillModule.forRoot({
      theme: "snow",
      placeholder: "Enter your text here",
      modules: {
        toolbar: [['bold', 'italic', 'underline', 'strike'],
          ['code-block'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          ['link'],
          ['clean']]
      }
    }),
    MatSnackBarModule,
    BreadcrumbModule,
    SupplyChainBuilderModule,
    MarkdownModule.forRoot()
  ],
  providers: [
    ...API_PROVIDERS
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
