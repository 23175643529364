<div class="under-top-toolbar container">

  <div class="page-title">Sign-ups</div>

  <cs-mat-table *ngIf="tableData.length > 0"
                [inputList]="tableData">
  </cs-mat-table>

  <div *ngIf="signups && !signups.length" class="mt-5">
    There are no new sign-ups
  </div>
</div>
