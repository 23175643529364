import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {InfoModalWindowComponent} from "../core/components/info-modal-window/info-modal-window.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  paymentResult: Subject<PaymentResultStatus> = new BehaviorSubject<PaymentResultStatus>(PaymentResultStatus.NOT_PROCESS);

  constructor(private dialog: MatDialog) { }

  showDialog(success: boolean, message: string, close?: boolean) {
    return this.dialog.open(InfoModalWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {success: success, message: message, subject: 'Payment'}
    })
  }

  endPaymentProcess(): void {
    this.paymentResult = new BehaviorSubject<PaymentResultStatus>(PaymentResultStatus.NOT_PROCESS);
  }
}

export enum PaymentResultStatus {
  SUCCESS, FAIL, NOT_PROCESS, PROCESSING,START
}
