/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum SubmissionState {
    ORDER_INFO_PROVIDED = 'ORDER_INFO_PROVIDED',
    USER_INFO_PROVIDED = 'USER_INFO_PROVIDED',
    CONTRACT_SIGNED = 'CONTRACT_SIGNED',
    PAYMENT_SUBMITTED = 'PAYMENT_SUBMITTED',
    PAYMENT_MADE = 'PAYMENT_MADE',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    SHIPPING_AWAITING = 'SHIPPING_AWAITING',
    SHIPPED = 'SHIPPED',
    DELIVERED = 'DELIVERED',
    COMPLETE = 'COMPLETE'
};

