import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../shared/services/auth.service";
import {AuthenticationApiService, Campaign, CreateSubmissionRequest, UnitScalingEntity} from "../../../api/cs";
import {GoogleMapsService} from "../../../shared/services/google-maps.service";
import {BehaviorSubject} from "rxjs";
import {ValidatorsService} from "../../../core/util/validators.service";
import {InfoModalWindowComponent} from "../../../core/components/info-modal-window/info-modal-window.component";
import {MatDialog} from "@angular/material/dialog";
import {UnitsScalingService} from "../../../shared/services/units-scaling.service";

@Component({
  selector: 'cs-step-buyer-info',
  templateUrl: './step-buyer-info.component.html',
  styleUrls: ['./step-buyer-info.component.scss', './../purchase.common.scss']
})
export class StepBuyerInfoComponent implements OnInit {

  @Input() nextStep: string;
  @Input() stepPassed: boolean;
  @Output() buyer = new EventEmitter<boolean>();
  @Output() prev = new EventEmitter<boolean>();

  @Input() campaign: Campaign;
  @Input() submissionRequest$: BehaviorSubject<CreateSubmissionRequest>;
  submissionRequest: CreateSubmissionRequest;

  businessTypes = ['Company', 'Individual', 'Non-profit'];
  predictions: string[] = [];
  private UUID: string;
  isLoggedIn: boolean;
  orderMeasure: UnitScalingEntity;

  form: FormGroup;

  requiredFields = ['firstName', 'lastName', 'email', 'phone', 'code', 'businessEntityName',
    'registeredBusinessAddress', 'employerIdentificationNumber',
    'businessPhone', 'website', 'contactName', 'contactTitle', 'contactEmail'];

  intentToRegister: boolean = false;
  intentToSubscribe: boolean = false;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private googleMapsService: GoogleMapsService,
              private unitsScalingService: UnitsScalingService,
              private authenticationApiService: AuthenticationApiService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.submissionRequest$.subscribe(request => {
      this.submissionRequest = request;
      this.orderMeasure = this.unitsScalingService.getScalingEntityById(this.submissionRequest.orderMeasure || 0);
    });
    this.isLoggedIn = this.authService.isLoggedIn;
    this.authService.userInfo.subscribe(() => {
      this.form = this.fb.group({
        companyName: new FormControl(this.submissionRequest.buyer?.company?.name || this.submissionRequest.creator?.company?.name, Validators.required),
        firstName: new FormControl(this.submissionRequest.buyer?.firstName || this.submissionRequest.creator?.firstName, Validators.required),
        lastName: new FormControl(this.submissionRequest.buyer?.lastName || this.submissionRequest.creator?.lastName, Validators.required),
        email: new FormControl(this.submissionRequest.buyer?.email || this.submissionRequest.creator?.email, [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,63}$')]),
        phone: new FormControl(this.submissionRequest.buyer?.phone || this.submissionRequest.creator?.phone, Validators.required),
        code: new FormControl(this.submissionRequest.buyer?.buyerCode || this.submissionRequest.creator?.buyerCode)
      });
      if (!this.submissionRequest.fullUpfrontPayment) {
        this.form.addControl('businessType',
          new FormControl(this.submissionRequest.creator?.kybInfo?.businessType || 'Company'));
        this.form.addControl('address',
          new FormControl(this.submissionRequest.creator?.kybInfo?.registeredBusinessAddress, Validators.required));
        this.form.addControl('employerIdentificationNumber',
          new FormControl(this.submissionRequest.creator?.kybInfo?.employerIdentificationNumber,
            [Validators.required, Validators.pattern('^\\d{1,}\\-?\\d{1,}$')]));
        this.form.addControl('website',
          new FormControl(this.submissionRequest.creator?.kybInfo?.website));
        this.form.addControl('contactName',
          new FormControl(this.submissionRequest.creator?.kybInfo?.contactName));
        this.form.addControl('contactTitle',
          new FormControl(this.submissionRequest.creator?.kybInfo?.contactTitle));
        this.form.addControl('contactEmail',
          new FormControl(this.submissionRequest.creator?.kybInfo?.contactEmail, [Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,63}$')]));
        this.form.addControl('businessPhone',
          new FormControl(this.submissionRequest.creator?.kybInfo?.businessPhone));
      }
      if (!this.submissionRequest.fullUpfrontPayment) {
        this.addDefaultValueToKyb();
      }
    })
    this.getUUID();
    this.googleMapsService.predicted.subscribe((predictions) => this.predictions = predictions);
  }

  private addDefaultValueToKyb() {
    if (!this.submissionRequest.buyer.kybInfo) {
      this.submissionRequest.buyer.kybInfo = {
        businessType: 'Company',
        businessPhone: '',
        registeredBusinessAddress: '',
        employerIdentificationNumber: '',
        contactName: '',
        contactTitle: '',
        contactEmail: '',
        website: ''
      }
    }
  }

  next(form: FormGroup): void {
    if (form.valid) {
      if (!this.isLoggedIn) {
        console.log('No user found')
        this.authenticationApiService.signUp({
          signUpRequest: {
            company: {
              name: this.form.value.companyName
            },
            user: {
              firstName: this.form.value.firstName,
              lastName: this.form.value.lastName,
              email: this.form.value.email,
              phone: this.form.value.phone,
              sendEmail: true,
              subscriptionAgreement: this.intentToSubscribe
            }
          }
        }).subscribe((res) => {
          const dialog = this.showInfoModalWindow(true, 'Account created, you are logged in, a temporary password has been sent to your email.')
          dialog.afterClosed().subscribe(() => {
            this.authService.updateTokens(res);
            this.authService.updateUserInfo();
            this.authService.userInfo.subscribe(user => {
              if (user?.id !== undefined) {
                this.submissionRequest.buyer.id = user.id
              }
              this.submissionRequest.creator = this.submissionRequest.buyer;
              this.submitStep(form);
              this.buyer.emit(true);
            })

          })
        });
      } else {
        this.submitStep(form);
        this.buyer.emit(true);
      }
    } else {
      for (let control in this.form.controls) {
        this.form.controls[control].markAsTouched();
      }
    }
  }

  private showInfoModalWindow(success: boolean, message: string) {
    return this.dialog.open(InfoModalWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        success: success,
        message: message,
        subject: ''
      }
    });
  }

  previousStep(): void {
    this.submitStep(this.form);
    this.prev.emit(true);
  }

  predict(address: string): void {
    if (this.form.get('address')?.dirty) {
      if (address) {
        this.googleMapsService.predict(this.UUID, address);
      }
    }
  }

  insert(prediction: string): void {
    if (this.form.get('address')?.value != prediction) {
      this.form.get('address')?.setValue(prediction);
    } else {
      this.predictions = [];
    }
  }

  /**
   * For make working with Google Maps API cheaper all request from one user need to have same id.
   */
  getUUID(): void {
    this.authService.userInfo?.subscribe(user => this.UUID = user?.id || '');
  }

  onlyEnglish($event: KeyboardEvent) {
    ValidatorsService.onlyEnglish($event);
  }

  onlyNumbers($event: KeyboardEvent) {
    ValidatorsService.onlyNumbers($event);
  }

  private submitStep(form: FormGroup): void {
    if (!this.submissionRequest.fullUpfrontPayment) {
      if (this.submissionRequest.buyer?.kybInfo !== undefined) {
        this.submissionRequest.buyer!.kybInfo!.registeredBusinessAddress = form.value.address;
      }
      if (!this.submissionRequest.buyer.kybInfo?.businessType) {
        this.submissionRequest.buyer.kybInfo!.businessType = 'Company';
      }
    }
    this.submissionRequest$.next(this.submissionRequest);
  }
}
