import {Component, OnInit} from '@angular/core';
import {DictionariesService} from '../../../shared/services/dictionaries.service';
import {DictionariesApiService, DictionaryAggregation, UnitScalingEntity} from '../../../api/cs';
import {LoaderService} from '../../../shared/services/loader.service';
import {finalize} from 'rxjs';
import {UnitsScalingService} from "../../../shared/services/units-scaling.service";
import {MatDialog} from "@angular/material/dialog";
import {
  DictionaryMeasureItemFormComponent
} from "../dictionary-measure-item-form/dictionary-measure-item-form.component";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'cs-dictionaries-management',
  templateUrl: './dictionaries-management.component.html',
  styleUrls: ['./dictionaries-management.component.scss']
})
export class DictionariesManagementComponent implements OnInit {
  dictionaries: DictionaryAggregation[];
  selectedTab: 'Common' | 'Company' | 'Product' = 'Common';
  tabs = ['Common', 'Company', 'Product'];
  subTabMap = new Map<string, string[]>();
  selectedSubTabIndex: number = 0;
  measures: UnitScalingEntity[];

  constructor(private dictionariesService: DictionariesService,
              private loader: LoaderService,
              private dialog: MatDialog,
              private unitsScalingService: UnitsScalingService,
              private dictionariesApiService: DictionariesApiService,
              private titleService: Title) {
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.titleService.setTitle("Dictionaries | Cultured Supply");
    this.loader.show();
    this.unitsScalingService.list$.subscribe(measures => this.measures = measures);
    this.dictionariesApiService.getDictionaries({withoutItems: false})
      .pipe(finalize(() => this.loader.hide()))
      .subscribe((res) => {
        res = DictionariesService.orderDictionaries(this.tabs, res);
        DictionariesService.sortItems(res);
        this.subTabMap = DictionariesService.resolveSubTabs(this.tabs, res);
        this.subTabMap.get("Common")?.push("Campaign Measure Units");
        this.dictionaries = res;
      });
  }

  getFilteredData(): DictionaryAggregation | undefined {
    return this.dictionaries?.filter((dict) => {
      return dict.group === this.selectedTab.toUpperCase() && this.subTabMap.get(this.selectedTab)?.indexOf(dict.title || '') == this.selectedSubTabIndex;
    })?.pop();
  }

  showMeasureForm(mode: 'EDIT' | 'CREATE', measure?: UnitScalingEntity) {
    this.dialog.open(DictionaryMeasureItemFormComponent, {panelClass: 'custom-dialog-container', data: {mode: mode, item: measure}})
      .afterClosed().subscribe(() => this.load());
  }

  getTabName(fullName: string): string {
    return fullName.split(" ").slice(1).join(" ");
  }
}
