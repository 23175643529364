import {Component, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {User, UsersApiService} from '../../../api/cs';
import {finalize} from 'rxjs';
import {LoaderService} from '../../../shared/services/loader.service';
import {MatDialog} from "@angular/material/dialog";
import {AdminCreateComponent} from "../admin-create/admin-create.component";
import {Title} from "@angular/platform-browser";
import {MatSort} from "@angular/material/sort";
import {formatDate} from "@angular/common";
import {MatTableValue} from "../../../shared/components/mat-table/mat-table.component";

/**
 * Entity that will be sent to mat-table-component
 * Each record is a row
 * Each field is a name of table column
 * Each MatTableValue.value - is a cell value
 * Each MatTableValue.link - link to which user will redirect if he clicks on value
 */
class UserTableRecord {

  email: MatTableValue;
  name: MatTableValue;
  companyName?: MatTableValue;
  title?: MatTableValue;
  createdAt: MatTableValue;
  admin: MatTableValue;
  subscriptionAgreement: MatTableValue;


  constructor(email: MatTableValue, name: MatTableValue, company_name: MatTableValue, title: MatTableValue, createdAt: MatTableValue, admin: MatTableValue, subscriptionAgreement: MatTableValue) {
    this.email = email;
    this.name = name;
    this.companyName = company_name;
    this.title = title;
    this.createdAt = createdAt;
    this.admin = admin;
    this.subscriptionAgreement = subscriptionAgreement;
  }
}

@Component({
  selector: 'cs-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  users: User[];
  companies = new Map<string, string>();

  tableData: UserTableRecord[] = [];

  @ViewChild('userTableSort') userTableSort = new MatSort();

  constructor(private usersApiService: UsersApiService,
              private loader: LoaderService,
              private dialog: MatDialog,
              @Inject(LOCALE_ID) private locale: string,
              private titleService: Title) { }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.titleService.setTitle("Users | Cultured Supply");
    this.loader.show();
    this.usersApiService.getUserSearchResult()
      .pipe(finalize(() => this.loader.hide()))
      .subscribe((res) => {
        this.users = res.items || [];
        let userRecords = this.users.map(user => new UserTableRecord(
          new MatTableValue(user.email, '/administration/users/' + user.id),
          new MatTableValue(user.firstName + " " + user.lastName),
          new MatTableValue(user.company?.name, '/companies/' + user.company?.alias),
          new MatTableValue(user.title),
          new MatTableValue(formatDate(Number.parseFloat(user.createdAt || '') * 1000, "MM-dd-YYYY", this.locale) || ''),
          new MatTableValue(user.admin ? 'Yes' : 'No'),
          new MatTableValue(user.subscriptionAgreement ? 'Yes' : 'No')
        ));
        this.tableData.push(...userRecords);
        for (let user of this.users) {
          if (!!user.company?.name && !!user.company.id) {
            this.companies.set(user.company.name, user.company.id);
          }
        }
      });
  }

  createNewUser() {
    this.dialog.open(AdminCreateComponent, {panelClass: 'custom-dialog-container', data: {companies: this.companies}})
      .afterClosed().subscribe(() => this.load());
  }
}
