import {HttpResponse} from '@angular/common/http';

const downloadFile = (href: string, fileName: string): void => {
    const element = document.createElement('a');
    element.href = href;
    element.setAttribute('download', fileName);
    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

const fileUtils = {
    downloadFile: (file: Blob, fileName: string): void => {
        downloadFile(window.URL.createObjectURL(file), fileName);
    },
    downloadTextAsFile: (text: string, fileName: string): void => {
        const file = fileUtils.makeFileFromText(text, fileName);

        fileUtils.downloadFile(file, fileName);
    },
    downloadFileFromBase64String: (content: string, fileName: string): void => {
        const href = `data:application/octet-stream;base64,${content}`;

        downloadFile(href, fileName);
    },
    getFileNameFromHttpResponse: (httpResponse: HttpResponse<any>): string | null => {
        const contentDisposition = httpResponse.headers.get('Content-Disposition');
        if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const rawFileName = fileNameRegex.exec(contentDisposition)?.[1];
            if (rawFileName != null) {
                return rawFileName.replace(/['"]/g, '');
            }
        }

        return null;
    },
    getFileExtension(fileName: string): string | null {
        if (fileName == null || fileName.length === 0) {
            return '';
        }

        return fileName.split('.').pop()?.toLowerCase() ?? '';
    },
    makeFileFromText: (text: string, fileName: string): File => new File([text], fileName),
    hasAllowedExtension(file: File, ...allowedExtensions: string[]): boolean {
        return !allowedExtensions?.length || allowedExtensions.some((extension) => file.name.toLowerCase().endsWith('.' + extension.toLowerCase()));
    },
    getFileNameFromPath: (filePath: string): string => {
        const parts = filePath.split('/');
        return parts[parts.length - 1];
    }
};

export default fileUtils;
