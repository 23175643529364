import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Campaign} from "../../../api/cs";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";

@Component({
  selector: 'cs-campaigns-carousel',
  templateUrl: './campaigns-carousel.component.html',
  styleUrls: ['./campaigns-carousel.component.scss']
})
export class CampaignsCarouselComponent implements OnInit {

  slideConfig: any;

  @Input() campaigns: Campaign[];
  @Input() editable: boolean = false;
  @Input() slideCount: number;
  @Output() editCampaignEmitter = new EventEmitter<Campaign>();

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.setSlideConfig();
  }

  setSlideConfig(): void {
    this.slideConfig = {
      slidesToShow: this.slideCount,
      infinite: false,
      outerEdgeLimit: true,
      slidesToScroll: 1,
      dots: false,
      responsive: [
        {
          breakpoint: 790,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
      nextArrow: '<div class=\'next-slide is-flex is-align-items-center is-justify-content-center\'><i class="fa fa-angle-left arrow"></i></div>',
      prevArrow: '<div class=\'prev-slide is-flex is-align-items-center is-justify-content-center\'><i class="fa fa-angle-right arrow"></i></div>',};
  }

  getCampaignsDaysLeft(campaign: Campaign): string {
    let daysLeft = Math.round((Date.parse(campaign.deliveryDate || '') - Date.now()) / (24 * 60 * 60 * 1000));
    if (daysLeft > 0) {
      return daysLeft + " DAYS LEFT";
    } else {
      return "CAMPAIGN FINISHED";
    }
  }

  getImagePreview(campaign: Campaign) {
    const imageReference = (campaign?.images && campaign.images.length > 0) ? (campaign?.images[0].reference || '') : '';
    return imageReference !== '' ? `https://${environment.s3BucketName}.${environment.s3DefaultApi}/images/${imageReference}` : '';
  }

  editCampaign(campaign: Campaign) {
    this.editCampaignEmitter.emit(campaign);
  }

  routeToCampaign(campaign: Campaign) {
    this.router.navigate([`/companies/${campaign.companyAlias}/campaigns/${campaign.alias}`]).then();
  }
}
