<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">{{headerText}}</p>
    <span class="icon cs-icon-button" aria-label="close" mat-dialog-close>
        <i class="fa fa-lg fa-times"></i>
      </span>
  </header>
  <section class="modal-card-body">
      <div class="block-info__numbers is-flex is-justify-content-space-between is-align-items-center mb-5" *ngIf="isOrderPage">
        <div>
          <div class="info-number-title">QUANTITY</div>
          <div class="info-number">{{submissionRequest?.quantity | number: '1.0-0'}}{{submissionRequest?.orderMeasure?.measureShortPluralName}}</div>
        </div>
        <div *ngIf="submissionRequest.submissionState == 'CONTRACT_SIGNED'">
          <div class="info-number-title">PRICE</div>
          <div class="info-number">{{submissionRequest?.campaignEntity?.unitCurrency}}{{paymentAmount | number: '1.2'}}</div>
        </div>
        <div *ngIf="isDiscountCodeApplied && submissionRequest.submissionState == 'CONTRACT_SIGNED'">
          <div class="info-number-title">DISCOUNT</div>
          <div class="info-number">-{{submissionRequest?.campaignEntity?.unitCurrency}}{{discountAmount | number: '1.2'}}</div>
        </div>
        <div *ngIf="submissionRequest.submissionState == 'CONTRACT_SIGNED'">
          <div class="info-number-title">DELIVERY</div>
          <div class="info-number">{{submissionRequest?.campaignEntity?.unitCurrency}}{{submissionRequest?.shippingCost | number: '1.2'}}</div>
        </div>
        <div>
          <div class="info-number-title" *ngIf="submissionRequest.submissionState == 'CONTRACT_SIGNED'">TOTAL</div>
          <div class="info-number-title" *ngIf="submissionRequest.submissionState != 'CONTRACT_SIGNED'">REMAINS TO PAY</div>
          <div class="info-number">{{submissionRequest?.campaignEntity?.unitCurrency}}{{estimatedCost | number: '1.2'}}</div>
        </div>
      </div>
      <form class="discount-code-section" *ngIf="submissionRequest.submissionState == 'CONTRACT_SIGNED'">
        <label>Have a discount or referral code?</label>
        <div class="is-flex">
          <input class="input" type="text" placeholder="Enter it here" [(ngModel)]="discountCode" [ngModelOptions]="{standalone: true}" [disabled]="isDiscountCodeApplied"/>
          <button class="button is-success discount-confirm-button" (click)="checkDiscountCode()" [disabled]="isDiscountCodeApplied">Apply</button>
        </div>
      </form>
      <cs-payment [paymentAmount]="estimatedCost" [receiptEmail]="receiptEmail" (paymentMethod)="paymentMethod.next($event)" (paymentIntentId)="paymentIntentId.next($event)" *ngIf="estimatedCost"></cs-payment>
      <div class="field is-grouped is-grouped-centered">
        <div class="control py-4">
          <button class="button wide is-success has-text-weight-bold is-fullwidth" [disabled]="isPaymentDisabled()" (click)="pay()">Pay</button>
        </div>
      </div>
  </section>
  <footer class="modal-card-foot is-flex is-justify-content-center pt-4">
  </footer>
</div>
