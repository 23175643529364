<div class="under-top-toolbar container  is-flex is-flex-direction-column">
  <div id="top-bar">
    <div id="bread-crumbs">
      <xng-breadcrumb [separator]="'>'"></xng-breadcrumb>
    </div>
    <div id="actions-line">
      <i *ngIf="isAdmin"
         class="fa fa-pencil pl-6 cs-icon-button is-size-5"
         (click)="editCampaign(campaign)"
      ></i>
    </div>
  </div>
  <div class="is-align-items-center is-justify-content-center bar is-flex-direction-column">
    <h1 class="campaign-title has-text-success has-text-weight-semibold hero-title">{{campaign?.title}}</h1>
    <h3 class="has-text-weight-semibold">{{campaign?.subtitle}}</h3>
  </div>
  <div class="is-flex video-and-info-container">
    <section class="block-video is-flex is-flex-direction-column is-justify-content-space-between">
      <ngx-slick-carousel
        #slickModal="slick-carousel"
        [config]="slideConfig" class="slick-container">
        <div ngxSlickItem *ngFor="let media of campaignMedia | orderBySequenceNumberAsc">
          <iframe class="block-video__video" [style.width.%]="85" [src]="media.link" loading="lazy"
                  *ngIf="media.isVideo"
                  title="YouTube video player" border="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>
          </iframe>
          <div class="block-video__logo is-flex is-align-items-center is-justify-content-center">
                    <img [src]="media.link"
                         *ngIf="!media.isVideo">
            <div id="player"></div>
          </div>
        </div>
      </ngx-slick-carousel>

      <div class="block-video__info is-flex is-justify-content-space-between">
        <div class="block-video__categories">
          <div class="info-tag has-text-weight-semibold"
               *ngIf="showIndustryFocuses && getDictionaryValuesText(campaign?.industryFocuses)?.length">
            <span class="has-text-success is-size-6">Industry Focus: </span>
            <span class="is-size-6">{{getDictionaryValuesText(campaign?.industryFocuses)}}</span>
          </div>
          <div class="info-tag has-text-weight-semibold"
               *ngIf="showCategory && campaign?.productCategory?.value">
            <span class="has-text-success is-size-6">Product Category: </span>
            <span class="is-size-6">{{campaign?.productCategory?.value}}</span>
          </div>
          <div class="info-tag has-text-weight-semibold"
               *ngIf="showCategory && campaign?.originCountry">
            <span class="has-text-success is-size-6">Made in: </span>
            <span class="is-size-6">{{campaign?.originCountry}}</span>
          </div>
        </div>
      </div>
    </section>
    <section class="block-info">
      <div class="block-info__title-mobile is-flex-direction-column mt-3">
        <h2 class="has-text-weight-bold">{{campaign?.title}}</h2>
        <h3 class="has-text-weight-semibold mt-1 mb-3">{{campaign?.subtitle}}</h3>
      </div>
      <div class="block-info__descr">
          <quill-view id="description" [content]="transformText(campaign?.shortDescription)"></quill-view>
          <a class="show-more-button" *ngIf="hasLongDescription" (click)="showFullDescription()">See&nbsp;more ></a>
      </div>
      <div class="block-info__numbers is-flex is-justify-content-space-between mt-4">
        <div>
          <div class="info-number-title">PRICE</div>
          <div class="info-number">{{campaign?.unitCurrency}}{{campaign?.unitPrice | number: '1.2'}}
            /{{campaign?.unitMeasure?.measureShortSingularName}}</div>
        </div>
        <div>
          <div class="info-number-title">QUANTITY</div>
          <div class="info-number">{{campaign?.unitCount | number: '1.0-0'}} {{campaign?.unitMeasure?.measureShortPluralName}}</div>
        </div>
        <div>
          <div class="info-number-title">DELIVERY TIME</div>
          <div class="info-number">~{{campaign?.deliveryTime | number: '1.0-0'}} days</div>
        </div>
      </div>
      <hr/>
      <cs-campaign-stats class="mr-6" *ngIf="campaign" [campaign]="campaign" [showBuyers]="false"></cs-campaign-stats>

      <div class="is-flex">
        <button class="button is-success has-text-weight-bold is-large is-flex-grow-1" (click)="makePurchase()">
          Purchase
        </button>
      </div>
      <div class="block-video__sample-and-socials is-flex is-justify-content-space-between">
        <div>
          <button *ngIf="showBuySample" class="block-video__sample button is-success has-text-weight-bold is-small is-light" (click)="buySample()">
            Buy Sample
          </button>
        </div>
        <div class="block-video__socials is-flex is-align-items-center">
          <div id="fb-root"></div>
          <script async defer crossorigin="anonymous"
                  src="https://connect.facebook.net/uk_UA/sdk.js#xfbml=1&version=v15.0" nonce="05RPMXNV"></script>
          <div class="share-button fb-share-button"
               data-href="https://developers.facebook.com/docs/plugins/"
               data-layout="button"
               data-size="small">
            <a target="_blank"
               href="https://www.facebook.com/sharer/sharer.php?dialog=popup&u={{getLink()}}"
               class="fb-xfbml-parse-ignore"><i class="fa fa-facebook"></i></a>
          </div>
          <a class="share-button twitter-share-button" target="_blank"
             href="https://twitter.com/intent/tweet?url={{getLink()}}">
            <i class="fa fa-twitter"></i>
          </a>
          <a class="share-button" href="mailto:?subject=Check this out: {{campaign?.title}}
          &body=I thought you would be interested in this product campaign from Cultured Supply - {{currentUrl}}">
            <i class="fa fa-envelope"></i>
          </a>
          <a (click)="copyLink()">
            <i class="fa fa-link"></i>
          </a>
        </div>
      </div>
    </section>
  </div>
</div>
<hr/>
<div class="container is-flex block-more-info">
  <section class="block-tabs is-flex is-flex-direction-column">
    <div class="tabs">
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="Campaign">
          <ng-template matTabContent>
            <div class="is-flex is-justify-content-space-between is-flex-direction-column">
              <quill-view [content]="campaign?.description"></quill-view>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Spec sheet">
          <ng-template matTabContent>
            <div>
              <quill-view [content]="campaign?.specification"></quill-view>
              <div *ngIf="campaign?.specSheetFileRef" class="is-flex is-justify-content-space-between">
                <button class="button is-success has-text-weight-bold"
                        (click)="downloadFile(campaign?.specSheetFileRef, 'Specification_' + campaign.title)"
                >
                  View/Download File
                </button>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="FAQ">
          <ng-template matTabContent>
            <quill-view [content]="campaign?.faq"></quill-view>
          </ng-template>
        </mat-tab>
        <mat-tab label="How It's Made">
          <ng-template matTabContent>
            <quill-view [content]="campaign?.howItsMade"></quill-view>
          </ng-template>
        </mat-tab>
        <mat-tab label="Meet The Team">
          <ng-template matTabContent>
            <quill-view [content]="campaign?.meetTheTeam"></quill-view>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="more-info is-flex">
      <div class="more-info__looking">
        <i class="fa fa-question-circle mr-4"></i>
        <div class="mr-5 has-text-weight-bold">Looking for more information?</div>
      </div>
      <button class="button button-primary is-success more-info__faq has-text-weight-bold" (click)="showLiveChat()">Chat with Us ></button>
    </div>
  </section>
  <section class="block-supplier">
    <h3 class="mb-2">
      Full Product Listing
    </h3>
    <div class="cs-card is-flex p-3">
      <img src="https://{{s3BucketName}}.{{s3DefaultApi}}/images/{{campaign?.productImage}}" *ngIf="campaign?.productImage"/>
      <div class="ml-3">
        <p class="is-size-6 has-text-weight-bold pb-1">{{campaign?.productName}}</p>
        <a class="button button-primary is-success is-size-7 has-text-weight-semibold" href="/companies/{{campaign?.companyAlias}}/products/{{campaign?.productAlias}}" target="_blank">Learn more ></a>
      </div>
    </div>
  </section>
</div>

