import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../shared/services/auth.service";

@Component({
  selector: 'cs-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  menuShown = false;
  whiteBg = false;
  isLoggedIn: boolean = false;
  currentYear = new Date().getFullYear();

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.userInfo.subscribe(() => this.isLoggedIn = this.authService.isLoggedIn);
  }

  toggleMenu(): void {
    this.menuShown = !this.menuShown;
  }

}
