import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";
import {
  CompanyProfile,
  User, UsersApiService
} from "../../api/cs";
import {environment} from "../../../environments/environment";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'cs-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isAdmin: boolean;
  baseUrl: string;
  user: User | null;
  company: CompanyProfile | null;
  s3DefaultApi = environment.s3DefaultApi;
  s3BucketName = environment.s3BucketName;

  constructor(private authService: AuthService,
              private titleService: Title,
              private usersApiService: UsersApiService) { }

  ngOnInit(): void {
    this.titleService.setTitle("Dashboard | Cultured Supply");
    this.baseUrl = environment.baseUrl;
    this.isAdmin = this.authService.isAdmin();
    this.authService.userInfo.subscribe(user => {
      this.user = user;
    });
    this.usersApiService.getUser({
      id: this.user?.id || ''
    }).subscribe(user => {
        this.user = user;
        this.authService.userInfo.next(user);
    })
  }
}
