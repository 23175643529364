<div class="navigation-container">
  <div class="container ">
    <div class="columns is-desktop footer-header">
      <div class="column phone-section mb-4">
        <h5>Text or call the <span class="green-text">Drip Line</span> for all of your biobased needs.</h5>
        <div class="footer-phone-contact">
          <a class="phone-number" href="tel:(313) 241-7544">(313) 241-7544</a>
          <a href="https://wa.me/13132417544" target="_blank">
            <img src="/assets/images/new-home/whatsapp-black.png" alt="whatsapp">
          </a>
        </div>
      </div>
      <div class="column is-half">
        <div class="columns is-desktop footer-nav">
          <ul>
            <li class="list-title">Company</li>
            <li><a href="about-us">About us</a></li>
            <li><a href="manifesto">Manifesto</a></li>
            <li><a href="contact-us">Contact</a></li>
          </ul>
          <ul>
            <li class="list-title">Services</li>
            <li><a href="sign-up-buyer">Buyers</a></li>
            <li><a href="sign-up-supplier">Suppliers</a></li>
            <li *ngIf="false"><a href="campaigns">Campaigns</a></li>
          </ul>
          <ul>
            <li class="list-title">Resources</li>
            <li><a href="https://s3.eu-west-3.amazonaws.com/shared.files.culturedsupply.com/TermsOfUse.pdf" target="_blank">Terms of use</a></li>
            <li><a href="https://s3.eu-west-3.amazonaws.com/shared.files.culturedsupply.com/PrivacyPolicy.pdf" target="_blank">Privacy Policy</a></li>
            <li *ngIf="false"><a href="faq">FAQ</a></li>
          </ul>
        </div>
      </div>
      <div class="social-links is-justify-content-center">
        <a href="https://www.instagram.com/culturedsupply1/" class="link-wrapper" target="_blank">
          <img src="/assets/images/new-home/instagram-only-symbol.png" alt="instagram">
        </a>
        <a href="https://www.linkedin.com/company/cultured-supply/" class="link-wrapper" target="_blank">
          <img src="/assets/images/new-home/linked-in.png" alt="linked-in">
        </a>
        <a href="https://twitter.com/Cultured_Supply" class="link-wrapper" target="_blank">
          <img src="/assets/images/new-home/twitter.png" alt="twitter">
        </a>
        <a href="https://www.facebook.com/profile.php?id=100087181841390" class="link-wrapper" target="_blank">
          <img src="/assets/images/new-home/facebook.png" alt="facebook">
        </a>
      </div>
    </div>
    <hr>
    <div class="columns is-desktop footer-copyright">
      <div class="column">
        <a class="navbar-item py-0 small-text" routerLink="/">
          © {{currentYear}} Cultured Supply. All rights reserved.
        </a>
        <a class="broscorp-link navbar-item py-0 small-text mt-3" href="https://broscorp.net" target="_blank">
          <div class="is-flex is-align-items-center">
            <img class="broscorp-logo" src="/assets/images/broscorp-logo.png" alt="logo"/>
            <label>Developed by Broscorp</label>
          </div>
        </a>
      </div>
      <div class="column mobile-social-links ">
        <a href="https://www.instagram.com/culturedsupply1/" class="link-wrapper" target="_blank">
          <img src="/assets/images/new-home/instagram-only-symbol.png" alt="instagram">
        </a>
        <a href="https://www.linkedin.com/company/cultured-supply/" class="link-wrapper" target="_blank">
          <img src="/assets/images/new-home/linked-in.png" alt="linked-in">
        </a>
        <a href="https://twitter.com/Cultured_Supply" class="link-wrapper" target="_blank">
          <img src="/assets/images/new-home/twitter.png" alt="twitter">
        </a>
        <a href="https://www.facebook.com/profile.php?id=100087181841390" class="link-wrapper" target="_blank">
          <img src="/assets/images/new-home/facebook.png" alt="facebook">
        </a>
      </div>
    </div>
  </div>
</div>
