import {AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {LoaderService} from '../../../shared/services/loader.service';
import {Router} from "@angular/router";
import {AuthService} from "../../../shared/services/auth.service";
import {isPlatformBrowser} from "@angular/common";
import {Meta} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {HubSpotLiveChatService} from "../../../shared/services/hubspot-live-chat.service";

@Component({
  selector: 'cs-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {

  isBrowser: boolean = false;
  bannerText: string = '';

  constructor(private loader: LoaderService,
              private router: Router,
              private authService: AuthService,
              private metaService: Meta,
              private httpClient: HttpClient,
              private hubSpotLiveChatService: HubSpotLiveChatService,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  ngOnInit(): void {
    this.checkPlatform();
    this.router.events.subscribe(event => {
      this.checkPlatform();
      this.updateMetaTags();
    });
    this.authService.userInfo.subscribe(user => {
      this.checkPlatform();
    });
    this.httpClient.get('assets/banner.content.txt', { responseType: 'text' })
      .subscribe(data => {
        this.bannerText = data;
      });
  }

  private checkPlatform() {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  get showLoader(): boolean {
    return this.loader.get();
  }

  ngAfterViewInit() {
    this.hubSpotLiveChatService.loadLiveChat();
  }

  ngOnDestroy(): void {
    this.hubSpotLiveChatService.deleteLiveChat();
  }

  private updateMetaTags() {
    // Create MetaService to handle Meta in all routes
    if (!this.router.url.includes('campaigns/')) {
      this.metaService.updateTag({property: 'og:url', content: `${window.location.origin}`});
      this.metaService.updateTag({property: 'robots', content: 'index,follow'});
      this.metaService.updateTag({property: 'og:title', content: `B2B Group Purchasing for the Bioeconomy`});
      this.metaService.updateTag({
        property: 'og:description',
        content: `Bio-based chemicals, ingredients, materials, and products`
      });
      this.metaService.updateTag({property: 'og:site_name', content: `Cultured Supply`});
      this.metaService.updateTag({
        property: 'og:image',
        content: 'https://culturedsupply.com/assets/images/real-logo-min-icon.jpg'
      })
      this.metaService.updateTag({property: 'og:image:width', content: `1200`});
      this.metaService.updateTag({property: 'og:image:height', content: `630`});
      this.metaService.updateTag({property: 'og:image:alt', content: `Cultured Supply logo`});
    }
  }

  isBannerShowed(): boolean {
    return this.bannerText.length > 0;
  }

  getBannerContent(): string {
    return this.bannerText;
  }
}


