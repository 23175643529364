<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">Product Heading Info Editing</p>
    <span class="icon cs-icon-button" aria-label="close" (click)="confirmClose()">
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <form mat-dialog-content
        id="main-info-modal-card"
        class="modal-card-body pt-6"
        *ngIf="!!form?.controls && (dictionariesService.loaded$ | async)"
        [formGroup]="form"
        (ngSubmit)="submit()">
    <div class="field mb-0" *ngIf="adminViewService.isAdmin">
      <div class="control view-switch is-flex is-flex-direction-row-reverse">
        <input type="checkbox"
               name="adminViewAboutInfo"
               id="adminViewAboutInfo"
               class="switch is-success is-rtl"
               (change)="adminViewService.changeAdminView()"
               [checked]="isAdminView"
        >
        <label class="label" for="adminViewAboutInfo">Admin View</label>
      </div>
    </div>

    <div class="field pb-4">
      <label class="label">Name:*</label>
      <div class="control">
        <input class="input is-medium"
               type="text"
               placeholder="Space X"
               formControlName="name"
        />
        <span class="tag verified-label ml-3"
              *ngIf="(isVerifiedFlagVisible || isAdminView) && isVerified()">
        Verified
      </span>
      </div>
      <span
        *ngIf="!form.get('name')!.valid && form.get('name')!.touched"
        class="help-block">Enter valid product name</span>
    </div>

    <div class="field pb-4" *ngIf="isDescriptionVisible || isAdminView">
      <label class="label">Description:</label>
      <div class="control">
        <quill-editor formControlName="description"
                      placeholder="Some info about the product"></quill-editor>
      </div>
    </div>

    <div class="field pb-4">
      <label class="label">Video Link:</label>
      <div class="control">
        <input class="input is-medium"
               type="text"
               placeholder="youtu.be/xxx"
               formControlName="video"
        />
      </div>
    </div>

    <div class="field pt-4">
      <label class="label">Images:</label>
      <div class="file has-name is-right is-fullwidth">
        <label class="file-label">
          <input class="file-input" type="file" accept="image/*"
                 formControlName="imageRef" multiple="multiple"
                 (change)="onFileChoose($event)">
          <span class="file-cta">
              <span class="file-icon">
                <i class="fa fa-upload"></i>
              </span>
              <span class="file-label">
                Browse
              </span>
            </span>
          <span *ngIf="images.length == 0" class="file-name"></span>
          <span *ngFor="let image of images | orderBySequenceNumberAsc; let i = index" class="file-name is-flex is-align-items-center" [style.z-index]="100" (click)="images.splice(i, 1)">
            <img *ngIf="image" class="image-preview" [src]="getImagePreview(i)">
            <span class="ml-1"><i class="fa fa-remove"></i></span>
          </span>
        </label>
      </div>
      <label class="label mb-1 mt-2 error"
             *ngIf="form.controls['imageRef']?.errors?.hasOwnProperty('too-big') || false">Image size should
        be less than 10MB</label>
      <label class="label mb-1 mt-2 error"
             *ngIf="form.controls['imageRef']?.errors?.hasOwnProperty('big-amount-files') || false">Max amount of images - 6</label>
      <p class="help">
        Up to 6 files can be uploaded.
      </p>
      <p class="help">
        The file should be a <b>PNG</b> or a <b>JPEG</b>.
      </p>
      <p class="help">
        We recommend it to be at least 1024px on the long side. 4:3 ratio fits best.
      </p>
      <p class="help">
        Max 10MB
      </p>
    </div>

    <div class="field pb-4" *ngIf="isVerifiedFlagVisible && isAdminView">
      <div class="control">
        <input type="checkbox"
               name="verified"
               id="verified"
               class="switch is-success is-rtl"
               formControlName="verified"
        >
        <label class="label" for="verified">Verified</label>
      </div>
    </div>

    <div class="field pb-4" formGroupName="platform" *ngIf="!!chassis">
      <label class="label">Product Chassis:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.platform[platform || '']"
             [class.mt-3]="!first"
             *ngFor="let platform of chassis; let first = first"
      >
        <input type="checkbox" [formControlName]="platform || ''">
        {{ platform | toCamelCase }}
      </label>
    </div>

    <div class="field pb-4" *ngIf="isDevelopmentStageVisible || isAdminView">
      <label class="label">Development Stage</label>
      <div class="control">
        <div class="is-flex is-flex-direction-column">
          <label class="radio mx-0 cs-radio px-4"
                 [for]="stage.key"
                 [class.checked]="form.value.developmentStage == stage.key"
                 [class.mt-3]="!first"
                 *ngFor="let stage of developmentStages; let first = first"
          >
            <input type="radio"
                   name="developmentStage"
                   [id]="stage.key"
                   [value]="stage.key"
                   formControlName="developmentStage"
                   [checked]="form.value.developmentStage == stage.key ? 'checked' : ''"
                   [defaultChecked]="form.value.developmentStage == stage.key ? 'checked' : ''"
            >
            {{stage.value}}
          </label>
        </div>
      </div>
    </div>

    <div class="field pt-4" *ngIf="isAdmin && isAdminView">
      <label class="label">Product State:*</label>
      <mat-select class="input is-medium" formControlName="state" placeholder="Select product state"
                  (ngModelChange)="setState($event)">
        <mat-option *ngFor="let state of states" value="{{state}}">{{toCamelCase(state)}}</mat-option>
      </mat-select>
    </div>

    <div class="field is-grouped is-grouped-centered mt-6">
      <div class="control py-4">
        <button type="submit" class="button wide is-success has-text-weight-bold is-fullwidth">
          Save
        </button>
      </div>
    </div>
  </form>
</div>
