import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

/**
 * A service which main purpose is to act as a wrapper for native angular snack-bars which normal people call toasts.
 */
@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public snackBar: MatSnackBar) {
  }

  public success(message: string) {
    this.snackBar.open(message, undefined, {panelClass: "green-snackbar", duration: 3000});
  }

  public error(message: string) {
    this.snackBar.open(message, undefined, {panelClass: "red-snackbar", duration: 3000});
  }

}
