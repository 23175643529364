import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilesApiService, ProductResponse, ProductsApiService, ProductState} from '../../../../../api/cs';
import {PlatformImageService} from "../../../../../shared/services/platform-image.service";
import {ToastService} from "../../../../../shared/services/toast.service";
import {ProductPromotionStatus} from "../../../../../api/cs/model/product-featuring-status";
import fileUtils from "../../../../../shared/utils/file-utils";
import {HubspotFormModalComponent} from "../../../../../shared/components/hubspot-form-modal/hubspot-form-modal.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: '[cs-featured-product-actions]',
  templateUrl: './featured-product-actions.component.html',
  styleUrls: ['./featured-product-actions.component.scss']
})
export class FeaturedProductActionsComponent implements OnInit {
  @Input() product: ProductResponse;
  @Input() hubspotForm: string;
  @Input() isAdmin: boolean;
  @Input() isVerticalAlignment: boolean;

  isProductDrafted: boolean = false;
  isProductArchived: boolean = false;
  isProductActive: boolean = false;
  isProductHidden: boolean = false;

  isProductPromoted: boolean = false;
  isProductDemoted: boolean = false;

  @Output() createdProduct = new EventEmitter<ProductResponse>();
  @Output() updatedProduct = new EventEmitter<ProductResponse>();

  constructor(
    private dialog: MatDialog,
    private toastService: ToastService,
    private filesApiService: FilesApiService,
    private productsApiService: ProductsApiService,
    public platformImageService: PlatformImageService) { }

  ngOnInit(): void {
    this.setProductState();
  }

  private setProductState(): void {
    switch (this.product.headingInfo.state) {
      case ProductState.DRAFT: this.isProductDrafted = true; break;
      case ProductState.ARCHIVED: this.isProductArchived = true; break;
      case ProductState.ACTIVE: this.isProductActive = true; break;
      case ProductState.HIDDEN: this.isProductHidden = true; break;
    }
    switch (this.product.headingInfo.promotionStatus) {
      case ProductPromotionStatus.FEATURED: this.isProductPromoted = true; break;
      case ProductPromotionStatus.BASIC: this.isProductDemoted = true; break;
    }
  }

  promoteProduct(): void {
    this.product.headingInfo.promotionStatus = ProductPromotionStatus.FEATURED;
    this.updateProductPromotionStatus();
  }

  demoteProduct(): void {
    this.product.headingInfo.promotionStatus = ProductPromotionStatus.BASIC;
    this.updateProductPromotionStatus();
  }

  openRequestSampleModal(): void {
    this.dialog.open(HubspotFormModalComponent, {
      panelClass: 'custom-dialog-container',
      data: JSON.parse(this.hubspotForm)
    });
  }

  loadSpecSheet(): void {
    this.filesApiService.getFileContent({
      reference: this.product.specSheet!, isImage: false
    }).subscribe((res) => {
      const name = this.product.headingInfo.name + "_Spec_Sheet"
      fileUtils.downloadFile(res, name);
    });
  }

  private updateProductVisibilityStatus(): void {
    this.productsApiService.updateProductHeading({
      id: this.product.id,
      productHeadingInfo: {
        name: this.product.headingInfo.name,
        description: this.product.headingInfo.description,
        chassis: this.product.headingInfo.chassis,
        developmentStage: this.product.headingInfo.developmentStage,
        verified: this.product.isCompanyVerified,
        state: this.product.headingInfo.state,
        promotionStatus: this.product.headingInfo.promotionStatus
      }
    }).subscribe((product: ProductResponse) => {
      this.updatedProduct.emit(product);
      this.toastService.success("Product state changed to " + product.headingInfo.state?.toLowerCase());
    });
  }

  private updateProductPromotionStatus(): void {
    this.productsApiService.updateProductHeading({
      id: this.product.id,
      productHeadingInfo: {
        name: this.product.headingInfo.name,
        description: this.product.headingInfo.description,
        chassis: this.product.headingInfo.chassis,
        developmentStage: this.product.headingInfo.developmentStage,
        verified: this.product.isCompanyVerified,
        state: this.product.headingInfo.state,
        promotionStatus: this.product.headingInfo.promotionStatus
      }
    }).subscribe((product: ProductResponse) => {
      this.updatedProduct.emit(product);
      this.toastService.success("Product promotion status changed to " + product.headingInfo.promotionStatus?.toLowerCase());
    });
  }
}
