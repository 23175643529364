import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CompanyVisibilityStatusValue, CompanyVerificationStatusValue} from "../../../../api/cs";

@Component({
  selector: 'cs-edit-company-status-form',
  templateUrl: './edit-company-status-form.component.html',
  styleUrls: ['./edit-company-status-form.component.scss']
})
export class EditCompanyStatusFormComponent implements OnInit {

  isModalActive: boolean;
  statusDescription: string;
  statusType: "visibility" | "verification";

  @Output()
  changeAccessStatus = new EventEmitter<boolean>();
  @Output()
  changeVerificationStatus = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  showWindow(statusDescription: string,
             status: CompanyVisibilityStatusValue | undefined): void;

  showWindow(statusDescription: string,
             status: CompanyVerificationStatusValue | undefined): void;

  showWindow(statusDescription: string, status: any): void {
    for (let companyVisibilityStatusValueKey in CompanyVisibilityStatusValue) {
      if (status == companyVisibilityStatusValueKey) {
        this.initWindow('visibility', statusDescription);
        return;
      }
    }
    for (let companyVerificationStatusValueKey in CompanyVerificationStatusValue) {
      if (status == companyVerificationStatusValueKey) {
        this.initWindow('verification', statusDescription);
        return;
      }
    }
    this.closeWindow();
  }

  private initWindow(statusType: string, statusDescription: string) {
    this.statusType = statusType == 'visibility' ? 'visibility' : 'verification';
    this.statusDescription = statusDescription.toLowerCase();
    this.isModalActive = true;
  }

  closeWindow(): void {
    this.isModalActive = false;
  }

  submit(): void {
    if (this.statusType == 'visibility') {
      this.changeAccessStatus.emit(true);
    } else if (this.statusType == 'verification') {
      this.changeVerificationStatus.emit(true);
    }
    this.closeWindow();
  }
}
