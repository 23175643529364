import {Component, OnInit} from "@angular/core";
import {marked} from "marked";
import {Title} from "@angular/platform-browser";

@Component({
  selector: "cs-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"]
})
export class FaqComponent implements OnInit {

  QUESTIONS: { question: string, answer: string }[] = [
    {
      question: "What is Cultured Supply?",
      answer: "Cultured Supply is the first B2B platform dedicated to the bioeconomy. Through group-purchasing, we provide buyers exclusive access to discover and purchase biobased items at an affordable cost."
    },
    {
      question: "Why does Cultured Supply exist?",
      answer: "To help create functioning markets for new classes of biobased chemicals, ingredients, materials, and finished products. We only work with suppliers that are changing the way we make things (biomanufacturing, upcycling, etc.) and aim to facilitate, “direct to the source” procurement where businesses can purchase inputs for their supply chains without layers of middlemen and brokers. You can read more about our mission to change our systems of production here - [Cultured Supply Manifesto](manifesto)."
    },
    {
      question: "What is group purchasing?",
      answer: "Group purchasing is a process where individual businesses come together and combine their buying power to purchase goods. This allows smaller buyers to secure better pricing and services from suppliers so they can successfully compete with larger companies that operate on a national or international basis."
    },
    {
      question: "What is a buyer pool?",
      answer: "A buyer pool is a group of businesses organized by industry and/or the types of products they are interested in purchasing. Examples include: food ingredients, materials for the fashion industry, and chemicals for personal care products."
    },
    {
      question: "Is there an upfront cost or obligation attached to being part of a buyer pool?",
      answer: "There is no upfront cost or obligation to purchase anything."
    },
    {
      question: "What is a campaign?",
      answer: "A campaign is when a supplier is offering a certain quantity of product to buyers through the Cultured Supply platform. Buyers typically save 10%-35% when purchasing through Cultured Supply as we have negotiated volume discounts with the suppliers."
    },
    {
      question: "Does a campaign have to reach a minimum quantity for a buyer to receive their order?",
      answer: "Not at all. Cultured Supply has negotiated pricing with the supplier so regardless of the overall quantity ordered during a campaign, an individual buyer can order the quantity they need and  receive the product."
    },
    {
      question: "How long does it take for a buyer to receive their order?",
      answer: "Each campaign and supplier is different. Some orders can be delivered within 2 weeks, while others can take up to 90 days. Most products will take about 30 days to be delivered."
    },
    {
      question: "Can I order more than what is available in a campaign?",
      answer: "<p>Yes, of course. Each campaign page has a contact button where buyers can request additional quantities and quotes.</p>" +
        "<p>Buyers can also contact Cultured Supply directly via email at <a href='mailto:orders@culturedsupply.com'>orders@culturedsupply.com</a> with a specific buying request or contact us via text or phone/Whatsapp at <a href='tel:+13132417544'>+1 (313) 241-7544</a>.</p>"
    },
    {
      question: "Can I make a recurring or standing order?",
      answer: "Not yet, but we will be launching a subscription option soon. Buyers who are interested in standing orders can select this option on the checkout page of a campaign and we will follow up directly."
    },
    {
      question: "Can I receive a sample before ordering?",
      answer: "Yes, of course. Buyers can order a sample through our 1-click process on the campaign page. All samples carry a nominal cost. Shipping is included in the price."
    },
    {
      question: "How do contracts and payments work?",
      answer: "<p>When ordering through Cultured Supply, a standard purchase order is automatically generated based on the pricing and delivery timeline provided in the campaign. This purchase order is signed by the buyer and is a legally binding contract between the buyer and supplier.</p>" +
        "<p>All payments are handled through the Cultured Supply platform and can be completed through ACH or Credit Card.</p>"
    },
    {
      question: "What are payment terms?",
      answer: "<p>Cultured Supply currently offers two payment options across all campaigns:</p>" +
        "<ul><li>100% upfront payment on all orders below $2,500.</li>" +
        "<li>On orders above $2,500; 50% upfront and remaining 50% within 30 days from product being delivered. Payment terms are subject to buyer verification and basic credit check.</li></ul>" +
        "<p>On recurring and larger orders, alternative payment terms may be arranged with Cultured Supply.</p>"
    },
    {
      question: "How do payments work?",
      answer: "All payments on Cultured Supply are handled online through ACH or credit card."
    },
    {
      question: "What are the supplier benefits from working with Cultured Supply?",
      answer: "Supplier benefits include:\n" +
        "<ul> <li>Gaining new customers without the hassle.</li>" +
        "<li>Building an online presence with well-organized product listings.</li>" +
        "<li>Saving valuable time by streamlining sample requests, order fulfillment, contract management, and online payments.</li>" +
        "<li>Receiving purchase order financing upfront in the matter of days, not weeks, to help manage cash flow.</li></ul>"
    },
    {
      question: "What are the buyer benefits from working with Cultured Supply?",
      answer: "Buyer benefits include:\n" +
        "<ul> <li>A one-stop shop for discovering and procuring biobased items.</li>" +
        "<li>Substantial cost savings ranging from 10% to 35%.</li>" +
        "<li>No upfront cost or obligation.</li>" +
        "<li>Streamlining all sourcing, contract management and payment functions through our online platform.</li></ul>"
    },
    {
      question: "Are there any requirements for selling my products on Cultured Supply?",
      answer: "<p>If you are a supplier using biological feedstocks, not oil, to make products then we want to hear from you! We are primarily focused on working with suppliers that are changing our systems of production through technologies and processes such as biomanufacturing, upcycling, and additive manufacturing.</p>" +
        "<p>Our goal is to have as many 100% biobased products as possible that carry regenerative properties. The reality is we are not there yet and we have a lot of building to do to create truly regenerative supply chains. But, we are focused on progress - we want to get biobased alternatives into our supply chains as quickly as possible so we can bring down the costs, create functioning markets, and transition towards a bioeconomy. Any product sold through Cultured Supply must include an accurate percentage of biobased content.</p>"
    },
    {
      question: "How can I start selling my products on Cultured Supply?",
      answer: "<p>All suppliers interesting in selling their products can fill out an <a href='sign-up-supplier'>intake form</a> and we will get back to you within 1-2 business days.</p>" +
        "<p>If there is a mutual fit, Cultured Supply will work with the supplier to set up and launch one or more product campaigns.</p>"
    },
    {
      question: "How can I sign up for a buyer pool?",
      answer: "Request an early access invitation [here](sign-up-buyer)."
    },
    {
      question: "How does Cultured Supply make money?",
      answer: "Cultured Supply earns a fee only when sales are conducted through the platform."
    },
    {
      question: "What is the bioeconomy?",
      answer: "A new model for industry and the economy. It involves companies using biological resources, instead of petroleum, to sustainably produce food, energy and industrial goods. The bioeconomy has the ability to transform every industry in the world."
    },
    {
      question: "What is a biobased product?",
      answer: "<p>Biobased products are those made from biological feedstocks and processes, rather than petroleum.</p>" +
        "<p>More than 6,000 products we consume on a daily basis contain petroleum. Petroleum is used as feedstock for making the chemicals, plastics, and synthetic materials that are in nearly everything we use.</p>" +
        "<p>The most frequently used biobased feedstocks are sugar, starch, plant oils, wood and natural fibres. The most frequently used proteins or building blocks to make biobased products are: algae, bacterial, fungi, mammalian cells, and plants.</p>"
    },
    {
      question: "What does regenerative mean?",
      answer: "<p>A regenerative approach goes well beyond sustainability to actively reverse accumulated negative effects. Regeneration restores, heals and contributes additional value to ecosystems. It actually expands the potential of a system.</p>" +
        "<p>The last 100 years of making things was based on an extractive model:  TAKE → MAKE → WASTE.</p>" +
        "<p>The next 100 years of making will be based on a regenerative model:  GROW → MAKE → REUSE.</p>"
    },
    {
      question: "Why is this important?",
      answer: "Our current man-made systems of petroleum-based production are major contributors to greenhouse gas emissions. The way we make things account for 31% of total annual CO2 emissions globally. We have to fundamentally change the way we make things to not only help restore planetary and human health, but to strengthen our local economies."
    },
  ];

  expandedIndex = 0;

  constructor(private titleService: Title) {
  }

  ngOnInit(): void {
    this.titleService.setTitle("Faq | Cultured Supply");
  }

  parse(i: number): void {
    document.getElementById(('markdown_' + i).toString())!.innerHTML = marked.parse(this.QUESTIONS[i].answer);
  }
}
