import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
  CertificationsApiService,
  FilesApiService,
  ProductResponse,
  ProductsApiService
} from '../../../../api/cs';
import {LoaderService} from '../../../../shared/services/loader.service';
import {finalize} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  ConfirmationWindowComponent
} from "../../../../core/components/confirmation-window/confirmation-window.component";
import {ToastService} from "../../../../shared/services/toast.service";
import {FileUploadService} from "../../../../shared/services/file-upload.service";

@Component({
  selector: 'cs-product-file-form',
  templateUrl: './product-file-form.component.html',
  styleUrls: ['./product-file-form.component.scss']
})
export class ProductFileFormComponent implements OnInit {

  form: FormGroup;
  fileType: 'PSS' | 'SDS' | 'LCA';

  private product: ProductResponse;

  @Output()
  updated = new EventEmitter<string>();
  private file?: File;

  constructor(private fb: FormBuilder,
              private loader: LoaderService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ProductFileFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {product: ProductResponse, fileType: 'PSS' | 'SDS' | 'LCA'},
              private productsApiService: ProductsApiService,
              private fileUploadService: FileUploadService) {
  }

  ngOnInit(): void {
    this.file = undefined;
    this.fileType = this.data.fileType;
    this.product = this.data.product;
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
    delete this.file;
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: new FormControl(null),
      authority: new FormControl(null),
      reference: new FormControl(null)
    });
  }

  confirmClose(): void {
    let confirmation = this.dialog.open(ConfirmationWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        header: "Window Close Confirmation",
        description: "discard changes and close the window"}
    });
    confirmation.beforeClosed().subscribe(() => {
      if (confirmation.componentInstance.confirmed.getValue()) {
        this.dialogRef.close();
      }
    })
  }

  get filename(): string {
    return this.file?.name ?? '';
  }

  submit(): void {
    if (!!this.file) {
      this.loader.show();
      this.handleFiles().then(async reference => {
        let request;
        switch (this.fileType) {
          case 'PSS': {
            request = this.productsApiService.updateProductPss({
              id: this.product.id || '',
              fileUpdateRequest: {
                reference
              }
            });
            break;
          }
          case 'SDS': {
            request = this.productsApiService.updateProductSds({
              id: this.product.id || '',
              fileUpdateRequest: {
                reference
              }
            });
            break;
          }

          case 'LCA': {
            request = this.productsApiService.updateProductLca({
              id: this.product.id || '',
              fileUpdateRequest: {
                reference
              }
            });
            break;
          }
        }

        request
          .pipe(finalize(() => this.loader.setVisible(false)))
          .subscribe(() => {
            this.updated.emit(reference);
            this.dialogRef.close();
          });
      });
    }
  }

  get title(): string {
    if (this.fileType === 'PSS') return 'Update Product Spec Sheet';
    if (this.fileType === 'SDS') return 'Update Safety Data Sheet';
    return 'Update Lifecycle Analysis'
  }

  onFileChoose(event: Event): void {
    this.file = this.fileUploadService.processFileUpload(event);
  }

  async handleFiles(): Promise<string> {
    return this.fileUploadService.loadFile(false);
  }
}
