import {Component, OnInit} from '@angular/core';
import {CompaniesApiService, ProductResponse, ProductsApiService, ProductState, User} from '../../../api/cs';
import {BehaviorSubject, finalize, map} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {LoaderService} from '../../../shared/services/loader.service';
import {AttributesService} from '../../../shared/services/attributes.service';
import {PlatformImageService} from "../../../shared/services/platform-image.service";
import {CookieService} from "ngx-cookie-service";
import {AuthService} from "../../../shared/services/auth.service";
import {Title} from "@angular/platform-browser";
import {BreadcrumbService} from "xng-breadcrumb";

@Component({
  selector: 'cs-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {

  isAdmin: boolean;
  isSupplyChainBuilderUser: boolean;
  user: User | null;
  showTabs: boolean = false;
  tabsState: ("ACTIVE" | "ARCHIVED" | "DRAFT" | "HIDDEN")[] = ["ACTIVE", "ARCHIVED", "DRAFT", "HIDDEN"];
  selectedTabIndex: number = 0;

  companyAlias: string | null;
  products$: BehaviorSubject<Array<ProductResponse> | undefined>;
  visibleProducts$: BehaviorSubject<Array<ProductResponse> | undefined>;
  expandedCards = new Map<string, boolean>();
  searchingText: string;
  listType: string;
  isLongText: boolean = false;
  s3DefaultApi = environment.s3DefaultApi;
  s3BucketName = environment.s3BucketName;

  showDescription: boolean;
  showType: boolean;
  showCategory: boolean;
  showRecommendation: boolean;
  showDevelopmentStage: boolean;

  constructor(
    private productsApiService: ProductsApiService,
    private companiesApiService: CompaniesApiService,
    private loader: LoaderService,
    private breadcrumbService: BreadcrumbService,
    private titleService: Title,
    private authService: AuthService,
    private attributesService: AttributesService,
    private activatedRoute: ActivatedRoute,
    public platformImageService: PlatformImageService,
    private cookieService: CookieService) { }

  ngOnInit(): void {
    this.titleService.setTitle("Products | Cultured Supply");
    this.isAdmin = this.authService.isAdmin();
    this.isSupplyChainBuilderUser = this.isSupplyChainUser();
    this.user = this.authService.userInfo.getValue();
    this.attributesService.productAttrsLoaded$.subscribe();
    this.resolveAttributesVisibility();
    this.listType = this.cookieService.get('listType') || "grid";
    this.companyAlias = this.activatedRoute.snapshot.paramMap.get('company-alias');
    this.setTabsVisibility();
    this.loader.setVisible(true);
    this.productsApiService.getProductSearchResult({
        ...this.companyAlias ? {companyAlias: this.companyAlias} : {}
    }).pipe(
      map(result => result.items),
      finalize(() => this.loader.setVisible(false))
    ).subscribe(products => {
      this.products$ = new BehaviorSubject<Array<ProductResponse> | undefined>(products);
      this.visibleProducts$ = new BehaviorSubject<Array<ProductResponse> | undefined>(products);
      this.setVisibleProducts(0);
      if (!!this.companyAlias) {
        this.breadcrumbService.set('/companies/:company-alias', !!products ? products[0]?.companyName : 'Company');
      }
    });
  }

  private setTabsVisibility(): void {
    this.showTabs = this.isAdmin || this.authService.userInfo.getValue()?.company?.alias == this.companyAlias;
  }

  private isSupplyChainUser(): boolean {
    return this.authService.userInfo.getValue()?.role == "SUPPLY_CHAIN_USER";
  }

  setVisibleProducts(tabNumber: number): void {
    this.selectedTabIndex = tabNumber;
    let selector = this.tabsState[tabNumber];
    let visibleProducts: ProductResponse[] = [];
    this.products$.getValue()?.forEach(product => {
      if (product.headingInfo.state == ProductState[selector]) {
        visibleProducts.push(product);
      }
    });
    this.visibleProducts$.next(visibleProducts);
  }

  showSearchResult(): void {
    let text = this.searchingText;
    setTimeout(() => {
      if (this.searchingText == text) {
        this.searchProducts();
      }
    }, 1000);
  }

  searchProducts(): void {
    this.productsApiService.getProductSearchResult({
      nameMatcher: this.searchingText
    }).pipe(
      map(result => result.items),
      finalize(() => this.loader.setVisible(false))
    ).subscribe(products => {
      this.visibleProducts$.next(products);
    })
  }

  get baseUrl(): string {
    return environment.baseUrl;
  }

  setDescription(product: ProductResponse): string {
    let maxSymbolsCountInRow = window.innerWidth * 0.5 / 7;
    let maxSymbolsCount = maxSymbolsCountInRow * 6;

    return this.clipText(product.headingInfo?.description || '', maxSymbolsCountInRow, maxSymbolsCount);
  }

  setRecommendation(product: ProductResponse): string {
    let maxSymbolsCountInRow = window.innerWidth * 0.2 / 7;
    let maxSymbolsCount = maxSymbolsCountInRow * 2;

    return this.clipText(product.generalInfo?.recommendation || '', maxSymbolsCountInRow, maxSymbolsCount);
  }

  setListTypeToGrid(): void {
    this.listType = "grid";
    this.cookieService.set('listType', 'grid');
  }

  setListTypeToBars(): void {
    this.listType = "bars";
    this.cookieService.set('listType', 'bars');
  }

  private clipText(fullText: string, maxSymbolsCountInRow: number, maxSymbolsCount: number): string {
    let clippedText = '';
    let currentSymbolsCount = 0;
    let strings = fullText.split('\n') || [];
    for (let string of strings) {
      clippedText = clippedText.concat(string);
      currentSymbolsCount += string.length;
      currentSymbolsCount += maxSymbolsCountInRow - string.length % maxSymbolsCountInRow;
      let words = string.split(' ');
      let row = '';
      for (let word of words) {
        if (row.concat(word).length < maxSymbolsCountInRow) {
          row = row.concat(word);
        } else {
          currentSymbolsCount += maxSymbolsCountInRow - row.length;
          row = '';
        }
      }

      if (currentSymbolsCount > maxSymbolsCount) {
        clippedText = clippedText.substring(0, maxSymbolsCount);
        let maxRegexIndex = Math.max(
          clippedText.lastIndexOf(' '),
          clippedText.lastIndexOf(','),
          clippedText.lastIndexOf('.')
        );
        clippedText = clippedText.substring(0, maxRegexIndex - 1).concat('...');
        this.isLongText = true;
        break;
      } else {
        this.isLongText = false;
      }
    }

    if (clippedText.charAt(clippedText.length - 1) == '\n') {
      clippedText = clippedText.substring(0, clippedText.lastIndexOf('\n'));
    }

    return clippedText;
  }

  updateProduct(updatedProduct: ProductResponse): void {
    this.products$.next(
      this.products$.getValue()?.map(existingProduct => {
        if (existingProduct.id == updatedProduct.id) {
          return updatedProduct;
        } else {
          return existingProduct;
        }
      })
    );
    this.setVisibleProducts(this.selectedTabIndex);
  }

  addProduct(newProduct: ProductResponse): void {
    let existingProducts = this.products$.getValue();
    existingProducts?.push(newProduct);
    this.products$.next(existingProducts);
    this.setVisibleProducts(this.selectedTabIndex);
  }

  private resolveAttributesVisibility(): void {
    this.setDescriptionVisibility();
    this.setTypeVisibility();
    this.setCategoryVisibility();
    this.setRecommendationVisibility();
    this.setDevelopmentStageVisibility();
  }

  private setDescriptionVisibility(): void {
    this.showDescription = this.attributesService.isShownProductAttribute('description');
  }

  private setTypeVisibility(): void {
    this.showType = this.attributesService.isShownProductAttribute('type');
  }

  private setCategoryVisibility(): void {
    this.showCategory = this.attributesService.isShownProductAttribute('category');
  }

  private setRecommendationVisibility(): void {
    this.showRecommendation = this.attributesService.isShownProductAttribute('recommendation');
  }

  private setDevelopmentStageVisibility(): void {
    this.showDevelopmentStage = this.attributesService.isShownProductAttribute('developmentStage');
  }
}
