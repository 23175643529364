import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, catchError, of, switchMap, EMPTY} from 'rxjs';
import {LoaderService} from '../../shared/services/loader.service';
import {AuthService} from "../../shared/services/auth.service";
import {AUTH_HEADER} from "./auth.interceptor";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastService} from "../../shared/services/toast.service";


@Injectable()
export class SubmitInterceptor implements HttpInterceptor {

  constructor(private loader: LoaderService,
              private toastService: ToastService,
              private authService: AuthService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError(err => {
          console.log(JSON.stringify(err));
          /**
           * If response from server is 401 and it was not “sign in” then
           * we are going to renew the token and try to execute initial request.
           */
          if (err instanceof HttpErrorResponse && !request.url.includes("signin") && err.status === 401) {
            return this.handleAuthenticationError(request, next);
          } else if (+err?.status == 404) {
            this.router.navigate(["/not-found"], {relativeTo: this.activatedRoute});
            return of(err);
          } else {
            this.loader.setVisible(false);
            if (+err.status == 417 || +err.status == 419) {  //For now, we can correctly catch only errors with 417 and 419 code
              throw err; //So if we see them, we throw them further to component that sent request
            } else {
              this.toastService.error(err?.error);
              return of(err);
            }
          }
        })
      )
  }

  private handleAuthenticationError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes("logout") || !request.url.includes("refresh")) {
      this.authService.makingLogout();
      return EMPTY;
    } else {
      return this.authService.renew().pipe(
        switchMap((response => {
            return next.handle(request.clone({
              headers: request.headers.set(AUTH_HEADER, `Bearer ${response}`)
            }));
          }
        )));
    }
  }
}
