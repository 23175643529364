import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DictionariesManagementComponent} from './components/dictionaries-management/dictionaries-management.component';
import {DictionaryItemFormComponent} from './components/dictionary-item-form/dictionary-item-form.component';
import {SharedModule} from '../shared/shared.module';
import {DictionaryMeasureItemFormComponent} from './components/dictionary-measure-item-form/dictionary-measure-item-form.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";


@NgModule({
  declarations: [
    DictionariesManagementComponent,
    DictionaryItemFormComponent,
    DictionaryMeasureItemFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatAutocompleteModule
  ]
})
export class DictionariesModule { }
