<div class="campaign-progress">
  <div class="progress-bar" *ngIf="campaignProgress">
    <div class="is-flex is-justify-content-space-between">
      <div>{{campaignProgress?.currentAmount || 0  | number: '1.0-0'}} {{campaign?.unitMeasure?.measureShortPluralName}} sold ({{campaignProgress?.currentProgress || 0}}%)</div>
      <div *ngIf="campaignProgress.leftProgress > 0">{{campaignProgress?.leftProgress | number: '1.0-0'}} {{campaign?.unitMeasure?.measureShortPluralName}} left</div>
      <div *ngIf="campaignProgress.leftProgress <= 0" class="success">Campaign successful!</div>
    </div>
    <div class="bar-container">
      <div class="bar-container__percents" [style.width.%]="campaignProgressBarWidth"></div>
    </div>
  </div>
  <div class="stats is-flex is-justify-content-space-between mt-5 is-align-items-center" *ngIf="campaignProgress">
    <div *ngIf="showBuyers">
      <div class="stats-number">{{campaignProgress?.buyersCount || 0  | number: '1.0-0'}}</div>
      <div>buyers</div>
    </div>
    <div *ngIf="campaignProgress.deliveryTime > 0">
      <div class="stats-number">{{campaignProgress?.deliveryTime | number: '1.0-0'}}</div>
      <div class="stats-last">days left to purchase</div>
    </div>
    <div *ngIf="campaignProgress.deliveryTime <= 0 && (campaign.state === state.ACTIVE || campaign.state === state.ARCHIVED)" class="success finish">Campaign finished!</div>

    <div class="info-tag has-text-weight-semibold"
         *ngIf="showState">
      <span class="has-text-success">State: </span>
      <span>{{campaignState}}</span>
    </div>
  </div>
</div>
