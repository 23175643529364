import {AuthService} from "./auth.service";
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

/**
 * Service that change page view for admins.
 * When Admin View is active, all private fields and hidden attributes are visible
 */
@Injectable({
  providedIn: 'root'
})
export class AdminViewService {

  adminView: Subject<boolean>;
  isAdmin: boolean;
  private value: boolean;

  constructor(private authService: AuthService) {
    this.isAdmin = this.authService.isAdmin();
    this.value = (localStorage.getItem('adminView') == 'true' || false) && this.isAdmin;
    this.adminView = new Subject<boolean>();
    this.adminView.next(this.value);
  }

  get isAdminView(): boolean {
    return this.value;
  }

  changeAdminView(): void {
    if (this.isAdmin) {
      this.value = !this.value;
      this.adminView.next(this.value);
      localStorage.setItem("adminView", (this.value) ? 'true' : 'false');
    }
  }
}
