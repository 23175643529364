<div class="form-container">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">Product Creating</p>
    <span class="icon cs-icon-button" aria-label="close" (click)="confirmClose()">
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <cs-product-form *ngIf="form && embeddedFormInitialised"
                   [form]="form"
                   [types]="types"
                   [chassis]="chassis"
                   [categories]="categories"
                   [algaeTypes]="algaeTypes"
                   [customerFocus]="customerFocuses"
                   id="product-form"
                   mat-dialog-content>
  </cs-product-form>

  <div class="field p-1">
    <div class="control">
      <a class="button wide is-medium is-success has-text-weight-bold is-fullwidth"
         (click)="submit()"
      >
        Save
      </a>
    </div>
  </div>
</div>

