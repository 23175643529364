import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LoaderService} from "../../services/loader.service";
import {ConfirmationWindowComponent} from "../../../core/components/confirmation-window/confirmation-window.component";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {FileUploadService} from "../../services/file-upload.service";

@Component({
  selector: 'cs-file-upload-modal',
  templateUrl: './file-upload-modal.html',
  styleUrls: ['./file-upload-modal.scss']
})
export class FileUploadModal implements OnInit {

  url: any;
  file?: File;
  acceptedFormats: string;
  modalDescription: string;
  fileReader: FileReader;
  @Output() reference = new EventEmitter<string>();

  s3DefaultApi = environment.s3DefaultApi;
  s3BucketName = environment.s3BucketName;

  constructor(private loader: LoaderService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<FileUploadModal>,
              @Inject(MAT_DIALOG_DATA) public data: { isImage: boolean, initialImage?: string },
              private fileUploadService: FileUploadService) {}

  ngOnInit(): void {
    this.fileReader = new FileReader();
    this.modalDescription = this.data.isImage ? "Image" : "File";
    this.acceptedFormats = this.data.isImage ? "image/*" : "*";
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
  }

  confirmClose(): void {
    let confirmation = this.dialog.open(ConfirmationWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        header: "Window Close Confirmation",
        description: "discard changes and close the window"}
    });
    confirmation.beforeClosed().subscribe(() => {
      if (confirmation.componentInstance.confirmed.getValue()) {
        this.dialogRef.close();
      }
    })
  }

  get filename(): string {
    return this.file?.name ?? '';
  }

  submit(): void {
    if (!!this.file) {
      this.loader.show();
      this.loadFile(this.data.isImage).then(async reference => this.reference.emit(reference));
    }
  }

  onFileChoose(event: Event): void {
    this.file = this.fileUploadService.processFileUpload(event);
    this.parseFile(this.file);
  }

  private parseFile(file: File | undefined): void {
    this.fileReader.onload = (event: any) => {
      this.url = event.target.result;
    }
    if (!!file) {
      this.fileReader.readAsDataURL(file);
    }
  }

  async loadFile(isImage: boolean): Promise<string> {
    return this.fileUploadService.loadFile(isImage);
  }
}
