<div class="under-top-toolbar container">
  <div id="top-bar" *ngIf="router.url != '/profile'">
    <div id="bread-crumbs">
      <xng-breadcrumb [separator]="'>'"></xng-breadcrumb>
    </div>
    <div id="actions-line">
    </div>
  </div>
  <div *ngIf="user" class="profile-container">
    <div class="page-title" *ngIf="isProfile">My Profile</div>

    <div class="user-header is-flex is-align-items-center mt-5">
      <div class="mr-6">
        <div class="user-title">
          <h2 class="title is-size-3 has-text-weight-semibold">
            {{user.firstName}} {{user.lastName}}
          </h2>
          <p class="underline">
            {{user.email}}
          </p>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="is-flex">
        <div class="pb-4 pr-4 has-text-weight-semibold">
          Phone:
        </div>
        <div>
          {{user.phone || '—'}}
        </div>
      </div>

      <div class="is-flex">
        <div class="pb-4 pr-4 has-text-weight-semibold">
          Company:
        </div>
        <div>
          <div class="is-flex" *ngIf="user.company?.id">
            <a routerLink="/companies/{{user.company?.alias}}" class="has-text-weight-bold" *ngIf="!!user.company?.alias">
              {{user.company?.name || user.company?.alias}}
            </a>
            <i
              class="fa fa-pencil pl-3 cs-icon-button is-size-5"
              (click)="showMainInfoEditWindow()"
              *ngIf="isAdmin"
            ></i>
          </div>
          <span *ngIf="!user?.company?.alias">
             —
          </span>
        </div>
      </div>

      <div class="is-flex">
        <div class="pb-4 pr-4 has-text-weight-semibold">
          Title:
        </div>
        <div>
          {{user.title || '—'}}
        </div>
      </div>

      <div class="is-flex">
        <div class="pb-4 pr-4">
          <span class="has-text-weight-semibold">
            Bio:
          </span>
          {{user.bio || '—'}}
        </div>
      </div>

      <div class="is-flex">
        <div class="pb-4 pr-4 has-text-weight-semibold">
          Registration Date:
        </div>
        <div>
          {{transformDate(user.createdAt)}}
        </div>
      </div>

      <div class="is-flex" *ngIf="isAdmin">
        <div class="pb-4 pr-4 has-text-weight-semibold">
          Newsletter Subscriber:
        </div>
        <div>
          {{user.subscriptionAgreement ? 'Yes' : 'No'}}
        </div>
      </div>

      <div class="is-flex">
        <div class="pb-4 pr-4 has-text-weight-semibold">
          Enabled:
        </div>
        <div>
          {{user.enabled ? 'Yes' : 'No'}}
        </div>
      </div>

      <div class="is-flex">
        <div class="pb-4 pr-4 has-text-weight-semibold">
          Admin:
        </div>
        <div>
          {{user.admin ? 'Yes' : 'No'}}
        </div>
      </div>

      <div class="actions-line mt-3" *ngIf="isAdmin || isProfile">
        <button class="button is-success wide" [routerLink]="['./edit']">Edit</button>
      </div>
    </div>
  </div>
</div>
