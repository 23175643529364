<div class="form-container">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">{{headerText}}</p>
    <span class="icon cs-icon-button" aria-label="close" mat-dialog-close>
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>

  <form [formGroup]="form" *ngIf="form" mat-dialog-content id="measure-form" (ngSubmit)="submit()">
    <div class="field pt-4 pb-4">
      <div id="product-dropdown" class="field is-flex pt-4">
        <div class="field mb-0 is-flex-grow-1">
          <label class="label" *ngIf="data.mode == 'EDIT'">Measure Policy:</label>
          <label class="label" *ngIf="data.mode == 'CREATE'">Measure Policy:* (choose existing or type new)</label>
          <input type="text"
                 class="input is-medium"
                 placeholder="Measure Policy"
                 [formControl]="policy"
                 [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let policy of filteredOptions | async" [value]="policy">
              {{policy}}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
      <div class="field pt-4">
        <label class="label">Plural Name:*</label>
        <div class="control">
          <input class="input is-medium" type="text" placeholder="meters" formControlName="pluralName">
        </div>
      </div>
      <div class="field pt-4">
        <label class="label">Singular Name:*</label>
        <div class="control">
          <input class="input is-medium" type="text" placeholder="meter" formControlName="singularName">
        </div>
      </div>
      <div id="short-names-line" class="field is-flex pt-4">
        <div class="field mb-0 is-flex-grow-1">
          <label class="label">Short Plural Name:*</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="m" formControlName="shortPluralName">
          </div>
        </div>
        <div class="field is-flex-grow-1">
          <label class="label">Short Singular Name:*</label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="m" formControlName="shortSingularName">
          </div>
        </div>
      </div>
      <div id="delivery-line" class="field is-flex pt-4">
        <div class="field is-flex-grow-1">
          <label class="label">Measure Factor:* (relative to a measure unit with same policy that have factor with value '1')</label>
          <div class="control">
            <input class="input is-medium is-halfwidth"
                   type="text"
                   placeholder="0.001"
                   formControlName="factor">
          </div>
        </div>
      </div>
      <div class="field" *ngIf="data.mode === 'EDIT'">
        <div class="control">
          <input id="switchExample"
                 type="checkbox"
                 name="switchExample"
                 class="switch is-danger is-rtl"
                 formControlName="retired"
          >
          <label class="label" for="switchExample">Retired</label>
        </div>
      </div>
      <div class="field mt-6">
        <div class="control">
          <button type="submit" class="button wide is-medium is-success has-text-weight-bold is-fullwidth">
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>



