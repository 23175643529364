import {Injectable} from '@angular/core';
import {
  AttributeConfig,
  AttributesApiService,
  Company,
  CompanyProfile, ProductGeneralInfo,
  ProductHeadingInfo, ProductPricingInfo,
  ProductResponse
} from '../../api/cs';
import {mapTo, Observable, shareReplay, startWith, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AttributesService {
  private productAttributes: Array<AttributeConfig> = [];
  public productAttributes$: Observable<AttributeConfig[]>;
  public productAttrsLoaded$: Observable<boolean>;

  private companyAttributes: Array<AttributeConfig> = [];
  public companyAttributes$: Observable<AttributeConfig[]>;
  public companyAttrsLoaded$: Observable<boolean>;

  constructor(private attributesApiService: AttributesApiService) {
    this.init();
  }

  private init(): void {
    this.productAttributes$ = this.attributesApiService.getAttributeConfigs({entity: 'product'})
      .pipe(
        shareReplay(1),
        tap((res) => {
          this.productAttributes = res;
        })
      );

    this.productAttrsLoaded$ = this.productAttributes$.pipe(
      mapTo(true),
      startWith(false)
    );

    this.companyAttributes$ = this.attributesApiService.getAttributeConfigs({entity: 'company'})
      .pipe(
        shareReplay(1),
        tap((res) => {
          this.companyAttributes = res;
        })
      );

    this.companyAttrsLoaded$ = this.companyAttributes$.pipe(
      mapTo(true),
      startWith(false)
    );
  }

  getProductAttributesConfig(): AttributeConfig[] {
    return this.productAttributes;
  }

  isShownProductAttribute(attributeName: keyof ProductResponse | keyof ProductHeadingInfo | keyof ProductGeneralInfo |
                                                                            keyof ProductPricingInfo| 'type'): boolean {
    return !this.productAttributes.find(attr => attr.key === 'product/' + attributeName)?.hidden;
  }

  getCompanyAttributesConfig(): AttributeConfig[] {
    return this.companyAttributes;
  }

  isShownCompanyAttribute(attributeName: keyof CompanyProfile | 'description'): boolean {
    return !this.companyAttributes.find(attr => attr.key === 'company/' + attributeName)?.hidden;
  }

  reload(): void {
    this.init();
  }
}
