import {ApplicationRef, Injectable, Injector} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _visible: boolean;

  constructor(private injector: Injector) { }

  setVisible(isVisible: boolean): void {
    setTimeout(() => {
      this._visible = isVisible;
      this.injector.get(ApplicationRef).tick();
    }, isVisible ? 0 : 150);
  }

  show(): void {
    this.setVisible(true);
  }

  hide(): void {
    this.setVisible(false);
  }

  get(): boolean {
    return this._visible;
  }
}
