import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {StripeElementsOptions} from "@stripe/stripe-js";
import {
  Campaign,
  CampaignsApiService,
  CreateSubmissionRequest, PaymentApiService, PaymentIntentResponse,
  ProductResponse,
  ProductsApiService
} from "../../../api/cs";
import {AuthService} from "../../../shared/services/auth.service";
import {StripePaymentElementComponent} from "ngx-stripe";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {PaymentResultStatus, PaymentService} from "../../../payment/payment.service";
import {UnitsScalingService} from "../../../shared/services/units-scaling.service";
import {ToastService} from "../../../shared/services/toast.service";

@Component({
  selector: 'cs-step-payment',
  templateUrl: './step-payment.component.html',
  styleUrls: ['./step-payment.component.scss', './../purchase.common.scss']
})
export class StepPaymentComponent implements OnInit {

  @Output() prev = new EventEmitter<boolean>();

  @Input() campaign: Campaign;
  @Input() submissionRequest$: BehaviorSubject<CreateSubmissionRequest>;
  submissionRequest: CreateSubmissionRequest;
  paymentMethod = new BehaviorSubject<string | null>(null);
  paymentIntentId = new BehaviorSubject<string | null>(null);

  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent;

  paying: boolean = false;
  estimatedCost: number;
  orderMeasureShortPluralName: string;

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  receiptEmail: string;
  product: ProductResponse;
  discountCode: string;
  discountAmount: number;
  isDiscountCodeApplied: boolean = false;
  restAmount: number;
  totalCost: number;

  constructor(private authService: AuthService,
              private campaignsApiService: CampaignsApiService,
              private unitsScalingService: UnitsScalingService,
              private dialog: MatDialog,
              private router: Router,
              private paymentApiService: PaymentApiService,
              private paymentProcessService: PaymentService,
              private toastService: ToastService,
              private productService: ProductsApiService) {
  }

  ngOnInit(): void {
    this.productService.getProduct({id: this.campaign.productId || ''}).subscribe(data => this.product = data);

    this.submissionRequest = this.submissionRequest$.getValue();
    this.orderMeasureShortPluralName = this.getOrderMeasure(this.submissionRequest.orderMeasure || 0);
    if (this.submissionRequest?.orderAmount !== undefined) {
      if (this.submissionRequest.fullUpfrontPayment) {
        this.estimatedCost = this.submissionRequest.orderAmount + (this.submissionRequest?.shippingCost || 0);
      } else {
        this.estimatedCost = (this.submissionRequest.orderAmount / 2) + (this.submissionRequest?.shippingCost || 0);
        this.restAmount = this.submissionRequest.orderAmount / 2;
      }
    }
    this.setTotalCost();
  }

  previousStep(): void {
    this.prev.emit(true);
  }

  pay() {
    if (this.authService.isLoggedIn && this.authService?.userInfo !== null) {
      this.authService.userInfo?.subscribe(user => {
        if (user !== null) {
          this.paying = true;
          this.receiptEmail = user.email || '';
          this.makePayment();
        }
      })
    } else {
      console.log("User needs to be logged in");
    }
  }

  private confirmSubmissionPayment(): void {
    if (this.submissionRequest?.id !== undefined) {
      this.campaignsApiService.confirmSubmissionPayment({
        id: this.submissionRequest?.id,
        confirmSubmissionPaymentRequest: {
          paymentMethod: this.paymentMethod.getValue() || undefined
        }
      }).subscribe(
        () => {
          this.paymentProcessService.paymentResult.next(PaymentResultStatus.NOT_PROCESS);
          this.showDialog(true, 'Your payment has been received. You can view the status of your order on the <a href="./profile/orders">My Orders</a> tab.');
          this.paymentProcessService.endPaymentProcess();
        },
        (error: { message: string; }) => {
          this.showDialog(false, error.message);
          this.paymentProcessService.endPaymentProcess();
        }
      );
    }
  }

  private showDialog(success: boolean, message: string, close?: boolean) {
    const dialogRef = this.paymentProcessService.showDialog(success, message, close);
    dialogRef.afterClosed().subscribe(() => {
        if (success) {
          this.router.navigate(['companies/' + this.campaign.companyAlias + '/campaigns', this.campaign.alias])
        }
      }
    );
  }

  private makePayment() {
    this.paymentProcessService.paymentResult.next(PaymentResultStatus.START);
    this.paymentProcessService.paymentResult.subscribe(
      result => {
        if (result === PaymentResultStatus.SUCCESS) {
          this.confirmSubmissionPayment();
        }
      }
    )
  }

  getOrderMeasure(id: number): string {
    return this.unitsScalingService.getScalingEntityById(id).measureShortPluralName || '';
  }

  checkDiscountCode(): void {
    this.paymentApiService.applyPaymentDiscount({
      discountRequest: {
        discountCode: this.discountCode,
        campaignId: Number.parseInt(this.campaign.id || '0'),
        submissionId: this.submissionRequest.id,
        intentId: this.paymentIntentId.getValue() || undefined,
      }
    }).subscribe(
      (response: PaymentIntentResponse) => {
        this.isDiscountCodeApplied = true;
        this.estimatedCost = response.paymentAmount;
        if (this.submissionRequest.fullUpfrontPayment) {
          this.discountAmount = ((this.submissionRequest.orderAmount || 0) + (this.submissionRequest.shippingCost || 0))
            - response.paymentAmount;
        } else {
          this.discountAmount = ((((this.submissionRequest.orderAmount || 0) / 2) + (this.submissionRequest.shippingCost || 0))
            - response.paymentAmount) * 2;
          this.restAmount = ((this.submissionRequest.orderAmount || 0) - this.discountAmount) / 2;
        }
        this.setTotalCost();
      });
  }

  private setTotalCost(): void {
    this.totalCost = (this.submissionRequest?.orderAmount || 0) + (this.submissionRequest.shippingCost || 0) - (this.discountAmount || 0);
  }
}
