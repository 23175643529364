import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/services/auth.service';
import {AuthenticationApiService} from '../../../api/cs';
import {ConfirmedValidator} from '../../../users/services/user-form.service';
import {LoaderService} from '../../../shared/services/loader.service';
import {finalize} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {InfoModalWindowComponent} from "../info-modal-window/info-modal-window.component";
import {ValidatorsService} from "../../util/validators.service";

@Component({
  selector: 'cs-sign-in',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  headerText: string = 'Welcome to Cultured Supply';
  adminMode: boolean;

  form: FormGroup;
  resetPasswordForm: FormGroup;
  resetPasswordFormConfirm: FormGroup;
  resetPasswordStep = 1;
  intentToSubscribe: boolean = false;
  intentToRegister: boolean = false;

  loginCompleted = new EventEmitter<boolean>();

  constructor(private router: Router,
              private fb: FormBuilder,
              private loader: LoaderService,
              private dialog: MatDialog,
              private authenticationApiService: AuthenticationApiService,
              private authService: AuthService,
              public dialogRef: MatDialogRef<AuthComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                mode: 'logIn' | 'signUp' | 'resetPwd';
                withoutRedirection?: boolean;
              }
  ) { }

  ngOnInit(): void {
    this.isAdminMode();
    switch (this.data.mode) {
      case "signUp": {
        this.setUpSignUpMode();
        break;
      }
      case "logIn": {
        this.setUpLoginMode();
        break;
      }
      case "resetPwd": {
        this.setUpResetPwdMode();
      }
    }
  }

  setUpLoginMode(): void {
    this.data.mode = 'logIn';
    this.form = this.fb.group({
      email: new FormControl(''),
      password: new FormControl('')
    });
    this.headerText = 'Welcome to Cultured Supply';
  }

  setUpSignUpMode(): void {
    this.data.mode = 'signUp';
    this.form = this.fb.group({
      companyName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      signUpEmail: new FormControl('', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,63}$')]),
      phone: new FormControl('', Validators.required),
    });
    this.headerText = 'Create New Account';
  }

  setUpResetPwdMode(): void {
    this.data.mode = 'resetPwd';
    this.resetPasswordForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,63}$')])
    });

    this.resetPasswordFormConfirm = this.fb.group({
      password: new FormControl('', Validators.required),
      confirm_password: new FormControl('', Validators.required),
      code: new FormControl('', Validators.required),
    }, {
      validator: ConfirmedValidator('password', 'confirm_password')
    });
    this.headerText = 'Password Reset';
  }

  login(): void {
    this.loader.show();
    this.authService.login(
      this.form.value.email,
      this.form.value.password
    ).pipe(finalize(() => this.loader.hide()))
      .subscribe(() => {
        this.dialogRef.close();
        if (!this.data.withoutRedirection) {
          if (this.authService.isAdmin()) {
            this.router.navigate(['']);
          } else {
            let subscription = this.authService.userInfo.subscribe(user => {
              if (!!user) {
                this.router.navigate(['/dashboard']).then(() => subscription.unsubscribe());
              }
            });
          }
        }
        this.loginCompleted.emit(true);
    });
  }

  resetPassword(): void {
    this.loader.show();
    this.authenticationApiService.passwordResetInitiate({
      passwordResetInitiateRequest: {
        email: this.resetPasswordForm.value.email
      }
    }).pipe(finalize(() => this.loader.hide()))
      .subscribe(() => {
      this.resetPasswordStep = 2;
    });
  }

  confirmResetPassword(): void {
    this.loader.show();
    this.authenticationApiService.passwordResetComplete({
      passwordResetCompleteRequest: {
        password: this.resetPasswordFormConfirm.value.password,
        email: this.resetPasswordForm.value.email,
        securityCode: this.resetPasswordFormConfirm.value.code
      }
    }).pipe(finalize(() => this.loader.hide()))
      .subscribe((res) => {
      this.authService.token = res.accessToken;
      this.dialogRef.close();
    });
  }

  signUp(): void {
    this.loader.show();
    this.authenticationApiService.signUp({
      signUpRequest: {
        company: {
          name: this.form.value.companyName
        },
        user: {
          firstName: this.form.value.firstName,
          lastName: this.form.value.lastName,
          phone: this.form.value.phone,
          email: this.form.value.signUpEmail,
          sendEmail: true,
          subscriptionAgreement: this.intentToSubscribe
        }
      }
    }).pipe(finalize(() => this.loader.hide()))
      .subscribe((res) => {
        if (!this.adminMode) {
          this.authService.updateTokens(res);
          this.authService.updateUserInfo();
          this.dialogRef.close();
          this.showInfoWindow(true, "Account created, you are logged in, a randomly generated password sent to your email");
        } else {
          this.dialogRef.close();
          this.showInfoWindow(true, "New user created, a randomly generated password sent to it's email");
        }
      }, () => {
        this.showInfoWindow(false, "Error, try later or contact us");
      })
  }

  private showInfoWindow(success: boolean, message: string): void {
    this.dialog.open(InfoModalWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {success: success, message: message}
    })
  }

  private isAdminMode(): void {
    this.adminMode = this.authService.isLoggedIn && this.authService.isAdmin();
  }

  onlyEnglish($event: KeyboardEvent) {
    ValidatorsService.onlyEnglish($event);
  }
}
