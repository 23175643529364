<div class="product-image-container mb-3">
  <a [href]="['/companies/' + product.companyAlias + '/products/' + product.alias]">
    <img *ngIf="!!product?.headingInfo?.images"
         src="https://{{s3BucketName}}.{{s3DefaultApi}}/images/{{product?.headingInfo?.images?.[0]?.reference}}">
    <div *ngIf="!product?.headingInfo?.images"
         class="cs-card no-image">
    </div>
  </a>
</div>
<div class="product-info p-2 is-size-6">
  <a class="is-size-5 has-text-weight-semibold"
     [href]="['/companies/' + product.companyAlias + '/products/' + product.alias]">
    {{product.headingInfo?.name}}
  </a>

  <div *ngFor="let platform of product.headingInfo.chassis" class="platform-line is-flex is-align-items-center">
    <p class="has-text-grey is-size-6 mr-1">
      {{platform}}
    </p>
    <img src="{{platformImageService.getPlatformImage(platform)}}"
         *ngIf="platformImageService.isPlatformImageExists(platform)">
    <span class="tag verified-label" *ngIf="product.headingInfo?.verified">
      Verified
    </span>
  </div>

  <div cs-product-actions
       *ngIf="showActions"
       [isAdmin]="isAdmin"
       [product]="product"
       [isVerticalAlignment]="false"
       (updatedProduct)="updateProduct($event)"
       (createdProduct)="addProduct($event)">
  </div>

  <div class="product-property mt-4" *ngIf="showType">
    <span class="has-text-weight-bold has-text-grey-dark pr-2">
      Product Type:
    </span>
    <span class="has-text-grey-dark">
      {{product.generalInfo?.type}}
    </span>
  </div>

  <div class="product-property mt-2" *ngIf="showCategory">
    <span class="has-text-weight-bold has-text-grey-dark pr-2">
      Product Category:
    </span>
    <span class="has-text-grey-dark">
      {{product.generalInfo?.category}}
    </span>
  </div>

  <div class="product-property mt-2" *ngIf="showRecommendation">
    <span class="has-text-weight-bold has-text-grey-dark pr-2">
      Recommended Use:
    </span>
    <span class="has-text-grey-dark">
      {{product.generalInfo?.recommendation}}
    </span>
  </div>

  <div class="product-property mt-2" *ngIf="showDevelopmentStage">
    <span class="has-text-weight-bold has-text-grey-dark pr-2">
      Stage of development:
    </span>
    <span class="has-text-grey-dark">
      {{product.headingInfo?.developmentStage?.value}}
    </span>
  </div>


  <div class="mt-3 product-description" *ngIf="showDescription">
    <quill-view [content]="product.headingInfo?.description"></quill-view>
  </div>
</div>

<div class="card-expander is-flex is-justify-content-center is-fullwidth pt-2 pb-2 is-clickable"
     (click)="changeExtending()">
  <i *ngIf="!isExtended"
     class="fa fa-arrow-down cs-icon-button"
  ></i>
  <i *ngIf="isExtended"
     class="fa fa-arrow-up cs-icon-button"
  ></i>
</div>
