import {Injectable} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() {}

  isValid(form: FormGroup, elementId: string, requiredFields: string[]): boolean {
    for(let field of requiredFields) {
      form.get(field)?.markAsTouched();
    }
    let invalidFields = Array.of(document
      .getElementById(elementId)!
      .getElementsByClassName('ng-invalid'));
    if (invalidFields[0].length > 0) {
      invalidFields[0][0].scrollIntoView({block: "center", behavior: "smooth"});
      return false;
    } else {
      return true;
    }
  }
}
