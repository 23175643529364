<div class="under-top-toolbar container">
  <section class="hero">
    <div class="hero-body has-text-centered">
      <div class="title has-text-weight-semibold">
        About us
      </div>
    </div>
  </section>
  <div class="is-flex is-flex-direction-column is-align-items-center pt-4">
    <p>
      Welcome to the messy middle – it’s where the rubber meets the road!
    </p>
    <p>
      Cultured Supply is building supply chains to power the bioeconomy. We help companies, big and small, discover and source biobased chemicals, ingredients, materials, and products.
    </p>
    <p>
      While we are optimists, we are pragmatic. For biobased alternatives to be adopted at any type of scale, it must make sense for a company’s bottom line without sacrificing performance.
    </p>
    <p>
      Let’s get to work!
    </p><br/>
    <p>
      Check out the <a routerLink="/manifesto">manifesto</a> here.
    </p>
  </div>
</div>
