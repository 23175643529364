/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductSearchResponse } from './product-search-response';


export interface CompanySearchResponse {
    id?: string;
    companyName?: string;
    companyAlias?: string;
    companyDescription?: string;
    companyType?: string;
    companyRegion?: string;
    companyCountryState?: string;
    companyAlgaeType?: string;
    companyIndustryFocus?: Array<string>;
    companyCustomerFocus?: Array<string>;
    isCompanyVerified?: boolean;
    isCompanyVisible?: boolean;
    products?: Array<ProductSearchResponse>;
}

