<div class="step-main is-flex">
  <div class="step-fields">
    <div class="is-flex is-flex-direction-column mt-6">
      <p>
        All orders on Cultured Supply carry standard terms and conditions between the buyer and supplier.
      </p>
      <p>Please read the purchase order carefully before signing. If you have any questions regarding the purchase order, <a href="/contact-us" target="_blank">contact us</a></p>
      <p class="mt-2">Please read the <a href="https://s3.eu-west-3.amazonaws.com/shared.files.culturedsupply.com/Electronic+Disclosure+Terms.pdf" target="_blank" >Electronic Record and Signature Disclosure</a></p>
      <mat-checkbox class="has-text-weight-bold mt-1" [(ngModel)]="intentToSign" [disabled]="isContractSigned">
        <div class="text-wrap"> I agree to use electronic records and signatures. I acknowledge that by signing the purchase order below, it is a legally binding contract between my company and the supplier.</div>
      </mat-checkbox>
      <button class="button button-primary is-success is-flex download-button mb-2" (click)="preparePdf(true)">
        <img class="icon mr-1" src="../../../../assets/images/white-download.svg">
        <span class="has-text-weight-bold">Download</span>
      </button>
    </div>


    <div class="contract">
      <pdf-viewer *ngIf="pdfSource" [src]="pdfSource"
                  [show-all]="true"
                  [fit-to-page]="false"
                  [zoom]="0.9"
                  [zoom-scale]="'page-width'"
                  [stick-to-page]="false"
                  [external-link-target]="'blank'"
                  [autoresize]="true"
                  [show-borders]="false"
                  [original-size]="false"
                  style="width: 100%; height: 350px"
      ></pdf-viewer>
      <div class="loader-wrapper" [class.is-active]="!pdfSource"
           *ngIf="!pdfSource">
        <div class="loader is-success is-loading"></div>
      </div>
    </div>
    <div class="step-buttons" [ngClass]="!(signature && !isContractSigned) ? 'is-flex-direction-row' : 'is-flex-direction-row-reverse'">
      <a class="button-previous is-flex" (click)="previousStep()" *ngIf="!(signature && !isContractSigned)">
        <span class="icon">
          <i class="fa fa-angle-left"></i>
        </span>
        <span>Back</span>
      </a>
      <button class="button button-next wide is-medium is-success has-text-weight-bold" *ngIf="signature || isContractSigned"
              [disabled]="!intentToSign" (click)="next()">Next: {{nextStep}}</button>
      <button class="button button-next wide is-medium is-success has-text-weight-bold" *ngIf="!signature && !isContractSigned"
              [disabled]="!intentToSign" (click)="sign()">Sign
      </button>
    </div>
  </div>
  <div class="side-hint-container">
    <div class="side-hint">
      <div class="side-hint__icon">
        <img src="assets/images/lightbulb.png"/>
      </div>
      <div class="side-hint__title">Standard Terms and Conditions</div>
      <div class="side-hint__subtitle">Delivery Date</div>
      <div class="side-hint__text">{{campaign.deliveryTime}} days from the date initial payment is received.</div>
      <div class="side-hint__subtitle">Shipping Cost</div>
      <div class="side-hint__text">Included in the price and detailed in purchase order.</div>
      <div class="side-hint__subtitle">Order Delays and Cancellation</div>
      <div class="side-hint__text">If supplier does not deliver product within 30 following delivery date;
        buyer has the right to cancel order and receive a full refund.</div>
    </div>
  </div>
</div>
