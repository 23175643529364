import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AttributesApiService } from './api/attributes-api.service';
import { AuthenticationApiService } from './api/authentication-api.service';
import { CampaignsApiService } from './api/campaigns-api.service';
import { CertificationsApiService } from './api/certifications-api.service';
import { CompaniesApiService } from './api/companies-api.service';
import { ConfigsApiService } from './api/configs-api.service';
import { DictionariesApiService } from './api/dictionaries-api.service';
import { FilesApiService } from './api/files-api.service';
import { LocationApiService } from './api/location-api.service';
import { PaymentApiService } from './api/payment-api.service';
import { ProductsApiService } from './api/products-api.service';
import { RequestSampleApiService } from './api/request-sample-api.service';
import { SampleApiService } from './api/sample-api.service';
import { StripeWebhookControllerApiService } from './api/stripe-webhook-controller-api.service';
import { SupplyChainAuthenticationApiService } from './api/supply-chain-authentication-api.service';
import { SupplyChainBuilderApiService } from './api/supply-chain-builder-api.service';
import { SupplyChainDataApiService } from './api/supply-chain-data-api.service';
import { UsersApiService } from './api/users-api.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
