import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {NotFoundPageComponent} from "../components/not-found-page/not-found-page.component";

@Injectable({
  providedIn: 'root'
})
export class NotFoundPageCircularRedirectGuard implements CanDeactivate<any> {

  allowedPaths = [
    "/",
    "/products",
    "/companies",
    "/campaigns",
    "/sign-up-supplier",
    "/sign-up-buyer",
    "/manifesto",
    "/faq",
    "/profile",
    "/contact-us"
  ];

  constructor() {}

  canDeactivate(component: NotFoundPageComponent,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    for (let path of this.allowedPaths) {
      if (path == nextState?.url) {
        return true;
      }
    }
    return false;
  }
}
