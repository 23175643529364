<div class="step-main is-flex is-flex-direction-column">
  <div class="is-flex">
    <form class="step-fields" [formGroup]="form" *ngIf="form" id="order-info" (ngSubmit)="next(form)">
      <div class="is-flex mt-3 mb-5">
        <div class="info-alternative-item">
          <div class="info-alternative-item__title">UNIT PRICE</div>
          <div class="info-alternative-item__value">{{campaign?.unitCurrency}}{{campaign?.unitPrice | number: '.2'}}
            /{{campaign.unitMeasure?.measureShortPluralName}}</div>
        </div>
        <div class="info-alternative-item">
          <div class="info-alternative-item__title">MINIMUM ORDER</div>
          <div class="info-alternative-item__value">{{campaign?.minimumOrder | number: '1.0'}} {{campaign.minimumOrderMeasure?.measureShortPluralName}}</div>
        </div>
        <div class="info-alternative-item">
          <div class="info-alternative-item__title">MAXIMUM ORDER</div>
          <div
            class="info-alternative-item__value"> {{campaign?.maximumOrder | number: '1.0'}} {{campaign?.maximumOrderMeasure?.measureShortPluralName}}</div>
        </div>
        <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <a class="button is-success has-text-weight-bold is-size-7" (click)="openContactWindow()"> Need more? </a>
        </div>
      </div>
      <div class="field is-flex is-flex-grow-1">
        <div class="field is-flex-grow-1">
          <label class="label">Order quantity <i>(increments of {{campaign?.orderStep | number: '1.0'}} {{campaign.orderStepMeasure?.measureShortPluralName}}):*</i></label>
          <div class="control">
            <input class="input is-medium" type="text" placeholder="100" mask="separator.0"
                   thousandSeparator="," [separatorLimit]="getMaxAmountOfDigits() || '1000000'"
                   formControlName="quantity" (ngModelChange)="calcCost()">
            <input class="input is-medium measure-units" type="text" [value]="campaign.minimumOrderMeasure?.measurePluralName || ''" [disabled]="true">
          </div>
          <label class="label mt-1 error" *ngIf="(form.controls['quantity']?.errors?.hasOwnProperty('lower-than-min') || false) == true">Order amount should be bigger than minimum order</label>
          <label class="label mt-1 error" *ngIf="(form.controls['quantity']?.errors?.hasOwnProperty('bigger-than-max') || false) == true">Order amount should be lower than maximum order</label>
          <label class="label mt-1 error" *ngIf="(form.controls['quantity']?.errors?.hasOwnProperty('non-divisible') || false) == true">Step of order should be appropriate to order step</label>
          <label class="label mt-1 error" *ngIf="(form.controls['quantity']?.errors?.hasOwnProperty('max-payment') || false) == true">Order amount should be less than {{MAX_PAYMENT_AMOUNT | currency}}</label>
        </div>
      </div>
      <div class="field is-flex-grow-1">
        <label class="label">Delivery address:*</label>
        <div class="control">
          <input class="input is-medium"
                 type="text"
                 placeholder="Enter Address"
                 aria-label="Delivery address"
                 matInput
                 formControlName="address"
                 [matAutocomplete]="auto"
                 (keypress)="onlyEnglish($event)"
                 (ngModelChange)="predict($event)">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="insert($event.option.value)">
            <mat-option *ngFor="let option of predictions" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          <label *ngIf="form.controls['address']?.errors?.hasOwnProperty('illegal-area')" class="error"><i>This product can only be shipped in the United States.</i></label>
        </div>
      </div>
      <div class="field is-flex-grow-1" *ngIf="showPaymentPlan">
        <label class="label">Payment plan</label>
        <div class="is-flex is-justify-content-space-between">
          <div class="control control-radio-container control-radio input"
               [class.checked]="submissionRequest.fullUpfrontPayment"
               (click)="changePaymentPlan()">
            <input type="radio" id="upfront" name="payment-plan" value="upfront"
                   [checked]="submissionRequest.fullUpfrontPayment"
                   [defaultChecked]="submissionRequest.fullUpfrontPayment">
            <label for="upfront" (click)="changePaymentPlan()">Pay 100% upfront</label>
          </div>
          <div class="control-radio-container is-flex is-flex-direction-column">
            <div class="control control-radio input"
                 [class.checked]="!submissionRequest.fullUpfrontPayment"
                 (click)="changePaymentPlan()">
              <input type="radio" id="later" name="payment-plan" value="later"
                     [checked]="!submissionRequest.fullUpfrontPayment"
                     [defaultChecked]="!submissionRequest.fullUpfrontPayment">
              <label for="later" (click)="changePaymentPlan()">Pay 50% now & 50% later</label>
            </div>
            <div class="additional-info"><i>Payment terms available on orders above $2,500 and subject to business verification.</i></div>
          </div>
        </div>
      </div>
      <div class="step-buttons is-flex is-flex-direction-row-reverse">
        <button type="submit" class="button button-next wide is-medium is-success has-text-weight-bold">
          Next: {{nextStep}}</button>
      </div>
    </form>
    <div class="side-hint-container">
      <div class="side-hint">
        <div class="side-hint__icon">
          <img src="assets/images/order-box.png"/>
        </div>
        <p class="side-hint__title mb-3 ">Order costs</p>
        <div class="summary-item">
          <p class="summary-item__title">Order quantity:</p>
          <p class="summary-item__value">{{form?.value?.quantity || 0 | number: '1.0'}} {{orderMeasure?.measureShortPluralName}}</p>
        </div>
        <div class="summary-item">
          <p class="summary-item__title">Unit cost:</p>
          <p class="summary-item__value">{{campaign?.unitCurrency}}{{campaign?.unitPrice | number: '.2'}}
            /{{campaign?.unitMeasure?.measureShortSingularName}}</p>
        </div>
        <div class="summary-item">
          <p class="summary-item__title">Total product cost:</p>
          <p class="summary-item__value">{{ (submissionRequest?.orderAmount || 0) | currency }}</p>
        </div>
        <div class="summary-item">
          <p class="summary-item__title">Shipping and handling:</p>
          <p class="summary-item__value">{{ shippingCost | currency }}</p>
        </div>
        <div class="summary-item summary-item-total">
          <p class="summary-item__title">Total cost:</p>
          <p class="summary-item__value">{{ (submissionRequest?.orderAmount || 0) + shippingCost | currency }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
