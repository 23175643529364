import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Campaign, CampaignProgress, CampaignsApiService, CampaignState} from "../../../api/cs";

@Component({
  selector: 'cs-campaign-stats',
  templateUrl: './campaign-stats.component.html',
  styleUrls: ['./campaign-stats.component.scss']
})
export class CampaignStatsComponent implements OnInit, OnChanges {

  @Input() campaign: Campaign;
  @Input() showBuyers: boolean;
  campaignProgress: CampaignProgress;
  campaignProgressBarWidth = 0;
  @Input() showState: boolean = false;
  campaignState: string;
  state = CampaignState;

  constructor(private campaignService: CampaignsApiService) { }

  ngOnInit(): void {
    if (this.campaign?.alias !== undefined) {
      this.loadCampaignProgress();
    }
  }

  private loadCampaignProgress() {
      this.getCampaignState();
      this.campaignService.getCampaignProgress({id: this.campaign.alias || ''}).subscribe(
        data => {
          this.campaignProgress = data
          if (this.campaignProgress?.currentProgress !== undefined) {
            this.campaignProgressBarWidth = this.campaignProgress?.currentProgress <= 100 ? this.campaignProgress?.currentProgress : 100;
          } else {
            this.campaignProgressBarWidth = 100;
          }
        });
  }

  getCampaignState() {
    let state = this.campaign.state;
    if (state) {
      this.campaignState = state?.substring(0, 1) + state?.replace(/_/g, ' ').substring(1, state?.length).toLowerCase();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const campaignChanges = changes['campaign'];

    if (campaignChanges && !campaignChanges.firstChange) {
      if (campaignChanges.previousValue.state !== campaignChanges.currentValue.state) {
        this.getCampaignState();
      }
    }
  }

}
