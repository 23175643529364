import {Injectable} from "@angular/core";
import {environment} from '../../../environments/environment';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export enum FormNames {
  CONTACT_US,
  FOR_BUYERS,
  FOR_SUPPLIERS,
  PRODUCT_BUYING_REQUEST,
  COMPANY_CONTACT_REQUEST,
  SUPPLY_CHAIN_SIGN_UP_REQUEST,
  SUPPLY_CHAIN_SIGN_IN_REQUEST
}

/**
 * Service that know how to send forms to HubSpot
 */
@Injectable({
  providedIn: 'root'
})
export class HubSpotFormsService {

  private pageUri = environment.loopUrl;
  private portalId: string = environment.HubSpotPortalId;
  private formId: string;
  private url: string;

  constructor(private httpClient: HttpClient) {

  }

  /**
   * Constructs a request from input values and send it to hubspot
   * @param formName - one of FormNames enum values
   * @param pageName - name of page from which request was sent
   * @param pageUri - link to page from which request was sent
   * @param values - list of values which will be set to json
   */
  sendFormRequest(formName: FormNames, pageName: string, pageUri: string, values: string[]): Observable<Object> {
    this.formId = this.FormIds[formName.valueOf()];
    let HubSpotForm = this.HubSpotFormTemplates[formName.valueOf()];
    HubSpotForm.context.pageName = pageName;
    HubSpotForm.context.pageUri = this.pageUri + pageUri;
    for (let i = 0; i < HubSpotForm.fields.length; i++) {
      HubSpotForm.fields[i].value = values[i];
    }
    this.url = `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formId}`;
    return this.httpClient.post(this.url, HubSpotForm);
  }

  private HubSpotFormTemplates = [
    //Contact Us - 0
    {
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "firstName",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "message",
          "value": ""
        }
      ],
      "context": {
        "pageUri": "/#/contact-us",
        "pageName": "Contact Us"
      }
    },
    //For Buyers - 1
    {
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "company",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "firstName",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "lastName",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "phone",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "industry_focus",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "message",
          "value": ""
        }
      ],
      "context": {
        "pageUri": "/#/sign-up-buyer",
        "pageName": "For Buyers"
      }
    },
    //For Suppliers - 2
    {
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "company",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "firstName",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "lastName",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "phone",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "i_want_to",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "message",
          "value": ""
        }
      ],
      "context": {
        "pageUri": "/#/sign-up-supplier",
        "pageName": "For Suppliers"
      }
    },
    //ProductBuyingRequest - 3
    {
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "user_name",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "buying_request_name",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "quantity",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "target_price",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "message",
          "value": ""
        }
      ],
      "context": {
        "pageUri": "/",
        "pageName": ""
      }
    },
    //Company Contact - 4
    {
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "user_name",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "subject",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "message",
          "value": ""
        }
      ],
      "context": {
        "pageUri": "/",
        "pageName": ""
      }
    },
    //Supply Chain Sign Up - 5
    {
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "company",
          "value": ""
        },
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": ""
        }
      ],
      "context": {
        "pageUri": "/",
        "pageName": ""
      }
    },
    //Supply Chain Sign In - 6
    {
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": ""
        }
      ],
      "context": {
        "pageUri": "/",
        "pageName": ""
      }
    },
  ];

  private FormIds = [
    environment.HubSpotFeedbackFormId,
    environment.HubSpotBuyerFormId,
    environment.HubSpotSupplierFormId,
    environment.HubSpotProductBuyingRequestFormId,
    environment.HubSpotCompanyContactRequestFormId,
    environment.HubSpotSupplyChainSignUpRequestFormId,
    environment.HubSpotSupplyChainSignInRequestFormId
  ]
}




