import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from "../../../shared/services/auth.service";

@Component({
  selector: 'cs-step-registration-confirmation',
  templateUrl: './step-registration-confirmation.component.html',
  styleUrls: ['./step-registration-confirmation.component.scss']
})
export class StepRegistrationConfirmationComponent implements OnInit {

  _isModalActive: boolean;

  @Output()
  submitted = new EventEmitter<boolean>();

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
  }

  get isModalActive(): boolean {
    return this._isModalActive;
  }

  set isModalActive(value: boolean) {
    this._isModalActive = value;
  }

  openWidow(): void {
    if (this.authService.isLoggedIn) {
      this.submitted.emit(true);
    } else {
      this.isModalActive = true;
    }
  }

  closeWindow(): void {
    this.isModalActive = false;
  }

  submit(): void {
    this.closeWindow();
    this.submitted.emit(true);
  }
}
