import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/services/auth.service';
import {CompanyProfile} from '../../../api/cs';
import {LoaderService} from "../../../shared/services/loader.service";
import {Router} from "@angular/router";
import {FormNames, HubSpotFormsService} from "../../../shared/services/hubspot-forms.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmationWindowComponent} from "../../../core/components/confirmation-window/confirmation-window.component";

@Component({
  selector: 'cs-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  form: FormGroup;

  @Output() submitted = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder,
              private loader: LoaderService,
              private router: Router,
              private hubSpotFormsService: HubSpotFormsService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ContactFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {company: CompanyProfile},
              private authService: AuthService) { }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
    this.initForm();
  }

  private initForm(): void {
    this.authService.userInfo?.subscribe((user) => {
      this.form = this.fb.group({
        name: new FormControl(!!user?.firstName && !!user?.lastName ? user?.firstName + ' ' + user?.lastName : "", Validators.required),
        email: new FormControl(user?.email, [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,63}$')]),
        subject: new FormControl(this.data.company.name + ' contact request'),
        message: new FormControl('', Validators.required)
      });
    });

  }

  confirmClose(): void {
    let confirmation = this.dialog.open(ConfirmationWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        header: "Window Close Confirmation",
        description: "discard changes and close the window"}
    });
    confirmation.beforeClosed().subscribe(() => {
      if (confirmation.componentInstance.confirmed.getValue()) {
        this.dialogRef.close();
      }
    })
  }

  submit(): void {
    if (this.form.valid) {
      this.loader.show();
      this.hubSpotFormsService.sendFormRequest(FormNames.COMPANY_CONTACT_REQUEST,
        this.data.company.name || "Company",
        this.router.url,
        [
          this.form.value.name,
          this.form.value.email,
          this.form.value.subject,
          this.form.value.message
        ]).subscribe(() => {
        this.form.reset();
        this.loader.hide();
        this.dialogRef.close();
        this.submitted.emit(true);
      });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
