import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {CompaniesApiService, CompanyProfile} from '../../../../api/cs';
import {AuthService} from '../../../../shared/services/auth.service';
import {AttributesService} from '../../../../shared/services/attributes.service';

@Component({
  selector: 'cs-edit-company-rnd-form',
  templateUrl: './edit-company-rnd-form.component.html',
  styleUrls: ['./edit-company-rnd-form.component.scss']
})
export class EditCompanyRndFormComponent implements OnInit {

  private _isModalActive = false;
  private company: CompanyProfile;

  rnd: string;

  @Output()
  updated = new EventEmitter<boolean>();

  constructor(private authService: AuthService,
              private attributesService: AttributesService,
              private companiesApiService: CompaniesApiService) {
  }

  ngOnInit(): void {
  }

  get isModalActive(): boolean {
    return this._isModalActive;
  }

  set isModalActive(value: boolean) {
    this._isModalActive = value;
  }

  closeWindow(): void {
    this.isModalActive = false;
  }

  showWindow(company?: CompanyProfile): void {
    if (company) {
      this.rnd = company.rnd || '';
      this.company = company;
    }
    this.isModalActive = true;
  }

  submit(): void {
    this.companiesApiService.updateCompanyRnd({
      id: this.company.id ?? '',
      companyTextUpdateRequest: {
        content: this.rnd
      }
    }).subscribe(() => {
      this.updated.emit(true);
      this.closeWindow();
    });
  }
}
