<section class="heading-section is-flex is-flex-direction-column">
  <h1 class="h1-title is-align-self-center has-text-weight-bold">Algae Supply Chain Builder</h1>
  <h2 class="h2-title is-align-self-center">Find companies and products along the entire algae value chain</h2>

  <div class="top-filters-container custom-ng-select">
    <div id="top-filters-line" class="top-filters-line">
      <div class="single-top-filter-container">
        <label class="top-filter-container-title">Are you looking for Seaweed or Micro-algae?</label>
        <ng-select [items]="algaeTypes"
                   bindLabel="value"
                   appearance="outline"
                   [multiple]="false"
                   [closeOnSelect]="false"
                   (change)="processHeadingFilterChange()"
                   [class.ng-has-value]="false"
                   [placeholder]="chosenAlgaeType"
                   [(ngModel)]="chosenAlgaeType">
          <ng-template ng-option-tmp let-item="item">
            <mat-checkbox [checked]="chosenAlgaeType == item" disabled>{{item}}</mat-checkbox>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="search-line">
    <input type="search"
           class="input"
           placeholder="Search by product, company, or industry"
           [formControl]="search"
           (ngModelChange)="doSearch(false)"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let item of filteredOptions | async"
                  [value]="item"
                  (onSelectionChange)="setSearchValue(item)">
        {{item}}
      </mat-option>
    </mat-autocomplete>
    <button class="button is-success" (click)="doSearch(true)">Search</button>
  </div>
</section>

<hr class="sections-divider">

<section class="main-content-wrapper">

  <div class="side-filters-container">
    <div id="general-filters-container" class="general-filters-container">
      <div class="general-filters-title-container is-flex is-justify-content-space-between is-align-items-baseline mb-2">
        <div class="general-filters-title">Specific Filters</div>
        <a class="side-clear-button has-text-weight-semibold" (click)="clearHeadingFilters()">Clear&nbsp;All</a>
      </div>
      <div class="custom-ng-select is-flex is-flex-wrap-wrap">
        <div *ngFor="let headingFilter of filtersData?.headingFilters | slice: 1; let i = index" class="general-filter is-flex-grow-1">
          <ng-select *ngIf="headingFilter.values.length > 0"
                     [items]="headingFilter.values"
                     bindLabel="displayedName"
                     groupBy="matchingGroupName"
                     appearance="outline"
                     [multiple]="true"
                     [closeOnSelect]="false"
                     [selectableGroup]="true"
                     [selectableGroupAsModel]="false"
                     (change)="processHeadingFilterChange()"
                     [class.ng-has-value]="false"
                     [placeholder]="headingFilter.name"
                     [(ngModel)]="headingFilter.chosenValues">
            <ng-template ng-optgroup-tmp let-item="item">
              {{item.matchingGroupName || 'Unnamed group'}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <mat-checkbox [checked]="headingFilter.chosenValues?.includes(item)" disabled>{{item.displayedName}}</mat-checkbox>
            </ng-template>
            <ng-template ng-multi-label-tmp>
              <span class="ng-placeholder">{{headingFilter.name}}: <div class="badge">{{headingFilter.chosenValues?.length}}</div></span>
            </ng-template>
          </ng-select>
          <div class="filters-tags desktop-filters-tags">
            <div class="filter-tag" *ngFor="let chosenFilter of headingFilter.chosenValues">
              <div class="tag-name">
                {{chosenFilter.displayedName}}
                <i class="fa fa-lg fa-times" (click)="unselectHeadingFilterByFilterValue(headingFilter, chosenFilter.displayedName)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filters-tags mobile-filters-tags">
        <div class="filter-tag" *ngFor="let chosenFilter of chosenFilters">
          <div class="tag-name">
            {{chosenFilter.groupName}}: {{chosenFilter.filterName}}
            <i class="fa fa-lg fa-times" (click)="unselectHeadingFilterByRecord(chosenFilter)"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="chain-filters-container">

      <div class="is-flex is-justify-content-space-between is-align-items-baseline mb-2">
        <div class="side-filters-title">Value Chain Filters</div>
        <a class="side-clear-button has-text-weight-semibold" (click)="clearSideFilters()">Clear&nbsp;All</a>
      </div>

      <div class="custom-ng-select step-2-filter is-flex is-flex-grow-1 is-flex-wrap-wrap">
        <label>I want to search for products in the supply chain that are:</label>
        <ng-select [items]="targetTypes"
                   bindLabel="value"
                   appearance="outline"
                   [multiple]="false"
                   [closeOnSelect]="false"
                   (change)="changeFilterType()"
                   [class.ng-has-value]="false"
                   [placeholder]="chosenTargetType"
                   [(ngModel)]="chosenTargetType">
          <ng-template ng-option-tmp let-item="item">
            <mat-checkbox [checked]="chosenTargetType == item" disabled>{{item}}</mat-checkbox>
          </ng-template>
        </ng-select>
      </div>

      <div *ngIf="isSideFiltersVisible">
        <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl" class="filter-tree">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <mat-checkbox [formControl]="nodeControlMap.get(node.name)!" [disabled]="!availabilityNodeMap.get(node)!"
                          (change)="processTreeNodeValueChange(node)">
              {{node?.name}}
            </mat-checkbox>
          </mat-tree-node>
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div class="mat-tree-node">
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <mat-checkbox *ngIf="!isFirstNode(node)" [disabled]="!availabilityNodeMap.get(node)!"
                            [formControl]="nodeControlMap.get(node.name)!"
                            (change)="processTreeNodeValueChange(node)">
                {{node?.name}}
              </mat-checkbox>
              <div class="top-level-filter-node" *ngIf="isFirstNode(node)">
                <div class="has-text-weight-bold">{{node.name}}</div>
                <div class="badge" *ngIf="filterGroupsActiveCount.get(node.name)">{{filterGroupsActiveCount.get(node.name)}}</div>
              </div>
            </div>
            <div [class.filter-tree-invisible]="!treeControl.isExpanded(node)"
                 role="group">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
      </div>
    </div>
  </div>

  <div class="content-container">
    <mat-tab-group animationDuration="0ms">
      <mat-tab>
        <ng-template mat-tab-label>
          Companies
          <div class="badge" *ngIf="!!companiesSortedList">{{companiesSortedList.length}}</div>
        </ng-template>
        <div *ngIf="!!companiesSortedList">
          <div class="cs-card is-flex" *ngFor="let company of getCurrentPage(companiesSortedList, companyPageIndex)">
            <div class="card-content is-flex-grow-1">
              <div class="card-top-line is-flex is-justify-content-space-between">
                <div class="card-heading has-text-success is-size-3">
                  {{company.companyName}}
                  <span class="tag verified-label" *ngIf="company.isCompanyVerified">
                    Verified
                  </span>
                </div>
                <div class="is-flex" *ngIf="!company.isCompanyVerified">
                  <a class="booking-link" href="{{hubSpotMeetingSchedulingLink}}" target="_blank">
                    Is this your company? Verify information
                  </a>
                  <i class="fa fa-question-circle" matTooltipPosition="above" matTooltip="Schedule a call with the Cultured Supply team to verify company and product information. Your company must be verified to receive buying requests."></i>
                </div>
              </div>

              <div class="additional-card-info-container">
                <div class="type-label">{{ company.companyType?.substring(1, (company.companyType?.length || 1) - 1) }}</div>
                <div class="empty-cell"></div>
                <div class="description-column">
                  <div class="description">
                    {{ company?.companyDescription | cutTextIfItGreaterThenLimit: 6 }}
                  </div>
                  <button class="more-info-button button is-success has-text-weight-bold"
                          (click)="navigateToUrl(company.companyAlias)">
                    More Info
                  </button>
                </div>
                <div class="info-column">
                  <div class="info-column-content-wrapper">
                    <div>
                      <i class="fa fa-flask"></i>
                      <label *ngIf="!!company.companyIndustryFocus"> {{company.companyIndustryFocus | transformArrayToStringPipe}}</label>
                      <label *ngIf="!company.companyIndustryFocus">&nbsp;&nbsp;&#8212;</label>
                    </div>
                    <div>
                      <i class="fa fa-globe"></i>
                      <label *ngIf="!!company.companyRegion"> {{company.companyRegion}}</label>
                      <label *ngIf="!company.companyRegion">&nbsp;&nbsp;&#8212;</label>
                    </div>
                    <div>
                      <i class="fa fa-location-arrow"></i>
                      <label *ngIf="!!company.companyCountryState"> {{company.companyCountryState}}</label>
                      <label *ngIf="!company.companyCountryState">&nbsp;&nbsp;&#8212;</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <mat-paginator #paginator
                       class="demo-paginator"
                       (page)="handleCompanyPageEvent($event)"
                       [length]="companiesSortedList?.length || 0"
                       [pageSize]="20"
                       [disabled]="false"
                       [showFirstLastButtons]="true"
                       [pageSizeOptions]="[]"
                       [hidePageSize]="false"
                       [pageIndex]="companyPageIndex"
                       aria-label="Select page">
        </mat-paginator>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          Products
          <div class="badge" *ngIf="!!productsSortedList">{{productsSortedList.length}}</div>
        </ng-template>
        <div *ngIf="!!productsSortedList">
          <div class="cs-card is-flex is-justify-content-space-between" *ngFor="let product of getCurrentPage(productsSortedList, productPageIndex)">
            <div class="card-content is-flex-grow-1">
              <div class="card-top-line is-flex is-justify-content-space-between">
                <div class="card-heading has-text-success is-size-3">
                  {{product.productName}}
                  <span class="tag verified-label ml-3 mb-4" *ngIf="product.isProductVerified">
                        Verified
                      </span>
                </div>
                <div class="is-flex" *ngIf="!product.isProductVerified">
                  <a class="booking-link" href="{{hubSpotMeetingSchedulingLink}}" target="_blank">
                    Is this your product? Verify information
                  </a>
                  <i class="fa fa-question-circle" matTooltipPosition="above" matTooltip="Schedule a call with the Cultured Supply team to verify company and product information. Your company must be verified to receive buying requests."></i>
                </div>
              </div>
              <div class="additional-card-info-container">
                <div class="type-label">by {{product.companyName}}</div>
                <div class="empty-cell"></div>
                <div class="description-column">
                  <div class="description">
                    <div class="recommended-use">
                      Recommended: {{ product.productRecommendedUse | cutTextIfItGreaterThenLimit: 10 }}
                    </div>
                    <div>
                      {{ product?.productDescription | cutTextIfItGreaterThenLimit: 6 }}
                    </div>
                  </div>
                  <button class="more-info-button button is-success has-text-weight-bold"
                          (click)="navigateToUrl(product.companyAlias, product.productAlias)">
                    More Info
                  </button>
                </div>
                <div class="info-column">
                  <div class="info-column-content-wrapper">
                    <div>
                      <i class="fa fa-flask"></i>
                      <label *ngIf="!!product.productIndustryFocus"> {{product.productIndustryFocus | transformArrayToStringPipe}}</label>
                      <label *ngIf="!product.productIndustryFocus">&nbsp;&nbsp;&#8212;</label>
                    </div>
                    <div>
                      <i class="fa fa-clipboard"></i>
                      <label *ngIf="!!product.productType"> {{ product.productType | toCamelCase }}</label>
                      <label *ngIf="!product.productType">&nbsp;&nbsp;&#8212;</label>
                    </div>
                    <div>
                      <i class="fa fa-list-ul"></i>
                      <label *ngIf="!!product.developmentStage"> {{ product.developmentStage | toCamelCase }}</label>
                      <label *ngIf="!product.developmentStage">&nbsp;&nbsp;&#8212;</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <mat-paginator #paginator
                       class="demo-paginator"
                       (page)="handleProductPageEvent($event)"
                       [length]="productsSortedList?.length || 0"
                       [pageSize]="20"
                       [disabled]="false"
                       [showFirstLastButtons]="true"
                       [pageSizeOptions]="[]"
                       [hidePageSize]="false"
                       [pageIndex]="productPageIndex"
                       aria-label="Select page">
        </mat-paginator>
      </mat-tab>
    </mat-tab-group>
  </div>
</section>

<div class="to-top" (click)="scrollToTop()" [ngClass]="{ 'show-button': windowScrolled }">
  <div class="button-shapes">
    <i class="fa fa-chevron-up"></i>
  </div>
</div>

<div class="feedback" (click)="showFeedbackModal()" [ngClass]="{ 'show-button': true }">
  <div class="button-shapes">
    <div class="content">
      Feedback
    </div>
  </div>
</div>

