/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Campaign } from './campaign';
import { CampaignState } from './campaign-state';
import { DictionaryValue } from './dictionary-value';
import { ProductHeadingInfo } from './product-heading-info';
import { ImageFileReference } from './image-file-reference';
import { ProductGeneralInfo } from './product-general-info';
import { ProductResponse } from './product-response';
import { ProductContactPerson } from './product-contact-person';
import { UnitScalingEntity } from './unit-scaling-entity';
import { ShippingCostInterval } from './shipping-cost-interval';
import { ProductPricingInfo } from './product-pricing-info';


/**
 * @type SampleRequestOrderedEntity
 * @export
 */
export type SampleRequestOrderedEntity = Campaign | ProductResponse;

