import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DictionariesApiService, DictionaryValue} from '../../../api/cs';
import {LoaderService} from '../../../shared/services/loader.service';
import {finalize} from 'rxjs';

@Component({
  selector: 'cs-dictionary-item-form',
  templateUrl: './dictionary-item-form.component.html',
  styleUrls: ['./dictionary-item-form.component.scss']
})
export class DictionaryItemFormComponent implements OnInit {

  form: FormGroup;

  private _isModalActive = false;

  @Output()
  updated = new EventEmitter<boolean>();

  mode: 'EDIT' | 'CREATE';

  private item: DictionaryValue;
  private dictionaryName: string;
  private requiredFields: string[] = ["key", "value"];

  constructor(private fb: FormBuilder,
              private loader: LoaderService,
              private dictionariesApiService: DictionariesApiService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      dictName: [],
      key: new FormControl(null, [Validators.required, Validators.pattern('[a-zA-Z0-9_]*')]),
      value: new FormControl(null, Validators.required),
      description: [],
      retired: []
    });
  }

  get isModalActive(): boolean {
    return this._isModalActive;
  }

  set isModalActive(value: boolean) {
    this._isModalActive = value;
  }

  isValueInvalid(): boolean {
    return !this.form.get('value')!.valid && this.form.get('value')!.touched;
  }

  closeWindow(): void {
    this.isModalActive = false;
  }

  showWindow(dictionaryName: string, mode: 'EDIT' | 'CREATE', item?: DictionaryValue): void {
    this.mode = mode;
    this.dictionaryName = dictionaryName;
    this.form.reset();

    if (item) {
      this.form.get('key')?.setValue(item.key);
      this.form.get('value')?.setValue(item.value);
      this.form.get('description')?.setValue(item.description);
      this.form.get('retired')?.setValue(item.retired);

      this.item = item;
    }

    this.form.get('dictName')?.setValue(dictionaryName);
    this.form.get('dictName')?.disable();

    if (mode === 'EDIT') {
      this.form.get('key')?.disable();
    } else {
      this.form.get('key')?.enable();
    }

    this.isModalActive = true;
  }

  submit(): void {
    for(let field of this.requiredFields) {
      this.form.get(field)!.markAsTouched();
    } //marks all required fields as "touched" to activate css style to invalid fields
    let inputs = Array.from(document
      .getElementById("new-dictionary-item-modal-card")!
      .querySelectorAll('input')); //collects all input fields in modal card into array
    for (let input of inputs) {
      if (input.getAttribute("class")!.includes("invalid")) {
        return; //stops method if one of fields is invalid
      }
    }

    this.loader.show();

    const {description, value, retired} = this.form.value;

    const request = this.mode === 'EDIT'
      ? this.dictionariesApiService.updateDictionaryItem({
          key: this.item.key || '',
          dict: this.dictionaryName,
          dictionaryValueUpdateRequest: {
            value,
            description,
            retired
          }
        })
      : this.dictionariesApiService.createDictionaryItem({
        dict: this.dictionaryName,
        dictionaryValueRequest: {
          value,
          key: value.toUpperCase().split(" ").join("_"),
          description
        }
      });

    request.pipe(finalize(() => this.loader.hide()))
      .subscribe(() => {
        this.updated.emit(true);
        this.closeWindow();
      });
    }
}
