import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ProductResponse, ProductsApiService, LocationApiService} from '../../../../api/cs';
import {LoaderService} from '../../../../shared/services/loader.service';
import {finalize} from 'rxjs';
import {GoogleMapsService} from "../../../../shared/services/google-maps.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  ConfirmationWindowComponent
} from "../../../../core/components/confirmation-window/confirmation-window.component";

@Component({
  selector: 'cs-product-address-form',
  templateUrl: './product-address-form.component.html',
  styleUrls: ['./product-address-form.component.scss']
})
export class ProductAddressFormComponent implements OnInit {

  form: FormGroup;

  private product: ProductResponse;
  private addressId: string | undefined;
  public predictions: string[];
  public formIdentifier: string;

  @ViewChild('address') addressInputElement!: ElementRef<HTMLInputElement>;

  @Output()
  updated = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder,
              private loader: LoaderService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ProductAddressFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {product: ProductResponse, addressId: string | undefined},
              private productsApiService: ProductsApiService,
              private locationApiService: LocationApiService,
              private googleMapsService: GoogleMapsService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.addressId = this.data.addressId;
    this.product = this.data.product;
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
    if (this.addressId) {
      this.formIdentifier = 'Edit';
      this.productsApiService.getProductAddressSearchResult({
        productId: this.product.id
      }).subscribe((res) => {
        const data = res.items?.filter((item) => item.id == this.addressId);
        this.form.get('address')?.setValue(data?.values().next().value.address);
        this.form.get('location')?.setValue(data?.values().next().value.location);
      })
    } else {
      this.form.get('address')?.setValue('');
      this.form.get('location')?.setValue('');
      this.formIdentifier = "Add";
    }
    this.googleMapsService.assumed.subscribe(assumption => this.form.get('location')?.setValue(assumption));
    this.googleMapsService.predicted.subscribe((predictions) => this.predictions = predictions);
  }

  private initForm(): void {
    this.form = this.fb.group({
      address: [],
      location: []
    });
  }

  confirmClose(): void {
    let confirmation = this.dialog.open(ConfirmationWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        header: "Window Close Confirmation",
        description: "discard changes and close the window"}
    });
    confirmation.beforeClosed().subscribe(() => {
      if (confirmation.componentInstance.confirmed.getValue()) {
        this.dialogRef.close();
      }
    })
  }

  assume(): void {
    const {address} = this.form.value;
    if (address) {
      this.googleMapsService.assume(this.product.id || '', address, true);
    }
    this.predictions = [];
  }

  predict(address: string): void {
    if (this.form.get('address')?.dirty) {
      if (address) {
        this.googleMapsService.predict(this.product.id || '', address);
      }
    }
  }

  insert(prediction: string): void {
    if (this.form.get('address')?.value != prediction) {
      this.form.get('address')?.setValue(prediction);
    } else {
      this.predictions = [];
    }
    this.addressInputElement.nativeElement.focus();
  }

  submit(): void {
    this.loader.show();
    const {address, location} = this.form.value;
    if (this.addressId) {
      this.productsApiService.updateProductAddress({
        id: this.addressId,
        productAddressUpdateRequest: {
          address,
          location
        }
      }).pipe(finalize(() => this.loader.setVisible(false)))
        .subscribe(() => {
          this.updated.emit(true);
          this.dialogRef.close();
        });
    } else {
      this.productsApiService.createProductAddress({
        productAddressRequest: {
          product_id: this.product.id,
          address,
          location
        }
      }).pipe(finalize(() => this.loader.setVisible(false)))
        .subscribe(() => {
          this.updated.emit(true);
          this.dialogRef.close();
        });
    }
  }
}
