<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">{{data.headerText}}</p>
    <span class="icon cs-icon-button" aria-label="close" mat-dialog-close>
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>

  <section class="modal-content" mat-dialog-content>
    <quill-view [content]="data.mainText"></quill-view>
  </section>

  <div class="field p-1">
    <div class="control">
      <a class="button wide is-medium is-success has-text-weight-bold is-fullwidth"
         mat-dialog-close
      >
        Close
      </a>
    </div>
  </div>
</div>



