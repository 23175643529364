import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {
  Campaign,
  CampaignsApiService,
  CampaignSubmission,
  CreateSubmissionRequest,
  SubmissionState,
  UsersApiService
} from "../../../api/cs";
import {BehaviorSubject, finalize, Observable} from "rxjs";
import {LoaderService} from "../../../shared/services/loader.service";
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../../shared/services/auth.service";
import {UnitsScalingService} from "../../../shared/services/units-scaling.service";
import {Title} from "@angular/platform-browser";

const PurchaseTabs = <const> ['Order Info', 'Company Info', 'Confirm Details', 'Purchase Order', 'Payment'];
type PurchaseTab = typeof PurchaseTabs[number];

@Component({
  selector: 'cs-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

  Tabs = PurchaseTabs;
  selectedTab: PurchaseTab = 'Order Info';
  selectedTabIndex: number;

  campaignAlias: string;
  submissionId: number;
  campaign: Campaign;
  campaign$: Observable<Campaign>;
  campaignPreviewImage: string;

  submissionRequest: CreateSubmissionRequest;
  submissionRequest$: BehaviorSubject<CreateSubmissionRequest>;

  signature: any;
  submissionState: SubmissionState;
  state = SubmissionState;

  constructor(private activatedRoute: ActivatedRoute,
              private loader: LoaderService,
              private titleService: Title,
              private authService: AuthService,
              private usersApiService: UsersApiService,
              private unitsScalingService: UnitsScalingService,
              private campaignsApiService: CampaignsApiService) { }

  ngOnInit(): void {
    this.titleService.setTitle("Purchase | Cultured Supply");
    this.activatedRoute.params.subscribe((params: Params) => {
      this.campaignAlias = params['campaign-alias'];
      this.submissionId = params['submission-id'];
      this.load();
    });

  }

  load(): void {
    if (!!this.campaignAlias && !this.submissionId) {
      this.loader.show();

      this.campaign$ = this.campaignsApiService.getCampaign({
        id: this.campaignAlias,
      });

      this.selectedTabIndex = PurchaseTabs.indexOf(this.selectedTab);
      this.submissionRequest$ = new BehaviorSubject<CreateSubmissionRequest>(PurchaseComponent.createNewSubmission());
      if (this.authService.isLoggedIn && !!this.authService.userInfo) {
        let user = this.authService.userInfo.getValue();
        let request = this.submissionRequest$.getValue();
        request.creator = user || {};
        request.buyer = user || {};
        if (request.buyer.company === null) {
          request.buyer.company = { name: '' };
        }
        this.submissionRequest$.next(request);
        this.authService.userInfo?.subscribe(user => {
          let request = this.submissionRequest$.getValue();
          request.creator = user || {};
          this.submissionRequest$.next(request);
        })
      }

      this.campaign$
        .pipe(finalize(() => this.loader.hide()))
        .subscribe((res) => {
          this.campaign = res;
          this.campaignPreviewImage = (res.images && res.images.length > 0) ? (res?.images[0].reference || '') : '';
          let request = this.submissionRequest$.getValue();
          request.campaignAlias = res.alias || '';
          this.submissionRequest$.next(request);
        }
      );
    } else if (!!this.submissionId) {
      this.loader.show();

      this.campaignsApiService.getSubmissionById({
        id: this.submissionId
      }).subscribe((sub) => {
          if (sub.submissionState == 'USER_INFO_PROVIDED') {
            this.selectedTabIndex = 3;
            this.selectedTab = 'Purchase Order';
          } else if (sub.submissionState == "CONTRACT_SIGNED") {
            this.selectedTabIndex = 4;
            this.selectedTab = 'Payment';
          }
          this.submissionState = sub.submissionState;
          this.submissionRequest$ = new BehaviorSubject<CreateSubmissionRequest>(PurchaseComponent.reproduceSubmission(sub));
          this.campaign$ = this.campaignsApiService.getCampaign({
            id: this.submissionRequest$.getValue().campaignAlias,
          });
          this.campaign$
            .pipe(finalize(() => this.loader.hide()))
            .subscribe((res) => {
                this.campaign = res;
                this.campaignAlias = res.alias || '';
              }
            );
      });
    }
  }

  get baseUrl(): string {
    return environment.baseUrl;
  }

  nextTab(direction: 1 | -1 = 1) {
    window.scroll(0, 0); // dirty hack to prevent scroll issues. Maybe you can try to remove it later
    this.selectedTab = this.Tabs[this.selectedTabIndex + direction];
    this.selectedTabIndex = this.Tabs.indexOf(this.selectedTab);
  }

  isStepPassed(tab: PurchaseTab): boolean {
    return PurchaseTabs.indexOf(tab) < PurchaseTabs.indexOf(this.selectedTab);
  }

  private static createNewSubmission(): CreateSubmissionRequest {
    return {
      id: undefined,
      creator: {},
      buyer: {
        company: {
          name: ''
        }
      },
      campaignAlias: '',
      fullUpfrontPayment: true,
      orderAmount: undefined,
      orderMeasure: undefined,
      quantity: undefined,
      deliveryAddress: undefined
    }
  }

  private static reproduceSubmission(submission: CampaignSubmission): CreateSubmissionRequest {
    return {
      id: submission.id,
      creator: submission.creator,
      buyer: submission.buyerInfo || {},
      campaignAlias: submission.campaignAlias || '',
      fullUpfrontPayment: submission.fullUpfrontPayment,
      orderAmount: submission.orderAmount,
      orderMeasure: submission.orderMeasure?.id,
      quantity: submission.quantity,
      deliveryAddress: submission.deliveryAddress,
      shippingCost: submission.shippingCost
    }
  }

  contractSigned() {
    this.nextTab();
    this.submissionState = SubmissionState.CONTRACT_SIGNED;
  }
}
