<div class="modal" [class.is-active]="isModalActive">
  <div class="modal-background" (click)="closeWindow()"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered has-text-weight-bold">
        Edit Company R&D Capabilities
      </p>
      <span class="icon cs-icon-button" aria-label="close" (click)="closeWindow()">
        <i class="fa fa-lg fa-times"></i>
      </span>
    </header>
    <section class="modal-card-body pt-6">
      <div class="field pb-4">
        <label class="label">R&D Capabilities:</label>
        <quill-editor [(ngModel)]="rnd">
        </quill-editor>
      </div>
      <div class="field is-grouped is-grouped-centered mt-6">
        <div class="control py-4">
          <a class="button wide is-success has-text-weight-bold is-fullwidth"
             (click)="submit()"
          >
            Save
          </a>
        </div>
      </div>
    </section>
  </div>
</div>
