<div class="under-top-toolbar container ">
  <h2 class="title">{{isProfile ? 'Edit Profile' : 'Edit user'}}</h2>
  <div class="form-container">
    <cs-user-form [form]="form"
                  [passwordForm]="passwordForm"
                  [user]="user"
                  (updated)="updateUser($event)"
                  (passwordChanged)="changePassword($event)"
    ></cs-user-form>
  </div>
</div>
