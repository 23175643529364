<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">Write your signature</p>
    <span class="icon cs-icon-button" aria-label="close" mat-dialog-close>
        <i class="fa fa-lg fa-times"></i>
      </span>
  </header>
  <section class="modal-card-body">
    <div class="dialog-text">
      <button class="button wide has-text-weight-bold is-align-self-flex-end" (click)="clear()"> Clear</button>
    </div>
    <div mat-dialog-content class="content">
      <mat-tab-group  (selectedIndexChange)="selectTab($event)">
        <mat-tab label="Draw as name">
          <mat-radio-group [(ngModel)]="selectedSignature" class="variants">
            <mat-radio-button *ngFor="let signature of signatures" [value]="signature">
              <img [src]="signature" [height]="100" [width]="200">
            </mat-radio-button>
          </mat-radio-group>
        </mat-tab>
        <mat-tab label="Draw your signature">
          <div class="signature-pad-container">
            <signature-pad [options]="signaturePadOptions"></signature-pad>
          </div>
        </mat-tab>
      </mat-tab-group>
      <p class="small-text">By clicking Done, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on envelopes, including legally binding contracts - just the same as a pen-and-paper signature or initial.</p>
    </div>
    <div mat-dialog-actions class="buttons">
      <button class="button wide has-text-weight-bold is-align-self-flex-end" mat-dialog-close> Close</button>
      <button class="button wide is-success has-text-weight-bold" (click)="drawComplete()">Done</button>
    </div>
  </section>
</div>
