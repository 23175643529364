<div class="under-top-toolbar container" *ngIf="product">
  <div id="top-bar">
    <div id="bread-crumbs">
      <a *ngIf="isRoutedFromFeaturedList" (click)="routeBack()">Featured Products</a>
      <a routerLink="/algae" *ngIf="isSupplyChainBuilderUser">Supply Chain Builder</a>
      <span *ngIf="isSupplyChainBuilderUser || isRoutedFromFeaturedList">&nbsp;&nbsp;>&nbsp;&nbsp;</span>
      <xng-breadcrumb [separator]="'>'"></xng-breadcrumb>
    </div>
    <div id="actions-line">
      <a class="is-size-6" (click)="scrollToBlock(BLOCKS.INFO)">Product Info</a>
      <a class="is-size-6" (click)="scrollToBlock(BLOCKS.PRICING)">Pricing</a>
      <a class="is-size-6" (click)="scrollToBlock(BLOCKS.FILES)">Product Files</a>
      <button class=" button has-text-weight-bold is-success is-size-6"
         (click)="showBuyingRequestModal()">
        Buying request
      </button>
    </div>
  </div>
  <div class="product-info-container is-flex is-flex-grow-1">
    <div class="left">
      <div class="main_container">
        <div *ngIf="!!product.headingInfo.images?.length || !!product.headingInfo.videoLink" class="gallery-wrapper mb-5">
          <gallery [items]="mediaFiles"
                   [thumb]="mediaFiles.length > 1"
                   [itemTemplate]="itemTemplate"
                   [thumbTemplate]="thumbTemplate"
                   [thumbHeight]="60"
                   [thumbWidth]="60">
          </gallery>

          <ng-template #itemTemplate let-data="data" let-index="index">
            <lib-ngx-image-zoom
                    [thumbImage]=data.src
                    [fullImage]=data.src
                    [magnification]="1"
                    [enableScrollZoom]="true"
                    [enableLens]="false"
                    [lensWidth]="200"
                    [lensHeight]="200"
                    (click)="lightbox.open(index)"
            ></lib-ngx-image-zoom>
          </ng-template>

          <ng-template #thumbTemplate let-data="data">
            <div *ngIf="data.type == 'video'">
              <img class="video-icon" src="assets/images/icons/video-icon.png" alt="video">
            </div>
          </ng-template>
        </div>
        <div *ngIf="!product.headingInfo.images?.length && !product.headingInfo.videoLink"
             class="cs-card no-image">
        </div>
      </div>


      <div *ngIf="company" class="mt-5">
        <h2 class="is-size-5 has-text-weight-bold pb-3">
          Seller Information
        </h2>
        <div class="cs-card company-card fullwidth">
          <p class="is-size-6 has-text-weight-bold pb-1">
            {{company.name}}
          </p>
          <p class="is-size-7 has-text-weight-semibold is-size-6 pr-4 pb-1">
            {{company.brief}}
          </p>
          <a class="is-size-7 has-text-weight-semibold"
             [href]="['/companies/' + company.alias]"
          >
            Learn more >
          </a>
        </div>
        <h2 class="is-size-5 has-text-weight-bold py-3" *ngIf="showSupplyLocationsBlock">
          Supply Locations
          <i
            class="fa fa-plus-square pl-5 cs-icon-button is-size-5"
            *ngIf="isAdmin"
            (click)="showAddressEditModal(undefined)"
          ></i>
        </h2>
        <div class="cs-card company-card fullwidth"
             *ngIf="showSupplyLocations">
          <div class="is-flex is-justify-content-space-between" *ngFor="let address of productAddress">
            <p class="is-size-6 has-text-weight-bold pb-1">
              {{address.location}}
            </p>
            <div class="is-flex is-justify-content-end">
              <div *ngIf="isAdmin">
                <a class="has-text-grey-darker"
                   *ngIf="isAdmin"
                   (click)="showAddressEditModal(address.id)"
                >
                  <i
                    class="fa fa-pencil ml-5 cs-icon-button is-size-5"
                  ></i>
                </a>
              </div>
              <div *ngIf="isAdmin">
                <a class="has-text-grey-darker"
                   *ngIf="isAdmin"
                   (click)="showProductAddressDeleteConfirmationModal(address)"
                >
                  <i
                    class="fa fa-trash pl-5 cs-icon-button is-size-5"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <button class="button has-text-weight-bold is-success is-size-7"
                  [disabled]="!product.pricingInfo?.sampleCost || product.pricingInfo?.sampleCost == 0"
                  (click)="buySample()">
            Buy Sample >
          </button>
        </div>
      </div>
    </div>
    <div class="right is-flex-grow-2">
      <div class="is-flex is-justify-content-space-between">
        <div class="header-container is-flex is-justify-content-start">
          <h2 class="is-size-4 has-text-weight-bold mr-3">
            {{product.headingInfo.name}}
          </h2>
          <div>
            <span class="tag verified-label"
                     *ngIf="showVerified"
            >
              Verified
            </span>
            <i
              class="fa fa-pencil ml-5 cs-icon-button is-size-5"
              *ngIf="canEdit"
              (click)="showHeadingInfoModal()"
            ></i>
          </div>
        </div>
        <div>
          <a class="button wide is-medium is-success has-text-weight-bold is-fullwidth mt-2"
             *ngIf="showVerificationButton"
             (click)="showVerificationModal()"
          >
            {{productNewVerificationStatusDescription}}
          </a>
          <div id="admin-view-switch" class="field is-flex is-justify-content-end is-align-items-baseline" *ngIf="showAdminViewSwitch">
            <div class="control">
              <input type="checkbox"
                     name="adminView"
                     id="adminView"
                     class="switch is-success is-rtl"
                     (change)="adminViewService.changeAdminView()"
                     [checked]="isAdminView"
              >
              <label class="label" for="adminView">
                Admin View
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="platform-line is-flex is-align-items-center">
        <div *ngFor="let platform of product.headingInfo.chassis" class="is-flex">
          <p class="has-text-grey is-size-6 mr-1">
            {{ platform | toCamelCase }}
          </p>
          <img class="mr-2" src="{{platformImageService.getPlatformImage(platform)}}"
               *ngIf="platformImageService.isPlatformImageExists(platform)"
          >
        </div>
      </div>
      <div class="product-description-block mt-6 pb-5">
        <div class="mt-6 pb-5">
          <quill-view [content]="product.headingInfo?.description"></quill-view>
        </div>
        <div *ngIf="showDevelopmentStage">
          <p class="has-text-success has-text-weight-semibold">
            Stage of development:
          </p>
          <div class="development-stage-tabs tabs is-toggle mt-2">
            <ul>
              <li *ngFor="let stage of developmentStages"
                  [class.is-active]="stage.key === product.headingInfo?.developmentStage?.key"
              >
                <a class="has-text-grey-dark"
                   [class.has-text-weight-semibold]="stage.key === product.headingInfo?.developmentStage?.key"
                >
                  <span>
                    {{stage.value}}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <h2 class="is-size-5 has-text-weight-bold pt-5 pb-4" [id]="BLOCKS.INFO">
        Product Information
        <i
          class="fa fa-pencil pl-5 cs-icon-button"
          *ngIf="canEdit"
          (click)="showGeneralInfoModal()"
        ></i>
      </h2>
      <div class="is-flex is-align-items-center product-information-block pb-5">
        <div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showCustomerFocus">
            <span class="has-text-success is-size-6">
              Customer Focus:
            </span>
            <span class="is-size-6">
              {{ product?.generalInfo?.customerFocus | toCamelCase }}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showType">
            <span class="has-text-success is-size-6">
              Product Type:
            </span>
            <span class="is-size-6">
              {{ product?.generalInfo?.type | toCamelCase }}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showCategory">
            <span class="has-text-success is-size-6">
              Product Category:
            </span>
            <span class="is-size-6">
              {{ product?.generalInfo?.category | toCamelCase }}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showIndustryFocuses">
            <span class="has-text-success is-size-6">
              Industry:
            </span>
            <span class="is-size-6">
              {{getDictionaryValuesText(product.generalInfo?.industryFocuses)}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showProcessing">
            <span class="has-text-success is-size-6">
              Processing:
            </span>
            <span class="is-size-6">
              {{product?.generalInfo?.processing?.value}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showFormat">
            <span class="has-text-success is-size-6">
              Format:
            </span>
            <span class="is-size-6">
              {{getDictionaryValuesText(product.generalInfo?.format)}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showRecommendation">
            <span class="has-text-success is-size-6">
              Recommended Use:
            </span>
            <span class="is-size-6">
              {{product.generalInfo?.recommendation}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showProductReplacement">
            <span class="has-text-success is-size-6">
              Product Replacement:
            </span>
            <span class="is-size-6">
              {{product.generalInfo?.productReplacement}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showBioContent">
            <span class="has-text-success is-size-6">
              Bio-based Content:
            </span>
            <span class="is-size-6">
              {{product.generalInfo.bioBasedContentPart}}%
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showBioSources">
            <span class="has-text-success is-size-6">
              Bio Sources:
            </span>
            <span class="is-size-6">
              {{getDictionaryValuesText(product.generalInfo?.bioSources)}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showMaterialTypes">
            <span class="has-text-success is-size-6">
              Material Types:
            </span>
            <span class="is-size-6">
              {{getDictionaryValuesText(product.generalInfo?.materialTypes)}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showProperties">
            <span class="has-text-success is-size-6">
              Properties:
            </span>
            <span class="is-size-6">
              {{getDictionaryValuesText(product.generalInfo?.properties)}}
            </span>
          </div>
        </div>
      </div>
      <h2 class="is-size-5 has-text-weight-bold pt-5 pb-4" [id]="BLOCKS.PRICING">
        Availability and Pricing
        <i
          class="fa fa-pencil pl-5 cs-icon-button"
          *ngIf="canEdit"
          (click)="showPricingInfoModal()"
        ></i>
      </h2>
      <div class="is-flex is-align-items-center availability-pricing-block pb-5">
        <div class="left-column">
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showPricing">
            <span class="has-text-success is-size-6">
              Pricing:
            </span>
            <span class="is-size-6">
              {{product.pricingInfo?.pricing}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="product?.pricingInfo?.sampleCost">
            <span class="has-text-success is-size-6">
              Sample Cost:
            </span>
            <span class="is-size-6">
              {{product?.pricingInfo?.sampleCost | currency}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="product?.pricingInfo?.shippingCost">
            <span class="has-text-success is-size-6">
              Shipping Cost:
            </span>
            <span class="is-size-6">
              {{product?.pricingInfo?.shippingCost | currency}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showCapacity">
            <span class="has-text-success is-size-6">
              Production capacity:
            </span>
            <span class="is-size-6">
              {{product.pricingInfo?.capacity}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showPackaging">
            <span class="has-text-success is-size-6">
              Packaging:
            </span>
            <span class="is-size-6">
              {{product.pricingInfo?.packaging}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showShippingDestination">
            <span class="has-text-success is-size-6">
              Ships to:
            </span>
            <span class="is-size-6">
              {{product.pricingInfo?.shippingDestination}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="showLeadTime">
            <span class="has-text-success is-size-6">
              Lead time:
            </span>
            <span class="is-size-6">
              {{product.pricingInfo?.leadTime}}
            </span>
          </div>
          <div class="info-tag has-text-weight-semibold mb-4" *ngIf="product?.pricingInfo?.sampleDescription">
            <span class="has-text-success is-size-6">
              Sample Description:
            </span>
            <span class="is-size-6">
              {{product?.pricingInfo?.sampleDescription}}
            </span>
          </div>
        </div>
      </div>
      <h2 class="is-size-5 has-text-weight-bold pt-5 pb-4" [id]="BLOCKS.FILES">
        Certifications
        <a class="has-text-grey-darker"
           *ngIf="canEdit"
           (click)="showCertificationCreateModal()"
        >
          <i
            class="fa fa-plus-square pl-5 cs-icon-button is-size-5"
          ></i>
        </a>
      </h2>
      <div class="is-flex is-align-items-center certifications-block pb-5">
        <div>
          <div class="mb-4">
            <div class="certifications-table mt-1 mb-2" *ngIf="certifications?.length">
              <div class="row">
                <div class="has-text-weight-bold">Name</div>
                <div class="has-text-weight-bold">File</div>
              </div>
              <div class="row" *ngFor="let cert of certifications">
                <div>{{cert.name}}</div>
                <div *ngIf="cert.document != null">
                  <a (click)="downloadFile(cert.document)">
                    View/Download
                  </a>
                </div>
                <div *ngIf="cert.document == null">
                  <p>
                    &#8212;
                  </p>
                </div>
                <div *ngIf="canEdit">
                  <a class="has-text-grey-darker"
                     *ngIf="canEdit"
                     (click)="showCertificationDeleteConfirmationModal(cert)"
                  >
                    <i
                      class="fa fa-trash pl-5 cs-icon-button is-size-5"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="" *ngIf="certifications && !certifications.length">
              There are no certifications
            </div>
          </div>
        </div>
      </div>
      <h2 class="is-size-5 has-text-weight-bold pt-5 pb-4">
        Product Files
      </h2>
      <div class="is-flex is-align-items-center product-files-block pb-5">
        <div class="left-column">
          <div class="file-line has-text-weight-semibold">
            <div class="pb-4">
              <i
                class="fa fa-pencil-square-o"
                aria-hidden="true"
              ></i>
              Product Spec Sheet
            </div>
            <div *ngIf="product.specSheet">
              <a class="has-text-success pl-5 pr-5"
                 (click)="downloadFile(product.specSheet, 'PSS_' + product.headingInfo.name)"
               >
                View/Download
              </a>
              <a class="has-text-grey-darker"
                 *ngIf="canEdit"
                 (click)="showFileDeleteConfirmationModal('Product Spec Sheet')"
              >
                <i
                  class="fa fa-trash pl-5 cs-icon-button is-size-5"
                ></i>
              </a>
            </div>
            <div *ngIf="!product.specSheet" class="is-flex is-align-items-center ml-4">
              <span>
                —
              </span>
              <a class="has-text-grey-darker"
                 *ngIf="canEdit"
                 (click)="showFileAddingModal('PSS')"
              >
                <i
                  class="fa fa-plus-square pl-5 cs-icon-button is-size-5"
                ></i>
              </a>
            </div>
          </div>
          <div class="file-line has-text-weight-semibold">
            <div class="pb-4">
              <i
                class="fa fa-shield"
                aria-hidden="true"
              ></i>
              Safety Data Sheet (SDS)
            </div>
            <div *ngIf="product.safetyDataSheet">
              <a class="has-text-success pl-5 pr-5"
                 (click)="downloadFile(product.safetyDataSheet, 'SDS_' + product.headingInfo.name)"
              >
                View/Download
              </a>
              <a class="has-text-grey-darker"
                 *ngIf="canEdit"
                 (click)="showFileDeleteConfirmationModal('Safety Data Sheet')"
              >
                <i
                  class="fa fa-trash pl-5 cs-icon-button is-size-5"
                ></i>
              </a>
            </div>
            <div *ngIf="!product.safetyDataSheet" class="is-flex is-align-items-center ml-4">
              <span>
                —
              </span>
              <a class="has-text-grey-darker"
                 *ngIf="canEdit"
                 (click)="showFileAddingModal('SDS')"
              >
                <i
                  class="fa fa-plus-square pl-5 cs-icon-button is-size-5"
                ></i>
              </a>
            </div>
          </div>
          <div class="file-line has-text-weight-semibold">
            <div class="pb-4">
              <i
                class="fa fa-life-ring"
                aria-hidden="true"
              ></i>
              Lifecycle Analysis (LCA)
            </div>
            <div *ngIf="product.lcaSheet">
              <a class="has-text-success pl-5 pr-5"
                 (click)="downloadFile(product.lcaSheet, 'LCA_' + product.headingInfo.name)"
              >
                View/Download
              </a>
              <a class="has-text-grey-darker"
                 *ngIf="canEdit"
                 (click)="showFileDeleteConfirmationModal('Lifecycle Analysis')"
              >
                <i
                  class="fa fa-trash pl-5 cs-icon-button is-size-5"
                ></i>
              </a>
            </div>
            <div *ngIf="!product.lcaSheet" class="is-flex is-align-items-center ml-4">
              <span>—</span>
              <a class="has-text-grey-darker"
                 *ngIf="canEdit"
                 (click)="showFileAddingModal('LCA')"
              >
                <i
                  class="fa fa-plus-square pl-5 cs-icon-button is-size-5"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <h2 class="is-size-5 has-text-weight-bold pt-5 pb-4">
        Ready to Buy?
      </h2>
      <p class="pb-3">
        If you are interested in purchasing this product, submit a buying request below.
      </p>
      <button class="button button-send is-medium wide has-text-weight-bold is-success"
         (click)="showBuyingRequestModal()">
        Send Buying Request
      </button>
    </div>
  </div>
</div>
