<div class="under-top-toolbar container  is-flex is-flex-direction-column" *ngIf="(campaign$ | async) && (submissionRequest$ | async)">
  <section class="header is-flex is-justify-content-space-between is-align-items-center">
    <div class="header__info is-flex  is-align-items-center">
      <div class="header__info__title">
        <div class="title-type has-text-weight-bold">CAMPAIGN</div>
        <h2 class="title-text is-size-4 has-text-weight-bold">{{campaign?.title}} - {{campaign?.subtitle}}</h2>
      </div>
    </div>
    <div class="header__button">
      <a class="button" type="button" target="_blank" [href]="['/companies/' + campaign?.companyAlias + '/campaigns/' + campaignAlias]">Details</a>
    </div>
  </section>
  <section class="main">
    <div class="main__steps">
      <ul class="is-flex">
        <li *ngFor="let tab of Tabs" class="is-size-5" [ngClass]="{'is-active has-text-success': tab === selectedTab, 'step-passed': isStepPassed(tab)}">
          <div>{{ tab }}</div>
        </li>
      </ul>
    </div>
    <div class="main__step">
      <cs-step-order-info *ngIf="selectedTab === 'Order Info'"
                          [nextStep]="Tabs[selectedTabIndex + 1]"
                          (order)="nextTab()"
                          [campaign$]="campaign$"
                          [submissionRequest$]="submissionRequest$"
      >
      </cs-step-order-info>
      <cs-step-buyer-info *ngIf="selectedTab === 'Company Info'"
                          [nextStep]="Tabs[selectedTabIndex + 1]"
                          (prev)="nextTab(-1)"
                          (buyer)="nextTab()"
                          [campaign]="campaign"
                          [submissionRequest$]="submissionRequest$"
      >
      </cs-step-buyer-info>
      <cs-step-confirmation *ngIf="selectedTab === 'Confirm Details'"
                            [nextStep]="Tabs[selectedTabIndex + 1]"
                            (prev)="nextTab(-1)"
                            (confirmation)="nextTab()"
                            [campaign]="campaign"
                            [submissionRequest$]="submissionRequest$"
                            [isPreviousStepAvailable]="submissionState !== state.CONTRACT_SIGNED"
      >
      </cs-step-confirmation>
      <cs-step-contract *ngIf="selectedTab === 'Purchase Order'"
                        (prev)="nextTab(-1)"
                        (contract)="contractSigned()"
                        [nextStep]="Tabs[selectedTabIndex + 1]"
                        [submissionRequest$]="submissionRequest$"
                        [campaign]="campaign"
                        (signatureEvent)="signature = $event"
                        [signature]="signature"
                        [isContractSigned]="submissionState === state.CONTRACT_SIGNED"
      >
      </cs-step-contract>
      <cs-step-payment *ngIf="selectedTab === 'Payment'"
                       (prev)="nextTab(-1)"
                       [campaign]="campaign"
                       [submissionRequest$]="submissionRequest$"
      ></cs-step-payment>
    </div>
  </section>
</div>
