import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../api/cs';

@Injectable({
  providedIn: 'root'
})
export class UserFormService {

  constructor(private fb: FormBuilder) { }

  buildForm(user?: User | null): FormGroup {
    return this.fb.group({
      email: [user?.email],
      phone: [user?.phone],
      firstName: [user?.firstName],
      lastName: [user?.lastName],
      title: [user?.title],
      bio: [user?.bio],
    });
  }

  buildPasswordForm(): FormGroup {
    return this.fb.group({
      // current: ['', Validators.required],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    }, {
      validator: ConfirmedValidator('password', 'confirm_password')
    });
  }
}

export function ConfirmedValidator(controlName: string, matchingControlName: string){
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
