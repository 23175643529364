import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ToastService} from "../../../shared/services/toast.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../../shared/services/loader.service";
import {FormNames, HubSpotFormsService} from "../../../shared/services/hubspot-forms.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'cs-sign-up-supplier',
  templateUrl: './sign-up-supplier.component.html',
  styleUrls: ['./sign-up-supplier.component.scss']
})
export class SignUpSupplierComponent implements OnInit {

  forSupplierForm: FormGroup;

  goals: string[] = [
    'Sell my product',
    'Get financing',
    'Both'
  ]

  constructor(private fb: FormBuilder,
              private loader: LoaderService,
              private router: Router,
              private titleService: Title,
              private toast: ToastService,
              private hubSpotFormsService: HubSpotFormsService) {
  }

  ngOnInit(): void {
    this.titleService.setTitle("For Suppliers | Cultured Supply");
    this.forSupplierForm = this.fb.group({
      companyName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      phone: new FormControl('', Validators.required),
      goal: new FormControl(this.goals[0], Validators.required),
      addInfo: new FormControl('')
    });
  }

  onSubmit(form: FormGroup): void {
    this.loader.show();
    this.hubSpotFormsService.sendFormRequest(FormNames.FOR_SUPPLIERS,
      'For Suppliers',
      this.router.url,
      [
        form.value.companyName,
        form.value.firstName,
        form.value.lastName,
        form.value.email,
        form.value.phone,
        form.value.goal,
        form.value.addInfo
    ]).subscribe(() => {
      form.reset();
      this.loader.hide();
      this.router.navigate(['/']).then();
      this.toast.success("Your request sent");
    });
  }
}




