import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductsListComponent} from './components/products-list/products-list.component';
import {ProductComponent} from './components/product/product.component';
import {SharedModule} from '../shared/shared.module';
import {ProductInfoFormComponent} from './components/product/product-info-form/product-info-form.component';
import {ProductAvailabilityPricingFormComponent} from './components/product/product-availability-pricing-form/product-availability-pricing-form.component';
import {ProductHeadingFormComponent} from './components/product/product-heading-form/product-heading-form.component';
import {ProductFormComponent} from './components/product-form/product-form.component';
import {ProductCreateComponent} from './components/product-create/product-create.component';
import {ProductCertificationsFormComponent} from './components/product/product-certifications-form/product-certifications-form.component';
import {ProductFileFormComponent} from './components/product/product-file-form/product-file-form.component';
import {ProductAddressFormComponent} from "./components/product/product-address-form/product-address-form.component";
import {ProductConfirmationFormComponent} from './components/product/product-confirmation-form/product-confirmation-form.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {BreadcrumbModule} from "xng-breadcrumb";
import {ProductsRoutingModule} from "./products-routing.module";
import {ProductsGridItemComponent} from "./components/products-list/product-grid-item/product-grid-item.component";
import {ProductsBarItemComponent} from "./components/products-list/product-bar-item/product-bar-item.component";
import {MatSelectModule} from "@angular/material/select";
import {MatTabsModule} from "@angular/material/tabs";
import {ProductActionsComponent} from "./components/products-list/product-actions-component/product-actions.component";
import {BuyingRequestModalComponent} from "./components/buying-request-modal/buying-request-modal.component";
import {ProductQuickStatusChangeModalComponent} from "./components/product/product-quick-status-change-modal/product-quick-status-change-modal.component";
import {FeaturedProductsShortListComponent} from "./components/featured-products/products-short-list/featured-products-short-list.component";
import {
  FeaturedProductActionsComponent
} from "./components/featured-products/products-short-list/featured-product-actions-component/featured-product-actions.component";
import {
  FeaturedProductsBarItemComponent
} from "./components/featured-products/products-short-list/featured-product-bar-item/featured-product-bar-item.component";
import {FeaturedProductsComponent} from "./components/featured-products/featured-products.component";
import {ListEditModalComponent} from "./components/featured-products/list-edit-modal/list-edit-modal.component";
import {
  ListProductsActionModalComponent
} from "./components/featured-products/list-products-action-modal/list-products-action-modal.component";
import {QuillModule} from "ngx-quill";
import {GALLERY_CONFIG, GalleryConfig, GalleryModule} from "ng-gallery";
import {LIGHTBOX_CONFIG, LightboxConfig, LightboxModule} from "ng-gallery/lightbox";
import {NgxImageZoomModule} from "ngx-image-zoom";


@NgModule({
  declarations: [
    ProductComponent,
    ProductsListComponent,
    FeaturedProductsShortListComponent,
    BuyingRequestModalComponent,
    ProductInfoFormComponent,
    ProductAvailabilityPricingFormComponent,
    ProductHeadingFormComponent,
    ProductFormComponent,
    ProductCreateComponent,
    ProductCertificationsFormComponent,
    ProductFileFormComponent,
    ProductAddressFormComponent,
    ProductConfirmationFormComponent,
    ProductsGridItemComponent,
    ProductsBarItemComponent,
    ProductActionsComponent,
    ProductQuickStatusChangeModalComponent,
    FeaturedProductsBarItemComponent,
    FeaturedProductActionsComponent,
    FeaturedProductsComponent,
    ListEditModalComponent,
    ListProductsActionModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatAutocompleteModule,
    ProductsRoutingModule,
    BreadcrumbModule,
    MatSelectModule,
    MatTabsModule,
    QuillModule,
    GalleryModule,
    LightboxModule,
    NgxImageZoomModule
  ],
  exports: [
    ProductsRoutingModule
  ],
  providers: [
    {
      provide: GALLERY_CONFIG,
      useValue: {
        autoHeight: true,
        imageSize: 'contain',
        thumbView: 'contain',
        thumbPosition: 'left',
        thumbMode: 'free',
        slidingDirection: "vertical",
        panSensitivity: 1,
        counter: false,
        dots: false,
        nav: false
      } as GalleryConfig
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: false,
        exitAnimationTime: 1000
      } as LightboxConfig
    }
  ]
})
export class ProductsModule {
}
