<section class="table-container">
  <table matSort (matSortChange)="sortData($event)" class="mat-table">
    <thead>
      <tr class="mat-table-header">
        <th *ngFor="let valueName of valueNames"
            class="mat-table-header-cell"
            [class]="'column_' + valueName"
            mat-sort-header="{{valueName}}">
          {{getColumnName(valueName)}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of sortedData" class="mat-table-row">
        <td *ngFor="let valueName of valueNames" class="mat-table-cell">
          <a *ngIf="!!user[valueName]?.link" [routerLink]="[user[valueName]?.link]">{{user[valueName].value}}</a>
          <p *ngIf="!user[valueName]?.link">{{user[valueName].value}}</p>
        </td>
      </tr>
    </tbody>
  </table>
</section>
