import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CompaniesApiService, User, UsersApiService} from '../../../api/cs';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../shared/services/auth.service';
import {LoaderService} from '../../../shared/services/loader.service';
import {finalize} from 'rxjs';
import {
  EditCompanyMainInfoFormComponent
} from "../../../companies/components/profile/edit-company-main-info-form/edit-company-main-info-form.component";
import {MatDialog} from "@angular/material/dialog";
import {AdminViewService} from "../../../shared/services/admin-view.service";
import {BreadcrumbService} from "xng-breadcrumb";
import {formatDate} from "@angular/common";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'cs-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  private userId: string;
  isAdmin: boolean;
  user: User | null;
  isProfile: boolean;
  s3DefaultApi = environment.s3DefaultApi;
  s3BucketName = environment.s3BucketName;

  constructor(@Inject(LOCALE_ID) private locale: string,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private adminViewService: AdminViewService,
              private dialog: MatDialog,
              private companiesApiService: CompaniesApiService,
              private loader: LoaderService,
              private titleService: Title,
              private usersApiService: UsersApiService,
              private breadcrumbService: BreadcrumbService,
              public router: Router) { }

  ngOnInit(): void {
    this.titleService.setTitle("My Profile | Cultured Supply");
    if  (this.activatedRoute.snapshot.data['currentProfile']) {
      this.userId = 'me';
      this.isProfile = true;
      this.load();
    }else {
      this.activatedRoute.params.subscribe((params: Params) => {
        this.userId = params['id'];
        this.load();
      });
    }
  }

  load(): void {
    this.loader.show();
    this.setAdmin();
    if (this.userId == 'me' && !!this.authService.userInfo) {
      this.authService.userInfo.subscribe(user => {
        this.user = user;
        this.breadcrumbService.set('/profile', 'My Profile');
        this.loader.hide();
      });
    } else {
      this.usersApiService.getUser({
        id: this.userId
      }).pipe(finalize(() => this.loader.hide()))
        .subscribe((res) => {
          this.user = res;
          this.breadcrumbService.set('administration/users/:id', this.user.firstName + " " + this.user.lastName);
        });
    }
  }

  get baseUrl(): string {
    return environment.baseUrl;
  }

  setAdmin(): void {
    this.isAdmin = this.authService.isAdmin();
  }

  transformDate(timestamp: string | undefined): string {
    return formatDate(Number.parseFloat(timestamp || '') * 1000, 'MM-dd-YYYY, hh:mma', this.locale);
  }

  showMainInfoEditWindow() {
    let matDialogRef = this.dialog.open(EditCompanyMainInfoFormComponent, {panelClass: 'custom-dialog-container',
      data: {companyId: this.user?.company?.id}});
    matDialogRef
      .afterOpened()
      .subscribe(() => this.dialog.afterOpened.subscribe(d => d.componentInstance.updated.subscribe(() => this.adminViewService.changeAdminView())));
    matDialogRef
      .afterClosed()
      .subscribe(() => this.load());
  }
}
