import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {ReactiveFormsModule} from "@angular/forms";
import {MatGridListModule} from "@angular/material/grid-list";
import {SharedModule} from "../shared/shared.module";
import {NewHomeComponent} from "./new-home/new-home.component";


@NgModule({
  declarations: [
    NewHomeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatGridListModule,
    SharedModule,
  ]
})
export class HomeModule { }
