<div class="under-top-toolbar container ">
  <div class="products-header is-flex is-justify-content-space-between mb-2">
    <div class="page-title">Featured Products Lists</div>
  </div>
  <div class="b-table">
    <div class="table-wrapper has-mobile-cards">
      <table class="table">
        <thead>
          <tr>
            <th class="name-column">Name</th>
            <th class="amount-column">Product Amount</th>
            <th class="form-column">Hubspot Form</th>
            <th class="action-column">Actions</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let featuredProductList of list$.getValue()?.featuredProductsLists">
              <td><a href="products/featured/{{featuredProductList.name}}">{{featuredProductList.name}}</a></td>
              <td>
                <div>
                  {{featuredProductList.products?.total}}
                  <i class="fa fa-plus" (click)="showProductAddModal(featuredProductList)"></i>
                  <i class="fa fa-minus" (click)="showProductDeleteModal(featuredProductList)"></i>
                </div>
              </td>
              <td><button class="button is-success" (click)="showSampleModal(featuredProductList)">Form</button></td>
              <td>
                <i class="fa fa-pencil" (click)="showListUpdateModal(featuredProductList)"></i>
                <i class="fa fa-trash" (click)="showLostDeleteModal(featuredProductList)"></i>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div>
    <i class="fa fa-plus" (click)="showListCreateModal()"></i>
  </div>
</div>
