import {HeadingFilter} from "./heading-filter.model";
import {FilterNode} from "./filter-node.model";

/**
 * Defines request data structure that user send to filter data set
 */
export class SearchRequestEntity {
  filterType: "USE" | "SELL";
  searchingText: string;
  headingFilters: HeadingFilter[];
  filterNodes: FilterNode[];

  constructor(filterType: "USE" | "SELL", searchingText: string, headingFilters: HeadingFilter[], filterNodes: FilterNode[]) {
    this.filterType = filterType;
    this.searchingText = searchingText;
    this.headingFilters = headingFilters;
    this.filterNodes = filterNodes;
  }
}
