<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">{{type}} Deleting</p>
    <span class="icon cs-icon-button" aria-label="close" mat-dialog-close>
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <form class="modal-card-body pt-6"
        id="main-info-modal-card"
        *ngIf="form"
        [formGroup]="form"
        (ngSubmit)="submit()">

    <div class="field">
      <p class="question">Do you really want to delete this {{description}}?</p>
    </div>

    <div class="field is-grouped is-grouped-centered mt-6">
      <div class="control py-2">
        <button mat-dialog-close class="button wide is-unchecked-success has-text-weight-bold is-fullwidth">
          No
        </button>
      </div>
      <div class="control py-2">
        <button type="submit" class="button wide is-success has-text-weight-bold is-fullwidth">
          Yes
        </button>
      </div>
    </div>
  </form>
</div>
