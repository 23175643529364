import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FilesApiService, User, UserPasswordUpdateRequest, UsersApiService, UserUpdateRequest} from '../../../api/cs';
import {LoaderService} from '../../../shared/services/loader.service';
import {environment} from '../../../../environments/environment';
import {ValidatorsService} from "../../../core/util/validators.service";
import {AuthService} from "../../../shared/services/auth.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'cs-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

  @Input()
  form: FormGroup;

  @Input()
  passwordForm: FormGroup;

  @Input()
  user: User | null;

  @Output()
  imageUpdated = new EventEmitter<string>();

  @Output()
  updated = new EventEmitter<UserUpdateRequest>();

  @Output()
  passwordChanged = new EventEmitter<UserPasswordUpdateRequest>();

  private file: File;
  newImageReference: string;
  isUserSubscribed: boolean;
  intentToBeSubscribed: boolean;
  s3DefaultApi = environment.s3DefaultApi;
  s3BucketName = environment.s3BucketName;
  userSubscription: Subscription;

  constructor(private loader: LoaderService,
              private authService: AuthService,
              private userService: UsersApiService) { }

  ngOnInit(): void {
    this.userSubscription = this.authService.userInfo.subscribe(user => {
      this.isUserSubscribed = user?.subscriptionAgreement || false;
      this.intentToBeSubscribed = user?.subscriptionAgreement || false;
    })
  }

  get filename(): string {
    return this.file?.name ?? '';
  }

  get baseUrl(): string {
    return environment.baseUrl;
  }

  submit(): void {
    this.updated.emit(this.form.value as UserUpdateRequest);
  }

  changePassword(): void {
    this.passwordChanged.emit({
      password: this.passwordForm.value.password
    });
    this.passwordForm.reset();
  }

  onlyEnglish($event: KeyboardEvent) {
    ValidatorsService.onlyEnglish($event);
  }

  changeSubscriptionAgreement() {
    this.loader.show();
    this.userService.updateUserSubscriptionAgreement({
      id: this.authService.userInfo.getValue()?.id || '',
      value: this.intentToBeSubscribed
    }).subscribe(user => {
      this.authService.userInfo.next(user);
      this.loader.hide();
    })
  }
}
