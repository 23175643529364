import {Component, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'cs-confirmation-window',
  templateUrl: './confirmation-window.component.html',
  styleUrls: ['./confirmation-window.component.scss']
})
export class ConfirmationWindowComponent implements OnInit {

  @Output()
  confirmed = new BehaviorSubject(false);

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    header: string,
    description: string
  }, private dialogRef: MatDialogRef<ConfirmationWindowComponent>) { }

  ngOnInit(): void {

  }

  confirm(): void {
    this.confirmed.next(true);
    this.dialogRef.close();
  }

  reject(): void {
    this.confirmed.next(false);
    this.dialogRef.close();
  }
}
