import {Component, OnInit} from '@angular/core';
import {Campaign, CampaignsApiService, CampaignState, User} from "../../../api/cs";
import {AuthService} from "../../../shared/services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'cs-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {

  campaigns: Campaign[];
  companyAlias: string | null;
  routedCompanyAlias: string | null;
  user: User | null;
  isAdmin = false;
  showActions: boolean = false;
  showTabs: boolean = false;
  companyName: string;

  activeCampaigns: Campaign[];
  completedCampaigns: (Campaign | undefined)[];
  draftCampaigns: Campaign[];
  hiddenCampaigns: Campaign[];

  constructor(private campaignsService: CampaignsApiService,
              private authService: AuthService,
              private dialog: MatDialog,
              private titleService: Title,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.titleService.setTitle("Campaigns | Cultured Supply");
    this.user = this.authService.userInfo.getValue();
    this.isAdmin = this.authService?.isAdmin();
    this.routedCompanyAlias = this.activatedRoute.snapshot.paramMap.get('company-alias');
    this.companyAlias = this.routedCompanyAlias;
    this.checkIfUserCanEdit();
    if (this.companyAlias !== null) {
      this.getCampaignsByCompany();
    } else {
      this.getCampaignsByUserRole();
    }
  }

  private checkIfUserCanEdit(): void {
    if ((!!this.companyAlias && this.user?.company?.alias === this.companyAlias) || this.isAdmin) {
      this.showActions = true;
      this.showTabs = true;
    }
  }

  private getCampaignsByUserRole() {
    this.authService.userInfo?.subscribe(user => {
      this.companyAlias = user?.company?.alias || '';

      if (this.isAdmin) {
        this.getAllCampaigns();
      } else {
        if (this.routedCompanyAlias) {
          this.getCampaignsByUser(this.user?.id);
        } else {
          this.getCampaignsByUser(undefined);
        }
      }
    });
  }

  private getCampaignsByUser(userId: string | undefined) {
    this.campaignsService.getCampaignsByUser(!!userId ? {userId: userId} : {}).subscribe(
    data => {
      this.setCampaigns(data, false);
      }
    );
  }

  private getAllCampaigns() {
    this.campaignsService.getCampaigns({onlyPublish: false}).subscribe(
      data => this.setCampaigns(data, false)
    );
  }

  private getCampaignsByCompany() {
    this.campaignsService.getCampaignsByCompany({companyAlias: this.companyAlias || ''}).subscribe(
      data => {
        const filterByUser = !this.isAdmin && this.authService.userInfo.value?.company?.alias !== this.companyAlias;
        this.setCampaigns(data, filterByUser);
        this.companyName = data[0]?.company_name || '';
      }
    )
  }

  private setCampaigns(campaigns: Array<Campaign>, filterByUser: boolean) {
    this.activeCampaigns = campaigns.filter(campaign => campaign.state === CampaignState.ACTIVE);
    if (this.showTabs) {
      this.completedCampaigns = campaigns.filter(campaign => campaign.state === CampaignState.ARCHIVED);
      this.draftCampaigns = filterByUser ? [] : campaigns.filter(campaign => campaign.state === CampaignState.DRAFT);
      if (this.isAdmin) {
        this.hiddenCampaigns = filterByUser ? [] : campaigns.filter(campaign => campaign.state === CampaignState.HIDDEN);
      }
    }
  }

  updateCampaignList(newCampaign: Campaign) {
    let filterListByAlias = this.campaigns.filter(campaign => campaign.alias === newCampaign.alias);
    if (filterListByAlias.length > 0) {
      let oldCampaign = filterListByAlias[0];
      oldCampaign.state = newCampaign.state;
    } else {
      this.campaigns.push(newCampaign);
    }
    this.setCampaigns(this.campaigns, false);
  }
}
