<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">Send Buying Request</p>
    <span class="icon cs-icon-button" aria-label="close" (click)="confirmClose()">
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <form class="modal-card-body pt-6"
        *ngIf="form"
        [formGroup]="form"
        (ngSubmit)="submit()">
    <div class="field pt-4 pb-4">
      <label class="label">Name:*</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="John" formControlName="name">
      </div>
    </div>
    <div class="field pb-4">
      <label class="label">Email:*</label>
      <div class="control">
        <input class="input is-medium" type="email" placeholder="example@email.com" formControlName="email">
      </div>
    </div>
    <div class="field pb-4">
      <label class="label">Name of the buying request:*</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="Name" formControlName="requestName">
      </div>
    </div>

    <div class="field pb-4">
      <label class="label">Quantity needed (if known)?:</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="2,000 sq meters" formControlName="quantity">
      </div>
    </div>

    <div class="field pb-4">
      <label class="label">Target price (if known)?:</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="$20/sq meter" formControlName="targetPrice">
      </div>
    </div>

    <div class="field pb-4">
      <label class="label">Message:*</label>
      <div class="control">
        <textarea class="textarea is-medium"
                  placeholder="Some details if needed"
                  formControlName="message"
        ></textarea>
      </div>
    </div>

    <div class="is-flex is-justify-content-center is-flex-grow-1">
      <div class="control py-4">
        <button type="submit" class="button wide is-success has-text-weight-bold is-fullwidth">
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
