<div class="step-main is-flex">
  <div class="step-fields">
    <div class="stripe-header">
      <div class="product-info mt-4">
        <div class="is-flex is-justify-content-space-between payment-modal-top-info">
          <label class="has-text-weight-bold">{{product?.headingInfo?.name}}</label>
        </div>
        <div class="payment-amount">
          <label *ngIf="!submissionRequest.fullUpfrontPayment">Payment Amount: {{ estimatedCost | currency }}</label>
          <label *ngIf="submissionRequest.fullUpfrontPayment">Payment Amount: {{ totalCost | currency }}</label>
        </div>
      </div>
    </div>
    <div class="discount-code-section">
      <label>Have a discount or referral code?</label>
      <div class="is-flex">
        <input class="input" placeholder="Enter it here" [(ngModel)]="discountCode" [disabled]="isDiscountCodeApplied"/>
        <button class="button is-success discount-confirm-button" (click)="checkDiscountCode()" [disabled]="isDiscountCodeApplied">Apply</button>
      </div>
    </div>
    <cs-payment [receiptEmail]="receiptEmail" [paymentAmount]="estimatedCost" (paymentMethod)="paymentMethod.next($event)" (paymentIntentId)="paymentIntentId.next($event)" *ngIf="estimatedCost"></cs-payment>
    <div class="step-buttons">
      <a class="button-previous is-flex" (click)="previousStep()">
        <span class="icon">
          <i class="fa fa-angle-left"></i>
        </span>
        <span>Back</span>
      </a>
      <button class="button button-next wide is-medium is-success has-text-weight-bold"
              (click)="pay()"
              [disabled]="paying"
      >
        Pay
      </button>
    </div>
  </div>
  <div class="side-hint-container">
    <div>
      <div class="side-hint">
        <div class="side-hint__icon">
          <img src="assets/images/order-box.png" />
        </div>
        <p class="side-hint__title mb-3">Order summary</p>
        <div class="summary-item">
          <p class="summary-item__value"><span class="info-item__title">Delivery address:</span> {{submissionRequest?.deliveryAddress}}</p>
        </div>
        <div class="summary-item">
          <p class="summary-item__value"><span class="info-item__title">Order quantity:</span> {{submissionRequest?.quantity | number: '1.0'}} {{orderMeasureShortPluralName}}</p>
        </div>
        <div class="summary-item">
          <p class="summary-item__value"><span class="info-item__title">Total product cost:</span> {{submissionRequest?.orderAmount | currency }}</p>
        </div>
        <div class="summary-item">
          <p class="summary-item__value"><span class="info-item__title">Delivery cost:</span> {{ submissionRequest.shippingCost | currency }}</p>
        </div>
        <div class="summary-item" *ngIf="!submissionRequest.fullUpfrontPayment">
          <p class="summary-item__value has-text-weight-bold"><span class="info-item__title">Pay now:</span> {{estimatedCost | currency }}</p>
        </div>
        <div class="summary-item" *ngIf="!submissionRequest.fullUpfrontPayment">
          <p class="summary-item__value"><span class="info-item__title">Pay later:</span>{{ restAmount | currency }}</p>
        </div>
        <div class="summary-item summary-item-total" *ngIf="!!discountAmount">
          <p class="summary-item__value"><span class="info-item__title">Discount:</span>-{{ discountAmount | currency}}</p>
        </div>
        <div class="summary-item summary-item-total">
          <p class="summary-item__value" [ngClass]="{'has-text-weight-bold' : submissionRequest.fullUpfrontPayment}"><span class="info-item__title">Total cost:</span>{{ totalCost | currency }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
