import { Component, OnInit } from '@angular/core';
import {AttributeConfig, AttributesApiService} from '../../../api/cs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {LoaderService} from '../../../shared/services/loader.service';
import {finalize} from 'rxjs';
import {AttributesService} from '../../../shared/services/attributes.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'cs-attributes-settings',
  templateUrl: './attributes-settings.component.html',
  styleUrls: ['./attributes-settings.component.scss']
})
export class AttributesSettingsComponent implements OnInit {
  productConfigs: AttributeConfig[];
  productForm: FormGroup;

  companyConfigs: AttributeConfig[];
  companyForm: FormGroup;
  tab: 'PRODUCT' | 'COMPANY' = 'PRODUCT';

  constructor(private attributesApiService: AttributesApiService,
              private attributesService: AttributesService,
              private loader: LoaderService,
              private titleService: Title,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.titleService.setTitle("Attributes | Cultured Supply");
    this.attributesApiService.getAttributeConfigs({
      entity: 'product'
    }).subscribe((configs) => {
      this.productConfigs = configs;
      this.productForm = this.buildForm(configs);
    });

    this.attributesApiService.getAttributeConfigs({
      entity: 'company'
    }).subscribe((configs) => {
      this.companyConfigs = configs;
      this.companyForm = this.buildForm(configs);
    });
  }

  buildForm(configs: AttributeConfig[]): FormGroup {
    const form = this.fb.group({});
    configs.forEach((attrConfig) => {
      const control = new FormControl(!attrConfig.hidden);
      if (!attrConfig.optional) {
        control.disable();
      }
      form.addControl(attrConfig.key as string, control);
    });

    return form;
  }

  saveProducts(): void {
    this.loader.show()
    this.attributesApiService.updateAttributeConfigs({
      entity: 'product',
      attributeConfigRequest: this.productConfigs.map(attr => ({key: attr.key, hidden: !this.productForm.getRawValue()[attr.key as string]}))
    }).pipe(finalize(() => this.loader.hide()))
      .subscribe(() => {
        this.attributesService.reload();
      });
  }

  saveCompany(): void {
    this.loader.show()
    this.attributesApiService.updateAttributeConfigs({
      entity: 'company',
      attributeConfigRequest: this.companyConfigs.map(attr => ({key: attr.key, hidden: !this.companyForm.getRawValue()[attr.key as string]}))
    }).pipe(finalize(() => this.loader.hide()))
      .subscribe(() => {
        this.attributesService.reload();
      });
  }
}
