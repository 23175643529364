<div class="modal-background" mat-dialog-close></div>
<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">
      Edit Company Profile
    </p>
    <span class="icon cs-icon-button" aria-label="close" mat-dialog-close>
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
  <form class="modal-card-body pt-6" [formGroup]="form" *ngIf="form" (ngSubmit)="submit()" id="main-info-modal-card">

    <div class="field pb-4">
      <label class="label">Name:*</label>
      <div class="control">
        <input class="input is-medium"
               type="text"
               placeholder="Space X"
               (keypress)="onlyEnglish($event)"
               formControlName="name"
        />
        <span class="tag verified-label ml-3 mb-4"
              *ngIf="(showVerified) && isVerified()">
          Verified
        </span>
      </div>
      <span
        *ngIf="!form.get('name')!.valid && form.get('name')!.touched"
        class="help-block">Enter valid product name</span>
    </div>

    <div class="field pb-4" *ngIf="showTagline">
      <label class="label">Short description*:</label>
      <div class="control">
        <input class="input is-medium"
               type="text"
               placeholder="Tagline"
               (keypress)="onlyEnglish($event)"
               formControlName="tagline"
        />
      </div>
    </div>

    <div class="field pb-4" *ngIf="showSummary">
      <label class="label">About:</label>
      <div class="control">
        <quill-editor formControlName="summary"
                      placeholder="Extended info about the company">
        </quill-editor>
      </div>
    </div>

    <div class="field pb-4" formGroupName="businessTypes" *ngIf="showBusinessType">
      <label class="label">Business Types:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.businessTypes[businessType.key || '']"
             [class.mt-3]="!first"
             *ngFor="let businessType of getBusinessTypes(); let first = first"
      >
        <input type="checkbox" [formControlName]="businessType.key || ''">
        {{businessType.value}}
      </label>
    </div>

    <div class="field pb-4" formGroupName="platforms" *ngIf="showPlatforms">
      <label class="label">Platforms:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.platforms[platform.key || '']"
             [class.mt-3]="!first"
             *ngFor="let platform of getPlatforms(); let first = first"
      >
        <input type="checkbox" [formControlName]="platform.key || ''">
        {{platform.value}}
      </label>
    </div>

    <div class="field pb-4" formGroupName="supplyChains" *ngIf="showSupplyChains">
      <label class="label">Supply Chains:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.supplyChains[chain.key || '']"
             [class.mt-3]="!first"
             *ngFor="let chain of getSupplyChains(); let first = first"
      >
        <input type="checkbox" [formControlName]="chain.key || ''">
        {{chain.value}}
      </label>
    </div>

    <div class="field pb-4" formGroupName="industryFocuses" *ngIf="showIndustryFocuses">
      <label class="label">Industry Focuses:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.industryFocuses[focus.key || '']"
             [class.mt-3]="!first"
             *ngFor="let focus of getIndustryFocuses(); let first = first"
      >
        <input type="checkbox" [formControlName]="focus.key || ''">
        {{focus.value}}
      </label>
    </div>

    <div class="field pb-4" formGroupName="customerFocuses" *ngIf="showCustomerFocuses">
      <label class="label">Customer Focuses:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.customerFocuses[focus || '']"
             [class.mt-3]="!first"
             *ngFor="let focus of getCustomerFocuses(); let first = first"
      >
        <input type="checkbox" [formControlName]="focus || ''">
        {{ focus | toCamelCase }}
      </label>
    </div>

    <div class="field is-grouped is-grouped-centered mt-6">
      <div class="control py-4">
        <button class="button wide is-success has-text-weight-bold is-fullwidth"
           type="submit"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</div>
