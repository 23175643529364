<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">Product Verification Status Change</p>
    <span class="icon cs-icon-button" aria-label="close" (click)="closeWindow()">
    <i class="fa fa-lg fa-times"></i>
  </span>
  </header>
  <section class="modal-card-body pt-6" id="main-info-modal-card">

    <div class="field">
      <p class="question">Do you really want to {{data.newStatusDescription.toLowerCase()}} this product?</p>
    </div>

    <div class="field is-grouped is-grouped-centered mt-6">
      <div class="control py-2">
        <a class="button wide is-unchecked-success has-text-weight-bold is-fullwidth"
           (click)="closeWindow()"
        >
          No
        </a>
      </div>
      <div class="control py-2">
        <a class="button wide is-success has-text-weight-bold is-fullwidth"
           (click)="submit()"
        >
          Yes
        </a>
      </div>
    </div>
  </section>
</div>
