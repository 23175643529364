import { Component, OnInit } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {FaqModalComponent} from "./faq-modal/faq-modal.component";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'cs-creator-led',
  templateUrl: './creator-led.component.html',
  styleUrls: ['./creator-led.component.scss']
})
export class CreatorLedComponent implements OnInit {

  constructor(private dialog: MatDialog,
              private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Cultured Supply | Creator Led");
  }

  openFAQs(): void {
    this.dialog.open(FaqModalComponent, {panelClass: 'custom-dialog-container'});
  }
}
