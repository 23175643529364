/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CompanyAboutUpdateRequest {
    name: string;
    tagline?: string;
    summary?: string;
    region?: Array<string>;
    businessTypes?: Array<string>;
    supplyChains?: Array<string>;
    industryFocuses?: Array<string>;
    customerFocuses?: Array<string>;
    platforms?: Array<string>;
}

