import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../shared/services/auth.service';
import {MatDialog} from "@angular/material/dialog";
import {AuthComponent} from "../components/auth-modal-window/auth.component";

@Injectable({
  providedIn: 'root'
})
export class PlatformUserAuthGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router,
              private dialog: MatDialog) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.isLoggedIn && (this.authService.userInfo.getValue()?.role == "PLATFORM_USER" || this.authService.userInfo.getValue()?.role == "ADMIN")) {
      return true;
    } else {
      this.router.navigate(['/']).then();
      this.dialog.open(AuthComponent, {panelClass: 'custom-dialog-container', data: {mode: 'logIn'}});
      return false;
    }
  }

}
