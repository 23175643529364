import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {AuthService} from '../../../shared/services/auth.service';
import {User, UserPasswordUpdateRequest, UsersApiService, UserUpdateRequest} from '../../../api/cs';
import {UserFormService} from '../../services/user-form.service';
import {FormGroup} from '@angular/forms';
import {LoaderService} from '../../../shared/services/loader.service';
import {finalize, Observable} from 'rxjs';
import {InfoModalWindowComponent} from "../../../core/components/info-modal-window/info-modal-window.component";
import {MatDialog} from "@angular/material/dialog";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'cs-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  private userId: string;
  user: User | null;
  isProfile: boolean;
  form: FormGroup;
  passwordForm: FormGroup;

  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private loader: LoaderService,
              private dialog: MatDialog,
              private titleService: Title,
              private userFormService: UserFormService,
              private usersApiService: UsersApiService) { }

  ngOnInit(): void {
    this.titleService.setTitle("My Profile | Cultured Supply");
    if (this.activatedRoute.snapshot.data['currentProfile']) {
      this.userId = 'me';
      this.isProfile = true;
      this.load();
    } else {
      this.activatedRoute.params.subscribe((params: Params) => {
        this.userId = params['id'];
        this.load();
      });
    }
  }

  load(): void {
    this.loader.show();
    let user$: Observable<User | null>;
    if (this.userId == 'me' && !!this.authService.userInfo) {
      user$ = this.authService.userInfo.asObservable();
    } else {
      user$ = this.usersApiService.getUser({id: this.userId});
    }
    user$.subscribe((res) => {
        this.loader.hide();
        this.user = res;
        this.form = this.userFormService.buildForm(this.user);
        this.passwordForm = this.userFormService.buildPasswordForm();
      });
  }

  updateUser(userUpdateRequest: UserUpdateRequest): void {
    this.loader.show();
    this.usersApiService.updateUser({
      id: this.user?.id || '',
      userUpdateRequest
    }).pipe(finalize(() => this.loader.hide()))
      .subscribe(() => {
        this.authService.updateUserInfo();
    });
  }

  changePassword(userPasswordUpdateRequest: UserPasswordUpdateRequest): void {
    this.loader.show()
    this.usersApiService.updateUserPassword({
      id: this.user?.id || '',
      userPasswordUpdateRequest
    }).pipe(finalize(() => this.loader.hide()))
      .subscribe((res) => {
        this.dialog.open(InfoModalWindowComponent, {
          panelClass: 'custom-dialog-container',
          data: {
            success: true,
            message: "You password was successfully changed."
          }
        });
    });
  }
}
