/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CampaignProgress { 
    currentAmount: number;
    buyersCount: number;
    currentProgress: number;
    leftProgress: number;
    deliveryTime: number;
    template_key?: string;
    paymentTerms?: string;
}

