import {AfterContentInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

declare var hbspt: any;

export class HubSpotForm {
  portalId: string;
  formId: string;
  target: string;
}

@Component({
  selector: 'cs-hubspot-form-modal',
  templateUrl: './hubspot-form-modal.component.html',
  styleUrls: ['./hubspot-form-modal.component.scss']
})
export class HubspotFormModalComponent implements AfterContentInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    title: string;
    form: HubSpotForm;
  }) {}

  ngAfterContentInit() {
    hbspt.forms.create(this.data.form);
  }
}
