import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  Certification,
  CertificationsApiService,
  FilesApiService,
  ProductResponse,
  ProductsApiService
} from '../../../../api/cs';
import {LoaderService} from '../../../../shared/services/loader.service';
import {finalize} from 'rxjs';
import {
  ConfirmationWindowComponent
} from "../../../../core/components/confirmation-window/confirmation-window.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToastService} from "../../../../shared/services/toast.service";
import {FileUploadService} from "../../../../shared/services/file-upload.service";

@Component({
  selector: 'cs-product-certifications-form',
  templateUrl: './product-certifications-form.component.html',
  styleUrls: ['./product-certifications-form.component.scss']
})
export class ProductCertificationsFormComponent implements OnInit {

  form: FormGroup;

  private product: ProductResponse;

  @Output() updated = new EventEmitter<Certification>();
  private file?: File;

  constructor(private fb: FormBuilder,
              private loader: LoaderService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ProductCertificationsFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {product: ProductResponse},
              private fileUploadService: FileUploadService,
              private certificationsApiService: CertificationsApiService) {
  }

  ngOnInit(): void {
    this.product = this.data.product;
    this.file = undefined;
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: new FormControl(null, Validators.required),
      authority: new FormControl(null),
      reference: new FormControl(null)
    });
  }

  confirmClose(): void {
    let confirmation = this.dialog.open(ConfirmationWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        header: "Window Close Confirmation",
        description: "discard changes and close the window"}
    });
    confirmation.beforeClosed().subscribe(() => {
      if (confirmation.componentInstance.confirmed.getValue()) {
        this.dialogRef.close();
      }
    })
  }

  get filename(): string {
    return this.file?.name ?? '';
  }

  submit(): void {
    if (this.form.valid) {
      if (!!this.file) {
        this.handleFiles(this.file).then(async reference => {
          this.sendCertificationRequest(reference);
        });
      } else {
        this.sendCertificationRequest(undefined);
      }
    }
  }

  private sendCertificationRequest(reference: string | undefined) {
    this.loader.show();
    let productId = this.product.id;
    const {name, authority} = this.form.value;
    this.certificationsApiService.createCertification({
      certificationRequest: {
        productId: productId,
        authority,
        name,
        document: reference || ''
      }
    }).pipe(finalize(() => this.loader.setVisible(false)))
      .subscribe((cert) => {
        this.updated.emit(cert);
        this.dialogRef.close();
      });
  }

  onFileChoose(event: Event): void {
    this.file = this.fileUploadService.processFileUpload(event);
  }

  async handleFiles(file: File): Promise<string> {
    return this.fileUploadService.loadFile(false);
  }
}
