<div class="modal-card">
  <header class="modal-card-head">
    <div *ngIf="mode === 'Sign In'" class="back-to-signup" (click)="mode = 'Sign Up'"><img class="mr-2" src="/assets/images/icons/arrow-left.svg" />Signup</div>
    <p class="modal-card-title has-text-centered has-text-weight-bold">Explore the builder - {{mode}}</p>
    <span class="icon cs-icon-button" aria-label="close" [mat-dialog-close]="true">
      <i class="fa fa-lg fa-times"></i>
    </span>
  </header>
<!--  Sign Up -->
  <section class="modal-card-body"
           *ngIf="mode === 'Sign Up' && !!signUpForm">
    <form (submit)="signUp()" [formGroup]="signUpForm">
      <div class="field">
        <label class="label">Email</label>
        <div class="control">
          <input userEmail class="input is-medium" type="text" formControlName="signUpUserEmail" (keypress)="onlyEnglish($event)" placeholder="example@email.com">
        </div>
      </div>
      <div class="field">
        <label class="label">Company name</label>
        <div class="control">
          <input class="input is-medium" type="text" formControlName="companyName" (keypress)="onlyEnglish($event)" placeholder="Space X">
        </div>
      </div>
      <div class="is-flex is-justify-content-center">
        <button type="submit" class="button button-signup is-success has-text-weight-bold">Sign up</button>
      </div>
      <div class="is-flex is-justify-content-center">
        <a class="inherit-color have-account" (click)="mode = 'Sign In'">I already have an account</a>
      </div>
    </form>
  </section>


<!--  Sign in -->
  <section class="modal-card-body"
           *ngIf="mode === 'Sign In' && !!signInForm">
    <form (submit)="signIn()" [formGroup]="signInForm">
      <div class="field">
        <label class="label">Registration email</label>
        <div class="control">
          <input userEmail class="input is-medium" type="text" formControlName="signInUserEmail" (keypress)="onlyEnglish($event)" placeholder="example@email.com">
        </div>
      </div>
      <div class="registration-email-info has-text-centered">
        Enter your registration email, and we’ll send you a login link. Open the mail and click on the link to log in.
      </div>
      <div class="is-flex is-justify-content-center">
        <button type="submit" class="button button-signup is-success has-text-weight-bold">Sign in</button>
      </div>
      <div class="is-flex is-align-items-center is-flex-direction-column">
        <div>Don’t have an account yet?</div>
        <a (click)="mode = 'Sign Up'">Sign up</a>
      </div>
    </form>
  </section>

<!-- Check your email -->
  <ng-container *ngIf="mode === 'Check Your Email'">
    <section class="modal-card-body">
      <div class="has-text-centered mb-5"><img src="/assets/images/icons/email.png" /></div>
      <div class="has-text-centered has-text-weight-bold is-size-4 mb-3">Check your email</div>
      <div class="field has-text-centered">
        We've sent an email to {{userEmail}} with your login link to the Algae Supply Chain Builder. If you don’t see it, check your Spam folder.
      </div>
      <div class="is-flex is-justify-content-center">
        <button class="button button-signup is-success has-text-weight-bold" (click)="resendEmail()">Resend link</button>
      </div>
    </section>
    <div class="issues has-text-centered">
      If you have any issues, please contact us at&nbsp;<a href="mailto:info@culturedsupply.com" class="inherit-color">info@culturedsupply.com</a>
    </div>
  </ng-container>
</div>
