import {Injectable} from "@angular/core";
import {ProductImage} from "../api/cs/model/product-image";
import {FileUploadService} from "../shared/services/file-upload.service";

@Injectable({
    providedIn: 'root'
})
export class ProductImageService {
    constructor(private fileUploadService: FileUploadService) {
    }

    public async processImages(files: File[]): Promise<ProductImage[]> {
        let references: ProductImage[] = [];
        for (let i = 0; i < files.length; i++) {
            let reference: string;
            await this.handleImages(files[i])
                .then(ref => reference = ref)
                .finally(() => references.push(new class implements ProductImage {
                    id: number;
                    product_id: number;
                    reference: string = reference;
                    sequence_number: number = i;
                }));
        }
        return references;
    }

    private async handleImages(file: File): Promise<string> {
        let reference: string;
        this.fileUploadService.processFileUpload(file);
        return await this.fileUploadService.loadFile(true)
            .then(ref => reference = ref)
            .finally(() => reference);
    }
}
