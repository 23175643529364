import {Inject, Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, delay, of, retryWhen, switchMap, timer} from "rxjs";
import {error} from "pdf-lib";
import {DOCUMENT} from "@angular/common";
import {Router} from "@angular/router";
import {AuthService} from "./auth.service";

/*
There are some properties and functions, that Hubspot adds to window. Should add a declaration of those,
that typescript could find those and build the project.
 */
declare global {
  var HubSpotConversations: {
    widget: {
      remove: () => void;
      open: () => void;
      close: () => void;
      refresh: () => void;
      load: (p: { widgetOpen: boolean }) => void;
    };
  };
  var hsConversationsOnReady: [
    () => void
  ];
  var _hsq: [any];
  var hsConversationsLoaded: BehaviorSubject<boolean>;
}

/**
 * Service that know how to load HubSpot LiveChat and operate with it
 */
@Injectable({
  providedIn: 'root'
})
export class HubSpotLiveChatService {

  private _hsq: [any];
  private currentUserEmail: string;
  private currentUrl: string;

  constructor(@Inject(DOCUMENT) private document: Document,
              private router: Router,
              private authService: AuthService) {
    this.loadScript();
    this.router.events.subscribe(() => {
      this.initializeHSQ(this.currentUserEmail, this.router.url);
    });
    this.authService.userInfo.subscribe(user => {
      this.initializeHSQ(user?.email, this.currentUrl);
    });
  }

  /**
   * Loads HubSpot LiveChat to page
   */
  public loadLiveChat(): void {
    this.initializeHSQ(this.currentUserEmail, this.currentUrl);
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations.widget.load({widgetOpen: false});
        }
      ];
    }
  }

  /**
   * Closes LiveChat after page will be closed
   */
  public deleteLiveChat(): void {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.close();
    }
  }

  /**
   * Hides LiveChat button from page
   */
  public hideLiveChat(): void {
    if (hsConversationsLoaded.getValue()) {
      window.document
        .getElementById('hubspot-messages-iframe-container')
        ?.setAttribute("style", "display: none !important");
    } else {
      hsConversationsLoaded.subscribe(value => {
        window.document
          .getElementById('hubspot-messages-iframe-container')
          ?.setAttribute("style", "display: none !important");
      });
    }
  }

  /**
   * Makes LiveChat visible after it was hidden
   */
  public showLiveChat(): void {
    if (hsConversationsLoaded.getValue()) {
      window.document
        .getElementById('hubspot-messages-iframe-container')
        ?.setAttribute("style", "width: 100px; height: 96px;");
    } else {
      hsConversationsLoaded.subscribe(value => {
        window.document
          .getElementById('hubspot-messages-iframe-container')
          ?.setAttribute("style", "width: 100px; height: 96px;");
      });
    }
  }

  private loadScript(): void {
    const node = this.document.createElement('script');
    node.src = `//js.hs-scripts.com/${environment.hotspotTrackingId}.js`;
    node.type = 'text/javascript';
    node.async = true;
    node.defer = true;
    node.id = 'hs-script-loader';
    this.document.getElementsByTagName('head')[0].appendChild(node);
    this.subscribeToHubspotLoadProcess();
  }

  private subscribeToHubspotLoadProcess(): void {
    window.hsConversationsLoaded = new BehaviorSubject<boolean>(false);
    //Emit true to hsConversationsLoaded window variable when hubSpot is loaded
    timer(0).pipe(
      switchMap(() => of(this.document.getElementById('hubspot-messages-iframe-container'))),
      switchMap((value, index) => {
        if (!!value) {
          return of(true);
        } else {
          throw error("Error");
        }
      }),
      retryWhen(errors => errors.pipe(
        delay(500)
      ))
    ).subscribe((value: boolean) => {
      if (value) {
        window.hsConversationsLoaded.next(true);
      }
    });
  }

  private initializeHSQ(newUserEmail: string | undefined, newRouterUrl: string) {
    if (!this._hsq) {
      this._hsq = window._hsq = window._hsq || [];
    }
    this.addParametersToHubspot(newUserEmail, newRouterUrl);
  }

  /*
  The current URL should be added to Single Page Application to tracking page from hubspot.
  Email should be added for identify user by Hubspot and looking of conversations.
   */
  private addParametersToHubspot(newUserEmail: string | undefined, newRouterUrl: string) {
    let hasChanges = false;
    if (this.currentUserEmail === undefined || this.currentUserEmail !== newUserEmail) {
      this.currentUserEmail = newUserEmail || '';
      hasChanges = true;
    }
    if (this.currentUrl === undefined || this.currentUrl !== newRouterUrl) {
      this.currentUrl = newRouterUrl;
      hasChanges = true;
    }
    if (hasChanges) {
      this._hsq.push(['setPath', newRouterUrl]);
      this._hsq.push(['trackPageView']);
      this._hsq.push(['identify', {
        email: newUserEmail
      }]);
    }
  }
}
